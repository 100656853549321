<div class="page_wrapper">
    <div class="heading">Download our app</div>
    <div class="content">For better experience, we would request you to use Parknstays mobile app</div>
    <img src="../../assets/images/mobile_app_download.svg" alt="Download mobile app" class="illustration">
    <button mat-raised-button class="host-button ft_family ft_500 app_store" (click)="onAppStoreClick()">
        <img src="../../assets/images/apple_logo.svg" alt="apple" class="logo"><span>Download now</span>
    </button>
    <button mat-raised-button class="host-button ft_family ft_500 play_store" (click)="onPlayStoreClick()">
        <img src="../../assets/images/google_play_logo.svg" alt="playstore" class="logo"><span>Download now</span>
    </button>
</div>

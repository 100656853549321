import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit, SimpleChanges, Input } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { FilterPopupComponent } from "./popups/filter-popup/filter-popup.component";
import { SortBySheetComponent } from "./popups/sort-by-sheet/sort-by-sheet.component";
import { ApicallService } from "../apicall.service";
import { CookieService } from "ngx-cookie";
import { UtilityService } from "../utility.service";
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { MapViewComponent } from "./map-view/map-view.component";
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: "app-property-search",
  templateUrl: "./property-search.component.html",
  styleUrls: ["./property-search.component.css"],
})
export class PropertySearchComponent implements OnInit {
  data: Array<any> = [];
  value: number = 80;
  highValue: number = 1000;
  loading = true;
  params;
  showGif = false;
  total;
  options: Options = {
    floor: 0,
    ceil: 10000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return "$" + value;
        default:
          return "$" + value;
      }
    },
  };
  gallery: Array<any> = [];
  // search term
  amenities_expanded: boolean = false;
  amenitiesLength;
  initialamenititesLength = 6;
  isMobile: boolean = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 760;
  apiUrl;
  propertyType = [
    {
      name: "Town house",
      is_enabled: false,
    },
    {
      name: "Apartment",
      is_enabled: false,
    },
    {
      name: "Condo",
      is_enabled: false,
    },
    {
      name: "Single Home",
      is_enabled: false,
    },
    {
      name: "Land",
      is_enabled: false,
    },
  ];

  amenities = [];
  room_type_array = [
    {
      name: "King",
      is_enabled: false,
    },
    {
      name: "Master",
      is_enabled: false,
    },
    {
      name: "Private",
      is_enabled: false,
    },
  ];

  page: number = 1;
  itemsPerPage: number = 10;
  toggleSearch = false;
  filterNumBeds: number = 0;
  sort_value: string;
  name: string;
  val: any = [];
  amenFilter = [];
  propTypeFilter = [];
  priceRaFilter = [];
  roomFilterArray = [];
  startRateFilter = [];
  houseRulesFilter = [];
  priceQurey: string;
  index;
  room_type;
  public urlparams: any;
  public check_in: string = "";
  private check_out: string = "";
  public rental_type: string = "";
  private loc: string = "";
  public location_name;
  bedCount = 0;
  bathroomCount = 0;
  guestCount = 0;
  min_range;
  max_range;
  constructor(
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private url: ApicallService,
    private urlQuery: ActivatedRoute,
    public cookie: CookieService,
    public utils: UtilityService
  ) {
    this.urlQuery.queryParams.subscribe((params) => {
      this.urlparams = params;

      this.check_in = params.check_in ? params.check_in : "";
      this.cookie.put(
        "check_in",
        this.utils.returnYYYYMMDDAddformat(
          params.check_in ? params.check_in : new Date(),
          0,
          "days"
        )
      );
      this.rental_type = this.urlparams.rental_type;
      this.loc = this.urlparams.loc;
      this.location_name = this.urlparams.location_name;
      this.location_name = this.loc && this.loc.replace(/%20/g, "");
      this.room_type = this.urlparams.room_type && this.urlparams.room_type;
      if (this.urlparams.room_type) {
        this.room_type_array.forEach((room) => {
          room.is_enabled = false;
          if (room.name === this.room_type) {
            room.is_enabled = false;
            room.is_enabled = true;
          }
        });
      }
      this.getApi("changePage");
      this.clearRoomBeds("");
      this.clearPrice();
      this.amenityClear("");
    });
  }

  // botttom sheet
  openShortBySheet(): void {
    this._bottomSheet.open(SortBySheetComponent);
    const bottomSheetRef = this._bottomSheet.open(SortBySheetComponent);
    bottomSheetRef.afterDismissed().subscribe((dataFromChild) => {
      this.sort_value = dataFromChild;
      this.FiltersApi("");
    });
  }
  getParams = (params: any) => {
    this.getApi("changePage");
  };
  ngOnInit(): void {
    this.url.get("common/amenities", this.params, (res: any) => {
      if (res.success) this.amenities = res.data.amenities;
      this.amenitiesLength = res.data.amenities.length;
    });
  }

  ngOnDestroy() {}
  onPageChange(e, caller) {
    if (e === "before" && this.page > 1) {
      this.page = this.page - 1;
    } else {
      this.page = this.page + 1;
    }
    this.FiltersApi("page");
    window.scroll(0, 0);
  }

  getApi(e) {
    if (e) {
      this.page = 1;
    }

    let url = `common/properties?page=${this.page}&limit=${this.itemsPerPage}&room_type=${this.room_type}&check_in=${this.check_in}&check_out=${this.check_out}&rental_type=${this.rental_type}&loc=${this.loc}`;
    if (!this.check_in) {
      url = url.replace(`&check_in=${this.check_in}`, "");
    }
    if (!this.room_type) {
      url = url.replace(`&room_type=${this.room_type}`, "");
    }
    if (!this.check_out) {
      url = url.replace(`&check_out=${this.check_out}`, "");
    }
    if (!this.rental_type) {
      url = url.replace(`&rental_type=${this.rental_type}`, "");
    }
    if (!this.loc) {
      url = url.replace(`&loc=${this.loc}`, "");
    }
    if (this.sort_value) {
      url += `&sort=${this.sort_value}`;
    }
    if (this.propTypeFilter.length) {
      url += `&property_type=${this.propTypeFilter}`;
    }
    if (this.amenFilter.length) {
      url += `&amenities=${this.amenFilter}`;
    }
    if (this.startRateFilter.length) {
      url += `&ratings=${this.startRateFilter}`;
    }
    this.url.get(url, this.params, (res: any) => {
      if (res) {
        this.loading = false;
      }
      if (res.success) {
        this.total = res.data.total;
        this.data = res.data.properties;
        let price = res.data.properties[0].price.toString();
      } else {
        this.data = [];
      }
    });
  }

  increase(value) {
    if (value === "bed") {
      this.bedCount = this.bedCount + 1;
    } else if (value === "guest") {
      this.guestCount = this.guestCount + 1;
    } else if (value === "bathroom") {
      this.bathroomCount = this.bathroomCount + 1;
    }
    this.FiltersApi("");
  }
  decrease(value) {
    if (value === "bed") {
      this.bedCount = this.bedCount - 1;
      if (this.bedCount < 0) {
        this.bedCount = 0;
      }
    } else if (value === "guest") {
      this.guestCount = this.guestCount - 1;
      if (this.guestCount < 0) {
        this.guestCount = 0;
      }
    } else if (value === "bathroom") {
      this.bathroomCount = this.bathroomCount - 1;
      if (this.bathroomCount < 0) {
        this.bathroomCount = 1;
      }
    }
    this.FiltersApi("");
  }
  showhideSearch() {
    this.toggleSearch = !this.toggleSearch;
  }
  getSliderValue() {
    this.min_range = this.value;
    this.max_range = this.highValue;
    this.FiltersApi("");
  }
  clearRoomBeds(e) {
    this.bathroomCount = 0;
    this.guestCount = 0;
    this.bedCount = 0;
    if (e) this.FiltersApi("");
  }
  // adding filter
  amenitiesFilter(e, temp): void {
    if (e.checked) {
      this.amenFilter.push(temp.amenity_id);
    } else {
      let i = this.amenFilter.findIndex((x: any) => x === temp.amenity_id);
      this.amenFilter.splice(i, 1);
    }
    this.FiltersApi("");
  }
  returnIsAmenityChecked = (id: any) => {
    return this.amenFilter.findIndex((x: any) => x === id) !== -1;
  };
  proTypeFilter(temp): void {
    temp.is_enabled = !temp.is_enabled;
    for (let i = 0; i < this.propTypeFilter.length; i++) {
      var itemval = this.propTypeFilter[i];
      if (itemval == temp.name) {
        this.propTypeFilter.splice(i, 1);
        break;
      }
    }
    if (temp.is_enabled === true) {
      let data = temp.name;
      this.propTypeFilter.push(data);
    }
    this.FiltersApi("");
  }
  roomTypeFilter(temp) {
    temp.is_enabled = !temp.is_enabled;
    for (let i = 0; i < this.roomFilterArray.length; i++) {
      var itemval = this.roomFilterArray[i];
      if (itemval == temp.name) {
        this.roomFilterArray.splice(i, 1);
        break;
      }
    }
    if (temp.is_enabled === true) {
      let data = temp.name;
      this.roomFilterArray.push(data);
    }
    this.FiltersApi("");
  }
  // end of adding filters
  FiltersApi(e) {
    this.loading = true;
    if (!e) {
      this.page = 1;
    }
    let apiUrl: string = `common/properties?page=${this.page}&limit=${this.itemsPerPage}&room_type=${this.roomFilterArray}&amenities=${this.amenFilter}&property_type=${this.propTypeFilter}&sort=${this.sort_value}&check_in=${this.check_in}&check_out=${this.check_out}&rental_type=${this.rental_type}&loc=${this.loc}&bedrooms=${this.bedCount}&bathrooms=${this.bathroomCount}&guests=${this.guestCount}&min_range=${this.min_range}&max_range=${this.max_range}`;
    if (!this.sort_value) {
      apiUrl = apiUrl.replace("&sort=", "");
      apiUrl = apiUrl.replace(`${this.sort_value}`, "");
    }
    if (!this.roomFilterArray.length) {
      apiUrl = apiUrl.replace(`&room_type=${this.roomFilterArray}`, "");
    }
    if (!this.propTypeFilter.length) {
      apiUrl = apiUrl.replace("&property_type=", "");
    }
    if (!this.amenFilter.length) {
      apiUrl = apiUrl.replace("&amenities=", "");
    }
    if (this.bedCount === 0) {
      apiUrl = apiUrl.replace(`&bedrooms=${this.bedCount}`, "");
    }
    if (this.guestCount === 0) {
      apiUrl = apiUrl.replace(`&guests=${this.guestCount}`, "");
    }
    if (this.bathroomCount === 0) {
      apiUrl = apiUrl.replace(`&bathrooms=${this.bathroomCount}`, "");
    }
    if (!this.min_range) {
      apiUrl = apiUrl.replace(`&min_range=${this.min_range}`, "");
    }
    if (!this.max_range) {
      apiUrl = apiUrl.replace(`&max_range=${this.max_range}`, "");
    }
    if (!this.check_in) {
      apiUrl = apiUrl.replace(`&check_in=${this.check_in}`, "");
    }
    if (!this.check_out) {
      apiUrl = apiUrl.replace(`&check_out=${this.check_out}`, "");
    }
    if (!this.rental_type) {
      apiUrl = apiUrl.replace(`&rental_type=${this.rental_type}`, "");
    }
    if (!this.loc) {
      apiUrl = apiUrl.replace(`&loc=${this.loc}`, "");
    }
    this.url.get(apiUrl, this.params, (res) => {
      if (res) {
        this.loading = false;
      }
      if (res.success) {
        this.total = res.data.total;
        this.data = res.data.properties;
      } else {
        this.data = [];
        if (this.data.length === 0) {
          this.showGif = true;
        }
      }
    });
  }
  showMore(expand) {
    if (expand === true) {
      this.initialamenititesLength = this.amenitiesLength;
      this.amenities_expanded = expand;
    } else {
      this.initialamenititesLength = 7;
      this.amenities_expanded = expand;
    }
  }
  // clearing filters
  clearAll() {
    this.PropertyClear("");
    this.amenityClear("");
    this.clearRoomBeds("");
    this.clearPrice();
    this.roomClear("");
    this.FiltersApi("");
  }
  clearPrice() {
    this.min_range = 0;
    this.max_range = 0;
    this.value = 0;
    this.highValue = 10000;
  }
  PropertyClear(e) {
    for (let i = 0; i < this.propertyType.length; i++) {
      let item = this.propertyType[i];
      item.is_enabled = false;
    }
    this.propTypeFilter = [];
    if (!e) this.FiltersApi("");
  }
  amenityClear(e) {
    for (let i = 0; i < this.amenities.length; i++) {
      let item = this.amenities[i];
      item.is_enabled = false;
    }
    this.amenFilter = [];
    if (e) this.FiltersApi("");
  }
  roomClear(e) {
    for (let i = 0; i < this.room_type_array.length; i++) {
      let div = this.room_type_array[i];
      div.is_enabled = false;
    }

    this.roomFilterArray = [];
    if (!e) this.FiltersApi("");
  }
  // end of clearing filters

  onNavigateToViewSearch(propertyId: any) {
    this.router.navigate([`detail/${propertyId}`]);
  }
  OpenMap() {
    this.dialog.open(MapViewComponent, {
      autoFocus: false,
      height: this.width < 480 && "68vh",
      data: {
        property_list: this.data,
        page: this.page,
        limit: this.itemsPerPage,
      },
    });
  }
}

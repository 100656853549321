import { Component, OnInit } from "@angular/core";
import { UtilityService } from "../utility.service";
import { async } from "@angular/core/testing";
import { ApicallService } from "../apicall.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-recommend-contractor",
  templateUrl: "./recommend-contractor.component.html",
  styleUrls: ["./recommend-contractor.component.css"],
})
export class RecommendContractorComponent implements OnInit {
  step = 1;
  toggleSelect = false;
  write_rating_value = 1;
  name = "";
  contact = "";
  email: "";
  button_spin = false;
  selected_category: any = {};
  categoryArray = [];
  booking;
  review = "";
  dummy = [
    {
      name: "option 1",
      enabled: false,
    },
    {
      name: "option 2",
      enabled: false,
    },
    {
      name: "option 3",
      enabled: false,
    },
    {
      name: "option 4",
      enabled: false,
    },
    {
      name: "option 5",
      enabled: false,
    },
  ];
  constructor(
    public utils: UtilityService,
    public url: ApicallService,
    public dialog: MatDialogRef<RecommendContractorComponent>
  ) {}
  ngOnInit(): void {
    this.getAllCategory();
    this.getProperty();
  }

  getProperty() {
    this.url.get("user/property", {}, (res) => {
      if (res.success) {
        this.booking = res.data.booking;
      }
    });
  }
  getAllCategory = async () => {
    await this.url.get(`user/services?status=approved`, {}, (res) => {
      if (res.success) {
        this.categoryArray = res.data.services;
      } else {
        this.utils.openToast("", res);
      }
    });
  };
  selectedOption = (value) => {
    this.selected_category = value;
    this.toggleSelect = false;
    this.checkObject();
  };
  onRate(e) {
    this.write_rating_value = e.newValue;
  }
  handleOnInputChange(e) {
    this.contact = this.utils.getUSFormat(e);
  }
  checkObject = () => {
    return Object.keys(this.selected_category).length === 0;
  };
  onFinish = () => {
    this.button_spin = true;
    let checkListArray: any = [];
    checkListArray = this.dummy
      .filter((x) => x.enabled)
      .map((item) => item.name);
    let data = {
      name: this.name,
      phone: {
        country_code: "91",
        national_number: this.utils.replaceToNormalNumber(this.contact),
      },
      email: this.email,
      property: {
        name: this.booking.property.name,
        id: this.booking.property.id,
        rental_type: this.booking.property.rental_type,
      },
      category: {
        id: this.selected_category.service_id,
        name: this.selected_category && this.selected_category?.name,
      },
      rating: this.write_rating_value,
      review: this.review,
      suggestions: checkListArray,
    };
    this.url.post(`user/recommend`, data, {}, (res) => {
      this.button_spin = false;
      if (res.success) {
        this.utils.openToast("", res);
        this.dialog.close();
      } else {
        this.utils.openToast("", res);
      }
    });
  };
  onStepChange = () => {
    let nameRegex = /^[a-zA-Z ]*$/;
    var eRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.step === 1) {
      ``;
      if (this.email && !eRegex.test(this.email)) {
        this.utils.openToast("Please provide valid  email", {});
      } else if (this.contact && this.contact.length < 16) {
        this.utils.openToast("Please provide valid  phone number", {});
      } else if (!this.email && !this.contact) {
        this.utils.openToast("Please provide email or phone number", {});
      } else {
        this.step = 2;
      }
    } else if (this.step === 2) {
      if (Object.keys(this.selected_category).length === 0) {
        this.utils.openToast("Please select a category", {});
      } else {
        this.step = 3;
      }
    } else {
      this.onFinish();
    }
  };
}

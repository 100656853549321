<div class="h_100 main_content_sideBar">
  <div class="payment_header">
    <p class="_m ft_family ft_600 ft_16">Recommend Contractor</p>
    <div class="close_btn_holder cursor_pointer" (click)="this.dialog.close()">
      <i class="fa fa-close"></i>
    </div>
  </div>
  <div class="content_holder">
    <div class="step_1" *ngIf="step === 1">
      <img src="../../assets//newIcons/step_1.svg" />
      <div class="mt_30">
        <p class="ft_18 ft_500 mb_10">Basic Details</p>
        <p class="sub_content_color ft_14 ft_400">
          Please share the basic details of service pro
        </p>
      </div>
      <div class="mt_30">
        <div class="mb_30">
          <p class="ft_14 ft_400 mb_12 heading">Name</p>
          <input class="input_contractor" [(ngModel)]="name" />
        </div>
        <div class="mb_30">
          <p class="ft_14 ft_400 mb_12 heading">Phone Number</p>
          <input
            class="input_contractor"
            [ngModel]="contact"
            maxlength="16"
            (ngModelChange)="handleOnInputChange($event)"
          />
        </div>
        <div class="mb_30">
          <p class="ft_14 ft_400 mb_12 heading">Email</p>
          <input class="input_contractor" [(ngModel)]="email" />
        </div>
        <div class="button_container">
          <button
            mat-flat-button
            class="next_button ft_family ft_500 ft_18"
            (click)="onStepChange()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div class="step_1" *ngIf="step === 2">
      <img src="../../assets//newIcons/step_2.svg" />
      <div class="mt_30">
        <p class="ft_18 ft_500 mb_10">Select the work Category</p>
        <p class="sub_content_color ft_14 ft_400">
          Please select which category the service pro worked
        </p>
      </div>
      <div class="mt_30">
        <div class="mb_30">
          <div class="relative">
            <p class="ft_14 ft_400 mb_15">Main Category</p>
            <div
              class="select_div ft_family ft_14 ft_400 sub_content_color cursor_pointer"
              (click)="this.toggleSelect = !this.toggleSelect"
            >
              {{ checkObject() ? "Select" : selected_category?.name }}
              <i class="fa fa-angle-down arrow"></i>
            </div>
            <div
              class="selected_option_div cursor_pointer"
              *ngIf="toggleSelect"
            >
              <div
                *ngFor="let item of categoryArray"
                class="select_customized_options cursor_pointer"
                (click)="selectedOption(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="button_container mt_60">
          <button
            mat-flat-button
            class="back_button ft_family ft_500 ft_18"
            (click)="this.step = 1"
          >
            Back
          </button>
          <button
            mat-flat-button
            class="next_button ft_family ft_500 ft_18"
            (click)="onStepChange()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <!-- step 3 -->
    <div class="step_1" *ngIf="step === 3">
      <img src="../../assets//newIcons/step_3.svg" />

      <div class="mt_30">
        <div class="mb_30">
          <div class="relative">
            <p class="ft_14 ft_400 mb_15">Selected Property</p>

            <div
              class="select_div ft_family ft_14 ft_400 sub_content_color cursor_pointer"
            >
              {{ this.booking.property.name }}
              <i class="fa fa-angle-down arrow"></i>
            </div>
          </div>
        </div>
        <div class="button_container mt_60">
          <button
            mat-flat-button
            class="back_button ft_family ft_500 ft_18"
            (click)="this.step = 2"
          >
            Back
          </button>
          <button
            mat-flat-button
            class="next_button ft_family ft_500 ft_18"
            (click)="this.step = 4"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <!-- step 4 -->
    <div class="step_1" *ngIf="step === 4">
      <img src="../../assets//newIcons/step_4.svg" />
      <div class="mt_30">
        <p class="ft_18 ft_500 mb_10">Rate Service Pro</p>
      </div>
      <div class="d_flex_align_justify mb_40">
        <star-rating
          [value]="write_rating_value"
          [totalstars]="5"
          checkedcolor="#077E8B"
          uncheckedcolor="#e1e1e1"
          size="42px"
          space="between"
          class="star_rating"
          readonly="false"
          (rate)="onRate($event)"
        ></star-rating>
      </div>
      <div class="">
        <div class="mb_30">
          <div class="relative">
            <p class="ft_14 ft_400 mb_15">Your Review on Service Pro</p>
            <textarea
              class="review_text_area"
              [(ngModel)]="review"
              maxlength="300"
            ></textarea>
          </div>
        </div>
        <p class="ft_14 ft_500 mb_15">Suggestions</p>
        <div class="check_container">
          <div
            class="check_box_container mb_10 mr_15"
            *ngFor="let item of dummy; index as i"
          >
            <mat-checkbox
              class="checkbox ft_13 ft_family mr_15 ft_400"
              [checked]="item.enabled"
              (change)="dummy[i].enabled = !item.enabled"
            ></mat-checkbox>
            <p class="ft_13 ft_family ft_400">
              {{ item.name }}
            </p>
          </div>
        </div>

        <div class="button_container mt_60">
          <button
            mat-flat-button
            class="back_button ft_family ft_500 ft_18"
            (click)="this.step = 3"
          >
            Back
          </button>
          <button class="next_button d_flex ft_500 ft_18" (click)="onFinish()">
            <div class="button_spinner mr_15" *ngIf="button_spin">
              <mat-spinner class="mr_10" [diameter]="20"></mat-spinner>
            </div>
            <span>Finish</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

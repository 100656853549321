import { Component, OnInit } from "@angular/core";
import { ApicallService } from "../../apicall.service";
import { Config } from "src/app/constant";
import { Router } from "@angular/router";
import * as moment from "moment";
import { UtilityService } from "src/app/utility.service";
import { CookieService } from "ngx-cookie";
import { CookieServiceService } from "../../cookie-service.service";
import { DashboardComponent } from "../../../app/components/dashboard/dashboard.component";
const propertyManagers = [
  {
    image: "../../../assets/newIcons/manage_prop.svg",
    header: "Manage All properties",
    content:
      "Parknstays is the Swiss Army Knife of property management. We do it all, helping landlords run a seamless operation.",
  },
  {
    image: "../../../assets/newIcons/maintenance_home.svg",
    header: "Schedule Maintenance",
    content:
      "Scheduling of property maintenance requests on a large scale level to keep your customers happy.",
  },
  {
    image: "../../../assets/newIcons/payments_home.svg",
    header: "Payments",
    content:
      "Track all your payments in one place to/from tenants, mortgages, taxes, insurances, utility bills, repairs, and more.",
  },
  {
    image: "../../../assets/newIcons/tenants_home.png",
    header: "Manage Tenants",
    content:
      "Manage all your tenants activities at one place and communicate with them directly via a messaging service",
  },
];
const landloards = [
  {
    image: "../../../assets/newIcons/house_owner.svg",
    header: "Manage All properties",
    content:
      "Parknstays is designed to make a landlord’s life easy. Vet, approve, receive rents, and communicate with your tenants and handymen through our portal. ",
  },
  {
    image: "../../../assets/newIcons/reporting_accounting.svg",
    header: "Reporting and Accounting",
    content:
      "Real-time performance insights are available 24/7 to maximize your property’s performance on an easy-to-read dashboard.",
  },
  {
    image: "../../../assets/newIcons/e_sign_doc.svg",
    header: "E- Sign Documents",
    content:
      "No need for printouts and paper or in-person meetings. E-Signing is painless and convenient.",
  },
  {
    image: "../../../assets/newIcons/payments_home.svg",
    header: "Online Payments",
    content:
      "Pay all your property-related expenses directly from the portal, linked to your bank account, with the payments logged on your dashboard and spreadsheets.",
  },
];
const contractors = [
  {
    image: "../../../assets/newIcons/portfolio.svg",
    header: "Portfolio",
    content:
      "A place where you can add and change all your work informations on the service pro's Dashboard.",
  },
  {
    image: "../../../assets/newIcons/tenants_home.png",
    header: "Manage Requests",
    content:
      "Maintenance ticketing gives your tenants peace of mind and handymen a clear understanding of when repairs are needed.",
  },
  {
    image: "../../../assets/newIcons/instant_payouts.svg",
    header: "Instant Payments",
    content:
      "Get your payments done instantly once you complete the maintenance ticket.",
  },
  {
    image: "../../../assets/newIcons/analysis_report.svg",
    header: "Essential Reporting",
    content:
      "Real-time performance insights are available 24/7 to maximize your property’s performance on an easy-to-read dashboard.",
  },
];
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  // to check is device is mobile
  isMobile: boolean = false;
  height: number = window.innerHeight;
  response;
  latest_property;
  room_type;
  mobileWidth: number = 760;
  public data: Array<any> = [];
  public availablePropertys: any[] = [];
  public checkInDate = moment(new Date()).format("YYYY-MM-DD");
  public checkOutDate = moment(new Date()).add(1, "M").format("YYYY-MM-DD");
  hostUrl = Config.HOST_URL;
  constructorUrl = Config.CONTRACTOR_URL;
  width: number = window.innerWidth;
  params;
  tabValue = "1";
  show_cookie = false;
  roleArray = propertyManagers;
  constructor(
    private router: Router,
    private url: ApicallService,
    private utils: UtilityService,
    public cookieService: CookieService,
    public cokie: CookieServiceService,
    public dashboard: DashboardComponent
  ) {}

  ngOnInit(): void {
    if (this.cokie.isLoggedIn()) {
      this.dashboard.getNotifications();
    }
    let accept_cookies = localStorage.getItem("enable_cookies");

    this.url.get("common/dashboard", this.params, (res) => {
      if (res.success) {
        this.data = res.data.featured;
        this.response = res.data;
        this.latest_property = res.data.latest_property;
      } else {
      }

      if (accept_cookies) {
        this.show_cookie = false;
      } else {
        this.show_cookie = true;
      }
    });
    const loadFirst: {
      checkInDate: string;
      checkOutDate: string;
      typeOfTenant: string;
    } = {
      checkInDate: this.convertDateIntoString(
        moment(new Date()).format("YYYY-MM-DD")
      ),
      checkOutDate: this.convertDateIntoString(
        moment(new Date(new Date().setMonth(7))).format("YYYY-MM-DD")
      ),
      typeOfTenant: "private",
    };
  }

  rentalSelect(value) {}
  convertDateIntoString(date: any): string {
    return moment(date).format("YYYY-MM-DD");
  }
  host() {
    window.open(this.hostUrl, "_blank");
  }
  redirect() {
    if (this.tabValue === "1" || this.tabValue === "2") {
      window.open(this.hostUrl, "_blank");
    } else {
      window.open(this.constructorUrl, "_blank");
    }
  }
  activeTab(tabValue) {
    this.tabValue = tabValue;
    tabValue === "1"
      ? (this.roleArray = propertyManagers)
      : tabValue === "2"
      ? (this.roleArray = landloards)
      : (this.roleArray = contractors);
  }
  onNavigateToViewSearch(propertyId: any) {
    this.router.navigate(["detail", propertyId]);
  }

  allowCookies() {
    localStorage.setItem("enable_cookies", "accept");
    this.show_cookie = false;
  }
}

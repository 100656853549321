<div class="card_box" style="width: 440px">
  <div
    *ngIf="loader === true"
    class="d_flex_align_justify"
    style="flex-direction: column"
  >
    <mat-spinner diameter="80"></mat-spinner>
    <p class="mt_20 mb_20"></p>
    <p class="ft_family sub_content_color ft_18 align_center">
      Please wait while your transaction is being processed
    </p>
  </div>
  <div
    class="icon icon--order-success svg d_flex_align"
    style="flex-direction: column"
    *ngIf="loader === false && paid_status === 'paid'"
  >
    <lottie-player
      autoplay
      mode="normal"
      src="../../../assets/payment_status/lf20_nus9odlt.json"
      style="width: 150px"
    >
    </lottie-player>
    <p class="mb_20 ft_family ft_500 ft_30 sub_content_color mt_20">
      Payment Successful
    </p>
    <p class="money mb_30 ft_family ft_30 ft_600">
      {{ this.utils.displayPrice(response.amount) }}
    </p>
    <p
      *ngIf="response.t_type !== 'deposit'"
      class="mb_30 ft_family ft_16 ft_400 sub_content_color align_center"
    >
      Your booking has been confirmed. Check your email for your booking
      confirmation.
    </p>
    <p
      *ngIf="response.t_type === 'deposit'"
      class="mb_30 ft_family ft_16 ft_400 sub_content_color align_center"
    >
      Your deposit payment has been received successfully.
    </p>
    <div class="d_flex_space_align mb_20 w_100">
      <p class="ft_family ft_16 ft_500 sub_content_color w_50">Payment Type</p>
      <p class="ft_family ft_16 ft_400 sub_content_color w_50 align_right">
        {{ response.t_type }}
      </p>
    </div>
    <div class="d_flex_space_align w_100">
      <p class="ft_family ft_16 ft_500 sub_content_color w_50">
        Transaction Id
      </p>
      <p class="ft_family ft_16 ft_400 sub_content_color w_50 align_right">
        {{ response.transaction_id }}
      </p>
    </div>
  </div>
  <div
    class="icon icon--order-success svg d_flex_align"
    style="flex-direction: column"
    *ngIf="!loader && paid_status === 'pending'"
  >
    <lottie-player
      autoplay
      mode="normal"
      src="../../../assets/payment_status/95614-error-occurred.json"
      style="width: 150px"
    ></lottie-player>
    <p class="mb_20 ft_family ft_500 ft_30 sub_content_color mt_20">
      Payment Failed
    </p>
    <p class="mb_30 ft_family ft_16 ft_400 sub_content_color align_center">
      Your Payment was not successfully processed. Please try again after
      sometime.
    </p>
  </div>

  <div
    class="icon icon--order-success svg d_flex_align"
    style="flex-direction: column"
    *ngIf="!loader && paid_status === 'processing'"
  >
    <lottie-player
      autoplay
      mode="normal"
      src="../../../assets/payment_status/processingLottie.json"
      style="width: 150px"
    ></lottie-player>
    <p class="mb_20 ft_family ft_500 ft_30 sub_content_color mt_20">
      Payment Processing
    </p>
    <p class="mb_30 ft_family ft_16 ft_400 sub_content_color align_center">
      Your Payment is being processed. Will update your invoice automatically.
    </p>
  </div>
</div>

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: "app-request-status",
  templateUrl: "./request-status.component.html",
  styleUrls: ["./request-status.component.css"],
})
export class RequestStatusComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<RequestStatusComponent>
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.dialog.close();
    }, 3000);
  }
}

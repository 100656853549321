<div class="history_main_container">
  <div class="payment_header">
    <p class="_m ft_family ft_600 ft_16">Security Deposit {{ data.title }}</p>
    <div class="close_btn_holder cursor_pointer" (click)="this.dialog.close()">
      <i class="fa fa-close"></i>
    </div>
  </div>
  <div class="body">
    <div class="container_holder" *ngFor="let item of data.data">
      <div class="individual_detail" [ngClass]="item.msg && 'add_height'">
        <div [ngStyle]="{'display':data.title === 'History' ? 'flex' : ''}"
          class="detail_content">
          <div class="round"></div>
          <div class="content_1">
            <div>
              <div class="content_box ft_15 ft_400 mb_12" *ngIf="item.msg">
                {{ item.msg }}
              </div>
              <div
                [ngStyle]="{'margin-bottom':data.title === 'History'?'':'45px'}"
                class="content_box ft_15 ft_400">
                {{ this.utils.defaultDate(item.date) }}
              </div>
              <div class="content_box ft_15 ft_400" style="margin-bottom: 45px;" *ngIf="item.amount">
                {{ this.utils.displayPrice(item.amount) }}
              </div>
            </div>

          </div>
          <div *ngIf="data.title === 'History'" class="ft_15 ft_500 status">
            Paid
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button_close body">
    <button
      class="primary_common_button rounded_4 d_flex_align_justify p_5"
      (click)="this.dialog.close()">
      Close
    </button>
  </div>
</div>

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApicallService } from "../apicall.service";
@Component({
  selector: "app-pay-method",
  templateUrl: "./pay-method.component.html",
  styleUrls: ["./pay-method.component.css"],
})
export class PayMethodComponent implements OnInit {
  total = 0;
  commissionAmount;
  act_commissionAmount;
  commission_data: any;
  loader = false;Total
  cardPaymentAmount;
  extraCharges;
  constructor(
    public dialog: MatDialogRef<PayMethodComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public url: ApicallService
  ) {}
  payment_method = "us_bank_account";
  
  ngOnInit(): void {
    this.getCardPercentage(
      this.data.is_non_customer
        ? "auth/contractor/commission"
        : "user/commission"
    );
  }

  calculateBankCharge = () => {};

  calculateAchAmount = () => {
    if (this.total < this.commission_data?.ach?.amount) {
      this.total =
        this.data.invoiceAmount + this.commission_data.ach?.commission;
    } else {
      this.total = this.data.invoiceAmount;
    }
  };

  getCardPercentage = async (url) => {
    this.loader = true;
    this.url.get(url, "", (res) => {
      if (res.success) {
        this.loader = false;
        this.commission_data = res.data.commission;
        this.total = this.data.invoiceAmount;
        this.commissionAmount = this.commission_data.card?.commission;
        this.calculateAchAmount();
        this.act_commissionAmount = this.commission_data.ach?.commission;
        this.extraCharges =
          this.data.invoiceAmount +
          (this.data.invoiceAmount * this.commissionAmount) / 100 +
          0.3;
        this.cardPaymentAmount =
          this.extraCharges - this.data.invoiceAmount.toFixed(2);
          if(this.act_commissionAmount < this.cardPaymentAmount) {
            this.payment_method = 'us_bank_account'
            this.total = (this.data.invoiceAmount + (this.total < this.act_commissionAmount ? this.act_commissionAmount : 0)).toFixed(2)
          } else  {
            this.payment_method = 'card'
            this.total = (this.data.invoiceAmount + (this.extraCharges - this.data.invoiceAmount.toFixed(2))).toFixed(2)
          }
      } else {
      }
    });
  };

  closePopUp = () => {
    this.dialog.close();
  };

  radioChange = (e) => {
    this.payment_method = e.value;
    if (e.value === "card") {
      this.total = (
        this.data.invoiceAmount +
        (this.data.invoiceAmount * this.commissionAmount) / 100 +
        0.3
      ).toFixed(2);
    } else {
      this.calculateAchAmount();
    }
  };

  returnCharges = () => {
    return (this.extraCharges - this.data.invoiceAmount).toFixed(2);
  };

  proceedPayment = () => {
    this.dialog.close({
      data: this.payment_method,
    });
  };
  
}

<mat-spinner *ngIf="loading" class="spinner" [diameter]="50"></mat-spinner>
<div *ngIf="!loading">
  <div class="d_flex_space_align mb_30">
    <h2 class="ft_family ft_500 ft_18 _m">Invoices</h2>
  </div>
  <div class="d_flex_space_align mb_30 res_filters_div">
    <div class="container_filter">
      <mat-form-field
        appearance="outline"
        class="select_filter arrow_position mr_20 res_filter">
        <mat-label>Date </mat-label>
        <mat-select
          class="arrow_position"
          (selectionChange)="filterValue($event.value)"
          [(value)]="filter_value">
          <mat-option value="this_month">This Month</mat-option>
          <mat-option value="last_month">Last Month</mat-option>
          <mat-option value="this_year">This Year</mat-option>
          <mat-option value="custom_date">Custom Range</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="filters_div" *ngIf="show_filters">
        <mat-form-field appearance="outline" class="select_filter">
          <mat-label>From</mat-label>
          <input
            (ngModelChange)="changeCheckDate($event, 'from_date')"
            matInput
            [matDatepicker]="picker1"
            [ngModel]="from_date"
            [max]="today"
            readonly />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="filters_div" *ngIf="show_filters">
        <mat-form-field appearance="outline" class="select_filter">
          <mat-label>To</mat-label>
          <input
            (ngModelChange)="changeCheckDate($event, 'to_date')"
            matInput
            [matDatepicker]="picker2"
            [ngModel]="to_date"
            [min]="minTo"
            [max]="today"
            readonly />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div>
      <div
        style="display: flex; justify-content: flex-end"
        class="paginator"
        *ngIf="!loading">
        <button
          [disabled]="page <= 1"
          [ngClass]="page <= 1 ? 'inactive_button' : 'active_button'"
          (click)="onPageChange('before')">
          <i class="fa fa-angle-left" style="font-size: 24px"></i>
        </button>
        <button
          [disabled]="list.length < limit"
          [ngClass]="list.length < limit ? 'inactive_button' : 'active_button'"
          (click)="onPageChange('after')">
          <i class="fa fa-angle-right" style="font-size: 24px"></i>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!pageLoading else loadingTemplate">
    <div class="table-container" *ngIf="list">
      <div *ngIf="!loading" class="table_div">
        <table class="profile_table">
          <tr class="profile_table_header">
            <th style="padding-left: 2rem">Invoice Id</th>
            <th>Date</th>
            <th>Property</th>
            <th>Amount</th>
            <th>Status</th>
            <th>
              <div *ngIf="pendingAmount" (click)="payNow(pendingAmount)" class="payOveraLL">
                Pay Overall
              </div>
            </th>
          </tr>
          <tr
            class="profile_table_border"
            *ngFor="let item of list; let i = index">
            <td
              class="cursor_pointer"
              style="padding-left: 32px; width: 30%"
              (click)="openBill(item)">
              {{ item.invoice_id }}
            </td>
            <td>
              {{ utils.dateUtc(item.createdAt) }}
            </td>
            <td class="capitalize">{{ item.property.name }}</td>

            <td>{{ utils.displayPrice(item.amount) }}</td>
            <td
              class="capitalize"
              [ngClass]="
                item.status === 'created'
                  ? 'status_created'
                  : item.status === 'paid'
                  ? 'status_paid'
                  : item.status === 'cancelled'
                  ? 'status_cancelled'
                  : 'status_processing'
              ">
              {{ item.status }}
            </td>

            <td class="d_flex_align_justify">
              <div *ngIf="item.status === 'created'">
                <button class="invoice_pay" (click)="payNow(item)">Pay</button>
              </div>
            </td>
          </tr>
          <tr class *ngIf="list && list.length === 0">
            <td
              style="padding-left: 32px"
              class="ft_family ft_16 ft_400 mb_15 sub_content_color">
              No Invoices Available
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
  </ng-template>

</div>

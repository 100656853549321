import { Component, OnInit } from "@angular/core";
import { UtilityService } from "src/app/utility.service";
import { LoginSignupDialogComponent } from "../login-signup-dialog.component";
import { ApicallService } from "src/app/apicall.service";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
@Component({
  selector: "app-email-invite",
  templateUrl: "./email-invite.component.html",
  styleUrls: ["./email-invite.component.css"],
})
export class EmailInviteComponent implements OnInit {
  email;
  params;
  constructor(
    public utils: UtilityService,
    public url: ApicallService,
    public dialog: MatDialogRef<EmailInviteComponent>,
    public box: MatDialog
  ) {}

  ngOnInit(): void {}
  submit() {
    var eRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.email === "" || this.email === undefined) {
      this.utils.openToast("Please enter the email address", {});
    } else if (!eRegex.test(this.email)) {
      this.utils.openToast("Please enter valid email address", {});
    } else {
      let data = {
        email: this.email,
      };
      this.url.post("auth/user/forgot_password", data, this.params, (res) => {
        if (res.success) {
          this.utils.openToast("", res);
          this.dialog.close();
        } else {
          this.utils.openToast("", res);
        }
      });
    }
  }
  reRoute() {
    this.dialog.close();
    this.box.open(LoginSignupDialogComponent);
  }
}

<div class="history_main_container">
  <mat-spinner *ngIf="loading" class="spinner" [diameter]="50"></mat-spinner>
  <div *ngIf="!loading">
    <div class="payment_header">
      <p class="_m ft_family ft_600 ft_16">Security Deposit</p>
      <div
        class="close_btn_holder cursor_pointer"
        (click)="this.depositeDialog.close()"
      >
        <i class="fa fa-close"></i>
      </div>
    </div>
    <div class="body">
      <div class="total_amount mb_20">
        <p class="content_box ft_15 ft_500 mb_12">
          <!-- {{ this.utils.displayPrice(item.amount) }} -->
          Total Security Amount
        </p>
        <p class="content_box ft_15 ft_500 mb_12">
          {{
            this.utils.displayPrice(
              deposite_data.overall_amount +
                (deposite_data.late_fee_amount
                  ? deposite_data.late_fee_amount
                  : 0)
            )
          }}
        </p>
      </div>
      <div class="total_amount">
        <p class="content_box ft_15 ft_500 mb_12">
          <!-- {{ this.utils.displayPrice(item.amount) }} -->
          Total Installments
        </p>
        <p class="content_box ft_15 ft_500 mb_12">
          {{ deposite_data.installments }}
        </p>
      </div>
      <div class="payment_types">
        <div class="d_flex_space_align mb_40">
          <mat-radio-button
            [ngClass]="
              payment_method === 'full' ? 'active_radio' : 'inactive_radio'
            "
            class="payment_radio"
            [checked]="payment_method === 'full'"
            (change)="radioChange($event)"
            value="full"
          >
            <div class="option_with_amount">
              <div>
                <div style="display: flex; align-items: center">
                  <p class="ft_600 ft_family ft_16 mr_10">Full Payment</p>
                </div>
              </div>
            </div>
          </mat-radio-button>
          <div>
            <p class="ft_500">
              {{ this.utils.displayPrice(deposite_data.pending_amount) }}
            </p>
          </div>
        </div>
        <div class="mb_40" *ngIf="deposite_data.pending_installments > 1">
          <mat-radio-button
            [ngClass]="
              payment_method === 'partial' ? 'active_radio' : 'inactive_radio'
            "
            class="payment_radio"
            [checked]="payment_method === 'partial'"
            (change)="radioChange($event)"
            value="partial"
          >
            <div class="option_with_amount">
              <div>
                <div style="display: flex; margin-bottom: 10px">
                  <p class="ft_600 ft_family ft_16">
                    {{
                      deposite_data.current_installement === 1
                        ? "Partial Payment"
                        : "Pay " +
                          getValue(deposite_data.current_installement) +
                          " installment"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </mat-radio-button>
          <div class="ft_family d_flex_space_align ft_13 ft_400">
            <div class="sub_content" style="margin-left: 29px">
              Installments pay of (
              {{ deposite_data.pending_amount }} /
              {{ deposite_data.pending_installments }} )
            </div>
            <p class="ft_14 ft_500">
              {{ this.utils.displayPrice(deposite_data.installment_amount) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt_40 button_close">
      <button
        class="primary_common_button rounded_4 d_flex_align_justify p_9"
        (click)="OpenPaymentType()"
      >
        Pay Now
      </button>
    </div>
  </div>
</div>

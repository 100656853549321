<div class="search_bar_container d_flex_align_justify relative mb_20">
  <div class="search_box d_flex">
    <div class="search-loc relative">
      <input
        type="text"
        class="ft_family ft_14"
        name="location_name"
        [(ngModel)]="location_name"
        placeholder="Where are you moving ?"
        (input)="locationChange()"
        autocomplete="off"
      />
    </div>
    <div
      class="search-room search-loc relative"
      (click)="showOptions(1, $event)"
    >
      <p class="ft_16">
        {{
          rental_type === "entire_home"
            ? "Entire Home"
            : rental_type === "by_room" && "By Room"
        }}
      </p>
      <i class="fa fa-angle-down"></i>
    </div>
    <div class="options_div" *ngIf="show_options">
      <p
        class="option cursor_pointer"
        (click)="rentalType('entire_home')"
        (click)="showOptions(2, $event)"
      >
        Entire Home
      </p>
      <p class="option cursor_pointer" (click)="rentalType('by_room')">
        By Room
      </p>
    </div>
    <div class="search_div">
      <button class="search_button ft_family ft_16" (click)="filterProperty()">
        <p>{{ width < 480 ? "" : "Explore Now" }}</p>
        <i
          class="fa fa-search ft_16"
          style="color: white; margin-bottom: 4px"
        ></i>
      </button>
    </div>
  </div>
  <div class="location_dropdown" id="location">
    <div class="location_dropdown_container">
      <div class="location_content">
        <div
          class="content d_flex_align cursor_pointer"
          *ngFor="let item of locationArray"
          (click)="getLocation(item)"
        >
          <i class="material-icons mr_10" style="color: #c6b7b7">location_on</i>
          <p class="_m">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main" id="profile-main">
  <div class="main_container">
    <div class="side_bar" *ngIf="width > 480">
      <div class="sticky_div">
        <div class="top_side_bar mb_30">
          <div
            class="logo_container d_flex_align_start"
            (click)="tabChange('profile')"
            [ngClass]="tabValue === 'profile' ? 'active_tab' : 'inactive_tab'"
          >
            <div class="icon_width">
              <img
                *ngIf="tabValue !== 'profile'"
                class="label_logo inactive_logo mr_15"
                src="../../../assets/newIcons/profile.svg"
                alt="icon"
              />
              <img
                *ngIf="tabValue === 'profile'"
                class="label_logo mr_15"
                src="../../../assets/newIcons/activeProfile.svg"
                alt="icon"
              />
            </div>

            <span> My Profile</span>
          </div>
          <div
            class="logo_container d_flex_align_start"
            (click)="tabChange('bookings')"
            [ngClass]="tabValue === 'bookings' ? 'active_tab' : 'inactive_tab'"
          >
            <div class="icon_width">
              <img
                *ngIf="tabValue !== 'bookings'"
                class="label_logo inactive_logo mr_15"
                src="../../../assets/newIcons/booking_inactive.svg"
                alt="icon"
              />
              <img
                *ngIf="tabValue === 'bookings'"
                class="label_logo mr_15"
                src="../../../assets/newIcons/activeBooking.svg"
                alt="icon"
              />
            </div>

            <span>My Property</span>
          </div>
          <div
            class="logo_container d_flex_align_start"
            (click)="tabChange('requests')"
            [ngClass]="tabValue === 'requests' ? 'active_tab' : 'inactive_tab'"
          >
            <div class="icon_width">
              <img
                *ngIf="tabValue !== 'requests'"
                class="label_logo inactive_logo mr_15"
                src="../../../assets/newIcons/requests.svg"
                alt="icon"
              />
              <img
                *ngIf="tabValue === 'requests'"
                class="label_logo mr_15"
                src="../../../assets/newIcons/activeRequest.svg"
                alt="icon"
              />
            </div>

            <span>My Requests</span>
          </div>
          <div
            class="logo_container d_flex_align_start"
            (click)="tabChange('notifications')"
            [ngClass]="
              tabValue === 'notifications' ? 'active_tab' : 'inactive_tab'
            "
          >
            <div class="icon_width">
              <img
                *ngIf="tabValue !== 'notifications'"
                class="label_logo inactive_logo mr_15"
                src="../../../assets/newIcons/notifications.svg"
                alt="icon"
              />
              <img
                *ngIf="tabValue === 'notifications'"
                class="label_logo mr_15"
                src="../../../assets/newIcons/activeNotification.svg"
                alt="icon"
              />
            </div>

            <span>My Notifications</span>
          </div>
          <div
            class="logo_container d_flex_align_start"
            (click)="tabChange('Documents')"
            [ngClass]="tabValue === 'Documents' ? 'active_tab' : 'inactive_tab'"
          >
            <div class="icon_width">
              <img
                *ngIf="tabValue !== 'Documents'"
                class="label_logo inactive_logo mr_15"
                src="../../../assets/newIcons/Documents.svg"
                alt="icon"
              />
              <img
                *ngIf="tabValue === 'Documents'"
                class="label_logo mr_15"
                src="../../../assets/newIcons/activeDocument.svg"
                alt="icon"
              />
            </div>

            <span>My Documents</span>
          </div>
        </div>
        <div>
          <div
            class="logo_container d_flex_align_start"
            (click)="tabChange('invoice')"
            [ngClass]="tabValue === 'invoice' ? 'active_tab' : 'inactive_tab'"
          >
            <div class="icon_width">
              <img
                *ngIf="tabValue !== 'invoice'"
                class="label_logo inactive_logo mr_15"
                src="../../../assets/newIcons/invoice_inactive.svg"
                alt="icon"
              />
              <img
                *ngIf="tabValue === 'invoice'"
                class="label_logo mr_15"
                src="../../../assets/newIcons/invoice_active.svg"
                alt="icon"
              />
            </div>

            <span>My Invoices</span>
          </div>
        </div>
        <div>
          <div
            class="logo_container d_flex_align_start"
            (click)="tabChange('deposit')"
            [ngClass]="tabValue === 'deposit' ? 'active_tab' : 'inactive_tab'"
          >
            <div class="icon_width">
              <img
                *ngIf="tabValue !== 'deposit'"
                class="label_logo inactive_logo mr_15"
                src="../../../assets/newIcons/invoice_inactive.svg"
                alt="icon"
              />
              <img
                *ngIf="tabValue === 'deposit'"
                class="label_logo mr_15"
                src="../../../assets/newIcons/invoice_active.svg"
                alt="icon"
              />
            </div>

            <span>My Deposits</span>
          </div>
        </div>
      </div>
    </div>

    <div class="main_content">
      <app-profile-details *ngIf="tabValue === 'profile'"></app-profile-details>
      <app-profile-bookings
        *ngIf="tabValue === 'bookings'"
      ></app-profile-bookings>
      <app-profile-documents
        *ngIf="tabValue === 'Documents'"
      ></app-profile-documents>
      <app-profile-request
        *ngIf="tabValue === 'requests'"
      ></app-profile-request>
      <app-notifications-list
        *ngIf="tabValue === 'notifications'"
      ></app-notifications-list>
      <app-profile-invoice *ngIf="tabValue === 'invoice'"></app-profile-invoice>
      <app-profile-deposite
        *ngIf="tabValue === 'deposit'"
      ></app-profile-deposite>
    </div>

  </div>
</div>

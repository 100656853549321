<div class="main_container">
  <div class="search_bar" style="margin-bottom: 3rem">
    <app-search-bar
      *ngIf="toggleSearch"
      pageName="property_list"
      [params]="urlparams"
      [getParams]="getParams"
      rentaltype="rental_type"
    ></app-search-bar>
  </div>
  <div class="sort_box d_flex_space_align">
    <div
      class="length_of_properties d_flex_space_align"
      *ngIf="data && data.length > 0"
    >
      <p class="length mr_15">{{ total && total }}</p>
      <span class="ft_17 sub_content_color">Properties are matching</span>
    </div>
    <div>
      <span class="ft_17 sub_content_color" *ngIf="total && total === 0">
        No Properties Found</span
      >
    </div>
    <div class="sort_button_div d_flex_space_align" *ngIf="width > 480">
      <div class="button_box">
        <button
          mat-stroked-button
          class="filter_buttons"
          (click)="showhideSearch()"
        >
          Search
        </button>
        <img
          class="button_box_icon"
          src="../../assets/newIcons/search.svg"
          alt="image"
        />
      </div>
      <div class="button_box">
        <mat-form-field appearance="outline" class="sort_by_filter">
          <mat-select
            (selectionChange)="FiltersApi('', '')"
            [(value)]="sort_value"
            placeholder="Sort by"
            class="sort-select"
          >
            <mat-option value="newest">Newest first</mat-option>
            <mat-option value="price_low">Price low to high</mat-option>
            <mat-option value="price_high">Price high to low</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="button_box" style="margin-right: 0px !important">
        <button
          mat-stroked-button
          class="filter_buttons"
          (click)="OpenMap()"
          [disabled]="!(data.length > 0)"
        >
          View On Map
        </button>
        <img
          class="button_box_icon"
          src="../../assets/newIcons/map.svg"
          alt="image"
        />
      </div>
    </div>
    <div class="res_filter_box d_flex" *ngIf="width < 480">
      <mat-form-field
        appearance="outline"
        class="sort_by_filter res_sort_by_filter mr_10"
      >
        <mat-select
          (selectionChange)="FiltersApi('', '')"
          [(value)]="sort_value"
          placeholder=""
          class=""
        >
          <mat-option value="newest">Newest first</mat-option>
          <mat-option value="price_low">Price low to high</mat-option>
          <mat-option value="price_high">Price high to low</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="button_box" style="margin-right: 0px !important">
        <button class="filter_buttons" (click)="OpenMap()"></button>
        <img
          (click)="OpenMap()"
          class="button_box_icon"
          src="../../assets/newIcons/map.svg"
          alt="image"
        />
      </div>
    </div>
  </div>

  <div class="d_flex_space_align filter_option_container">
    <div class="d_flex responsive_filter">
      <div class="individual_sort" *ngIf="rental_type === 'by_room'">
        <div class="filter_div" mat-button [matMenuTriggerFor]="room_type">
          <p class="mr_15 ft_family ft_16">Room Type</p>
          <img src="../../assets/newIcons/dropdown.svg" alt="image" />
        </div>
        <mat-menu #room_type="matMenu" class="property">
          <div class="data" (click)="$event.stopPropagation()">
            <div
              *ngFor="let room of room_type_array; let i = index"
              class="ftp-checkbox-div property_checkbox"
            >
              <mat-checkbox
                class="ftp-checkbox"
                [checked]="room.is_enabled"
                (change)="roomTypeFilter(room)"
                >{{ room.name }}
              </mat-checkbox>
            </div>
            <p class="mat_clear" (click)="roomClear('')">Clear</p>
          </div>
        </mat-menu>
      </div>
      <div class="individual_sort" *ngIf="rental_type === 'entire_home'">
        <div class="filter_div" mat-button [matMenuTriggerFor]="property">
          <p class="mr_15 ft_family ft_16">Property</p>
          <img src="../../assets/newIcons/dropdown.svg" alt="image" />
        </div>
        <mat-menu #property="matMenu" class="property">
          <div class="data" (click)="$event.stopPropagation()">
            <div
              *ngFor="let propType of propertyType; let i = index"
              class="ftp-checkbox-div property_checkbox capitalize"
            >
              <mat-checkbox
                class="ftp-checkbox"
                [checked]="propType.is_enabled"
                (change)="proTypeFilter(propType)"
                >{{ propType.name }}
              </mat-checkbox>
            </div>
            <p class="mat_clear" (click)="PropertyClear('')">Clear</p>
          </div>
        </mat-menu>
      </div>
      <div class="individual_sort">
        <div class="filter_div" mat-button [matMenuTriggerFor]="bedsRooms">
          <p class="mr_15 ft_family ft_16">Rooms & Beds</p>
          <img src="../../assets/newIcons/dropdown.svg" alt="image" />
        </div>
        <mat-menu
          #bedsRooms="matMenu"
          class="price"
          [xPosition]="width > 480 ? 'after' : 'before'"
        >
          <div class="data" (click)="$event.stopPropagation()">
            <div class="beds_div d_flex_space_align mb_15">
              <p class="ft_16 ft_family">Beds</p>
              <div class="increase_buttons d_flex">
                <div class="decrease_increase" (click)="decrease('bed')">
                  <img src="../../assets/newIcons/-.svg" alt="image" />
                </div>
                <p class="ft_14 ft_family sub_text_green mr_15">
                  {{ bedCount }}
                </p>
                <div class="decrease_increase _m" (click)="increase('bed')">
                  <img src="../../assets/newIcons/+.svg" alt="image" />
                </div>
              </div>
            </div>
            <div class="beds_div d_flex_space_align mb_15">
              <p class="ft_16 ft_family">Bathrooms</p>
              <div class="increase_buttons d_flex">
                <div class="decrease_increase" (click)="decrease('bathroom')">
                  <img src="../../assets/newIcons/-.svg" alt="image" />
                </div>
                <p class="ft_14 ft_family sub_text_green mr_15">
                  {{ bathroomCount }}
                </p>
                <div
                  class="decrease_increase _m"
                  (click)="increase('bathroom')"
                >
                  <img src="../../assets/newIcons/+.svg" alt="image" />
                </div>
              </div>
            </div>
            <div class="beds_div d_flex_space_align mb_15">
              <p class="ft_16 ft_family">Guests</p>
              <div class="increase_buttons d_flex">
                <div class="decrease_increase" (click)="decrease('guest')">
                  <img src="../../assets/newIcons/-.svg" alt="image" />
                </div>
                <p class="ft_14 ft_family sub_text_green mr_15">
                  {{ guestCount }}
                </p>
                <div class="decrease_increase _m" (click)="increase('guest')">
                  <img src="../../assets/newIcons/+.svg" alt="image" />
                </div>
              </div>
            </div>
            <div class="bathroom_div"></div>
            <p class="mat_clear" (click)="clearRoomBeds('clear')">Clear</p>
          </div>
        </mat-menu>
      </div>
      <div class="individual_sort">
        <div class="filter_div" mat-button [matMenuTriggerFor]="price">
          <p class="mr_15 ft_family ft_16">Price Range</p>
          <img src="../../assets/newIcons/dropdown.svg" alt="image" />
        </div>
        <mat-menu
          #price="matMenu"
          class="price"
          [xPosition]="width > 480 ? 'after' : 'before'"
        >
          <div class="data p_30" (click)="$event.stopPropagation()">
            <div class="d_flex_space_align mb_30">
              <h4 class="ft_500 ft_16 ft_family">Price Range</h4>
            </div>
            <ngx-slider
              class="slider_search"
              [(value)]="value"
              [(highValue)]="highValue"
              [options]="options"
              (userChangeEnd)="getSliderValue()"
            ></ngx-slider>
          </div>
        </mat-menu>
      </div>
      <div class="individual_sort">
        <div class="filter_div" mat-button [matMenuTriggerFor]="amenity">
          <p class="mr_15 ft_family ft_16">Amenities</p>
          <img src="../../assets/newIcons/dropdown.svg" alt="image" />
        </div>
        <mat-menu
          #amenity="matMenu"
          class="amenities"
          yPosition="below"
          [xPosition]="width > 480 ? 'after' : 'before'"
          [overlapTrigger]="false"
        >
          <div
            class="data"
            (click)="$event.stopPropagation()"
            style="text-transform: capitalize"
          >
            <div *ngFor="let amen of amenities; let i = index">
              <mat-menu-item *ngIf="i <= initialamenititesLength">
                <div class="pb_25">
                  <mat-checkbox
                    class="property_checkbox"
                    [checked]="returnIsAmenityChecked(amen.amenity_id)"
                    (change)="amenitiesFilter($event, amen)"
                    >{{ amen.name }}</mat-checkbox
                  >
                </div>
              </mat-menu-item>
            </div>
            <div class="d_flex_space_align">
              <div *ngIf="amenities.length > 5 && amenities_expanded === false">
                <label class="ftp-checkbox mat_clear" (click)="showMore(true)">
                  + Show all</label
                >
              </div>
              <div *ngIf="amenities.length > 5 && amenities_expanded === true">
                <label class="ftp-checkbox mat_clear" (click)="showMore(false)">
                  - Show less</label
                >
              </div>
              <p class="mat_clear" (click)="amenityClear('clear')">Clear</p>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="d_flex_space_align res_clear_all">
      <p
        class="sub_content_color ft_family ft_17 cursor_pointer"
        (click)="clearAll()"
      >
        Clear All
      </p>
    </div>
  </div>
  <div>
    <div class="property_list_container">
      <div class="property_list_container mt_70" *ngIf="loading">
        <div class="dummy_content">
          <ngx-shimmer-loading
            class="property_div_shimmer"
            [shape]="'square'"
            width="393px"
          >
          </ngx-shimmer-loading>
          <div class="property_shimmer_detail_container">
            <ngx-shimmer-loading
              class="guest_div_shimmer"
              width="348px"
              height="58px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="348px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="300px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <div class="d_flex_space_align">
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="30px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="43px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
            </div>
          </div>
        </div>
        <div class="dummy_content">
          <ngx-shimmer-loading
            class="property_div_shimmer"
            [shape]="'square'"
            width="393px"
          >
          </ngx-shimmer-loading>
          <div class="property_shimmer_detail_container">
            <ngx-shimmer-loading
              class="guest_div_shimmer"
              width="348px"
              height="58px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="348px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="300px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <div class="d_flex_space_align">
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="30px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="43px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
            </div>
          </div>
        </div>
        <div class="dummy_content" style="margin-right: 0px">
          <ngx-shimmer-loading
            class="property_div_shimmer"
            [shape]="'square'"
            width="393px"
          >
          </ngx-shimmer-loading>
          <div class="property_shimmer_detail_container">
            <ngx-shimmer-loading
              class="guest_div_shimmer"
              width="348px"
              height="58px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="348px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="300px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <div class="d_flex_space_align">
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="30px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="43px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
            </div>
          </div>
        </div>
        <div class="dummy_content">
          <ngx-shimmer-loading
            class="property_div_shimmer"
            [shape]="'square'"
            width="393px"
          >
          </ngx-shimmer-loading>
          <div class="property_shimmer_detail_container">
            <ngx-shimmer-loading
              class="guest_div_shimmer"
              width="348px"
              height="58px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="348px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="300px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <div class="d_flex_space_align">
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="30px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="43px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
            </div>
          </div>
        </div>
        <div class="dummy_content">
          <ngx-shimmer-loading
            class="property_div_shimmer"
            [shape]="'square'"
            width="393px"
          >
          </ngx-shimmer-loading>
          <div class="property_shimmer_detail_container">
            <ngx-shimmer-loading
              class="guest_div_shimmer"
              width="348px"
              height="58px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="348px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="300px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <div class="d_flex_space_align">
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="30px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="43px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
            </div>
          </div>
        </div>
        <div class="dummy_content">
          <ngx-shimmer-loading
            class="property_div_shimmer"
            [shape]="'square'"
            width="393px"
          >
          </ngx-shimmer-loading>
          <div class="property_shimmer_detail_container">
            <ngx-shimmer-loading
              class="guest_div_shimmer"
              width="348px"
              height="58px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="348px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <ngx-shimmer-loading
              class="property_name_shimmer"
              width="300px"
              height="30px"
              borderRadius="5px"
            >
            </ngx-shimmer-loading>
            <div class="d_flex_space_align">
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="30px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
              <ngx-shimmer-loading
                class="property_name_shimmer"
                width="100px"
                height="43px"
                borderRadius="5px"
              >
              </ngx-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <div
        class="individual_property_box"
        *ngFor="let item of data; index as i"
      >
        <!-- | paginate
      : {
          itemsPerPage: itemsPerPage,
          currentPage: page,
          totalItems: totalLength
        }; -->
        <div class="property_div" *ngIf="!loading && data && data.length !== 0">
          <div class="image_div cursor_pointer">
            <img
              [src]="this.utils.imageFormat(400, 700, item.gallery[0], 'c_fit')"
              (click)="onNavigateToViewSearch(item.property_id)"
              alt="image"
            />
            <p class="image_address ft_14 ft_family">
              <i class="fa fa-map-marker mr_10" style="color: white"></i>
              {{ item.address.line_1 }}
            </p>
          </div>

          <div class="property_details">
            <div class="guest_div mb_30">
              <div class="text_with_icon">
                <div class="guest_room_icon_holder">
                  <img src="../../assets/newIcons/bedroom.svg" alt="image" />
                </div>
                <p class="ft_12 sub_content_color ft_family">
                  {{ item.bedrooms }} bedrooms
                </p>
              </div>
              <div class="text_with_icon">
                <div class="guest_room_icon_holder">
                  <img src="../../assets/newIcons/bathroom.svg" alt="image" />
                </div>
                <p class="ft_12 sub_content_color ft_family">
                  {{ item.bathrooms }} bathrooms
                </p>
              </div>
              <div class="text_with_icon">
                <div class="guest_room_icon_holder">
                  <img src="../../assets/newIcons/guests.svg" alt="image" />
                </div>
                <p class="ft_12 sub_content_color ft_family">
                  {{ item.guests }} guests
                </p>
              </div>
            </div>
            <div class="proper_name mb_25">
              <h2
                class="ft_family ft_20 ft_600 mb_15 cursor_pointer"
                (click)="onNavigateToViewSearch(item.property_id)"
              >
                {{ item.name }}
              </h2>
              <p class="ft_family sub_content_color ft_14 about_text">
                {{ item.about }}
              </p>
            </div>
            <div class="price_book">
              <p class="ft_20 ft_family ft_700">
                {{ utils.returnRoomPrice(item) }}
              </p>

              <div class="button_with_icon">
                <button
                  [ngClass]="
                    rental_type === 'by_room'
                      ? 'view_rooms_button'
                      : 'common_button'
                  "
                  class=""
                  (click)="onNavigateToViewSearch(item.property_id)"
                >
                  {{ rental_type === "by_room" ? "View Rooms" : "Book" }}
                </button>
                <img
                  class=""
                  [ngClass]="
                    rental_type === 'by_room' ? 'save_icon_room' : 'save_icon'
                  "
                  src="../../assets/newIcons/save.svg"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no_data mb_30" *ngIf="data.length === 0">
      <lottie-player
        src="https://assets9.lottiefiles.com/packages/lf20_hyy9fqjt.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 300px"
        loop
        autoplay
      ></lottie-player>
      <p class="ft_family ft_18 ft_600 mb_15">
        No matching properties are found
      </p>
      <p class="ft_family ft_16 ft_500 mb_15 sub_content_color mb_30">
        Try clearing filters to get the properties
      </p>
      <button
        mat-flat-button
        class="close_filters ft_16 ft_family"
        (click)="clearAll()"
        style="outline: none !important"
      >
        Clear Filters
      </button>
    </div>
    <div class="pagination_div">
      <div
        style="display: flex; justify-content: flex-end"
        class="mb_20 paginator"
        *ngIf="!loading"
      >
        <button
          [disabled]="page <= 1"
          [ngClass]="page <= 1 ? 'inactive_button' : 'active_button'"
          (click)="onPageChange('before')"
        >
          <i class="fa fa-angle-left" style="font-size: 24px"></i>
        </button>
        <button
          [disabled]="data.length < itemsPerPage"
          [ngClass]="
            data.length < itemsPerPage ? 'inactive_button' : 'active_button'
          "
          (click)="onPageChange('after')"
        >
          <i class="fa fa-angle-right" style="font-size: 24px"></i>
        </button>
      </div>
    </div>
  </div>
</div>

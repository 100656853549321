import {
  Component,
  OnInit,
  Inject,
  ViewChildren,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilityService } from "src/app/utility.service";
import { ApicallService } from "src/app/apicall.service";
import { FormsModule } from "@angular/forms";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgOtpInputComponent } from "ng-otp-input";
import { Router } from "@angular/router";
@Component({
  selector: "app-reject-request",
  templateUrl: "./reject-request.component.html",
  styleUrls: ["./reject-request.component.css"],
})
export class RejectRequestComponent implements OnInit {
  reason = "";
  isValidOtp = false;
  otp: any;
  form: FormGroup;
  formInput = [
    {
      key: "input1",
      value: "",
    },
    {
      key: "input2",
      value: "",
    },
    {
      key: "input3",
      value: "",
    },
    {
      key: "input4",
      value: "",
    },
  ];
  @ViewChildren("formRow") rows: any;
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput: NgOtpInputComponent;
  constructor(
    public utils: UtilityService,
    public url: ApicallService,
    public dialog: MatDialogRef<RejectRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) {
    this.form = this.toFormGroup(this.formInput);
  }
  onSubmit() {
    this.formInput.forEach((item) => {
      if (!item.value) {
        return (this.isValidOtp = false);
      } else {
        this.isValidOtp = true;
      }
    });
    if (this.reason === "") {
      this.utils.openToast("Enter enter valid reason", {});
    } else if (!this.isValidOtp) {
      this.utils.openToast("Enter valid OTP", {});
    } else {
      let dummyOtp = "";
      this.formInput.forEach((item) => {
        dummyOtp += item.value;
      });
      let obj = {
        otp: dummyOtp,
        reject_reason: this.reason,
      };
      this.url.patch(
        `auth/contractor/open_request/reject/${this.data.id}`,
        obj,
        {},
        (res) => {
          if (res.success) {
            this.utils.openToast("", res);
            this.dialog.close();
            this.router.navigate(["/"]);
          } else {
            this.utils.openToast("", res.message);
          }
        }
      );
    }
  }
  ngOnInit(): void {}
  toFormGroup(elements) {
    const group: any = {};

    elements.forEach((item) => {
      group[item.key] = new FormControl(item.value, Validators.required);
    });
    return new FormGroup(group);
  }
  onOtpChange(e) {
    this.otp = e;
  }
  keyUpEvent(event, index) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }
  cancelRequest() {}
}

import { Component, OnInit, HostListener } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UtilityService } from "src/app/utility.service";
import { CookieService } from "ngx-cookie";
import { CloudinaryService } from "src/app/cloudinary.service";
import { ApicallService } from "src/app/apicall.service";
import { DocumentStatusComponent } from "../../../../document-status/document-status.component";
import * as moment from "moment";
import { CancelDocumentComponent } from "../../../../cancel-document/cancel-document.component";
@Component({
  selector: "app-profile-documents",
  templateUrl: "./profile-documents.component.html",
  styleUrls: ["./profile-documents.component.css"],
})
export class ProfileDocumentsComponent implements OnInit {
  length;
  limit = 20;
  from_date;
  to_date;
  minTo;
  loading = true;
  add_documents = false;
  show_filters;
  documents_list;
  filter_value = "this_month";
  expiry_date;
  document_type = "";
  toggleSelect = false;
  page: number = 1;
  other_type;
  params;
  document_detail;
  imageArray = [];
  imageLoading = false;
  today = new Date();
  current_tab = 0;
  shared_document = [];
  disable_button = false;
  constructor(
    public dialog: MatDialog,
    public utils: UtilityService,
    public cookie: CookieService,
    public imageUploader: CloudinaryService,
    public url: ApicallService
  ) {}
  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    this.showPopup(2, event);
    event.stopPropagation();
  }
  ngOnInit(): void {
    this.from_date = new Date();
    this.from_date.setDate(1);
    this.from_date.setHours(0, 0, 0, 0);
    this.to_date = new Date();
    this.getDocuments("");
    this.getSharedDocument();
  }

  showPopup(id, event) {
    if (id === 1) {
      this.toggleSelect = true;
      event.stopPropagation();
    } else {
      this.toggleSelect = false;
    }
  }
  addDocument() {
    this.add_documents = true;
  }
  tabClick(e, link) {}
  reRoute() {
    this.clearData();
  }
  openBox() {
    this.toggleSelect = true;
  }
  onPageChange(e) {
    this.page = e === "before" && this.page > 1 ? this.page - 1 : this.page + 1;
    this.getDocuments("");
  }

  selectedOption(value) {
    this.document_type = value;
    this.toggleSelect = false;
  }

  changeCheckDate(value, input) {
    if (input === "from_date") {
      this.from_date = value;
      this.minTo = value;
    } else {
      this.to_date = value;
    }
    if (this.from_date && this.to_date) {
      this.getDocuments("");
    }
  }

  async upload(e: any) {
    let file = e.target.files[0];
    if (
      file.type !== "application/pdf" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      this.utils.openToast("Invalid File Format", {});
    } else if (file.size > 5000000) {
      this.utils.openToast("File size should be less than 5 MB", {});
    } else {
      this.imageLoading = true;
      let profile_id = this.cookie.get("profile_id");
      let image = {
        public_id: "",
        version: "",
        format: "",
      };

      this.imageUploader.uploadCloudinary(
        file,
        `documents/${profile_id}`,
        (response: any) => {
          this.imageLoading = false;
          if (response && response.url) {
            image = {
              public_id: response.public_id,
              version: "v" + response.version,
              format: response.format,
            };
            this.imageArray.push(image);

            e.target.value = "";
            let saveData = {
              image: image,
            };
          } else this.utils.openToast("Could not upload image", {});
        }
      );
    }
  }

  removeImage(e, index) {
    this.imageArray.forEach((image) => {
      if (e.public_id === image.public_id) {
        this.imageArray.splice(index, 1);
      }
    });
  }
  dowloadApi = (id, name) => {
    this.url.downloadDocument(id, name);
  };

  SharedAction = (link, type) => {
    if (type === "view") {
      window.open(link, "_blank");
    } else {
      // const blob = new Blob([link]);
      // const url = window.URL.createObjectURL(blob);
      // window.open(url, "_blank");
    }
  };
  getSharedDocument = () => {
    this.url.get(
      `user/documents?page=${this.page}&limit=${this.limit}&type=shared`,
      this.params,
      (res) => {
        this.loading = false;
        if (res.success) {
          this.shared_document = res.data.documents;
        }
      }
    );
  };
  getDocuments(id) {
    // let start = moment(this.from_date).format("YYYY-MM-DD");
    // let end = moment(this.to_date).format("YYYY-MM-DD");
    if (id) {
      this.url.get(`user/documents/${id}`, this.params, (res) => {
        this.loading = false;
        if (res.success) {
          this.document_detail = res.data.documents;
          this.add_documents = true;
          this.document_type = this.document_detail.type;
          this.imageArray = this.document_detail.documents;
          this.other_type =
            this.document_detail.other_type && this.document_detail.other_type;
          this.expiry_date = this.document_detail.expiry_date;
        }
      });
    } else {
      this.url.get(
        `user/documents?page=${this.page}&limit=${this.limit}`,
        this.params,
        (res) => {
          this.loading = false;
          if (res.success) {
            this.documents_list = res.data.documents;
          }
        }
      );
    }
  }
  deleteDocument(id) {
    const cancelNotice = this.dialog.open(CancelDocumentComponent, {
      data: {
        data: id,
      },
    });
    cancelNotice.afterClosed().subscribe((result) => {
      if (result) {
        this.getDocuments("");
      }
    });
  }
  filterValue(e) {
    if ("custom_date" === e) {
      this.show_filters = true;
      this.filter_value = e;
      this.from_date = "";
      this.to_date = "";
    } else {
      this.show_filters = false;
      this.page = 1;
      let filter = this.utils.dateFilter(e, this.from_date, this.to_date);
      this.from_date = filter.start;
      this.to_date = filter.end;
      this.getDocuments("");
    }
  }
  addDocs(id) {
    if (this.document_type === "" || this.document_type === undefined) {
      this.utils.openToast("Please select document Type", {});
    } else if (this.expiry_date === "" || this.expiry_date === undefined) {
      this.utils.openToast("Please enter the expiry date", {});
    } else if (
      this.document_type === "Others" &&
      (this.other_type === "" || this.other_type === undefined)
    ) {
      this.utils.openToast("Please enter document type", {});
    } else if (this.imageArray.length === 0) {
      this.utils.openToast("Please upload the document", {});
    } else {
      this.disable_button = true;
      let data = {
        type: this.document_type,
        other_type: this.other_type,
        documents: this.imageArray,
        expiry_date: this.expiry_date,
      };
      if (id) {
        this.url.patch(`user/documents/${id}`, data, this.params, (res) => {
          this.disable_button = false;
          if (res.success) {
            this.dialog.open(DocumentStatusComponent, {
              data: "updated",
            });
            this.clearData();
            this.getDocuments("");
          }
        });
      } else {
        this.url.post(`user/documents`, data, this.params, (res) => {
          this.disable_button = false;
          if (res.success) {
            this.clearData();
            if (res.success) {
              this.dialog.open(DocumentStatusComponent, {
                data: "created",
              });
              this.getDocuments("");
            }
          } else {
            this.utils.openToast("", res);
          }
        });
      }
    }
  }
  clearData() {
    this.add_documents = false;
    this.expiry_date = "";
    this.imageArray = [];
    this.document_type = "";
    this.other_type = "";
    this.document_detail = {};
  }
}

import { Component, OnInit, HostListener } from "@angular/core";
import { ApicallService } from "src/app/apicall.service";
import { CloudinaryService } from "src/app/cloudinary.service";
import { UtilityService } from "src/app/utility.service";
import * as moment from "moment";
import { CancelNoticeComponent } from "../profile-bookings/cancel-notice/cancel-notice.component";
import { MatDialog } from "@angular/material/dialog";
import { RequestStatusComponent } from "./request-status/request-status.component";
import { RequestAssignComponent } from "./request-assign/request-assign.component";
import { RatingComponentComponent } from "./rating-component/rating-component.component";
import { ProfilerequestService } from "./profilerequest.service";
import { ActivatedRoute } from "@angular/router";
import { ChatComponent } from "./chat/chat.component";
import { get } from "http";
import { retry } from "rxjs-compat/operator/retry";
@Component({
  selector: "app-profile-request",
  templateUrl: "./profile-request.component.html",
  styleUrls: ["./profile-request.component.css"],
})
export class ProfileRequestComponent implements OnInit {
  constructor(
    public imageUploader: CloudinaryService,
    public url: ApicallService,
    public utils: UtilityService,
    public dialog: MatDialog,
    public profile_request: ProfilerequestService,
    public route: ActivatedRoute
  ) {}
  starsCount;
  currentTab = 1;
  imageLoading = false;
  image;
  edit: Boolean = false;
  add_requst = false;
  toggleSelect = false;
  profileImageUrl;
  selected_problem = "";
  type_of_problem: any = "";
  page = 1;
  limit = 5;
  desc = "";
  loading = true;
  params = "";
  show_issue_length = 2;
  request;
  problem_date = new Date();
  cancelled_view = false;
  rejected_view = false;
  show_request_details = false;
  request_list;
  curent_booking = false;
  current_booking_data;
  selected_problems_Array: Array<any> = [];
  imageArray = [];
  stage_1;
  stage_2;
  stage_3;
  stage_4;
  stage_5;
  status_1_date;
  status_2_date;
  status_3_date;
  status_4_date;
  status_5_date;
  cancelled_date;
  accepted_view;
  rejected_date;
  close_request = false;
  request_details;
  selectedProblem;
  from_date;
  to_date;
  filter_value = "this_month";
  today = new Date();
  minTo = new Date();
  problemMin = new Date();
  show_filters = false;
  show_problem_select = false;
  show_sub_problem = false;
  apiRequestList;
  selected_option_problem: any = {};
  sub_issues_list;
  selected_sub_problem: Array<any> = [];
  supplyIssues: Array<any> = [];
  saveSpinner:Boolean = false
  problem = [
    // {
    //   key: "Electrical",
    //   issues: [
    //     { name: "Ceiling Fan", is_enabled: false },
    //     { name: "Air Conditionar", is_enabled: false },
    //     { name: "Lights", is_enabled: false },
    //     { name: "Outlets", is_enabled: false },
    //   ],
    // },
    // {
    //   key: " House Exterior",
    //   issues: [
    //     { name: "A/C Condenser", is_enabled: false },
    //     { name: "Concrete", is_enabled: false },
    //     { name: "Doors & Windows", is_enabled: false },
    //     { name: "Roof & Gutters", is_enabled: false },
    //     { name: "Siding / Exterior", is_enabled: false },
    //   ],
    // },
    // {
    //   key: "Service",
    //   issues: [
    //     { name: "Ceiling", is_enabled: false },
    //     { name: "Cleaning", is_enabled: false },
    //     { name: "Doors & Windows", is_enabled: false },
    //     { name: "Flooring", is_enabled: false },
    //     { name: "Pest Control", is_enabled: false },
    //     { name: "Walls & Cabinets", is_enabled: false },
    //     { name: "Fencing & Roof", is_enabled: false },
    //     { name: "Garbage / Rubbish", is_enabled: false },
    //     { name: "Landscaping", is_enabled: false },
    //     { name: "Parking", is_enabled: false },
    //     { name: "Pool", is_enabled: false },
    //     { name: "Porch & Walkways", is_enabled: false },
    //   ],
    // },
    // {
    //   key: "Plumbing",
    //   issues: [
    //     { name: "Leak", is_enabled: false },
    //     { name: "Shower/Tub", is_enabled: false },
    //     { name: "Sink", is_enabled: false },
    //     { name: "Sprinklers", is_enabled: false },
    //     { name: "Toilet", is_enabled: false },
    //   ],
    // },
    // {
    //   key: "Appliances",
    //   issues: [
    //     { name: "Dishwasher", is_enabled: false },
    //     { name: "Heathing & Cooling", is_enabled: false },
    //     { name: "Laundry / Vendor", is_enabled: false },
    //     { name: "Oven / Stove", is_enabled: false },
    //     { name: "Refrigerator", is_enabled: false },
    //     { name: "Water Heater", is_enabled: false },
    //   ],
    // },
    {
      key: "Supply Request",
      issues: [
        // { name: "Tissue Paper", is_enabled: false },
        // { name: "Dustbin Cover", is_enabled: false },
        // { name: "Hand Wash", is_enabled: false },
      ],
    },
    {
      key: "Other",
      issues: [],
    },
  ];
  visited: Boolean = false;
  // ngOnInit(): void {
  //   let request = false
  //   let details = false
  //   this.route.queryParams.subscribe((params) => {
  //     if(params.id) {
  //       this.getrequestDetail(params.id).then(response => {
  //         details = true
  //         if (response && request) this.loading = false
  //       })
  //     }
  //   })
  //   this.from_date = new Date();
  //   this.from_date.setDate(1);
  //   this.from_date.setHours(0, 0, 0, 0);
  //   this.to_date = new Date();
  //   this.getRequest().then(response => {
  //     request = true
  //     if (response && details) this.loading = false
  //   })
  // }

  ngOnInit(): void {
    this.from_date = moment().startOf("month").format("YYYY-MM-DD hh:mm");
    this.to_date = moment().endOf("month").format("YYYY-MM-DD hh:mm");
    let requestDetails = [this.getRequest()];
    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        requestDetails.push(this.getrequestDetail(params.id));
      }
    });
    // this.from_date = new Date();
    // this.from_date.setDate(1);
    // this.from_date.setHours(0, 0, 0, 0);
    // this.to_date = new Date();
    Promise.all(requestDetails).then((response) => {
      this.loading = false;
    });
  }
  ngAfterViewInit() {
    let location = document.getElementById("problem");
    if (location) {
      window.addEventListener("click", (event: any) => {
        location.style.display = "none";
      });
    }
  }
  setPopUpFalse() {
    this.show_problem_select = false;
  }
  filterValue(e) {
    if ("custom_date" === e) {
      this.show_filters = true;
      this.filter_value = e;
      this.from_date = "";
      this.to_date = "";
    } else {
      this.show_filters = false;
      this.page = 1;
      let filter = this.utils.dateFilter(e, this.from_date, this.to_date);
      this.from_date = filter.start;
      this.to_date = filter.end;
      this.getRequest();
    }
  }
  CheckDate(value, input) {
    if (input === "from_date") {
      this.from_date = value;
      this.minTo = value;
    } else {
      this.to_date = value;
    }
    if (this.from_date && this.to_date) {
      this.getRequest();
    }
  }

  getSupplies = async () => {
    return new Promise((resolve, reject) => {
      this.saveSpinner = true
      this.url.get(
        `user/requests/supply/${this.current_booking_data.booking_id}`,
        this.params,
        (res) => {
          if (res.success) {
            this.saveSpinner = false
            this.visited = true;
            this.sub_issues_list = res.data.supplyItem;
            this.sub_issues_list.forEach((element) => {
              element.is_enabled = false;
            });
            this.problem.map((obj) => {
              if (obj.key === "Supply Request") {
                obj.issues = res.data.supplyItem;
              }
            });
            resolve(res);
          } else {
            this.saveSpinner = false
            reject(res);
          }
        }
      );
    });
  };

  async getBooking() {
    // Wrap the callback-based operation in a Promise
    return new Promise((resolve, reject) => {
      this.url.get(`user/bookings/current`, this.params, (res) => {
        if (res.success) {
          this.current_booking_data = res.data.booking;
          this.curent_booking = true;
          this.add_requst = true;
          this.problemMin = new Date(this.current_booking_data?.check_in);
          // Resolve the Promise with the data
          resolve(res);
        } else {
          this.add_requst = false;
          this.utils.openToast("", res);
          this.resetIssues();
          // Reject the Promise with the error
          reject(res);
        }
      });
    });
  }

  resetIssues() {
    this.problem.map((item) => {
      if (item.issues?.length > 0) {
        item?.issues.map((issue) => {
          issue.is_enabled = false;
        });
      }
    });
  }
  getRequest() {
    return new Promise((resolve, reject) => {
      let start = moment(this.from_date).format("YYYY-MM-DD");
      let end = moment(this.to_date).format("YYYY-MM-DD");
      this.url.get(
        `user/requests?page=${this.page}&limit=${this.limit}&from=${start}&to=${end}`,
        this.params,
        (res) => {
          if (res.success) {
            this.request_list = res.data.requests;
            resolve(true);
          } else resolve(false);
        }
      );
    });
  }
  cancelRequest(request, value) {
    const cancelNotice = this.dialog.open(CancelNoticeComponent, {
      data: {
        data: request,
        value: value === "cancel" ? "cancel" : "close",
      },
    });
    cancelNotice.afterClosed().subscribe((result) => {
      if (result) {
        this.getRequest();
        this.show_request_details = false;
        this.add_requst = false;
      }
    });
  }
  rateTheWork(request) {
    const dialog = this.dialog.open(RatingComponentComponent, {
      width: "400px",
      position: { right: "0px", top: "0px" },
      height: "100vh",
      data: { requestId: request.request_id },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        // this.getRequest()
        this.getrequestDetail(request.request_id);
        // this.createRequest(this.request && this.request.request_id, res);
        // this.loading = true;
      }
    });
  }
  //

  comment(request) {
    const dialog = this.dialog.open(ChatComponent, {
      width: "600px",
      position: { right: "0px", top: "0px" },
      height: "100vh",
      data: { requestId: request.request_id, request: request.status },
    });
  }

  TypeOfProblem = (item) => {
    this.selected_option_problem = item;
    this.show_problem_select = false;
    this.selected_sub_problem = [];
    this.sub_issues_list = [];

    this.getSubRequestList(item.service_id, () => {});
  };
  TypeOfSubProblem = (item) => {
    // for (let i = 0; i < this.selected_problems_Array.length; i++) {
    //   var itemval = this.selected_problems_Array[i];
    //   if (itemval === temp.name) {
    //     this.selected_problems_Array.splice(i, 1);
    //     break;
    //   }
    // }
    // if (temp.is_enabled === true) {
    //   let data = temp.name;
    //   this.selected_problems_Array.push(data);
    // }
    // this.sub_problem = item;
  };
  problemfilter(temp, i): void {
    const isService = this.type_of_problem === "Other";
    temp.is_enabled = !temp.is_enabled;
    for (let i = 0; i < this.selected_sub_problem?.length; i++) {
      var itemval = this.selected_sub_problem[i];
      const leftId = itemval.id;
      const rightId = isService ? temp.sub_service_id : temp.id;
      if (leftId === rightId) {
        this.selected_sub_problem.splice(i, 1);
        this.supplyIssues.splice(i, 1);
        break;
      }
    }
    if (temp.is_enabled === true) {
      let data = {
        id: isService ? temp.sub_service_id : temp.id,
        name: temp.name,
      };
      this.supplyIssues.push(temp.name);
      this.selected_sub_problem.push(data);
    }
  }

  async switchTab(e) {
    if (e) {
      this.currentTab = this.currentTab - 1;
    } else {
      if (this.type_of_problem === "Supply Request" && this.currentTab === 1) {
        !this.visited && !this.edit && await this.getSupplies();
      }
      if (this.type_of_problem === "") {
        this.utils.openToast(
          "Please select the type of issue you are facing",
          {}
        );
      } else if (this.problem_date === undefined && this.currentTab === 2) {
        this.utils.openToast("Please select a date", {});
      } else if (
        (this.selected_problem === "" || this.selected_problem === undefined) &&
        this.currentTab === 2
      ) {
        this.utils.openToast(
          "Please select the Priority level of your issue",
          {}
        );
      } else if (
        this.type_of_problem === "Other" &&
        this.currentTab === 2 &&
        Object.keys(this.selected_option_problem)?.length === 0
      ) {
        this.utils.openToast("Please select the problem you are facing", {});
      } else if (
        this.currentTab === 2 &&
        Object.keys(this.selected_option_problem).length > 0 &&
        this.sub_issues_list?.length > 0 &&
        this.selected_sub_problem.length === 0
      ) {
        this.utils.openToast(
          "Please select the exact problem you are facing",
          {}
        );
      } else if (
        this.type_of_problem !== "Other" &&
        this.currentTab === 2 &&
        this.selected_sub_problem.length === 0
      ) {
        this.utils.openToast(
          "Please select the exact problem your are facing",
          {}
        );
      } else {
        this.currentTab = this.currentTab + 1;
      }
    }
  }

  // select functionality
  openBox() {
    this.toggleSelect = !this.toggleSelect;
  }
  openProblemSelect() {
    this.show_problem_select = !this.show_problem_select;
  }
  openSubProblem() {
    this.show_sub_problem = true;
  }
  selectedOption(e) {
    this.selected_problem = e;
    this.toggleSelect = false;
  }
  // end of select functionality

  // show and hide add request page
  addRequest() {
    this.getBooking();
  }
  //end show and hide add request page

  // brudcrumbs functionality
  reRoute() {
    this.add_requst = false;
    this.clearRequestData();
    this.show_request_details = false;
  }

  //end of brudcrumbs functionality

  onPageChange(e) {
    if (e === "before" && this.page > 1) {
      this.page = this.page - 1;
    } else {
      this.page = this.page + 1;
    }
    this.getRequest();
  }

  onCardClick(item, e, index) {
    const isService = this.type_of_problem === "Other";
    if (!item.utilized) {
      this.sub_issues_list.forEach((el, i) => {
        if (i === index) {
          this.sub_issues_list[i].is_enabled =
            !el.is_enabled;
            // this.problemfilter(this.sub_issues_list[i],i)
        }
      });
      for (let i = 0; i < this.selected_sub_problem?.length; i++) {
        var itemval = this.selected_sub_problem[i];
        const leftId = itemval.id;
        const rightId = isService ? item.sub_service_id : item.id;
        if (leftId === rightId) {
          this.selected_sub_problem.splice(i, 1);
          this.supplyIssues.splice(i, 1);
          break;
        }
      }
      if (item.is_enabled === true) {
        let data = {
          id: isService ? item.sub_service_id : item.id,
          name: item.name,
        };
        this.supplyIssues.push(item.name);
        this.selected_sub_problem.push(data);
      }
    }
  }

  typeOfProblem(e) {
    this.type_of_problem = e;
    this.selectedProblem = this.problem.findIndex((x) => x.key === e);
    this.selected_sub_problem = [];
    this.selected_problem = "";
    this.sub_issues_list = [];
    this.selected_option_problem = {};
    if (e === "Other") {
      this.getRequestList(() => {});
    } else {
      this.sub_issues_list = this.problem[this.selectedProblem].issues;
    }
  }

  getRequestList = async (callback) => {
    await this.url.get(`user/requests/services`, this.params, (res) => {
      if (res.success) {
        this.apiRequestList = res.data.services;
        this.apiRequestList.push({ name: "Others" });
        this.problem[this.selectedProblem].issues = res.data.services;
        callback(this.problem);
      }
    });
  };

  changeCheckDate(value, input) {
    this.problem_date = value;
  }
  createRequest(id, additionInfo) {
    if(this.type_of_problem === 'Other' && this.selected_option_problem.name === 'Others' && !this.desc.trim().length) {
      this.utils.openToast("Please enter the description", {});
    } else {
      this.loading = true;
      let data = {
        from: moment(this.problem_date).format("YYYY-MM-DD"),
        priority_level: this.selected_problem,
        items: this.selected_sub_problem,
        issues: this.supplyIssues,
        images: this.imageArray,
        desc: this.desc,
        type:
          this.type_of_problem === "Other"
            ? this.selected_option_problem.name
            : this.type_of_problem,
      };
      if (id) {
        if (Object.keys(additionInfo).length > 0) {
          if (additionInfo?.data?.contractor) {
            data["contractor_id"] = additionInfo.data.contractor;
          }
          data["direct_request"] = additionInfo.data.direct_request;
        }
        this.url.patch(`user/requests/${id}`, data, this.params, (res) => {
          this.loading = false;
          if (res.success) {
            this.dialog.open(RequestStatusComponent, {
              data: "updated",
            });
            this.add_requst = false;
            this.clearRequestData();
            this.getRequest();
          } else {
            this.utils.openToast("", res);
          }
        });
      } else {
        let url = "";
        if (
          Object.keys(additionInfo).length > 0 &&
          additionInfo?.data.contractor
        ) {
          url = `user/requests/direct/${this.current_booking_data.booking_id}`;
          data["contractor_id"] = additionInfo.data.contractor;
        } else {
          url = `user/requests/${this.current_booking_data.booking_id}`;
        }
        this.url.post(url, data, this.params, (res) => {
          this.loading = false;
          if (res.success) {
            this.dialog.open(RequestStatusComponent, {
              data: "created",
            });
            this.add_requst = false;
            this.getRequest();
            this.clearRequestData();
          } else {
            this.utils.openToast("", res);
          }
        });
      }
    }
  
  }
  async editRequest(id) {
    let resData = [];
    this.selected_problems_Array = [];
    await this.getBooking();
    this.edit = true;
    this.loading = true;
    this.currentTab = 1;
    this.type_of_problem;
    this.url.get(`user/requests/${id}`, this.params, async (res) => {
      if (res.success) {
        resData = res.data.requests.items;
        this.request = res.data.requests;
        this.type_of_problem =
          res.data.requests.type === "Supply Request"
            ? "Supply Request"
            : "Other";
        this.desc = res.data.requests.desc;
        this.imageArray = res.data.requests.images;
        this.selected_problem = res.data.requests.priority_level;
        this.problem_date = res.data.requests.from;
        this.selectedProblem = this.problem.findIndex(
          (x) => x.key === this.type_of_problem
        );
        if (this.type_of_problem !== "Supply Request") {
          this.getRequestList((problems) => {
          this.loading = false;
            this.problem[this.selectedProblem].issues.map((item) => {
              if (res.data.requests.type === item.name) {
                this.selected_option_problem = item;
                this.getSubRequestList(
                  this.selected_option_problem.service_id,
                  () => {
                    this.sub_issues_list.map((item) => {
                      if (res.data.requests.issues.includes(item.name)) {
                        item.is_enabled = true;
                        this.supplyIssues.push(item.name);
                        this.selected_sub_problem.push(item);
                      }
                    });
                  }
                );
              }
            });
          });
        } else {
          let tempObj: any = {};
          await this.getSupplies();
          this.loading = false;
          resData.forEach((obj) => {
            tempObj[obj.id] = obj;
          });
          this.visited = true;
          this.sub_issues_list.forEach((item) => {
            if (item.id === tempObj[item.id]?.id) {
              item.is_enabled = true;
              this.supplyIssues.push(item.name);
              this.selected_sub_problem.push(item);
              item.utilized = false;
            }
          });
        }
      }
    });
  }
  clearRequestData() {
    this.request = "";
    if (this.selectedProblem && this.type_of_problem !== "Other") {
      this.problem[this.selectedProblem].issues.forEach(
        (item) => (item.is_enabled = false)
      );
    }
    if (this.sub_issues_list?.length > 0) {
      this.sub_issues_list.forEach((item) => (item.is_enabled = false));
    }
    this.type_of_problem = "";
    this.currentTab = 1;
    this.desc = "";
    this.imageArray = [];
    this.problem_date = new Date();
    this.selected_problem = "";
    this.selected_problems_Array = [];
    this.selected_sub_problem = [];
    this.selected_option_problem = {};
    this.supplyIssues = [];
    this.visited = false;
    this.saveSpinner = false
  }
  async upload(e: any) {
    let file = e.target.files[0];
    if (file.size > 5 * 1000000) {
      this.utils.openToast("Uploaded image should be less than 5MB", {});
    } else if (file.type !== "image/png" && file.type !== "image/jpeg") {
      this.utils.openToast("Please upload only jpeg or png images", {});
    } else {
      this.imageLoading = true;
      this.image = {
        public_id: "",
        version: "",
        format: "",
      };
      this.imageUploader.uploadCloudinary(
        file,
        `requests/${this.current_booking_data.booking_id}`,
        (response: any) => {
          this.imageLoading = false;
          if (response && response.url) {
            this.image = {
              public_id: response.public_id,
              version: "v" + response.version,
              format: response.format,
            };
            this.imageArray.push(this.image);
            this.profileImageUrl = this.utils.imageFormat(
              80,
              80,
              this.image,
              "c_fill"
            );
            e.target.value = "";
            let saveData = {
              image: this.image,
            };
          } else this.utils.openToast("Could not upload image", {});
        }
      );
    }
  }
  AssignRequest() {
    const dialog = this.dialog.open(RequestAssignComponent, {
      width: "400px",
      position: { right: "0px", top: "0px" },
      height: "100vh",
      data: { data: this.current_booking_data, request: this.request },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.createRequest(this.request && this.request.request_id, res);
        // this.loading = true;
      }
    });
  }

  removeImage(e, index) {
    this.imageArray.forEach((image) => {
      if (e.public_id === image.public_id) {
        this.imageArray.splice(index, 1);
      }
    });
  }
  getSubRequestList(id, callback) {
    this.url.get(
      `user/requests/services?service_id=${id}`,
      this.params,
      (res) => {
        if (res.success) {
          this.sub_issues_list = res.data.services;
          this.sub_issues_list.map((item) => {
            item["is_enabled"] = false;
          });
          callback(this.sub_issues_list);
        }
      }
    );
  }
  getrequestDetail(request_id) {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.stage_2 = this.stage_3 = this.stage_4 = this.stage_5 = false;
      this.url.get(`user/requests/${request_id}`, this.params, (res) => {
        if (res.success) {
          this.loading = false;
          this.show_request_details = true;
          this.request_details = res.data.requests;
          if (this.request_details?.review) {
            this.request_details.review.rating = Math.round(
              this.request_details.review.rating
            );
          }
          this.starsCount = new Array(
            this.request_details?.review?.rating
          ).fill(1);
          let data = res.data.requests.timeline;
          this.accepted_view = false;
          data.forEach((item) => {
            if (item.status === "pending") {
              this.status_1_date = this.utils.defaultDate(item.date);
              this.accepted_view = true;
              this.cancelled_view = false;
              this.rejected_view = false;
            }
            if (item.status === "accepted") {
              this.status_2_date = this.utils.defaultDate(item.date);
              this.stage_2 = true;
            }
            if (item.status === "in_progress") {
              this.status_3_date = this.utils.defaultDate(item.date);
              this.stage_3 = true;
            }
            if (item.status === "completed") {
              this.status_4_date = this.utils.defaultDate(item.date);
              this.stage_4 = true;
              this.close_request = true;
            }
            if (item.status === "closed") {
              this.status_5_date = this.utils.defaultDate(item.date);
              this.stage_5 = true;
              this.close_request = false;
            }
            if (item.status === "cancelled") {
              this.cancelled_view = true;
              this.accepted_view = false;
              this.cancelled_date = this.utils.defaultDate(item.date);
            }
            if (item.status === "rejected") {
              this.rejected_view = true;
              this.accepted_view = false;
              this.rejected_date = this.utils.defaultDate(item.date);
            }
          });
          resolve(true);
        } else resolve(false);
      });
    });
  }
}

<div class="sort-by__main">
  <div class="sort-by__heading">
    <label>SORT BY</label>
    <button mat-flat-button (click)="apply()">Apply</button>
  </div>
  <hr />
  <div class="sort-by__content">
    <div class="mt_20">
      <label for="newest_first">Newest First</label><br />
      <input
        type="radio"
        id="newest_first"
        name="sortby"
        value="newest"
        [ngModel]="sort_by"
        (change)="handleChange($event)"
      />
    </div>
    <div>
      <label for="low_to_high">Price -- Low to High</label><br />
      <input
        type="radio"
        id="low_to_high"
        name="sortby"
        value="price_low"
        [ngModel]="sort_by"
        (change)="handleChange($event)"
      />
    </div>
    <div>
      <label for="high_to_low">Price -- High to Low</label><br />
      <input
        type="radio"
        id="high_to_low"
        name="sortby"
        value="price_high"
        [ngModel]="sort_by"
        (change)="handleChange($event)"
      />
    </div>
  </div>
</div>

import { Injectable , OnInit } from "@angular/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CookieService } from "ngx-cookie";
@Injectable({
  providedIn: "root",
})
export class CookieServiceService {

  constructor(private cookieService: CookieService) {}

 

  cookie = this.cookieService.get("customer_token");
  getCooikie() {
    return this.cookieService.get("customer_token");
  }
  remove() {
    this.cookieService.remove("customer_token");
    this.cookieService.remove("transaction_id");
    this.cookieService.remove("check_in");
    this.cookieService.remove("payment_detail");
    this.cookieService.remove("profile_id");
    localStorage.removeItem("token");
  }

  isLoggedIn() {
    let cookie = this.cookieService.get("customer_token");
    // if (cookie) return true;
    if(cookie) {
      localStorage.setItem("token",cookie)
      return true
    }
    else return false;
  }
}

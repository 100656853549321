import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { UtilityService } from "../utility.service";
import { ApicallService } from "../apicall.service";
@Component({
  selector: "app-invitefriend",
  templateUrl: "./invitefriend.component.html",
  styleUrls: ["./invitefriend.component.css"],
})
export class InvitefriendComponent implements OnInit {
  constructor(public utils: UtilityService, public url: ApicallService) {}
  currentTab = "tenant";
  url_key: any = "";
  loading = false;
  ngOnInit(): void {}
  generateLink = () => {
    this.loading = true;
    this.url.post(`user/refer/${this.currentTab}`, {}, {}, (res) => {
      this.loading = false;
      if (res.success) {
        this.url_key = res.data.link;
      } else {
        this.utils.openToast("", res);
      }
    });
  };
  copyUrl = () => {
    navigator.clipboard.writeText(this.url_key);
    this.utils.openToastSuccess("Copied successfully", true);
  };
}

import { Component, OnInit ,OnChanges} from "@angular/core";
import { ApicallService } from "src/app/apicall.service";
import * as moment from "moment";
import { UtilityService } from "src/app/utility.service";
import { MatDialog } from "@angular/material/dialog";
import { TenderNoticeComponent } from "./tender-notice/tender-notice.component";
import { CancelNoticeComponent } from "./cancel-notice/cancel-notice.component";
import { BillComponent } from "./bill/bill.component";
import { Router, ActivatedRoute } from "@angular/router";
import { ProfileComponent } from "../../profile.component";
import { CookieService } from "ngx-cookie";
import { StatusCheckComponent } from "src/app/payment-checkout/status-check/status-check.component";
import { PayMethodComponent } from "../../../../pay-method/pay-method.component";
import { DepositeHistoryComponent } from "./deposite/deposite-history/deposite-history.component";
import { DepositeTypePaymentComponent } from "./deposite/deposite-type-payment/deposite-type-payment.component";
import { PreBookPopUpComponent } from "./pre-book-pop-up/pre-book-pop-up.component";
import { RentalApplicationComponent } from "src/app/components/back-link-pages/rental-application/rental-application.component";
import { HttpParams } from '@angular/common/http';
@Component({
  selector: "app-profile-bookings",
  templateUrl: "./profile-bookings.component.html",
  styleUrls: ["./profile-bookings.component.css"],
})

export class ProfileBookingsComponent implements OnInit {
  constructor(
    public url: ApicallService,
    public utils: UtilityService,
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    public profile: ProfileComponent,
    public cookie: CookieService
  ) {}

  paramsSubscription;
  booking;
  length;
  limit = 3;
  page: number = 1;
  loading = true;
  item;
  showButton = true;
  due_date;
  can_cancel_notice = true;
  params;
  lateFeeCount = 0;
  currentDate = moment(new Date())
  minCancelDateDifference
  tenantScreening:boolean = false;
  tenantStatus:string;
  bookingId:string;
  amountToPay;  
  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParams.subscribe(async(params) => {
      await this.getProperty()
      params.rental_application && this.redirectToRentalApplication()
    })
    const statusSubscription = this.route.queryParams.subscribe((params) => {
      if (params.status) {
        const dialog = this.dialog.open(StatusCheckComponent, {
          data: {
            status: params.status,
          },
        });
        dialog.afterClosed().subscribe((result) => {
          this.utils.routeWithQuery("profile", "bookings");
        });
      }
    });
    statusSubscription.unsubscribe();
  }

  ngOnChanges(){
 
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe()
  }

  getProperty() {
    return new Promise((resolve, reject) => {
      // if (this.bookingId) return resolve(true)
      this.loading = true
      this.url.get("user/property", this.params, (res) => {
        this.loading = false
        if (res.success) {
          this.item = res.data;
          this.tenantScreening = ((this.item?.booking?.screening) ? true : false)
          // this.tenantScreening = false
          this.tenantStatus =  this.item?.booking?.screening?.status
          this.bookingId = this.item.booking.booking_id
          if (
            this.item.security_deposit &&
            this.item.security_deposit.late_fee?.length > 0
          ) {
            this.item.security_deposit.late_fee.map((item) => {
              if (!item.is_waived) {
                this.lateFeeCount = this.lateFeeCount + 1;
              }
            });
          }
          this.loading = false;
          if (!this.item.notice) {
            this.showButton = true;
          }
          // if(this.item.property){
  
          // }
          this.minCancelDateDifference = Math.abs(this.currentDate.diff(moment(this.item.booking.check_in),'days'))
          
          if(this.item.property.min_cancel_days) {
          } 
  
          if (this.item.property?.check_out) {
            this.showButton = false;
          }
          if (!this.item.property?.check_out) {
            this.showButton = true;
          }
          if (this.item?.room && this.item.room?.check_out) {
            this.showButton = false;
          }
          if (this.item?.room && !this.item.room.check_out) {
            this.showButton = true;
          }
          if (this.item.notice && this.item.notice.status === "pending") {
            this.showButton = false;
          }
          if (
            (this.item.notice && this.item.notice.status === "cancelled") ||
            (this.item.notice && this.item.notice.status === "rejected")
          ) {
            this.showButton = true;
          }
          // if check_in date < today, don't show tender notice
          if (moment().diff(moment(this.item?.booking?.check_in), "days") < 0)
            this.showButton = false;
        } else {
          this.loading = true;
        }
        resolve(true)
        if (this.item && this.item.notice) {
          let today = moment().startOf('day');
          let not_date = moment(this.item.notice.notice_date);
          let total = not_date.diff(today, "days");
          this.can_cancel_notice = (this.item?.property?.min_cancel_days ? this.item?.property?.min_cancel_days: 7) >= total;
        }
        this.getAmountToPay()
        this.getBooking();
      });
    })
  }

  getAmountToPay() {
    const {room,property,outstanding_payment} = this.item
    let price;
    if(room && Object.keys(room).length > 0) {
      price = room?.price
    } else price = property.price
    this.amountToPay = (((price)/30) * property.notice_period.value) + outstanding_payment.amount
  }

  redirectToRentalApplication() {
    let rental_dialogRef = this.dialog.open(RentalApplicationComponent,{
      // width: '75%',
      // height:  '80%',
        // minWidth: '55%',
      // maxHeight: '100%',
      hasBackdrop: true,
      disableClose: true,
      panelClass:'rental_app_background',
      data:{
        bookingId:this.bookingId
      }
    })
    rental_dialogRef.afterClosed().subscribe((res) => {
      this.loading = true
      window.scrollTo(0,0)
      setTimeout(() => {
        this.loading = false
      },2000)
    })
  }

  onCompleteRentalApplication() {
    this.router.navigate([],{queryParams: { 'rental_application': 'true' },queryParamsHandling: 'merge',})
  }

  routeToCheck() {
    window.open("#" + `/check_list/${this.item.booking.booking_id}`, "_blank");
  }

  cancelPreBooked() {
    const dialog = this.dialog.open(PreBookPopUpComponent, {
      width: "450px",

      data: {
        invoiceAmount: this.item.outstanding_payment.amount,
        is_non_customer: false,
        message:this.minCancelDateDifference >= this.item.property.min_cancel_days ? 'You are already crossed the cancellation period for this booking. You will not receive any refund . Do you still want to cancel ?' : 'Are you sure you want to cancel your booking. Please Confirm !'
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.url.patch(
          `user/bookings/${this.item.booking.booking_id}`,
          {},
          "",
          (res) => {
            this.loading = false;
            if (res.success) {
              this.utils.openToast("", res);
              window.location.reload();
            }
          }
        );
      }
    });
  }

  payNow(amount) {
    if (amount) {
      const dialog = this.dialog.open(PayMethodComponent, {
        width: "652px",
        data: {
          invoiceAmount: amount,
          is_non_customer: false,
        },
      });
      dialog.afterClosed().subscribe((res) => {
        if (res) {
          this.loading = true;
          let data = {
            success_url: `${window.location.href}&status=success`,
            cancel_url: `${window.location.href}&status=failure`,
            type: res.data,
          };
          this.url.post(`user/transactions/bulk`, data, this.params, (res) => {
            this.loading = false;
            if (res.success) {
              window.open(res.data.link, "_self");
              this.cookie.put("transaction_id", res.data.transaction_id);
            } else {
              this.utils.openToast("", res);
            }
          });
        }
      });
    } 
    else {
      this.router.navigate([], {
        queryParams: {
          tab: "invoice",
          status: "created",
        },
      });
    }
  }

  viewHistory = () => {
    const dialog = this.dialog.open(DepositeHistoryComponent, {
      width: "450px",
      position: { right: "0px", top: "0px" },
      height: "100vh",
      data: { data: this.item.security_deposit.history, title: "History" },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
      }
    });
  };

  PayDeposite = () => {
    const dialog = this.dialog.open(DepositeTypePaymentComponent, {
      width: "400px",
      position: { right: "0px", top: "0px" },
      height: "100vh",
      data: this.item.security_deposit,
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
      }
    });
  };

  onPageChange(e) {
    if (e === "before" && this.page > 1) {
      this.page = this.page - 1;
    } else {
      this.page = this.page + 1;
    }
    
    this.getBooking();
  }

  getBooking() {
    this.url.get(
      `user/bookings?page=${this.page}&limit=${this.limit}`,
      this.params,
      (res) => {
        this.loading = false;
        if (res.success) {
          this.booking = res.data.bookings;
          this.length = res.data.total;
        } else {
        }
      }
    );
  }

  TenderNotice() {
    const dialog = this.dialog.open(TenderNoticeComponent, {
      data: {...this.item,amountToPay : this.amountToPay}
    });
    dialog.afterClosed().subscribe((result) => {
      if (result.event === 'success') {
        this.getProperty();
      }
    });
  }

  cancelNotice() {
    const cancelNotice = this.dialog.open(CancelNoticeComponent, {
      data: this.item,
    });
    cancelNotice.afterClosed().subscribe((result) => {
      if (result) {
        this.getProperty();
      }
    });
  }

  viewBill() {
    if (this.item.outstanding_payment.count === 1) {
      const bill = this.dialog.open(BillComponent, {
        data: this.item,
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          tab: "invoice",
        },
      });
    }
  }
  
}

export const Constants = {
  stagingConfig: {
    // BASE_URL: "http://172.18.25.115:1402/api/",
    BASE_URL: "http://3.16.194.5:8000/api/v1/",
    // BASE_URL : "http://192.168.0.183:1402/api/",
    HOST_URL: "http://3.16.194.5:1234",
    CONTRACTOR_URL: "http://3.16.194.5:4321",
    DOMAIN_NAME: "http://3.16.194.5",
  },
  liveConfig: {
    BASE_URL: "https://api.parknstays.com/api/v1/",
    HOST_URL: "https://host.parknstays.com",
    CONTRACTOR_URL: "https://pro.parknstays.com",
    DOMAIN_NAME: "https://parknstays.com",
  },
};

export const Config = {
  //LIve
  API_URL: Constants.liveConfig.BASE_URL,
  HOST_URL: Constants.liveConfig.HOST_URL,
  DOMAIN_NAME: Constants.liveConfig.DOMAIN_NAME,
  CONTRACTOR_URL: Constants.liveConfig.CONTRACTOR_URL,

  // Staging
  // API_URL: Constants.stagingConfig.BASE_URL,
  // HOST_URL: Constants.stagingConfig.HOST_URL,
  // CONTRACTOR_URL: Constants.stagingConfig.CONTRACTOR_URL,
  // DOMAIN_NAME: Constants.stagingConfig.DOMAIN_NAME,
};

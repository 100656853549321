import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { UtilityService } from "src/app/utility.service";
import { ApicallService } from "src/app/apicall.service";
import { CookieService } from "ngx-cookie";
import { ForgotPasswordComponent } from "src/app/forgot-password/forgot-password.component";
import { CookieServiceService } from "src/app/cookie-service.service";
import { EmailInviteComponent } from "./email-invite/email-invite.component";
import { DashboardComponent } from "../dashboard.component";
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
  FacebookLoginProvider,
} from "angularx-social-login";
import { GlobalStoreService } from "src/app/service/global-store.service";
@Component({
  selector: "app-login-signup-dialog",
  templateUrl: "./login-signup-dialog.component.html",
  styleUrls: ["./login-signup-dialog.component.css"],
})
export class LoginSignupDialogComponent implements OnInit {
  // toggle login-signup
  displayPanel = "login";
  saveSpinner = false;
  // login details
  email = "";
  password = "";
  pHide = true;
  checked = true;
  // signup details
  firstName = "";
  lastName = "";
  sEmail = "";
  mNum = "";
  pass = "";
  cnfPass = "";
  cnfPHide = true;
  params;
  social_login_token;
  constructor(
    private url: ApicallService,
    public utils: UtilityService,
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LoginSignupDialogComponent>,
    public urlparams: ActivatedRoute,
    private cookieService: CookieService,
    public cookie: CookieServiceService,
    private socialAuthService: SocialAuthService,
    public dashboard: DashboardComponent,
    private globalStore: GlobalStoreService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.email = "";
    this.password = "";
  }

  ngOnInit(): void {}

  onLoginClick = () => {
    this.saveSpinner = true;
    var eRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (
      this.email.trim() === "" ||
      this.email === undefined ||
      !eRegex.test(this.email)
    ) {
      this.saveSpinner = false;
      this.utils.openToast("Please enter valid email", {});
    } else if (this.password.trim() === "" || this.password === undefined) {
      this.saveSpinner = false;
      this.utils.openToast("Please enter valid password", {});
    } else if (this.checked === false) {
      this.saveSpinner = false;
      this.utils.openToast("Please accept the terms and conditions", {});
    } else {
      let data = {
        email: this.email,
        password: this.password,
      };
      this.url.post("auth/user/signin", data, this.params, (res) => {
        // let data = this.router.url;
        if(res.success){
          this.cookieService.put("customer_token", res.data.token, {
            expires: this.utils.expiryDate(),
          });
          this.cookieService.put("profile_id", res.data.user.user_id, {
            expires: this.utils.expiryDate(),
          });
          this.commonLoginRoute();
          this.dialogRef.close(res);
          this.dashboard.getNotifications();
        } 
        else {
          this.utils.openToast("", res);
          this.saveSpinner = false
        }
        
      });
    }
  };
  termsCondtions(e) {
    this.dialogRef.close();
    if (e === "terms") {
      this.router.navigate(["/terms_condtions"]);
    } else {
      this.router.navigate(["/privacy_policy"]);
    }
  }
  handleOnInputChange(e) {
    this.mNum = this.utils.getUSFormat(e);
  }
  onSignUpClick = () => {
    this.saveSpinner = true;
    let nameRegex = /^[A-Za-z]+$/;
    var eRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (this.firstName.trim() === "" || !nameRegex.test(this.firstName)) {
      this.saveSpinner = false;
      this.utils.openToast("Please enter valid First Name", {});
    } else if (this.lastName.trim() === "" || !nameRegex.test(this.lastName)) {
      this.saveSpinner = false;
      this.utils.openToast("Please enter valid Last Name", {});
    } else if (this.sEmail.trim() === "" || !eRegex.test(this.sEmail)) {
      this.saveSpinner = false;
      this.utils.openToast("Please enter valid email", {});
    } else if (this.mNum === "") {
      this.saveSpinner = false;
      this.utils.openToast("Please enter valid Phone Number", {});
    } else if (this.pass === "" || this.pass === undefined) {
      this.saveSpinner = false;
      this.utils.openToast("Please enter valid password", {});
    } else if (!passwordRegex.test(this.pass)) {
      this.saveSpinner = false;
      this.utils.openToast(
        "Password should contain atleast one uppercase one special character and one number",
        {}
      );
    } else if (this.cnfPass === "") {
      this.saveSpinner = false;
      this.utils.openToast("Please enter Confirm Password", {});
    } else if (this.cnfPass !== this.pass) {
      this.saveSpinner = false;
      this.utils.openToast("Password Matching Incorrect", {});
    } else if (this.checked === false) {
      this.saveSpinner = false;
      this.utils.openToast("Please accept the terms and conditions", {});
    } else {
      let data = {
        name: {
          first: this.firstName,
          last: this.lastName,
        },
        phone: {
          country_code: "1",
          national_number: this.utils.replaceToNormalNumber(this.mNum),
        },
        password: this.pass,
        email: this.sEmail,
      };
      this.url.post("auth/user/signup", data, this.params, (res) => {
        if (res.success) {
          this.cookieService.put("customer_token", res.data.token, {
            expires: this.utils.expiryDate(),
          });

          this.commonLoginRoute();
          this.dialogRef.close(res);
          this.dashboard.getNotifications();
        } else {
          this.saveSpinner = false
          this.utils.openToast("", res);
        }
      });
    }
  };
  openForget() {
    let token = this.cookie.getCooikie();
    this.dialogRef.close();
    this.dialog.open(EmailInviteComponent);
  }

  clearData = (panel) => {
    // login details
    this.email = "";
    this.password = "";
    this.pHide = true;

    // signup details
    this.firstName = "";
    this.lastName = "";
    this.sEmail = "";
    this.mNum = "";
    this.pass = "";
    this.cnfPass = "";
    this.cnfPHide = true;
    this.checked = true;
    if (panel === "login") {
      this.displayPanel = "signup";
    } else {
      this.displayPanel = "login";
    }
  };
  updateCheckBox(e) {
    this.checked = e;
  }
  async socialSignIn(media) {
    if (media == "facebook") {
      this.socialAuthService
        .signIn(FacebookLoginProvider.PROVIDER_ID)
        .then(async (userData) => {
          let data = {
            token: userData.authToken,
            id: userData.id,
            email: userData.email,
          };
          await this.socialMediaApi(data, media);
        });
    } else if (media == "google") {
      this.socialAuthService
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then(async (userData) => {
          await this.socialMediaApi(userData.idToken, media);
        });
    }
  }
  socialMediaApi(data, media) {
    let apiurl = "";
    let dataToSend;
    if (media === "google") {
      apiurl = "auth/user/google";
      dataToSend = {
        token: data,
      };
    } else if (media === "facebook") {
      apiurl = "auth/user/facebook";
      dataToSend = data;
    }

    this.url.post(`${apiurl}`, dataToSend, {}, (res) => {
      if (res.success) {
        this.dialogRef.close(res);
        this.cookieService.put("customer_token", res.data.token, {
          expires: this.utils.expiryDate(),
        });
        this.commonLoginRoute();
        this.dashboard.getNotifications();
      } else {
        this.utils.openToast("", res);
      }
    });
  }
  commonLoginRoute() {
    let data = this.router.url;
    if (data === "/") {
      this.globalStore.floatBar.next(!this.globalStore.floatBar);
      this.utils.routeWithQuery("profile", "bookings");
      // this.router.navigate(["/profile"]);
    } else {
      window.location.reload();
    }
  }
}

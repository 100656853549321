import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";
@Component({
  selector: "app-bill",
  templateUrl: "./bill.component.html",
  styleUrls: ["./bill.component.css"],
})
export class BillComponent implements OnInit {
  invoice;
  late_fee;
  constructor(
    public dialog: MatDialogRef<BillComponent>,
    public url: ApicallService,
    public utils: UtilityService,

    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.invoice = this.data.invoices ? this.data.invoices[0] : this.data;
    this.late_fee = this.data.outstanding_payment
      ? this.data.outstanding_payment.amount -
        this.data.outstanding_payment.base_price
      : this.invoice.amount - this.invoice.base_price;
  }
}

<div class="main_container d_flex_align_justify">
  <div class="loader" *ngIf="loading" style="height: 250px">
    <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
  </div>
  <div class="success_div" *ngIf="!loading && verify_status">
    <lottie-player
      autoplay
      mode="normal"
      src="../../assets/payment_status/verifySuccess.json"
      style="width: 150px"
    >
    </lottie-player>
    <p class="ft_family ft_500 ft_22 verify_color mb_20">Well Done!</p>
    <p class="ft_family ft_300 ft_14 mb_20">
      Your Email has been successfully Verified
    </p>
    <p class="ft_family ft_300 ft_14">
      You will be redirected to home page in {{ timer }} seconds
    </p>
  </div>
  <div class="failure_div" *ngIf="!loading && !verify_status">
    <lottie-player
      autoplay
      mode="normal"
      src="../../assets/payment_status/failureLottie.json"
      style="width: 150px"
    >
    </lottie-player>
    <p class="ft_family ft_500 ft_22 verify_color mt_20 mb_20">Oops!</p>
    <p class="ft_family ft_300 ft_14 mb_20">
      Something went Wrong Please try again
    </p>
    <p class="ft_family ft_300 ft_14 align_center">
      You will be redirected to home page in {{ timer }} seconds <br />
    </p>
    <a
      class="sub_text_green ft_500 align_center mt_20"
      href=""
      [routerLink]="['/profile']"
      >Go Back</a
    >
  </div>
</div>

import { Component, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { ApicallService } from "../apicall.service";
import * as moment from "moment";
import { UtilityService } from "../utility.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CookieServiceService } from "../cookie-service.service";
import { MatDialog } from "@angular/material/dialog";
import { LoginSignupDialogComponent } from "../components/dashboard/login-signup-dialog/login-signup-dialog.component";
import { InviteStatusComponent } from "../invite-status/invite-status.component";
import { CookieService } from "ngx-cookie";
import { NgOtpInputComponent } from "ng-otp-input";
import { FormControl, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-customer-invite",
  templateUrl: "./customer-invite.component.html",
  styleUrls: ["./customer-invite.component.css"],
})
export class CustomerInviteComponent implements OnInit {
  saveSpinner;
  check_in: any;
  params;
  todayDate = new Date(
    new Date(new Date().setMonth(new Date().getMonth() - 2)).setDate(1)
  );
  check_in_min = new Date();
  maxDate = new Date();
  loading = true;
  otp: any;
  invite_id;
  actual_start;
  data: any;
  form: FormGroup;
  isValidOtp = false;
  noticeDays;
  formInput = [
    {
      key: "input1",
      value: "",
    },
    {
      key: "input2",
      value: "",
    },
    {
      key: "input3",
      value: "",
    },
    {
      key: "input4",
      value: "",
    },
  ];
  disable_btn = true
  checkBox = {
    min_notice_period:false,
    accept_kyc:false
  }
  @ViewChildren("formRow") rows: any;
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput: NgOtpInputComponent;
  constructor(
    public url: ApicallService,
    public utils: UtilityService,
    public route: ActivatedRoute,
    public router: Router,
    public loggedIn: CookieServiceService,
    public dialog: MatDialog,
    public cookie: CookieService
  ) {
    this.form = this.toFormGroup(this.formInput);
  }

  ngOnInit(): void {
    let loginDialog;
    if (!this.loggedIn.isLoggedIn()) {
      loginDialog = this.dialog.open(LoginSignupDialogComponent, {
        data: {},
        disableClose: true,
      });
    }
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.invite_id = params.id;
        this.getInviteApi(params.id, loginDialog);
      }
      // let storedData = this.cookie.get(this.invite_id);
      // if (storedData) {
      //   let data = JSON.parse(storedData);
      //   this.check_in = data?.check_in;
      //   if (data.otp) {
      //     this.otp = data?.otp;
      //     if (this.ngOtpInput) this.ngOtpInput.setValue(data.otp);

      //     this.formInput.forEach((item, i) => {
      //       item.value = this.otp[i].value;
      //     });
      //   }
      // }
    });
  }

  getInviteApi(id, dialogBox) {
    this.url.get(`common/invite/${id}`, this.params, (res) => {
      this.loading = false;
      if (res.success) {
        this.data = res.data;
        this.noticeDays = res.data.property.notice_period.value
        this.maxDate = new Date(
          new Date(this.maxDate).setMonth(this.maxDate.getMonth() + 2)
        );
        this.actual_start = this.utils.dateUtc(res.data.invite.actual_start);
      } else {
        if (dialogBox) {
          dialogBox.close();
        }
        const dialog = this.dialog.open(InviteStatusComponent, {
          data: "failure",
          disableClose: true,
          autoFocus: true,
        });
      }
    });
  }

  handleCheckBox(e,from) {
    this.checkBox[from] = e.checked;
    this.checkDisable_btn()
  }

  checkDisable_btn(){
    this.formInput.forEach((item) => {
      if (!item.value) {
        return (this.isValidOtp = false);
      } else {
        this.isValidOtp = true;
      }
    });
    this.disable_btn = !(Object.values(this.checkBox).every(value => value === true) && this.isValidOtp)
  }
  changeCheckDate(e) {
    this.check_in = e;
  }
  redirect() {
    this.router.navigate(["/"]);
  }
  onOtpChange(e) {
    this.otp = e;
  }

  keyUpEvent(event, index) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
    this.checkDisable_btn()
  }
  restrictEvent(index) {}
  toFormGroup(elements) {
    const group: any = {};
    elements.forEach((item) => {
      group[item.key] = new FormControl(item.value, Validators.required);
    });
    return new FormGroup(group);
  }

  onSubmit() {
    this.saveSpinner = true    
    this.formInput.forEach((item) => {
      if (!item.value) {
        return (this.isValidOtp = false);
      } else {
        this.isValidOtp = true;
      }
    });
    // if (!this.loggedIn.isLoggedIn()) {
    //   let filledData = {
    //     check_in: this.check_in,
    //     otp: this.formInput,
    //   };

    //   if (this.check_in || filledData.otp) {
    //     this.cookie.put(`${this.invite_id}`, JSON.stringify(filledData));
    //   }
    //   const loginDialog = this.dialog.open(LoginSignupDialogComponent, {
    //     data: {},
    //   });
    // }
     if (this.check_in === "" || !this.check_in) {
      this.saveSpinner = false    
      this.utils.openToast("Please select check-in date", {});
    } else if (!this.isValidOtp) {
      this.saveSpinner = false    
      this.utils.openToast("Please enter valid OTP ", {});
    } else {
      let dummyOtp = "";
      this.formInput.forEach((item) => {
        dummyOtp += item.value;
      });
      let data = {
        check_in: this.check_in && moment(this.check_in).format("YYYY-MM-DD"),
        otp: dummyOtp,
        room_id: this.data.room && this.data.room.room_id,
      };
      this.url.post(
        `user/invite/accept/${this.invite_id}`,
        data,
        this.params,
        (res) => {
          if (res.success) {
            const dialog = this.dialog.open(InviteStatusComponent, {
              data: "success",
              autoFocus: true,
            });
            this.cookie.remove(this.params);
          } else {
            this.saveSpinner = false    
            this.utils.openToast("", res);
          }
        }
      );
    }
  }
}

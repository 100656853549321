<div class="main">
  <div>
    <div class="animation-ctn" *ngIf="stripeResponse === 'Success'">
      <div class="icon icon--order-success svg">
        <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
          <g fill="none" stroke="#22AE73" stroke-width="2">
            <circle
              cx="77"
              cy="77"
              r="72"
              style="stroke-dasharray: 480px, 480px; stroke-dashoffset: 960px"
            ></circle>
            <circle
              id="colored"
              fill="#22AE73"
              cx="77"
              cy="77"
              r="72"
              style="stroke-dasharray: 480px, 480px; stroke-dashoffset: 960px"
            ></circle>
            <polyline
              class="st0"
              stroke="#fff"
              stroke-width="10"
              points="43.5,77.8 63.7,97.9 112.2,49.4 "
              style="stroke-dasharray: 100px, 100px; stroke-dashoffset: 200px"
            />
          </g>
        </svg>
      </div>
    </div>
    <div
      class="animation-ctn"
      *ngIf="stripeResponse === 'Failed' || stripeResponse === 'payment_error'"
    >
      <div class="icon icon--order-success svg">
        <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
          <g fill="none" stroke="#F44812" stroke-width="2">
            <circle
              cx="77"
              cy="77"
              r="72"
              style="stroke-dasharray: 480px, 480px; stroke-dashoffset: 960px"
            ></circle>
            <circle
              id="colored"
              fill="#F44812"
              cx="77"
              cy="77"
              r="72"
              style="stroke-dasharray: 480px, 480px; stroke-dashoffset: 960px"
            ></circle>
            <polyline
              class="st0"
              stroke="#fff"
              stroke-width="10"
              points="43.5,77.8  112.2,77.8 "
              style="stroke-dasharray: 100px, 100px; stroke-dashoffset: 200px"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>

  <h5>
    Payment
    {{
      stripeResponse === "Success"
        ? "successful"
        : stripeResponse === "Failed"
        ? "failed"
        : stripeResponse === "Pending"
        ? "pending. We'll notify you shortly once it has been verified"
        : "error. Please try again later."
    }}
  </h5>
</div>

import { Component, OnInit, Inject, OnChanges } from "@angular/core";
import { UtilityService } from "src/app/utility.service";
import { CloudinaryService } from "src/app/cloudinary.service";
import { ApicallService } from "src/app/apicall.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AccountLinkStatusComponent } from "../../profile/components/profile-details/accountStatus/account-link-status/account-link-status.component";
import { AlertOverlayComponent } from "./alert-overlay/alert-overlay.component";
import { HttpParams } from "@angular/common/http";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CurrencyPipe} from '@angular/common';

@Component({
  selector: "app-rental-application",
  templateUrl: "./rental-application.component.html",
  styleUrls: ["./rental-application.component.css"],
})
export class RentalApplicationComponent implements OnChanges {
  customerSearch = "";
  page = 1;
  fillPage = 0;
  image;
  isBookIdFetched: boolean = false;
  minDate = new Date();
  maxAdditionalOccupants: number = 5;
  profileImageUrl;
  loading: boolean = false;
  selfEmpTax: boolean = false;
  salariedEmpSlip1 = false;
  salariedEmpPaySlip1Loading = false;
  saveSpinner = false;
  employee_references = [
    { value: "self_employed", checked: true },
    { value: "salaried_employee", checked: false },
  ];
  slips = [
    { value: "yes", checked: true },
    { value: "no", checked: false },
  ];
  query = {
    name: "",
  };
  type = "self_employed";
  haveSlips = true;
  addAccount: boolean = false;
  steps = [
    { step: "step 1", detail: "about_yourself", checked: true },
    { step: "step 2", detail: "kyc_details", checked: false },
    { step: "step 3", detail: "bank_details", checked: false },
    { step: "step 4", detail: "emergency_contact", checked: false },
    { step: "step 5", detail: "additional_occupants", checked: false },
    { step: "step 6", detail: "landlord_reference", checked: false },
    { step: "step 7", detail: "employer_reference", checked: false },
    { step: "step 8", detail: "success", checked: false },
  ];
  id_proofs = ["passport", "driving_license", "employer_id", "green_card"];
  address_proofs = [
    "passport",
    "green_card",
    "water_bill",
    "eB_bill",
    "existing_rental_agreement",
    "driving_license",
  ];
  national = [
    { name: "Albania", code: "AL" },
    { name: "Åland Islands", code: "AX" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "Andorra", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas (the)", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia (Plurinational State of)", code: "BO" },
    { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory (the)", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cabo Verde", code: "CV" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cayman Islands (the)", code: "KY" },
    { name: "Central African Republic (the)", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands (the)", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros (the)", code: "KM" },
    { name: "Congo (the Democratic Republic of the)", code: "CD" },
    { name: "Congo (the)", code: "CG" },
    { name: "Cook Islands (the)", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Curaçao", code: "CW" },
    { name: "Cyprus", code: "CY" },
    { name: "Czechia", code: "CZ" },
    { name: "Côte d'Ivoire", code: "CI" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic (the)", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Eswatini", code: "SZ" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (the) [Malvinas]", code: "FK" },
    { name: "Faroe Islands (the)", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories (the)", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia (the)", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and McDonald Islands", code: "HM" },
    { name: "Holy See (the)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran (Islamic Republic of)", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea (the Democratic People's Republic of)", code: "KP" },
    { name: "Korea (the Republic of)", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People's Democratic Republic (the)", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands (the)", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia (Federated States of)", code: "FM" },
    { name: "Moldova (the Republic of)", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands (the)", code: "NL" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger (the)", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands (the)", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestine, State of", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines (the)", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Republic of North Macedonia", code: "MK" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation (the)", code: "RU" },
    { name: "Rwanda", code: "RW" },
    { name: "Réunion", code: "RE" },
    { name: "Saint Barthélemy", code: "BL" },
    { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Martin (French part)", code: "MF" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Sint Maarten (Dutch part)", code: "SX" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "South Sudan", code: "SS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan (the)", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan (Province of China)", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands (the)", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates (the)", code: "AE" },
    {
      name: "United Kingdom of Great Britain and Northern Ireland (the)",
      code: "GB",
    },
    { name: "United States Minor Outlying Islands (the)", code: "UM" },
    { name: "United States of America (the)", code: "US" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands (British)", code: "VG" },
    { name: "Virgin Islands (U.S.)", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];
  properties = [];
  nations = [];
  responseData;
  userId;
  data = [
    {
      firstName: null,
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
      dateOfBirth: null,
      nationality: "",
      currentAddress: "",
      occupation: "",
      pets: null,
      smoker: null,
    },
    {
      kyc: {
        proof_one: {
          p_type: "",
          number: "",
          images: [
            {
              name: "Front",
              imageLoading: false,
              public_id: null,
            },
            {
              name: "Back",
              imageLoading: false,
              public_id: null,
            },
          ],
        },
        proof_two: {
          p_type: "",
          number: "",
          images: [
            {
              name: "front",
              imageLoading: false,
              public_id: null,
            },
            {
              name: "back",
              imageLoading: false,
              public_id: null,
            },
          ],
        },
      },
    },
    {
      bankName: null,
      accountNumber: null,
      payoutStatus: null,
      routingNumber: null,
    },
    // {
    //   property: "",
    // },
    {
      emergencyContactName: null,
      relation: null,
      emergencyMobileNumber: null,
      emailId: null,
      emergencyAddress: null,
    },
    {
      stayingAlone: false,
      noOfAdditionalOccupants: "1",
      additionalOccupants: [
        {
          name: null,
          phone: {
            national_number: null,
            country_code: null,
          },
          dob: null,
        },
      ],
    },
    {
      landlordName: "",
      phone: {
        national_number: "",
        country_code: "1",
      },
      // landlordNumber: null,
      landlordAddress: "",
    },
    {
      details: null,
      monthlyRevenue: null,
      salariedEmpCompName: null,
      salariedEmpNumber: null,
      salariedEmpEmail: null,
      salariedEmpAddress: null,
      haveSlips: this.haveSlips,
      taxSlip: {
        name: "tax_slip",
        taxslip_uploading: false,
        public_id: null,
        // taxSlip: null,
        // taxSlipUploaded: false,
      },
      paySlips: [
        {
          name: "payslip 1",
          payslips_Loading: false,

          // paySlip: null,
          // uploaded: false,
          public_id: null,
        },
        {
          name: "payslip 2",
          payslips_Loading: false,
          // paySlip: null,
          public_id: null,
          // uploaded: false,
        },
        // {
        //   name: "payslip 3",
        //   payslips_Loading: false,
        //   // paySlip: null,
        //   public_id: null,
        //   // uploaded: false,
        // },
      ],
      notes: null,
      employer: {
        is_self_emp: true,
        employeement: "",
        revenue: "",
        images: "",
        company: "",
        email: "",
        phone: {
          national_number: "",
          country_code: "1",
        },
        address: "",
        is_pay_slip: "",
        notes: "",
      },
    },
  ];
  bookingId: string;
  searchTimeOut;

  constructor(
    @Inject(MAT_DIALOG_DATA) public value: any,
    public utils: UtilityService,
    public imageUploader: CloudinaryService,
    public url: ApicallService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RentalApplicationComponent>,
    public router: Router,
    private currencyPipe : CurrencyPipe,
  ) {
    this.nations = this.national;
    this.bookingId = value.bookingId;
  }

  ngOnChanges() {
  }



  async ngOnInit() {
    await this.getProfile();
    const revokedData = JSON.parse(localStorage.getItem("data"));
    if (revokedData) {
      const linkStatus = true;
      this.data = revokedData;
      this.addAccount = revokedData.isAccountAdded;
      this.page = revokedData.page ?? 1;
      this.fillPage = revokedData.fillpage ?? 0;
      this.steps.forEach((obj, i) => {
        if (i + 1 <= this.fillPage) {
          obj.checked = true;
        }
      });
      if (linkStatus) {
        const dialog = this.dialog.open(AccountLinkStatusComponent, {
          data: {
            status: this.responseData,
          },
        });
        dialog.afterClosed().subscribe((result) => {
          localStorage.removeItem("data");
        });
      }
    }
    // });
  }

  onNext() {
    const dataObj = this.data[this.fillPage - 1];
    let error = false;
    let proof1Image = [];
    let proof2Image = [];
    let payslip = [];
    switch (this.fillPage) {
      case 1:
        if (!dataObj.firstName) {
          error = true;
          return this.utils.openToast("Please enter your first name", {});
        } else if (!dataObj.mobileNumber) {
          error = true;
          return this.utils.openToast("Please enter your mobile number", {});
        } else if (!dataObj.emailAddress) {
          error = true;
          return this.utils.openToast("Please enter your email address", {});
        } else if (!dataObj.dateOfBirth) {
          error = true;
          return this.utils.openToast("Please select your date of birth", {});
        } else if (!dataObj.nationality) {
          error = true;
          return this.utils.openToast("Please select your nationality", {});
        } else if (dataObj.nationality && this.ifNationalityExists()) {
          error = true;
          return this.utils.openToast("Please enter a valid nationality", {});
        } else if (!dataObj.currentAddress) {
          error = true;
          return this.utils.openToast("Please enter your current address", {});
        } else if (+dataObj.currentAddress.trim().length < 10) {
          error = true;
          return this.utils.openToast(
            "Address should have at least 10 character",
            {}
          );
        } else if (!dataObj.occupation) {
          error = true;
          return this.utils.openToast("Please enter your occupation", {});
        } else if (
          dataObj.occupation &&
          this.isSpecialCharactersIncluded(dataObj.occupation)
        ) {
          error = true;
          return this.utils.openToast("Please enter a valid occupation", {});
        } else if (!dataObj.pets) {
          error = true;
          return this.utils.openToast("Please select the pet option", {});
        } else if (!dataObj.smoker) {
          error = true;
          return this.utils.openToast("Please select the smoking option", {});
        }
        break;
      case 2:
        if (!dataObj.kyc.proof_one.p_type) {
          error = true;
          return this.utils.openToast(
            "Please select the option for ID proof",
            {}
          );
        } else if (!dataObj.kyc.proof_one.number) {
          error = true;
          return this.utils.openToast(
            "Please enter the ID number for ID proof",
            {}
          );
        } else if (
          this.isSpecialCharactersIncluded(dataObj.kyc.proof_one.number)
        ) {
          error = true;
          return this.utils.openToast(
            "ID number should not contain any special characters",
            {}
          );
        } else if (
          this.atleastOneImageNotPresent(dataObj.kyc.proof_one, "proof_one")
        ) {
          error = true;
          return this.utils.openToast(
            "Please add at least one image for ID proof",
            {}
          );
        } else if (!dataObj.kyc.proof_two.p_type) {
          error = true;
          return this.utils.openToast(
            "Please select the option for address proof",
            {}
          );
        } else if (!dataObj.kyc.proof_two.number) {
          error = true;
          return this.utils.openToast(
            "Please enter the ID number for address proof",
            {}
          );
        } else if (
          this.isSpecialCharactersIncluded(dataObj.kyc.proof_two.number)
        ) {
          error = true;
          return this.utils.openToast(
            "ID number should not contain any special characters",
            {}
          );
        } else if (
          this.atleastOneImageNotPresent(dataObj.kyc.proof_two, "proof_two")
        ) {
          error = true;
          return this.utils.openToast(
            "Please add at least one image for address proof",
            {}
          );
        }
        break;
      case 3:
        if (this.addAccount) {
          if (!dataObj.bankName) {
            error = true;
            return this.utils.openToast("Please add bank name", {});
          } else if (!dataObj.accountNumber) {
            error = true;
            return this.utils.openToast("Please add the account number", {});
          }
        }
        break;
      // case 4:
      //   if (!dataObj.property) {
      //     error = true;
      //     return this.utils.openToast("Please select the property", {});
      //   }
      //   break;
      case 4:
        if (!dataObj.emergencyContactName) {
          error = true;
          return this.utils.openToast("Please enter the contact name", {});
        } else if (dataObj.emergencyContactName.length < 2) {
          error = true;
          return this.utils.openToast(
            "Name should have at lest 3 characters",
            {}
          );
        } else if (
          this.isSpecialCharactersIncluded(dataObj.emergencyContactName)
        ) {
          error = true;
          return this.utils.openToast(
            "Name should not contain any special characters",
            {}
          );
        } else if (!dataObj.relation) {
          error = true;
          return this.utils.openToast("Please enter the relationship", {});
        } else if (this.isSpecialCharactersIncluded(dataObj.relation)) {
          error = true;
          return this.utils.openToast("Enter a valid relation name", {});
        } else if (dataObj.relation.length < 2) {
          error = true;
          return this.utils.openToast(
            "Name should have at lest 3 characters",
            {}
          );
        } else if (!dataObj.emergencyMobileNumber) {
          error = true;
          return this.utils.openToast("Please enter the contact number", {});
        } else if (dataObj.emergencyMobileNumber.length < 16) {
          error = true;
          return this.utils.openToast("Please enter valid contact number ", {});
        } else if (
          dataObj.emailId &&
          this.utils.isInvalidEmail(dataObj.emailId.trim())
        ) {
          error = true;
          return this.utils.openToast("Please enter a valid email id", {});
        } else if (
          dataObj.emergencyAddress &&
          dataObj.emergencyAddress.trim().length < 10
        ) {
          error = true;
          return this.utils.openToast(
            "Address should have at least 10 character",
            {}
          );
        }
        break;
      case 5:
        this.getPropertyList(this.query);
        if (!this.data[4].stayingAlone) {
          if (
            this.checkErrorInAdditionalOccupants(dataObj.additionalOccupants)
          ) {
            error = true;
          }
        }
        break;
      case 6:
        if (!dataObj.landlordName) {
          error = true;
          return this.utils.openToast("Please enter the landlord name", {});
        } else if (this.isSpecialCharactersIncluded(dataObj.landlordName)) {
          error = true;
          return this.utils.openToast("Please enter a valid name", {});
        } else if (dataObj.landlordName.length < 2) {
          error = true;
          return this.utils.openToast(
            "Name should have at lest 3 characters",
            {}
          );
        } else if (!dataObj.phone.national_number) {
          error = true;
          return this.utils.openToast(
            "Please enter the landlord contact number",
            {}
          );
        } else if (dataObj.phone.national_number.length < 16) {
          error = true;
          return this.utils.openToast("Please enter valid contact number", {});
        } else if (
          dataObj.landlordAddress &&
          dataObj.landlordAddress.trim().length < 10
        ) {
          error = true;
          return this.utils.openToast(
            "Address should have at least 10 characters",
            {}
          );
        }
        break;
      case 7:
        if (this.type === "self_employed") {
          if (!dataObj.details) {
            error = true;
            return this.utils.openToast("Please enter the details", {});
          } else if (!dataObj.monthlyRevenue) {
            error = true;
            return this.utils.openToast("Please enter the monthly revenue", {});
          }
          // else if (dataObj.monthlyRevenue && (typeof(+dataObj.monthlyRevenue) === Number)) {
          //   error = true;
          //   return this.utils.openToast("Please enter the monthly revenue", {});
          // }  
          else if (!dataObj.taxSlip.public_id) {
            error = true;
            return this.utils.openToast("Please upload a tax slip", {});
          }
        } else if (this.type === "salaried_employee") {
          if (!dataObj.salariedEmpCompName) {
            error = true;
            return this.utils.openToast("Please enter the company name", {});
          } else if (
            this.isSpecialCharactersIncluded(dataObj.salariedEmpCompName)
          ) {
            error = true;
            return this.utils.openToast("Please enter a valid name", {});
          } else if (dataObj.salariedEmpCompName.length < 2) {
            error = true;
            return this.utils.openToast(
              "Name should have at lest 3 characters",
              {}
            );
          } else if (!dataObj.salariedEmpNumber) {
            error = true;
            return this.utils.openToast("Please enter the contact number", {});
          } else if (dataObj.salariedEmpNumber.length < 16) {
            error = true;
            return this.utils.openToast(
              "Please enter valid contact number",
              {}
            );
          } else if (
            dataObj.salariedEmpEmail &&
            this.utils.isInvalidEmail(dataObj.salariedEmpEmail.trim())
          ) {
            error = true;
            return this.utils.openToast("Please enter valid email id", {});
          } else if (
            dataObj.salariedEmpAddress &&
            dataObj.salariedEmpAddress.trim().length < 10
          ) {
            error = true;
            return this.utils.openToast("Please enter the address", {});
          } else if (
            this.haveSlips &&
            this.atleastOneImageNotPresent(dataObj.paySlips, "payslip")
          ) {
            error = true;
            return this.utils.openToast(
              "Please add at least one image for payslip",
              {}
            );
          } else if (!this.haveSlips && !dataObj.notes) {
            error = true;
            return this.utils.openToast("Please enter the notes", {});
          }
        }
        break;
    }
    if (!error) {
      if (this.fillPage === 3 && !this.addAccount) {
        this.addAccount = true;
      } else if (this.fillPage < 7) {
        this.steps[this.fillPage].checked = true;
        this.fillPage += 1;
      } else if (this.fillPage === 7) {
        // this.data[6].paySlips.forEach((obj) => {
        //   obj.paySlip && payslip.push(obj.paySlip);
        // }
        // );
        const obj = {
          extra: {
            occupation: this.data[0].occupation,
            nationality: this.data[0].nationality,
            is_smoker: this.data[0].smoker === "yes" ? true : false,
            kyc: this.data[1].kyc,
            occupants: {
              is_extra: this.data[4].stayingAlone ? false : true,
              count: this.data[4].stayingAlone
                ? 0
                : this.data[4].noOfAdditionalOccupants,
              list: this.data[4].stayingAlone
                ? []
                : this.data[4].additionalOccupants,
            },
            landlord: {
              name: this.data[5].landlordName,
              phone: {
                national_number: this.data[5].phone.national_number,
                country_code: "1",
              },
              // email: String,
              address: this.data[5].landlordAddress,
            },
            employer: {
              is_self_emp: this.type === "self_employed" ? true : false,
              employeement:
                this.type === "self_employed" ? this.data[6].details : null,
              revenue:
                this.type === "self_employed"
                  ? this.data[6].monthlyRevenue
                  : null,
              images:
                this.type === "self_employed"
                  ? [this.data[6].taxSlip]
                  : this.data[6].paySlips,
              company:
                this.type === "self_employed"
                  ? null
                  : this.data[6].salariedEmpCompName,
              email:
                this.type === "self_employed"
                  ? null
                  : this.data[6].salariedEmpEmail,
              phone: {
                national_number:
                  this.type === "self_employed"
                    ? null
                    : this.data[6].salariedEmpNumber,
                country_code: String,
              },
              address:
                this.type === "self_employed"
                  ? null
                  : this.data[6].salariedEmpAddress,
              is_pay_slip:
                this.type === "self_employed" ? null : this.data[6].haveSlips,

              notes: this.type === "self_employed" ? null : this.data[6].notes,
            },
            address: {
              line1: this.data[0].currentAddress,
            },
            dob: this.data[0].dateOfBirth,
          },
          emergency_contact: {
            name: this.data[3].emergencyContactName,
            phone: {
              national_number: this.utils.replaceToNormalNumber(
                this.data[3].emergencyMobileNumber
              ),
              country_code: "1",
            },
            email: this.data[3].emailId,
            address: this.data[3].emergencyAddress,
            relationship: this.data[3].relation,
          },
        };
        this.submit(obj);
      }
    }
  }

  ifNationalityExists() {
    let error = true;
    this.national.forEach((obj) => {
      if (obj.name.toLowerCase() === this.data[0].nationality.toLowerCase()) {
        error = false;
      }
    });
    return error;
  }

  submit(obj) {
    const alert = this.dialog.open(AlertOverlayComponent, {
      width: "550px",
      // height:'200px',
      data: {
        title: "Submit Rental Application",
        subTitle:
          " Please make sure that you updated all information, Our team will verify the details you submitted. Are you sure you want to submit now ?",
      },
    });
    alert.afterClosed().subscribe((res) => {
      if (res.res) {
        this.updateProfile(obj);
      }
    });
  }

  updateProfile(data) {
    this.saveSpinner = true;
    this.url.patch(
      `user/bookings/screening/${this.bookingId}`,
      data,
      {},
      (res) => {
        this.saveSpinner = false;
        if (res.success) {
          this.utils.openToast("", res);
          window.scrollTo(0, 0);
          this.steps[this.fillPage].checked = true;
          this.fillPage++;
        } else {
          this.utils.openToast("", res);
        }
      }
    );
  }

  checkPaySlip(data) {
    let error = false;
    data.forEach((element) => {
      if (!element.uploaded) {
        error = true;
        return this.utils.openToast(
          `Please add the ${element.name} document`,
          {}
        );
      }
    });
    return error;
  }

  toProfile() {
    this.router.navigate(["/profile"], { queryParams: { tabs: "profile" } });
    this.dialogRef.close();
  }

  startnow() {
    this.page += 1;
    this.fillPage += 1;
  }

  getPropertyList(query) {
    this.url.get(
      `common/properties/list${this.utils.queryParamasGenerator(query)}`,
      {},
      (res) => {
        this.properties = res.data.properties;
      }
    );
  }

  onBack() {
    if (this.fillPage >= 1) {
      this.fillPage -= 1;
    }
  }

  queryParamasGenerator(params) {
    this.utils.queryParamasGenerator(params);
  }

  isSpecialCharactersIncluded(string) {
    let error = false;
    const s = "[@_!#$%^&*()<>?}{~:;-=+'/.]";
    for (let i = 0; i < string.length; i++) {
      s.includes(string[i]) && (error = true);
    }
    return error;
  }

  atleastOneImageNotPresent(data, from) {
    let error = true;
    let tempArray;
    (from === "proof_one" || from === "proof_two") && (tempArray = data.images);
    from === "payslip" && (tempArray = data);
    tempArray.forEach((obj) => {
      obj.public_id && (error = false);
    });
    return error;
  }

  checkErrorInAdditionalOccupants(data) {
    let error = false;
    data.forEach((element, i) => {
      if (!element.name) {
        error = true;
        this.utils.openToast(`Please enter the name for person ${i + 1}`, {});
      } else if (this.isSpecialCharactersIncluded(element.name)) {
        error = true;
        return this.utils.openToast("Please enter a valid name", {});
      } else if (element.name.length < 2) {
        error = true;
        return this.utils.openToast(
          "Name should have at lest 3 characters",
          {}
        );
      } else if (!element.phone.national_number) {
        error = true;
        this.utils.openToast(`Please enter the number for person ${i + 1}`, {});
      } else if (element.phone.national_number.length < 16) {
        error = true;
        this.utils.openToast(
          `Please enter the valid contact number for person ${i + 1}`,
          {}
        );
      }
    });
    return error;
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.url.get("user", {}, (res) => {
        if (res.success) {
          this.loading = false;
          this.responseData = res.data.user;
          this.userId = this.responseData.user_id;
          this.addAccount = this.responseData.stripe.is_bank;
          this.data[0] = {
            firstName: this.responseData.name.first,
            lastName: this.responseData.name.last,
            mobileNumber: this.utils.getUSFormat(
              this.responseData.phone.national_number
            ),
            emailAddress: this.responseData.email,
            dateOfBirth: this.responseData?.extra?.dob,
            pets:
              Object.keys(this.responseData?.pets).length > 0 ? "yes" : "no",
            nationality: this.responseData?.extra?.nationality,
            occupation: this.responseData?.extra?.occupation,
            currentAddress: this.responseData?.extra?.address?.line1,
            smoker: this.responseData?.extra?.is_smoker ? "yes" : "no",
          };

          this.data[1].kyc.proof_one.p_type =
            this.responseData?.extra?.kyc?.proof_one?.p_type;
          this.data[1].kyc.proof_one.number =
            this.responseData?.extra?.kyc?.proof_one?.number;
          if (this.responseData?.extra?.kyc?.proof_one.images.length > 0) {
            this.data[1].kyc.proof_one.images.map((obj1, i) => {
              this.responseData?.extra?.kyc?.proof_one.images[i] &&
                (this.data[1].kyc.proof_one.images[i] =
                  this.responseData?.extra?.kyc.proof_one.images[i]);
              if (this.responseData?.extra.kyc.proof_one.images[i]) {
                const tempObj = {
                  ...this.responseData?.extra?.kyc.proof_one.images[i],
                  name: i === 0 ? "Front" : "Back",
                  imageLoading: false,
                };
                this.data[1].kyc.proof_one.images[i] = tempObj;
              }
            });
          }
          this.data[1].kyc.proof_two.p_type =
            this.responseData?.extra?.kyc?.proof_two?.p_type;
          this.data[1].kyc.proof_two.number =
            this.responseData?.extra?.kyc?.proof_two?.number;
          if (this.responseData?.extra?.kyc.proof_two.images.length > 0) {
            this.data[1].kyc.proof_two.images.map((obj1, i) => {
              this.responseData?.extra?.kyc.proof_two.images[i] &&
                (this.data[1].kyc.proof_two.images[i] =
                  this.responseData?.extra?.kyc.proof_two.images[i]);
              if (this.responseData?.extra?.kyc.proof_two.images[i]) {
                const tempObj = {
                  ...this.responseData?.extra?.kyc.proof_two.images[i],
                  name: i === 0 ? "Front" : "Back",
                  imageLoading: false,
                };
                this.data[1].kyc.proof_two.images[i] = tempObj;
              }
            });
          }
          if (this.addAccount) {
            this.data[2].bankName = this.responseData.stripe.name;
            this.data[2].accountNumber = this.responseData.stripe.ac_number;
            this.data[2].payoutStatus = this.responseData.stripe.payout_enabled
              ? "Enabled"
              : "Information Required";
            this.data[2].routingNumber = this.responseData.stripe.route_number;
          }
          if (this.responseData?.emergency_contact) {
            this.data[3].emergencyContactName =
              this.responseData.emergency_contact.name;
            this.data[3].relation =
              this.responseData.emergency_contact.relationship;
            this.data[3].emergencyMobileNumber = this.utils.getUSFormat(
              this.responseData.emergency_contact.phone.national_number
            );
            this.data[3].emailId = this.responseData.emergency_contact?.email;
          }
          this.data[4].stayingAlone = !this.responseData?.extra?.occupants
            .is_extra
            ? true
            : false;
          this.responseData?.extra?.occupants.count &&
            (this.data[4].noOfAdditionalOccupants = "1");
          if (this.responseData?.extra?.occupants.list) {
            this.data[4].additionalOccupants =
              this.responseData?.extra?.occupants.list;
            this.data[4].additionalOccupants.map((obj) => {
              obj.phone.national_number = this.utils.getUSFormat(
                obj.phone.national_number
              );
            });
          }

          this.data[5].landlordName = this.responseData?.extra?.landlord.name;
          this.data[5].phone.national_number = this.utils.getUSFormat(
            this.responseData?.extra?.landlord.phone.national_number
          );
          this.data[5].landlordAddress =
            this.responseData?.extra?.landlord?.address;

          this.employee_references.forEach((obj) => {
            obj.checked = false;
            this.responseData;
          });

          this.data[6].employer.is_self_emp =
            this.responseData?.extra?.employer?.is_self_emp;
          this.employee_references.forEach((obj) => {
            obj.checked = false;
            if (this.data[6].employer.is_self_emp) {
              this.employee_references[0].checked = true;
              this.type = "self_employed";
            } else {
              this.employee_references[1].checked = true;
              this.type = "salaried_employee";
            }
          });
          if (this.responseData?.extra?.employer?.is_self_emp) {
            this.data[6].details = this.responseData?.extra?.employer
              ?.is_self_emp
              ? this.responseData?.extra?.employer?.employeement
              : "";
            this.data[6].monthlyRevenue = this.responseData?.extra?.employer
              ?.is_self_emp
              ? this.responseData?.extra?.employer?.revenue
              : "";
            this.data[6].taxSlip = this.responseData?.extra?.employer?.is_self_emp
              ? this.responseData?.extra?.employer?.images[0]
              : {};

            if (Object.keys(this.data[6].taxSlip).length > 0) {
              const obj = {
                ...this.responseData?.extra?.employer.images[0],
                taxslip_uploading: false,
                name: "tax_slip",
              };
              this.data[6].taxSlip = obj;
            }
          } else {
            this.haveSlips = this.data[6].haveSlips =
              this.responseData?.extra?.employer?.is_pay_slip;
            this.slips.forEach((obj) => {
              obj.checked = false;
              if (this.haveSlips) {
                this.slips[0].checked = true;
              } else this.slips[1].checked = true;
              // if (obj.value === this.haveSlips) {
              //   obj.checked = true;
              // }
            });
            this.data[6].notes = this.responseData?.extra?.employer?.notes;
            this.data[6].salariedEmpCompName = !this.responseData?.extra
              ?.employer.is_self_emp
              ? this.responseData?.extra?.employer.company
              : "";
            this.data[6].salariedEmpNumber = !this.responseData?.extra?.employer
              .is_self_emp
              ? this.utils.getUSFormat(
                  this.responseData?.extra?.employer.phone.national_number
                )
              : "";
            this.data[6].salariedEmpEmail = !this.responseData?.extra?.employer
              .is_self_emp
              ? this.responseData?.extra?.employer?.email
              : "";

            this.data[6].salariedEmpAddress = !this.responseData?.extra
              ?.employer.is_self_emp
              ? this.responseData?.extra?.employer?.address
              : "";

            if (this.data[6].haveSlips) {
              this.data[6].paySlips.forEach((obj, i) => {
                this.responseData?.extra?.employer.images[i] &&
                  (this.data[6].paySlips[i] =
                    this.responseData?.extra?.employer.images[i]);
                if (this.responseData?.extra?.employer.images[i]) {
                  const tempObj = {
                    ...this.responseData?.extra?.employer.images[i],
                    name: `Payslip ${i + 1}`,
                    payslips_Loading: false,
                  };
                  this.data[6].paySlips[i] = tempObj;
                }
              });
            }
          }
        }
        resolve(true);
      });
    });
  }

  onBankClick(from) {
    if (from === "skip") {
      this.steps[this.fillPage].checked = true;
      this.fillPage += 1;
    } else if (from === "update") return this.linkAccount();
  }

  linkAccount() {
    this.loading = true;
    let data = {
      refresh_url: `${window.location.href}?link_status=success`,
      return_url: `${window.location.href}?link_status=success&page=${this.page}&fillpage=${this.fillPage}`,
    };
    localStorage.setItem(
      "data",
      JSON.stringify({
        ...this.data,
        isAccountAdded: this.addAccount,
        page: this.page,
        fillpage: this.fillPage,
        link_status: true,
      })
    );
    this.url.post(`user/link_bank`, data, {}, (res) => {
      this.loading = false;
      if (res.success) {
        window.open(res.data.link, "_self");
      } else {
        this.utils.openToast("", res);
      }
    });
  }

  setSessionStorage(Object) {
    sessionStorage.setItemItem("data", JSON.stringify(Object));
  }

  addAdditionalOccupants(number) {
    this.data[4].noOfAdditionalOccupants = number;
    const occupantArray = [...this.data[4].additionalOccupants];
    const length = occupantArray.length;
    if (number > length)
      for (let i = length; i < number; i++) {
        occupantArray.push({
          name: null,
          phone: {
            national_number: null,
            country_code: null,
          },
          dob: null,
        });
      }
    else occupantArray.splice(number);
    this.data[4].additionalOccupants = occupantArray;
  }

  isStayingAlone(event) {
    this.data[4].stayingAlone = event === "true" ? true : false;
    !this.data[4].stayingAlone &&
      this.addAdditionalOccupants(this.data[4].noOfAdditionalOccupants);
  }

  onCheck(e, i, type) {
    if (type === "reference") {
      this.employee_references.forEach((obj) => {
        obj.checked = false;
      });
      if (this.employee_references[i].value === "self_employed") {
        this.data[6].employer.is_self_emp = true;
      } else this.data[6].employer.is_self_emp = false;
      this.employee_references[i].checked = e.source._checked;
      this.type = e.value;
    } else if (type === "slips") {
      this.slips.forEach((obj) => {
        obj.checked = false;
      });
      this.slips[i].checked = e.source._checked;
      this.haveSlips = e.value === "yes";
      this.data[6].haveSlips = e.value === "yes";
    }
  }

  removeSalariedEmpImage(e, index) {
    if (this.data[6].paySlips[index].public_id === e.public_id) {
      this.data[6].paySlips[index].public_id = "";
      // this.data[6].paySlips[index].uploaded = false;
    }
  }

  removeSelfEmp(obj) {
    if (this.data[6].taxSlip.public_id === obj.public_id) {
      this.data[6].taxSlip.public_id = "";
      // this.data[6].taxSlip.taxSlipUploaded = false;
    }
  }

  removeProofImage(e, index, from) {
    if (from === "proof_one") {
      if ((this.data[1].kyc.proof_one.images[index].public_id = e.public_id)) {
        this.data[1].kyc.proof_one.images[index].public_id = "";
      }
    } else if (from === "proof_two") {
      if ((this.data[1].kyc.proof_two.images[index].public_id = e.public_id)) {
        this.data[1].kyc.proof_two.images[index].public_id = "";
      }
    }
  }

  async upload(e: any, from, index) {
    let file = e.target.files[0];
    if (file.size > 5 * 1000000) {
      this.utils.openToast("Uploaded image should be less than 5MB", {});
    } else if (
      file.type !== "image/png" &&
      file.type !== "image/jpeg" &&
      // file.type !== "application/pdf" &&
      file.type !== "image/svg+xml"
    ) {
      this.utils.openToast("Please upload only jpeg ,png ,svg files", {});
    } else {
      if (["proof_one", "proof_two"].includes(from))
        this.data[1].kyc[from].images[index].imageLoading = true;
      if (from === "selEmpTaxSlip") {
        this.data[6].taxSlip.taxslip_uploading = true;
        // this.data[6].taxSlip.taxSlipUploaded = true;
      }
      if (from === "salaried_emp_paySlip") {
        this.data[6].paySlips[index].payslips_Loading = true;
        // this.data[6].paySlips[index].uploaded = true;
      }
      this.image = {
        public_id: "",
        version: "",
        format: "",
      };
      this.imageUploader.uploadCloudinary(
        file,
        `user/${this.userId}/rental_application_${from}`,
        (response: any) => {
          if (response && response.url) {
            this.image = {
              public_id: response.public_id,
              version: "v" + response.version,
              format: response.format,
            };
            if (from === "proof_one") {
              const obj = {
                ...this.data[1].kyc.proof_one.images[index],
                ...this.image,
              };
              this.data[1].kyc.proof_one.images[index] = obj;
              this.data[1].kyc.proof_one.images[index].imageLoading = false;
            } else if (from === "proof_two") {
              const obj = {
                ...this.data[1].kyc.proof_one.images[index],
                ...this.image,
              };
              this.data[1].kyc.proof_two.images[index] = obj;
              this.data[1].kyc.proof_two.images[index].imageLoading = false;
            } else if (
              from === "selEmpTaxSlip" &&
              this.type === "self_employed"
            ) {
              const obj = {
                ...this.data[6].taxSlip,
                ...this.image,
              };
              this.data[6].taxSlip = obj;
              this.data[6].taxSlip.taxslip_uploading = false;
            } else if (from === "salaried_emp_paySlip") {
              const obj = {
                ...this.data[6].paySlips[index],
                ...this.image,
              };
              this.data[6].paySlips[index] = obj;
              this.data[6].paySlips[index].payslips_Loading = false;
            }
            this.profileImageUrl = this.utils.imageFormat(
              80,
              80,
              this.image,
              "c_fill"
            );
            e.target.value = "";
            let saveData = {
              image: this.image,
            };
          } else this.utils.openToast("Could not upload image", {});
        }
      );
    }
  }

  handleOnInputChange(e, from) {
    if (from === "nationality") {
      const obj = this.national.filter((obj) => {
        return obj.name.toLowerCase().includes(e.toLowerCase());
      });
      this.nations = obj;
    }
  }

  propertySearch(event) {
    if (this.query.name !== event) {
      clearTimeout(this.searchTimeOut);
      this.query.name = event;
      this.searchTimeOut = setTimeout(() => {
        this.getPropertyList(this.query);
      }, 1000);
    }
  }

  // onPropertSelect(obj) {
  //   this.data[4].property = obj;
  // }

  getUsFormat(e, from, index?) {
    if (from === "emergency") {
      this.data[3].emergencyMobileNumber = this.utils.getUSFormat(e);
    } else if (from === "is_extra") {
      this.data[4].additionalOccupants[index].phone.national_number =
        this.utils.getUSFormat(e);
    } else if (from === "landlord") {
      this.data[5].phone.national_number = this.utils.getUSFormat(e);
    } else if (from === "salaried") {
      this.data[6].salariedEmpNumber = this.utils.getUSFormat(e);
    }
  }

  getUSD(number) {
    this.data[6].monthlyRevenue = this.currencyPipe.transform(this.data[6].monthlyRevenue).slice(1);
  }

  IdHandler(e, from) {
    if (from === "id_2") {
      this.data[1].kyc.proof_two.number = e.toUpperCase();
    } else if (from === "id_1") {
      this.data[1].kyc.proof_one.number = e.toUpperCase();
    }
  }
}

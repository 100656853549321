<div class="card_box" style="width: 440px">
  <div
    *ngIf="loader === true"
    class="d_flex_align_justify"
    style="flex-direction: column"
  >
    <mat-spinner diameter="80"></mat-spinner>
    <p class="mt_20 mb_20"></p>
    <p class="ft_family sub_content_color ft_18 align_center">
      Please wait this will take some time.
    </p>
  </div>
  <div
    class="icon icon--order-success svg d_flex_align"
    style="flex-direction: column"
    *ngIf="loader === false && status"
  >
    <lottie-player
      autoplay
      loop
      mode="normal"
      src="../../../assets/payment_status/lf20_nus9odlt.json"
      style="width: 150px"
    >
    </lottie-player>
    <p class="mb_20 ft_family ft_500 ft_18 sub_content_color mt_20">
      Bank Account Updated Successfully
    </p>
  </div>
  <div
    class="icon icon--order-success svg d_flex_align"
    style="flex-direction: column"
    *ngIf="!loader && !status"
  >
    <lottie-player
      autoplay
      loop
      mode="normal"
      src="../../../assets/payment_status/95614-error-occurred.json"
      style="width: 150px"
    ></lottie-player>
    <p class="mb_20 ft_family ft_500 ft_18 sub_content_color mt_20">
      Bank Account Link Failed
    </p>
    <p class="mb_30 ft_family ft_16 ft_400 sub_content_color align_center">
      Please try again after sometime.
    </p>
  </div>
</div>

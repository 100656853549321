<div class="overlayContainer">
    <div style="text-align: center;" class="overlayTitle">{{title}}</div>
    <div style="margin-bottom: 30px;text-align: center">{{subTitle}}</div>
    <div class="d_flex_align_justify">
        <button
            mat-stroked-button
            class="login100-form-btn mrl_15 cancel mr_15"
            (click)="cancel()"
            >
            No Not Now
        </button>
        <button-spinner
            childClass="common_button_bg"
            mat-flat-button
            type="submit"
            (click)="proceed()"
            >
            Yes Proceed
        </button-spinner>

    </div>
</div>

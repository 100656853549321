import { Component, OnInit, Inject } from "@angular/core";
import * as moment from "moment";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilityService } from "src/app/utility.service";
import { ApicallService } from "src/app/apicall.service";
@Component({
  selector: "app-tender-notice",
  templateUrl: "./tender-notice.component.html",
  styleUrls: ["./tender-notice.component.css"],
})
export class TenderNoticeComponent implements OnInit {
  vaccate;
  reason;
  params;
  minDate = new Date();
  vaccateAlarm = false;
  notice_date;
  set_disabled = false;
  totalAmoount;
  outstanding_payment;
  isChecked = false;
  saveSpinner = false;
  amount_to_pay;
  constructor(
    public dialog: MatDialogRef<TenderNoticeComponent>,
    public utils: UtilityService,
    public url: ApicallService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    if (this.data.notice) {
      this.vaccate = this.utils.dateUtc(this.data.notice.notice_date);
      this.vaccate = new Date(this.vaccate);
      this.reason = this.data.notice.reason;
    }
    let due_date = parseInt(this.data.property.notice_period.value);
    this.outstanding_payment = this.data.outstanding_payment.amount;
    this.addDaysToDate(new Date(), due_date);
  }

  addDaysToDate(date, days) {
    var res = new Date(date);
    res.setDate(res.getDate() + days);
    this.notice_date = moment(this.utils.dateUtc(res)).toDate();
  }
  handleCheckBox(e) {
    this.isChecked = e.checked;
  }
  changeCheckDate(e) {
    this.vaccate = e;
    let diff = moment(e).diff(moment(this.notice_date), "days");
    if (diff < 0) this.vaccateAlarm = true;
    else this.vaccateAlarm = false;
  }
  onSubmit() {
    if (this.vaccate === "" || this.vaccate === undefined) {
      this.utils.openToast("Please select a date", {});
    } else if (
      this.reason === "" ||
      this.reason === undefined ||
      this.reason.length <= 3
    ) {
      this.utils.openToast("Please enter a valid reason", {});
    } else {
      this.set_disabled = true;
      let data = {
        notice_date: moment(this.vaccate).format("YYYY-MM-DD"),
        reason: this.reason,
        use_deposit: this.isChecked,
      };
      this.saveSpinner = true;
      if (this.data.notice) {
        this.url.patch(
          `user/notice/${this.data.notice.notice_id}`,
          data,
          this.params,
          (res) => {
            this.set_disabled = false;
            if (res.success) {
              this.dialog.close({ event: "success" });
              this.utils.openToast("", res);
            }
          }
        );
      } else {
        this.url.post(
          `user/notice/${this.data.booking.booking_id}`,
          data,
          this.params,
          (res) => {
            this.set_disabled = false;
            if (res.success) {
              this.dialog.close({ event: "success" });
              this.utils.openToast("", res);
            } else {
              this.utils.openToast("", res);
            }
          }
        );
      }
    }
  }
}

<div class="invite_main_container">
  <div class="left_container">
    <img src="../../assets/newIcons/inviteBanner.svg" />
  </div>
  <div class="right_container">
    <p class="ft_18 sub_text_green mb_15 ft_600">Refer a Friend</p>
    <p class="sub_content_color ft_13 ft_500 mb_24">
      Just share the link with your friends and family and ask them to signup
      and receive rewards for it.
    </p>
    <p class="mb_24 ft_500 sub_text">Select whom to refer</p>
    <div class="type_container mb_24">
      <div
        class="solo_container align_center cursor_pointer"
        (click)="this.currentTab = 'tenant'"
        [ngClass]="[currentTab === 'tenant' ? 'active_invite' : '']"
      >
        <img class="mb_10" src="../../assets/newIcons/inviteTenant.svg" />
        <p class="ft_14 ft_500">Tenant</p>
      </div>
      <div
        class="solo_container align_center cursor_pointer"
        (click)="this.currentTab = 'host'"
        [ngClass]="[currentTab === 'host' ? 'active_invite' : '']"
      >
        <img class="mb_10" src="../../assets/newIcons/inviteHost.svg" />
        <p class="ft_14 ft_500">Host</p>
      </div>
      <div
        class="solo_container align_center cursor_pointer"
        (click)="this.currentTab = 'contractor'"
        [ngClass]="[currentTab === 'contractor' ? 'active_invite' : '']"
      >
        <img class="mb_10" src="../../assets/newIcons/inviteService.svg" />
        <p class="ft_14 ft_500">Service Pro</p>
      </div>
    </div>
    <div class="url_container mb_24" *ngIf="this.url_key">
      <div class="url_1">
        <p>{{ this.url_key }}</p>
      </div>
      <div class="url_2 cursor_pointer" (click)="copyUrl()">
        <i class="fa fa-link" aria-hidden="true"></i>
        Copy
      </div>
    </div>
    <div *ngIf="!this.url_key">
      <button class="generate_button" (click)="generateLink()">
        <div class="button_spinner" *ngIf="loading">
          <mat-spinner class="mr_10" [diameter]="20"></mat-spinner>
        </div>
        Generate Link
      </button>
    </div>
  </div>
</div>

import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ApicallService } from "../apicall.service";
import { DashboardComponent } from "../components/dashboard/dashboard.component";
import { CookieServiceService } from "src/app/cookie-service.service";
import * as moment from "moment";
import { UtilityService } from "../utility.service";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  constructor(
    private router: Router,
    public url: ApicallService,
    public cookie: CookieServiceService,
    public utils:UtilityService,
    public dashboard: DashboardComponent
  ) {}
  profile;
  lastActivity;
  ngOnInit(): void {
    if (this.cookie.isLoggedIn()) {
      this.getProfile();
    }
    this.lastActivity =
      moment().format("DD MMM, YYYY") + " - " + moment().format("hh:mm A");
  }
  rental_type;

  params;
  room_type;
  rentalType(room_size) {
    let queryPrams: any = {};
    if (room_size) {
      queryPrams["room_type"] = room_size;
      queryPrams["rental_type"] = "by_room";
    } else {
      queryPrams["rental_type"] = "entire_home";
    }
    this.router.navigate(["search"], { queryParams: queryPrams });
  }
  getProfile() {
    this.url.get("user", this.params, (res) => {
      if (res.success) {
        this.profile = res.data.user;
      }
    });
  }
}

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApicallService } from "src/app/apicall.service";
@Component({
  selector: "app-cancel-document",
  templateUrl: "./cancel-document.component.html",
  styleUrls: ["./cancel-document.component.css"],
})
export class CancelDocumentComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<CancelDocumentComponent>,
    public url: ApicallService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(MAT_DIALOG_DATA) public value
  ) {}

  ngOnInit(): void {
  }
  onSubmit() {
    this.url.delete(`user/documents/${this.data.data}`, {}, (res) => {
      if (res.success) {
        this.dialog.close(res);
      }
    });
  }
}

import {
  Component,
  Inject,
  AfterContentInit,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApicallService } from "src/app/apicall.service";
import * as moment from "moment";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit {
  @ViewChild("scrollContainer") scrollContainer: ElementRef;
  @ViewChild("inputTextarea") inputTextarea: ElementRef;
  requestId;
  status:string;
  date;
  chats;
  row: number = 1;
  myForm: FormGroup;
  comment: string = "";
  btnLoader: boolean = false;
  initPageSpinner: boolean = true;
  moment = moment;
  name: string = "";
  roles: string[] = ["contractor", "host", "manager"];
  enterKey:boolean = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<ChatComponent>,
    public url: ApicallService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    this.myForm = fb.group({
      email: [
        "",
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
  }

  async ngOnInit() {
    this.requestId = this.data.requestId;
    this.status = this.data.request
    await this.getChats();
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  handleKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' && (event.shiftKey || event.ctrlKey) && !event.repeat) {
      event.preventDefault();
      this.insertLineBreak();
    } else if (event.key === 'Enter' && !event.repeat) {
      event.preventDefault();
      this.onSend()
    } else if (event.key === 'Backspace') {
      this.adjustTextareaHeight();
    }
  }

  handleKeyUp(event:KeyboardEvent){

  }

  adjustTextareaHeight(): void {
    const textarea: HTMLTextAreaElement = this.inputTextarea.nativeElement;
    const lines = textarea.value.split('\n').length;
    const newHeight = lines * 20; // Adjust this value as needed

    textarea.style.height = `${newHeight}px`;
  }

  insertLineBreak(): void {
    const textarea: HTMLTextAreaElement = this.inputTextarea.nativeElement;
    const { selectionStart, selectionEnd, value } = textarea;
    const newValue =  value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd);
    textarea.value = newValue;
    textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
    textarea.style.height = this.inputTextarea.nativeElement.scrollHeight + 'px';
  }

  scrollToBottom(): void {
    if (this.scrollContainer && this.scrollContainer?.nativeElement) {
      this.scrollContainer.nativeElement.scrollTop =
        this.scrollContainer.nativeElement.scrollHeight;
    }
  }

  getChats = () => {
    return new Promise((resolve, reject) => {
      this.url.get(`user/chats/${this.requestId}`, {}, (res) => {
        this.initPageSpinner = false;
        if (res.success) {
          this.chats = res.data.chats;
          this.chats.reverse();
          resolve(true);
        } else {
          resolve(false);
        }
        this.cdr.markForCheck();
      });
    });
  };

  CheckRepeatName = (chat) => {
    let returnvalue = false;
    if (
      this.name !==
      (chat?.contractor?.name ?? chat?.host?.name ?? chat?.manager?.name)
    ) {
      this.name =
        chat?.contractor?.name ?? chat?.host?.name ?? chat?.manager?.name;
      returnvalue = true;
    }
    return returnvalue;
  };

  returnName = (chat) => {
    return chat?.contractor?.name ?? chat?.host?.name ?? chat?.manager?.name;
  };

  dateCollection = (obj) => {
    let returnValue = false;
    let startDay = moment(obj.createdAt).startOf("day").format("DD MMMM");
    if (!this.date.includes(startDay)) {
      this.date.push(moment(obj.createdAt).format("DD MMMM"));
      returnValue = true;
    }
    return returnValue;
  };

  dateCollectionChat = (obj) => {
    let retrunValue = null;
    if (this.date !== moment(obj.createdAt).startOf("day").format()) {
      this.date = retrunValue = moment(obj.createdAt).startOf("day").format();
    }
    return retrunValue;
  };

  checkRole = (obj) => {
    return Object.keys(obj).includes("user") ? true : false;
  };

  returnTimeInSec(chatTime) {
    return moment(chatTime).format("LT");
  }

  isToday = (obj) => {
    let getDate = moment(obj.createdAt).startOf("day").format();
    let today = moment().startOf("day").format();
    return today === getDate ? "Today" : moment(getDate).format("DD MMM YYYY");
  };

  onSend = () => {
    const textarea: HTMLTextAreaElement = this.inputTextarea.nativeElement;
    if (this.comment.trim()) {
      this.btnLoader = true;
      const data = {
        content: this.comment.trimStart(),
        source: this.requestId,
      };
      this.url.post(`user/chats`, data, {}, (res) => {
        this.btnLoader = false;
        if (res.success) {
          let resData = res.data.chat;
          delete resData._id;
          delete resData.updatedAt;
          delete resData.__v;
          this.chats.push(resData);
          this.comment = "";
          textarea.style.height = '30px'
          setTimeout(() => {
            this.scrollToBottom();
          }, 100);
          this.cdr.markForCheck();
        }
      });
    }
  };
}

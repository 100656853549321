import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "app-profile",
  templateUrl: "./success-popup.component.html",
  styleUrls: ["./success-popup.component.css"],
})
export class PaymentSuccessComponent implements OnInit {
  name = "";
  content = {
    header: "",
    text: "",
    isLoading: false,
  };

  selectedIndex = 0;
  stripeResponse="";

  constructor(
    private dialogRef: MatDialogRef<PaymentSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.stripeResponse = this.data.response;

    setTimeout(() => {
      this.onCloseClick();
    }, 5000);
  }

  onCloseClick() {
    this.dialogRef.close("cancel");
    this.data.redirectToMainProfile();
  }
}

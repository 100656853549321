import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Constants } from "./app.constants";
import { ApicallService } from "./apicall.service";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { ToastMessageComponent } from "./toast-message/toast-message.component";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UtilityService {
  email$ = new BehaviorSubject("");
  name$ = new BehaviorSubject("")
  date$ = new BehaviorSubject("")
  constructor(
    public snackbar: MatSnackBar,
    private apiservice: ApicallService,
    public dialog: MatDialog,
    public router: Router
  ) {}
  open(message, action) {
    this.snackbar.open(message, action, {
      duration: 2000,
      horizontalPosition: "left",
    });
  }
  close() {
    this.snackbar.dismiss();
  }
  dateUtc(date) {
    return moment.utc(date).format("MMM DD, YYYY");
  }
  dayMonthUtc(date) {
    return moment.utc(date).format("MMM DD");
  }
  monthFormatDate(date) {
    return date ? moment(date).format("MMM DD, YYYY") : "";
  }
  expiryDate() {
    let date = new Date();
    return new Date(date.setDate(date.getDate() + 7));
  }
  defaultDate(date) {
    return moment(date).format("MMM DD, YYYY");
  }
  returnYYYYMMDDAddformat = (date: any, value: any, unit: any) => {
    if (date) return moment(date).add(value, unit).format("YYYY-MM-DD");
  };
  returnCloudinaryUrl(cloudname: string) {
    return this.apiservice.cloudinary_base_url + cloudname + "/auto/upload";
  }
  returnYear() {
    return moment().year();
  }

  isInvalidEmail(email) {
    let eRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let CapsRegex = /[A-Z]/;
    if (CapsRegex.test(email)) {
      return true
    } else {
      if (!eRegex.test(email)) return true;
      else return false;
    }
  }

  returnRoomPrice = (property: any) => {
    if (property.rental_type === "entire_home")
      return "$ " + property.price.toLocaleString();
    if (property.room_price) {
      if (property.room_price.length > 0) {
        let price: any = property.room_price[0];
        return price.min === price.max
          ? this.displayPrice(price.max)
          : `${this.displayPrice(price.min)} -  ${price.max.toLocaleString()}`;
      }
    }
    if (!property) return 0;
    if (!property.price) return 0;
  };

  mapPrice(property) {
    if (!property) return 0;
    if (property.rental_type === "entire_home")
      return "$ " + property.price.toLocaleString();
    if (property.room_price) {
      if (property.room_price.length > 0) {
        let price: any = property.room_price[0];
        return price.min === price.max
          ? this.displayPrice(price.max)
          : ` ${"$" + this.formatCash(price.min)} -  ${this.formatCash(
              price.max
            )}`;
      }
    }
  }
  formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };
  displayPrice = (price) => {
    if (price) {
      return "$" + price.toLocaleString();
    } else {
      return "$0";
    }
  };

  queryParamasGenerator = (obj) => {
    let queryParams = "";
    Object.keys(obj).map((string) => {
      queryParams += `&${string}=${obj[string]}`;
    });
    return "?" + queryParams.slice(1);
  };

  routeWithQuery = (url, query) => {
    this.router.navigate([url], {
      queryParams: {
        tab: query,
      },
    });
  };

  removeUnderscoreAndCapitalize(status: string) {
    if (!status) return "";
    let arr = status.split("_");
    arr.forEach((val, i) => {
      arr[i] = val[0].toUpperCase() + val.slice(1);
    });

    return arr.join(" ");
  }

  dateFilter(value, from, to) {
    let start = new Date();
    let end = new Date();
    if (value === "this_month") {
      start.setDate(1);
      start = new Date(start);
      start.setHours(0, 0, 0, 0);
    } else if (value === "last_month") {
      start.setDate(1);
      start.setHours(0, 0, 0, 0);
      start.setMonth(start.getMonth() - 1);
      end.setMonth(end.getMonth() - 1);
      end = new Date(end.getFullYear(), end.getMonth() + 1, 0);
      end.setHours(23, 59, 59, 999);
    } else if (value === "this_year") {
      start.setDate(1);
      start.setMonth(0);
      start.setHours(0, 0, 0, 0);
    } else {
      start = new Date(from);
      to = new Date(from);
    }
    return { start, end };
  }
  getUSFormat(number) {
    if (!number) return null;
    const input = number.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    let newNumber = "";
    if (input.length > 6) {
      newNumber = `(${areaCode}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      newNumber = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      newNumber = `(${areaCode}`;
    }
    return newNumber;
  }

  replaceToNormalNumber(number) {
    let newNumber = number.replace(/[- )(]/g, "");
    return newNumber;
  }
  openToast(message, response) {
    this.dialog.open(ToastMessageComponent, {
      panelClass: "snackbar",
      position: {
        right: "50%",
        top: "1%",
      },

      data: {
        response: response,
        message: message,
      },
      hasBackdrop: false,
    });
  }
  openToastSuccess(message, showSuccess) {
    this.dialog.open(ToastMessageComponent, {
      panelClass: "snackbar",
      position: {
        right: "50%",
        top: "1%",
      },

      data: {
        response: { success: true, message: message },
        message: "",
      },
      hasBackdrop: false,
    });
  }
  returnSourceImg(image) {
    return image ? this.returnImageUrl("", image) : "";
  }

  imageFormat(width, height, image, fit) {
    if (image) {
      return this.returnImageUrl(
        `fl_lossy,w_${width},h_${height},${fit ? fit : ""}/`,
        image
      );
    }
  }

  returnImageUrl(scale, image) {
    return (
      "https://res.cloudinary.com/parknstays/image/upload/" +
      scale +
      image.version +
      "/" +
      image.public_id +
      "." +
      image.format
    );
  }

  removeAddTransparent(element) {
    window.onscroll = function () {
      if (element) {
        if (window.pageYOffset > 30) {
          element.classList.add("db-toolbar-container");
          element.classList.remove("transparent");
        } else {
          element.classList.add("transparent");
          element.classList.remove("db-toolbar-container");
        }
      }
    };
  }
}

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UtilityService } from "../utility.service";
@Component({
  selector: "app-invite-status",
  templateUrl: "./invite-status.component.html",
  styleUrls: ["./invite-status.component.css"],
})
export class InviteStatusComponent implements OnInit {
  loader: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InviteStatusComponent>,
    public router: Router,
    public utils: UtilityService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false
    },10000)
  }
  reRoute(status) {
    if (status === "success") {
      this.utils.routeWithQuery("profile", "profile");
      // this.router.navigate(["/profile"]);
      this.dialogRef.close();
    } else {
      this.router.navigate(["/"]);
      this.dialogRef.close();
    }
  }
}

<mat-spinner *ngIf="loading" class="spinner" [diameter]="50"></mat-spinner>
<div>
  <div
    style="display: flex; justify-content: flex-end"
    class="paginator mb_30"
    *ngIf="!loading"
  >
    <button
      [disabled]="page <= 1"
      [ngClass]="page <= 1 ? 'inactive_button' : 'active_button'"
      (click)="onPageChange('before')"
    >
      <i class="fa fa-angle-left" style="font-size: 24px"></i>
    </button>
    <button
      [disabled]="notification.length < limit"
      [ngClass]="
        notification.length < limit ? 'inactive_button' : 'active_button'
      "
      (click)="onPageChange('after')"
    >
      <i class="fa fa-angle-right" style="font-size: 24px"></i>
    </button>
  </div>
</div>
<div class="notification_box profile_content card" *ngIf="!loading">
  <table class="profile_table">
    <tr class="profile_table_header card-header">
      <th class="pl_20">Notifications</th>
    </tr>
    <tr
      class="profile_table_border cursor_pointer card-body cursor_pointer"
      *ngFor="let item of notification; let i = index"
      [ngClass]="item.read === 0 && 'unread_message'"
      (click)="markAsRead(item, i)"
    >
      <td>
        <div class="notifications">
          <div class="d_flex_space_align mb_10">
            <h2
              class="ft_family ft_16 ft_500 _m"
              [ngClass]="item.read === 0 && 'unread_message'"
            >
              {{ item.title }}
            </h2>
            <p
              class="sub_content_color ft_14 ft_family"
              [ngClass]="item.read === 0 && 'unread_message'"
            >
              {{ utils.defaultDate(item.createdAt) }}
            </p>
          </div>

          <p
            style="line-height: 20px; overflow: hidden"
            [ngStyle]="{ height: item.message.length > 400 ? '60px' : '100%' }"
            class="sub_content_color ft_14 mb_20"
            [ngClass]="item.read === 0 && 'unread_message'"
            [id]="'para_' + [i]"
          >
            {{ item.message }}
          </p>
          <p
            class="cursor_pointer ft_14 mb_20 para"
            [id]="'show_more' + [i]"
            (click)="toggle('show', i)"
            *ngIf="item.message.length > 400"
          >
            Show More
          </p>
          <p
            class="cursor_pointer show_less para mb_20 para"
            [id]="'show_less' + [i]"
            (click)="toggle('hide', i)"
          >
            Show Less
          </p>
        </div>
      </td>
    </tr>
    <tr class="profile_table_border" *ngIf="notification.length === 0">
      <td style="padding-left: 19px; padding-bottom: 20px">
        No Notifications Available
      </td>
    </tr>
  </table>
</div>

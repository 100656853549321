import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilityService } from "../../utility.service";
import { PropertySearchComponent } from "../property-search.component";
import { AgmCoreModule } from "@agm/core";
@Component({
  selector: "app-map-view",
  templateUrl: "./map-view.component.html",
  styleUrls: ["./map-view.component.css"],
})
export class MapViewComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<MapViewComponent>,
    public utils: UtilityService,
    public searchComponent: PropertySearchComponent
  ) {
 
  }
  property_list;
  page;
  limit;
  width: number = window.innerWidth;
  ngOnInit(): void {
    this.property_list = this.data.property_list;
    this.page = this.data.page;
    this.limit = this.data.itemsPerPage;
  }
  // onPageChange(e) {
  //   this.searchComponent.onPageChange(e, "popUp");
  // }
}

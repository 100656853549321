import { Injectable } from "@angular/core";
import { CookieServiceService } from "../../app/cookie-service.service";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private cookie: CookieServiceService) {}

  isLoggedIn = () => {
    return this.cookie.getCooikie();
  };
}

import { Component, OnInit, Input } from "@angular/core";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import { DashboardComponent } from "src/app/components/dashboard/dashboard.component";
@Component({
  selector: "app-notifications-list",
  templateUrl: "./notifications-list.component.html",
  styleUrls: ["./notifications-list.component.css"],
})
export class NotificationsListComponent implements OnInit {
  constructor(
    public url: ApicallService,
    public utils: UtilityService,
    public dashboard: DashboardComponent,
    private router: Router,
  ) {}
  notification;
  loading = true;
  page = 1;
  limit = 5;
  params;
  readNotificationsArray: Array<any> = [];
  ngOnInit(): void {
    this.getNotifications();
  }
  getNotifications() {
    this.url.get(
      `user/notification?page=${this.page}&limit=${this.limit}`,
      this.params,
      (res) => {
        this.loading = false;
        if (res.success) {
          this.notification = res.data.notifications;
        }
      }
    );
  }
  markAsRead(item, index) {
    let queryParams = {
      tab: 'requests',
      id:item.ref
    }
    this.router.navigate(["/profile"], { queryParams: queryParams });
    if (this.notification[index].read === 0) {
      this.notification[index].read = 1;
      this.readNotificationsArray.push(item.notification_id);
      let data = {
        ids: this.readNotificationsArray,
      };
      this.url.patch(`user/notification/read`, data, {}, (res) => {
        if (res.success) {
          this.dashboard.getNotifications();
        }
      });
    }

  }
  onPageChange(e) {
    if (e === "before" && this.page > 1) {
      this.page = this.page - 1;
    } else {
      this.page = this.page + 1;
    }

    this.getNotifications();
  }
  toggle(e, i) {
    let para = document.getElementById("para_" + i);
    let show = document.getElementById("show_more" + i);
    let hide = document.getElementById("show_less" + i);

    if (e === "show") {
      para.style.height = "100%";
      show.style.display = "none";
      hide.style.display = "block";
    } else {
      para.style.height = "60px";
      show.style.display = "block";
      hide.style.display = "none";
    }
  }
}

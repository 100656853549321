import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  BreakpointObserver,
  Breakpoints,
  MediaMatcher,
} from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { LoginSignupDialogComponent } from "./login-signup-dialog/login-signup-dialog.component";
import { AuthService } from "src/app/service/auth.service";
import { CookieServiceService } from "src/app/cookie-service.service";
import { Config } from "src/app/constant";
import { UtilityService } from "src/app/utility.service";
import { ApicallService } from "src/app/apicall.service";
import { CookieService } from "ngx-cookie";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { RecommendContractorComponent } from "src/app/recommend-contractor/recommend-contractor.component";
import { GlobalStoreService } from "src/app/service/global-store.service";
import { InvitefriendComponent } from "src/app/invitefriend/invitefriend.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  mobileQuery: MediaQueryList;
  private popupOpenSubscription: Subscription;
  private _mobileQueryListener: () => void;
  isLoggedIn: any = false;
  name = "";
  image;
  profileData;
  notification_count;
  profileImageUrl;
  transparentnavbar = "true";
  params;
  responsive_nav = false;
  hostUrl = Config.HOST_URL;
  constructorUrl = Config.CONTRACTOR_URL;
  responsive_side_bar = false;
  isProfilePage = false;
  isProfileRoute = false;
  width: number = window.innerWidth;
  floatButton = false;
  openFloatOption = false;
  currentProperty;
  mobileView: boolean = false
  constructor(
    private dialog: MatDialog,
    private breakPointObserver: BreakpointObserver,
    media: MediaMatcher,
    public utils: UtilityService,
    public auth: AuthService,
    public url: ApicallService,
    private urlQuery: ActivatedRoute,
    public cookie: CookieServiceService,
    public cookieService: CookieService,
    private router: Router,
    private globalStore: GlobalStoreService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 800px)");
    this.checkLogin();
    this.routerListner();

    let firstTime = true;
    !this.globalStore.dashboardFloatBarSubscription &&
      (this.globalStore.dashboardFloatBarSubscription =
        this.globalStore.floatBar.subscribe((response) => {
          if (firstTime) return (firstTime = false);
          this.ngOnInit();
        }));

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        event.url && event.url === "/profile"
          ? (this.isProfileRoute = true)
          : (this.isProfileRoute = false);
      }
    });
  }

  ngOnInit() {
    window.addEventListener('storage',(e)=>{
      if(e.storageArea === localStorage) {
        let token = localStorage.getItem("token")
        if(token === null || token === undefined) {
          window.location.reload()
          this.cookie.remove()
        }
      }
    })

    if (this.width < 480) {
      this.responsive_nav = true;
    } else {
      this.responsive_nav = false;
    }
    if (this.cookie.isLoggedIn()) {
      this.getProfile();
      this.floatButton = true;
      this.getProperty();
      this.getNotifications();
    }
    this.cookieService.put("transparent", "true");
    this.checkScreenSize()
    setTimeout(() => {
      let navbar = document.getElementById("navbar");
      this.utils.removeAddTransparent(navbar);
    }, 1000);
  }

  ngOnDestroy() {
    this.globalStore.dashboardFloatBarSubscription.unsubscribe();
  }

  ngAfterViewInit() {}
  routerListner() {
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart) {
        if (
          value.url === "/profile" ||
          value.url === "/profile?tab=profile" ||
          value.url === "/profile?tab=bookings" ||
          value.url === "/profile?tab=requests" ||
          value.url === "/profile?tab=notifications" ||
          value.url === "/profile?tab=Documents" ||
          value.url === "/profile?tab=transactions" ||
          value.url === "/profile?tab=invoice"
        ) {
          this.isProfilePage = true;
        } else {
          this.isProfilePage = false;
        }
      }
    });
  }
  redirectProfile = () => {
    this.router.navigate(["/profile"], {
      queryParams: {
        tab: "profile",
      },
    });
  };
  openPopUp = () => {
    const dialogbox = this.dialog.open(RecommendContractorComponent, {
      position: { right: "0px", top: "0px" },
      width: "450px",
      data: {
        close: close,
      },
    });

    dialogbox.afterClosed().subscribe((res) => {});
  };
  openInviteFriend = () => {
    const dialogbox = this.dialog.open(InvitefriendComponent, {
      width: "690px",
      height: "400px",

      data: {},
    });

    dialogbox.afterClosed().subscribe((res) => {});
  };
  closeFunction = () => {};

  showLogin = () => {
    const loginDialog = this.dialog.open(LoginSignupDialogComponent, {});

    loginDialog.afterClosed().subscribe((res) => {
      if (res && res.success) {
        this.isLoggedIn = true;
        this.getProfile();
      }
    });
    this.responsive_side_bar = false;
  };
  ResponsiveNavBarRoute() {}
  checkLogin = () => {
    this.isLoggedIn = this.cookie.getCooikie();
  };

  handleLogOut = () => {
    this.cookie.remove();
    // window.location.reload();
    window.location.href = ""
    this.isLoggedIn = false;
  };
  getProperty() {
    this.url.get("user/property", this.params, (res) => {
      if (res.success) {
        this.currentProperty = res.data;
      }
    });
  }
  getProfile() {
    this.url.get("user", this.params, (res) => {
      if (res.success) {
        this.profileData = res.data.user;
        this.image = this.profileData.image && this.profileData.image;
        this.profileImageUrl = this.utils.imageFormat(
          50,
          50,
          this.image,
          "c_fill"
        );
        this.utils.email$.next(this.profileData.email)
      }
    });
  }
  updateProfilePicture(image) {
    this.image = image;
    this.profileImageUrl = this.utils.imageFormat(50, 50, image, "c_fill");
  }
  routeToNotify() {
    let queryPrams = {
      tab: "notifications",
    };
    this.router.navigate(["/profile"], { queryParams: queryPrams });
  }
  getNotifications() {
    this.url.get(`user/notification/unread`, {}, (res) => {
      if (res.success) {
        this.notification_count = res.data.notifications;
      } else {
      }
    });
  }
  sideBar() {
    this.responsive_side_bar = true;
  }
  closeSideBar() {
    this.responsive_side_bar = false;
  }
  routeProfile() {
    this.router.navigate(["/profile"]);
    this.responsive_side_bar = false;
  }
  toggleProfilePage(tab) {
    let queryPrams = {
      tab: tab,
    };
    this.router.navigate(["/profile"], { queryParams: queryPrams });
    this.responsive_side_bar = false;
  }

  checkScreenSize() {
    this.breakPointObserver.observe(['(max-width: 450px)'])
      .subscribe(result => {
        this.mobileView = result.matches  
      });
  }
}

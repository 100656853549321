<div class="invite_container" style="padding: 20px">
  <div class="invite_content mb_20">
    <div class="success_div">
      <div
        class="mb_20"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <img
          style="margin-bottom: 10px; width: 52px"
          src="../../assets/newIcons/failure.svg"
          alt="image"
        />
        <h2 class="ft_20 align-center _m" style="color: #ec5849">
          {{ data.message }}
        </h2>
      </div>
      <div
        *ngIf="data.data.reason"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <p class="ft_16 ft_500 mb_10">Reason</p>
        <p class="mb_20 align_center" style="font-size: 14px; color: #757575">
          {{ data.data.reason }}
        </p>
      </div>
      <button
        class="primary_common_button"
        mat-flat-button
        autofocus="false"
        (click)="reRoute()"
      >
        Ok
      </button>
    </div>
  </div>
</div>

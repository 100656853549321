import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'button-spinner',
  templateUrl: './button-spinner-component.component.html',
  styleUrls: ['./button-spinner-component.component.css']
})
export class ButtonSpinnerComponentComponent implements OnInit {
  @Input() showSpinner: boolean = false
  @Input() childClass: string | string[] | object = "common_button default_button_padding"
  @Input() disabledClass:boolean = false
  @Input() childStyle: object = {}
  @Input() spinnerColor: "red" | "white" | 'blue' | 'green' | 'yellow' = "white"
  constructor() { }

  ngOnInit() {
    if (typeof(this.childClass) !== 'string') {
      let tempClass = this.childClass.toString()
      this.childClass = tempClass.replace(',', ' ')
    }
  }
  
}

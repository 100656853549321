import { Component, OnInit } from "@angular/core";
import { UtilityService } from "../utility.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ApicallService } from "../apicall.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  saveSpinner:boolean = false
  constructor(
    public utils: UtilityService,
    public url: ApicallService,
    public router: Router,
    public dialog: MatDialogRef<ChangePasswordComponent>
  ) {}
  newhide = true;
  cnfirmhide = true;
  oldHide = true;
  newPassword;
  confirmPassword;
  oldPassword;
  params;
  ngOnInit(): void {}
  cancel() {
    this.dialog.close();
  }
  submit() {
    this.saveSpinner = true
    if (this.oldPassword === "" || this.oldPassword === undefined) {
      this.saveSpinner = false
      this.utils.openToast("Please enter current password", {});
    } else if (this.newPassword === "" || this.newPassword === undefined) {
      this.saveSpinner = false
      this.utils.openToast("Please enter new password", {});
    } else if (
      this.confirmPassword === "" ||
      this.confirmPassword === undefined
    ) {
      this.saveSpinner = false
      this.utils.openToast("Please enter confirm password", {});
    } else if (this.newPassword !== this.confirmPassword) {
      this.saveSpinner = false
      this.utils.openToast("Password matching incorrect", {});
    } else {
      let data = {
        old_pass: this.oldPassword,
        new_pass: this.newPassword,
      };
      this.url.post("user/change_password", data, this.params, (res) => {
        this.saveSpinner = false
        if (res.success) {
          this.utils.openToastSuccess(res.message,{});
          this.dialog.close();
        } else {
          this.utils.openToast(res.message,{});
        }
      });
    }
  }
}

import { Component, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ApicallService } from "../apicall.service";
import { InviteStatusComponent } from "../invite-status/invite-status.component";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { UtilityService } from "../utility.service";
import * as moment from "moment";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgOtpInputComponent } from "ng-otp-input";
import { RejectRequestComponent } from "../reject-request/reject-request.component";
import { RequestInviteStatusComponent } from "../request-status/request-status.component";
import { ignoreElements } from "rxjs-compat/operator/ignoreElements";
import { PayMethodComponent } from "../pay-method/pay-method.component";
import { CookieService } from "ngx-cookie";
import { StatusCheckComponent } from "../payment-checkout/status-check/status-check.component";
import { OpenRequestPayStatusComponent } from "../open-request-pay-status/open-request-pay-status.component";
@Component({
  selector: "app-accept-request",
  templateUrl: "./accept-request.component.html",
  styleUrls: ["./accept-request.component.css"],
})
export class AcceptRequestComponent implements OnInit {
  loading = false;
  data;
  name = "";
  schedule_date;
  selected_problem = "";
  issue_from = "";
  toggleSelect = false;
  form: FormGroup;
  isValidOtp = false;
  params: any;
  button_spin = false;
  otp: any;
  minDate: any;
  maxDate: any;
  getTimeFormat = (date: any) => {
    return date ? moment(date).format("MMM DD, YYYY hh:mm A") : "";
  };

  timeLine = [
    {
      status: "created",
      date: "",
      is_active: true,
    },
    {
      status: "accepted",
      date: "",
      is_active: false,
    },
    {
      status: "in_progress",
      date: "",
      is_active: false,
    },
    {
      status: "completed",
      date: "",
      is_active: false,
    },
  ];
  requestData: any;
  timeLineApiData = [];
  completed_date;
  formInput = [
    {
      key: "input1",
      value: "",
    },
    {
      key: "input2",
      value: "",
    },
    {
      key: "input3",
      value: "",
    },
    {
      key: "input4",
      value: "",
    },
  ];
  @ViewChildren("formRow") rows: any;
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput: NgOtpInputComponent;
  constructor(
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public url: ApicallService,
    public utils: UtilityService,
    private router: Router,
    public cookie: CookieService
  ) {
    this.form = this.toFormGroup(this.formInput);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.status) {
        const dialog = this.dialog.open(OpenRequestPayStatusComponent, {
          data: {
            status: params.status,
          },
        });
        dialog.afterClosed().subscribe((result) => {
          this.getInviteApi(this.params);
        });
      }
      if (params.open_request_id) {
        this.params = params;
        this.getInviteApi(this.params);
      } else {
        window.location.href = "/";
      }
    });
  }
  toFormGroup(elements) {
    const group: any = {};

    elements.forEach((item) => {
      group[item.key] = new FormControl(item.value, Validators.required);
    });
    return new FormGroup(group);
  }

  redirect() {
    this.dialog.open(RejectRequestComponent, {
      width: "400px",
      data: {
        id: this.params,
      },
    });
  }
  onOtpChange(e) {
    this.otp = e;
  }
  onSubmit() {
    this.formInput.forEach((item) => {
      if (!item.value) {
        return (this.isValidOtp = false);
      } else {
        this.isValidOtp = true;
      }
    });
    let nameRegex = /^[a-zA-Z ]*$/;
    if (this.name.trim() === "" || !nameRegex.test(this.name)) {
      this.utils.openToast("Please enter valid name", {});
    } else if (this.selected_problem === "") {
      this.utils.openToast("Please select your issue priority", {});
    } else if (this.issue_from === "") {
      this.utils.openToast(
        "Please select the date of this issue occurs from",
        {}
      );
    } else if (!this.isValidOtp) {
      this.utils.openToast("Please enter valid OTP", {});
    } else {
      this.button_spin = true;
      let dummyOtp = "";
      this.formInput.forEach((item) => {
        dummyOtp += item.value;
      });
      let obj = {
        otp: dummyOtp,
        from: moment(this.issue_from).format("YYYY-MM-DD"),
        priority_level: this.selected_problem,
        name: {
          first: this.name,
        },
      };
      this.url.patch(
        `auth/contractor/open_request/accept/${this.params.open_request_id}`,
        obj,
        {},
        (res) => {
          this.button_spin = false;
          if (res.success) {
            this.utils.openToast("", res);
            this.getInviteApi(this.params);
          } else {
            this.utils.openToast(res.message, res.message);
          }
        }
      );
    }
  }
  changeCheckDate(e) {
    this.issue_from = e;
  }
  keyUpEvent(event, index) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  openBox() {
    this.toggleSelect = true;
  }
  selectedOption(e) {
    this.selected_problem = e;
    this.toggleSelect = false;
  }
  getInviteApi(params) {
    this.loading = true;
    this.url.get(
      `auth/contractor/open_request/${params.open_request_id}`,
      {},
      (res) => {
        this.loading = false;
        if (res.success) {
          this.data = res.data.open_request;
          if (this.data.status !== "pending") this.timeLineAPI();
          if (this.data.status === "completed")
            this.completed_date = moment(this.data.completed_date).format(
              "MMM DD, YYYY"
            );
          this.maxDate = new Date(this.data.schedule_date);
          let minDate = new Date(this.data.schedule_date);
          this.minDate = new Date(minDate.setMonth(minDate.getMonth() - 1));
          this.schedule_date = this.utils.monthFormatDate(
            this.data.schedule_date
          );
        } else {
          const dialog = this.dialog.open(RequestInviteStatusComponent, {
            data: res,
            autoFocus: true,
          });
        }
      }
    );
  }
  timeLineAPI = () => {
    this.url.get(
      `auth/contractor/open_request/request/${this.data.request_id}`,
      {},
      (res) => {
        if (res.success) {
          this.requestData = res.data.request;
          this.schedule_date = this.utils.monthFormatDate(
            this.requestData.schedule_date
          );
          this.timeLineApiData = this.timeLine.filter((item: any, i) => {
            return this.data?.timeline.some((timeLineItem: any) => {
              if (timeLineItem.status === item.status) {
                this.timeLine[i].is_active = true;
                this.timeLine[i].date = timeLineItem.date;
              }
            });
          });
        } else {
        }
      }
    );
  };

  onPay = () => {
    const dialog = this.dialog.open(PayMethodComponent, {
      width: "652px",
      data: {
        invoiceAmount: this.data.amount,
        is_non_customer: true,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        let data = {
          success_url: `${window.location.href}&status=success`,
          cancel_url: `${window.location.href}&status=failure`,
          type: res.data,
        };
        this.url.post(
          `auth/contractor/open_request/invoice/${this.data.request_id}/${this.data.invoice.invoice_id}`,
          data,
          {},
          (res) => {
            this.loading = false;
            if (res.success) {
              window.open(res.data.link, "_self");
              this.cookie.put("transaction_id", res.data.transaction_id);
            } else {
              this.utils.openToast("", res);
            }
          }
        );
      }
    });
  };
}

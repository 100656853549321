<div class="container">
  <h2 class="ft_400 ft_family ft_17 mb_40">
    Are you sure you want to Cancel the Document?
  </h2>
  <div class="button_div d_flex_align_justify">
    <button mat-flat-button class="common_button mr_20" (click)="onSubmit()">
      Yes
    </button>
    <button
      mat-stroked-button
      class="cancel_button mr_20"
      (click)="dialog.close()"
    >
      No
    </button>
  </div>
</div>

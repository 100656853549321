import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";

@Component({
  selector: "app-account-link-status",
  templateUrl: "./account-link-status.component.html",
  styleUrls: ["./account-link-status.component.css"],
})
export class AccountLinkStatusComponent implements OnInit {
  loader: boolean = true;
  status = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AccountLinkStatusComponent>,
    public cookie: CookieService,
    public url: ApicallService,
    public utils: UtilityService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.url.get("user", {}, (res) => {
        this.loader = false;
        if (res.success) {
          if (res.data.user.stripe.is_bank) this.status = true;
        } else {
          this.status = false;
        }
        setTimeout(() => this.dialogRef.close(),2000)
      });
    }, 10000);
  }
}

<div class="payment_container">
  <div class="payment_header">
    <p class="_m ft_family ft_500 ft_18">Payment Method</p>
    <div class="close_btn_holder cursor_pointer" (click)="this.closePopUp()">
      <i class="fa fa-close"></i>
    </div>
  </div>
  <div>
    <p class="ft_family ft_16 _m ft_400 mt_30 pay_body_padding">
      Please Select the payment method to proceed
    </p>
    <div class="act_amt">
      <p class="_m ft_500 ft_family ft_16">Invoice Amount</p>
      <p class="_m ft_500 ft_family ft_16">
        $ &nbsp; {{ this.data.invoiceAmount }}
      </p>
    </div>
  </div>
  <div class="pay_body_padding mt_20 mb_20">
    <div class="transaction_info mb_30">
      <p class="ft_family ft_500 ft_16">Transaction Fee</p>
      <i
        class="material-icons cursor_pointer"
        matTooltip="Payment Gateway Service Fee"
        style="color: rgba(117, 117, 117, 50%); font-size: 22px">info</i>
    </div>
    <div class="payment_types">
      <mat-radio-button
        [ngClass]="
          payment_method === 'us_bank_account'
            ? 'active_radio'
            : 'inactive_radio'
        "
        class="payment_radio mb_40"
        [checked]="payment_method === 'us_bank_account'"
        (change)="radioChange($event)"
        value="us_bank_account">
        <div class="option_with_amount">
          <div>
            <div
              style="display: flex; margin-bottom: 10px; align-items: center">
              <p class="ft_600 ft_family ft_16 mr_10">Bank Transfer</p>
              <span *ngIf="+act_commissionAmount < +cardPaymentAmount"
                class="recommended">{{'(Recommended)'}}</span>
            </div>

            <p
              class="ft_family ft_13 ft_400"
              *ngIf="this.total < commission_data?.ach?.amount">
              You will be charged
              {{ commission_data.ach.commission }} as transaction fee
            </p>

            <p 
              class="ft_family ft_13 ft_400"
              *ngIf="this.total >= commission_data?.ach?.amount">
              If ACH payment is failed then you will be charged
              ${{commission_data?.ach_fail?.commission}} in next invoice
            </p>
          </div>
          <div>
            <p class="ft_500" *ngIf="this.total < commission_data?.ach?.amount">
              $ &nbsp; {{ commission_data?.ach?.commission }}
            </p>
            <p class="ft_500" *ngIf="this.total > commission_data?.ach?.amount">
              $ &nbsp; 0
            </p>
          </div>
        </div>
      </mat-radio-button>
      <mat-radio-button
        [ngClass]="
          payment_method === 'card' ? 'active_radio' : 'inactive_radio'
        "
        class="payment_radio"
        [checked]="payment_method === 'card'"
        (change)="radioChange($event)"
        value="card">
        <div class="option_with_amount">
          <div>
            <div
              style="display: flex; margin-bottom: 10px; align-items: center">
              <p class="ft_600 ft_family ft_16 mr_10">Card Payment</p>
              <span *ngIf="+act_commissionAmount > +cardPaymentAmount"
                class="recommended">{{'(Recommended)'}}</span>
            </div>

            <p class="ft_family ft_13 ft_400">
              You will be charged
              {{
              this.commission_data &&
              this.commissionAmount &&
              this.commissionAmount
              }}% of Invoice + $30
            </p>
          </div>
          <div>
            <p class="ft_500" *ngIf="loader">
              <mat-spinner class="spinner" [diameter]="20"></mat-spinner>
            </p>
            <p class="ft_500" *ngIf="!loader">
              $
              {{
              this.commission_data &&
              this.commissionAmount &&
              this.returnCharges()
              }}
            </p>
          </div>
        </div>
      </mat-radio-button>
    </div>
  </div>
  <div class="act_amt flx-ed mb_30">
    <p class="_m ft_600 ft_family ft_16">Total</p>
    <p class="_m ft_500 ml_20 ft_family ft_16">$ &nbsp; {{ this.total }}</p>
  </div>
  <div class="make_pay">
    <button
      mat-stroked-button
      class="proceed_to_pay ft_family"
      (click)="this.proceedPayment()">
      Proceed to Payment
    </button>
  </div>
</div>

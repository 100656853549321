<div style="margin-top: 130px"></div>
<div *ngIf="loading" class="loader_div">
  <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
</div>
<div class="main_container mb_40t" style="min-height: 100vh" *ngIf="data && !loading">
  <div class="main_div">
    <div class="check_out_res">
      <h2 class="ft_family ft_500 ft_25">Check Out</h2>
    </div>

    <!-- Detail Card -->
    <div class="card res_card">
      <div class="res_box">
        <div class="flex mb_40 res_box">
          <img
            class="image_main res_image"
            [src]="property_url"
            alt="House-3"
          />
          <div class="checkout_detail">
            <div>
              <div
                class="mb_20 ft_family ft_500 ft_18"
                style="font-size: 16px; color: #22212f"
              >
                {{ data.name && data.name }}
              </div>
              <span
                class="sub_content_color ft_16 ft_family"
                style="font-size: 20px"
                >{{ data.address.line_1 }}</span
              >
              <div class="d_flex mt_40 res_check_in_out">
                <div
                  class="d_flex_align res_check_in_out"
                  style="max-height: 65px; margin-right: 2rem"
                >
                  <p
                    class="ft_16 ft_family ft_500 mr_20"
                    style="font-size: 18px; color: #606060"
                  >
                    Check-In
                  </p>
                  <mat-form-field appearance="outline" class="check_out_picker">
                    <input
                      (ngModelChange)="changeCheckDate($event, 'check_in')"
                      matInput
                      [matDatepicker]="picker1"
                      [(ngModel)]="checkIn"
                      [min]="minDate"
                      [max]="maxDate"
                      readonly
                    />
                    <mat-datepicker #picker1></mat-datepicker>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker1"
                    ></mat-datepicker-toggle>
                  </mat-form-field>
                </div>
                <div
                  class="d_flex_align res_check_in_out"
                  style="max-height: 65px"
                >
                  <p
                    class="ft_16 ft_family ft_500 mr_20"
                    style="font-size: 18px; color: #606060"
                  >
                    Check-Out
                  </p>
                  <mat-form-field appearance="outline" class="check_out_picker">
                    <input
                      (ngModelChange)="changeCheckDate($event, 'check_out')"
                      matInput
                      [matDatepicker]="picker2"
                      [(ngModel)]="checkOut"
                      [min]="checkoutMinDate"
                      readonly
                    />
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pay_button">
          <button
            class="square_button filled_red pointer"
            style="white-space: nowrap"
            (click)="payment()"
          >
            <span
              *ngIf="
                this.room_id
                  ? this.response?.room?.is_occupied &&
                    this.response?.room?.check_out &&
                    !this.response?.room?.pre_booking
                  : this.response?.property?.is_occupied &&
                    this.response?.property?.check_out &&
                    !this.response?.property?.pre_booking
              "
              >Prebook Now</span
            >
            <span
              *ngIf="
                this.room_id
                  ? !this.response?.room?.is_occupied &&
                    !this.response?.room?.check_out
                  : !this.response?.property?.is_occupied &&
                    !this.response?.property?.check_out
              "
              >Pay {{ utils.displayPrice(response.total) }}</span
            >
          </button>
        </div>
      </div>

      <div class="mt_25 mb_30" *ngIf="this.payment_detail.previous_check_in">
        <p class="sub_content_color ft_16 ft_family">
          There is an active <span class="primary_site_color">tenant</span> for
          this property at this moment. Stil you can prebook now to proceed.
        </p>
      </div>
      <mat-divider class="divider_mat mb_40"></mat-divider>
      <div>
        <p class="title ft_family ft_500 ft_18 mb_30">Payment Details</p>
        <div class="d_flex_space_align mb_20">
          <p class="payment_details_text ft_family">Base Price</p>
          <p class="payment_details_text ft_family">
            {{ utils.displayPrice(response.base_price) }}
          </p>
        </div>
        <div class="d_flex_space_align mb_20">
          <p class="payment_details_text ft_family">Tax & Service Fees</p>
          <p class="payment_details_text ft_family">
            {{ utils.displayPrice(response.tax) }}
          </p>
        </div>
        <div class="d_flex_space_align mt_30">
          <p class="payment_details_text ft_family ft_18">Payable Amount</p>
          <p class="ft_700 ft_family ft_20">
            {{ utils.displayPrice(response.total) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="no_property" *ngIf="!data && !loading">
  <img src="../../assets/images/no_property.png" alt="image" />
  <button
    mat-flat-button
    class="close_filters ft_16 ft_family"
    (click)="redirect()"
  >
    Go Home
  </button>
</div>

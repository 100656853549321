import { Pipe, PipeTransform } from "@angular/core";
import { UtilityService } from "../utility.service";
import * as moment from "moment";
@Pipe({
  name: "checkRepeatName",
})
export class CheckRepeatName implements PipeTransform {
  name;
  constructor(public utils: UtilityService) {
    this.utils.name$.subscribe((response) => {
      this.name = response;
    });
  }

  transform(chat,arg) {
    let obj = arg[0]
    let index = arg[1]
    let string1 = chat?.contractor?.name ?? chat?.host?.name ?? chat?.manager?.name
    let string2 = obj[index -1]?.contractor?.name ?? obj[index -1]?.host?.name ?? obj[index -1]?.manager?.name 
    let string1Date = moment(chat?.createdAt).startOf('day').format()
    let string2Date = moment(obj[index-1]?.createdAt).startOf('day').format()
  return  (string1Date === string2Date) && (string1 === string2) ? true : false
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { UtilityService } from "src/app/utility.service";
import { ApicallService } from "src/app/apicall.service";
import { CloudinaryService } from "src/app/cloudinary.service";
import { DashboardComponent } from "src/app/components/dashboard/dashboard.component";
import { ChangePasswordComponent } from "src/app/change-password/change-password.component";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountLinkStatusComponent } from "./accountStatus/account-link-status/account-link-status.component";
@Component({
  selector: "app-profile-details",
  templateUrl: "./profile-details.component.html",
  styleUrls: ["./profile-details.component.css"],
})
export class ProfileDetailsComponent implements OnInit {
  saveSpinner:boolean = false
  constructor(
    public utils: UtilityService,
    public url: ApicallService,
    public route: ActivatedRoute,
    public router: Router,
    public imageUploader: CloudinaryService,
    public dashboard: DashboardComponent,
    public dialog: MatDialog
  ) {}
  fname;
  lname;
  phone;
  email;
  loading = true;
  relationship;
  emergencyContactFullName;
  hobbiesInt;
  language;
  healthCondition;
  currentEmployer;
  currentClient;
  jobTitle;
  technologyStack;
  emergencyContactNumber;
  image = {
    public_id: "",
    version: "",
    format: "",
  };
  is_pets_available;
  profileImageUrl;
  profileData;
  acc_no;
  bank_name;
  payout_enabled:boolean
  route_number;
  gender;
  width: number = window.innerWidth;
  food_preference;
  imageLoading = false;
  selected_pets = [];
  pets = [
    { name: "Cat", is_enabled: false },
    { name: "Dog", is_enabled: false },
  ];
  params;
  ngOnInit(): void {
    this.getProfile();
  }

  LinkAccount = () => {
    // /user/link_bank
    this.loading = true;
    let data = {
      refresh_url: `${window.location.href}&link_status=success`,
      return_url: `${window.location.href}&link_status=success`,
    };
    this.url.post(`user/link_bank`, data, {}, (res) => {
      this.loading = false;
      if (res.success) {
        window.open(res.data.link, "_self");
      } else {
        this.utils.openToast("", res);
      }
    });
  };

  getProfile() {
    let payout
    this.url.get("user", this.params, (res) => {
      if (res.success) {
        this.profileData = res.data.user;
        this.fname = this.profileData.name.first;
        this.lname = this.profileData.name.last;
        this.phone = this.utils.getUSFormat(
          this.profileData.phone.national_number.toString()
        );
        this.email = this.profileData.email;
        this.acc_no = this.profileData.stripe?.ac_number;
        this.bank_name = this.profileData.stripe?.name;
        this.route_number = this.profileData.stripe?.route_number;
        this.payout_enabled = this.profileData.stripe?.payout_enabled;
       
        let data = this.profileData;
        this.emergencyContactFullName = data.emergency_contact
          ? data.emergency_contact.name
          : "";
        this.relationship = data.emergency_contact
          ? data.emergency_contact.relationship
          : "";
        (this.hobbiesInt = data.additional_info
          ? data.additional_info.hobbies
          : ""),
          (this.language = data.additional_info
            ? data.additional_info.lang
            : ""),
          (this.healthCondition = data.additional_info
            ? data.additional_info.health_cond
            : ""),
          (this.currentEmployer = data.professional_details
            ? data.professional_details.current_emp
            : ""),
          (this.currentClient = data.professional_details
            ? data.professional_details.current_client
            : ""),
          (this.jobTitle = data.professional_details
            ? data.professional_details.job_title
            : "");
        this.technologyStack = data.professional_details
          ? data.professional_details.tech_stack
          : "";
        this.emergencyContactNumber = data.emergency_contact
          ? this.utils.getUSFormat(
              data.emergency_contact.phone.national_number.toString()
            )
          : "";
        this.is_pets_available =
          this.profileData && this.profileData.is_pets === true ? "Yes" : "No";
        this.pets.forEach((item) => {
          if (this.profileData.pets.includes(item.name)) {
            item.is_enabled = true;
            this.selected_pets.push(item.name);
          }
        });
        this.food_preference =
          data.additional_info &&
          data.additional_info.food_preference &&
          data.additional_info.food_preference;

        this.gender = data.gender && data.gender;
        this.image = data.image && data.image;
        this.profileImageUrl = this.utils.imageFormat(
          150,
          150,
          this.image,
          "c_fill"
        );
        this.loading = false;
      } else {
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params.link_status) {
        const dialog = this.dialog.open(AccountLinkStatusComponent, {
          data: {
            status: this.profileData,
          },
        });
        dialog.afterClosed().subscribe((result) => {
          this.router.navigate(["/profile"], {
            queryParams: {
              tab: "profile",
            },
          });
        });
      }
    });
  }

  addPets(temp, index) {
    temp.is_enabled = !temp.is_enabled;
    for (let i = 0; i < this.selected_pets.length; i++) {
      var itemval = this.selected_pets[i];
      if (itemval == temp.name) {
        this.selected_pets.splice(i, 1);
        break;
      }
    }
    if (temp.is_enabled === true) {
      let data = temp.name;
      this.selected_pets.push(data);
    }
  }
  handleOnInputChange(e) {
    this.phone = this.utils.getUSFormat(e);
  }
  handleOnInputEmergencyChange(e) {
    this.emergencyContactNumber = this.utils.getUSFormat(e);
  }
  updateProfile() {
    this.saveSpinner = true
    var phoneno = /^\d{10}$/;
    let nameRegex = /^[a-zA-Z ]*$/;
    if (this.fname.trim() === "" || !nameRegex.test(this.fname)) {
       this.saveSpinner = false
      this.utils.openToast("Please enter valid First Name", {});
    } else if (this.lname.trim() === "" || !nameRegex.test(this.lname)) {
       this.saveSpinner = false

      this.utils.openToast("Please enter valid Last Name", {});
    } else if (this.gender === "" || this.gender === undefined) {
      this.saveSpinner = false

      this.utils.openToast("Please select an gender", {});
    } else if (this.phone === "") {
      this.saveSpinner = false

      this.utils.openToast("Please enter valid Phone Number", {});
    } else if (
      this.is_pets_available === "Yes" &&
      this.selected_pets.length === 0
    ) {
      this.saveSpinner = false

      this.utils.openToast("Please provide us your Pet's details", {});
    } else if (
      this.emergencyContactFullName === "" ||
      this.emergencyContactFullName === undefined ||
      !nameRegex.test(this.emergencyContactFullName)
    ) {
      this.saveSpinner = false

      this.utils.openToast(
        "Please enter valid full name for emergency contact",
        {}
      );
    } else if (this.emergencyContactNumber === "") {
      this.saveSpinner = false

      this.utils.openToast(
        "Please enter valid contact number for emergency contact",
        {}
      );
    } else if (this.relationship === "" || this.relationship === undefined) {
      this.saveSpinner = false

      this.utils.openToast(
        "Please enter your relationship status for emergency contact",
        {}
      );
    } else {
      let saveData = {
        name: {
          first: this.fname,
          last: this.lname,
        },
        email: this.email,
        gender: this.gender,
        phone: {
          country_code: "1",
          national_number: this.utils.replaceToNormalNumber(this.phone),
        },
        professional_details: {
          current_emp: this.currentEmployer ? this.currentEmployer : "",
          current_client: this.currentClient ? this.currentClient : "",
          job_title: this.jobTitle ? this.jobTitle : "",
          tech_stack: this.technologyStack ? this.technologyStack : "",
        },
        is_pets: this.is_pets_available === "Yes" ? true : false,
        pets: this.selected_pets,
        additional_info: {
          hobbies: this.hobbiesInt ? this.hobbiesInt : "",
          lang: this.language ? this.language : "",
          health_cond: this.healthCondition ? this.healthCondition : "",
          food_preference: this.food_preference ? this.food_preference : "",
        },
        emergency_contact: {
          name: this.emergencyContactFullName,
          phone: {
            national_number: this.utils.replaceToNormalNumber(
              this.emergencyContactNumber
            ),
            country_code: "1",
          },
          relationship: this.relationship,
        },
        image: this.image,
      };
      this.profileApi(saveData);
    }
  }
  petsAvailable(event) {
    if (this.is_pets_available !== event) {
      this.selected_pets = [];
      this.pets.forEach((item) => {
        item.is_enabled = false;
      });
    }
    this.is_pets_available = event;
  }
  profileApi(saveData) {
    this.url.patch("user", saveData, this.params, (res) => {
      if (res.success) {
        this.saveSpinner = false
        this.utils.openToast("", res);
        window.scrollTo(0, 0);
        this.dashboard.updateProfilePicture(this.image);
      } else {
        this.saveSpinner = false
        this.utils.openToast("", res);
      }
    });
  }
  async upload(e: any) {
    let file = e.target.files[0];
    if (file?.size > 5 * 1000000) {
      this.utils.openToast("Uploaded image is greater than 5MB", {});
    } else if (
      file.type !== "image/png" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg"
    ) {
      this.utils.openToast("Please upload only jpeg and png images", {});
    } else {
      this.imageLoading = true;
      this.imageUploader.uploadCloudinary(
        file,
        `users/${this.profileData.user_id}`,
        (response: any) => {
          this.imageLoading = false;
          if (response && response.url) {
            this.image = {
              public_id: response.public_id,
              version: "v" + response.version,
              format: response.format,
            };
            this.profileImageUrl = this.utils.imageFormat(
              80,
              80,
              this.image,
              "c_fill"
            );
            e.target.value = "";
            let saveData = {
              image: this.image,
            };
            this.profileApi(saveData);
          } else this.utils.openToast("Could not upload image", {});
        }
      );
    }
  }
  openChangePassword() {
    this.dialog.open(ChangePasswordComponent);
  }
  verifyEmail() {
    let data = {
      email: this.profileData.email,
    };
    this.url.post(`auth/user/verify_mail`, data, {}, (res) => {
      if (res.success) {
        this.utils.openToast("", res);
      } else {
        this.utils.openToast("", res);
      }
    });
  }
}

<div class="sidenav-container">
  <div class="mobile_nav_bar" *ngIf="responsive_nav" id="responsive_navbar">
    <div class="header_container">
      <div class="hamburger_div" *ngIf="!mobileView">
        <img
          alt="image"
          src="../../../assets/newIcons/hamburger.svg"
          (click)="sideBar()"
        />
      </div>
      <div class="responsive_banner">
        <img
          src="./assets/newLogo/newLogoText.svg"
          alt="logo"
          [routerLink]="['/']"
        />
      </div>
    </div>
  </div>
  <div class="div" *ngIf="!responsive_nav && !mobileView">
    <div class="transparent" id="navbar">
      <div [ngClass]="isProfileRoute && 'adjustNavBar'" class="db-toolbar">
        <div class="db-company">
          <img
            src="./assets/newLogo/newLogoText.svg"
            alt="icon"
            class="image_default"
            [routerLink]="['/']"
          />
        </div>
        <div class="db-profile" *ngIf="!mobileView">
          <div class="host_a_tag" style="width: 51%">
            <a
              [href]="hostUrl"
              class="become_a_host ft_family ft_16"
              target="_blank"
              >Become a Host</a
            >
          </div>

          <div class="bell_container" style="width: 22%" *ngIf="isLoggedIn">
            <div class="bell_icon">
              <img
                class="bell_icon cursor_pointer"
                (click)="routeToNotify()"
                src="../../../assets/newIcons/normal_bell.svg"
              />
              <div
                class="badge ft_family ft_14 ft_500 cursor_pointer"
                *ngIf="notification_count"
                (click)="routeToNotify()"
              >
                {{ notification_count }}
              </div>
            </div>
            <div class="bell">
              <img
                class="bell cursor_pointer"
                (click)="routeToNotify()"
                src="../../../assets/newIcons/bell.svg"
              />
              <div
                class="badge ft_family ft_14 ft_500 cursor_pointer"
                *ngIf="notification_count"
                (click)="routeToNotify()"
              >
                {{ notification_count }}
              </div>
            </div>
          </div>
          <div
            class="profile_img cursor_pointer"
            mat-button
            [matMenuTriggerFor]="menu"
          >
            <img
              alt="userimage"
              class="profile_image"
              [src]="
                this.profileImageUrl
                  ? this.profileImageUrl
                  : '../../../assets/newIcons/profileuser.svg'
              "
            />
          </div>
          <mat-menu #menu="matMenu" class="login-menu">
            <div>
              <button
                mat-menu-item
                class="menu-login"
                (click)="showLogin()"
                *ngIf="!isLoggedIn"
              >
                Tenant Log in
              </button>
              <button mat-menu-item class="menu-login">
                <a
                  style="text-decoration: none; color: rgba(0, 0, 0, 0.87)"
                  [href]="hostUrl"
                  target="_blank"
                  class=""
                  >Host Log in</a
                >
              </button>
              <button mat-menu-item class="menu-login">
                <a
                  style="text-decoration: none; color: rgba(0, 0, 0, 0.87)"
                  [href]="constructorUrl"
                  target="_blank"
                  class=""
                  >Pro User Login</a
                >
              </button>
            </div>

            <button
              mat-menu-item
              class="menu-login ft_family"
              (click)="redirectProfile()"
              [routerLink]="['profile']"
              [routerLinkActive]="['active-link']"
              *ngIf="isLoggedIn"
            >
              My Profile
            </button>
            <button
              mat-menu-item
              class="menu-login ft_family"
              *ngIf="isLoggedIn"
              (click)="handleLogOut()"
            >
              Log Out
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div
    class="left_side_bar"
    [ngStyle]="{
      transform: responsive_side_bar ? 'translateX(0%)' : 'translateX(-104%)'
    }"
    id="toggle_left"
  >
    <div class="continer_content">
      <div class="logo_box">
        <img
          src="./assets/newLogo/newLogoText.svg"
          (click)="responsive_side_bar = false"
          [routerLink]="['/']"
        />
      </div>
      <ng-container *ngIf="!mobileView">
        <div class="sidebar_content">
          <div class="d_flex_align mb_20" *ngIf="!isLoggedIn">
            <img class="mr_15" src="../../../assets/newIcons/profile.svg" />
            <p class="sidebar_text" (click)="showLogin()">Tenant Log in</p>
            <!-- <button>
              <a
                style="text-decoration: none; color: rgba(0, 0, 0, 0.87)"
                [href]="hostUrl"
                target="_blank"
                class=""
                >Host Log in</a
              >
            </button> -->
          </div>
          <div
            class="individual_tab"
            (click)="routeProfile()"
            *ngIf="isLoggedIn && !isProfilePage"
          >
            <div class="icon_width">
              <img
                class="label_logo mr_15"
                src="../../../assets/newIcons/profile.svg"
                alt="icon"
              />
            </div>
            <p>My Profile</p>
          </div>
          <div class="icon_width"></div>
        </div>
        <div class="profile_page_content" *ngIf="isLoggedIn && isProfilePage">
          <div class="individual_tab" (click)="toggleProfilePage('profile')">
            <div class="icon_width">
              <img
                class="label_logo mr_15"
                src="../../../assets/newIcons/profile.svg"
                alt="icon"
              />
            </div>
            <p>My Profile</p>
          </div>
          <div class="individual_tab" (click)="toggleProfilePage('bookings')">
            <div class="icon_width">
              <img
                class="label_logo mr_15"
                src="../../../assets/newIcons/booking_inactive.svg"
                alt="icon"
              />
            </div>
            <p>My Property</p>
          </div>
          <div class="individual_tab" (click)="toggleProfilePage('requests')">
            <div class="icon_width">
              <img
                class="label_logo mr_15"
                src="../../../assets/newIcons/requests.svg"
                alt="icon"
              />
            </div>
            <p>My Requests</p>
          </div>
          <div
            class="individual_tab"
            (click)="toggleProfilePage('notifications')"
          >
            <div class="icon_width">
              <img
                class="label_logo mr_15"
                src="../../../assets/newIcons/notifications.svg"
                alt="icon"
              />
            </div>
            <p>My Notifications</p>
          </div>
          <div class="individual_tab" (click)="toggleProfilePage('Documents')">
            <div class="icon_width">
              <img
                class="label_logo mr_15"
                src="../../../assets/newIcons/Documents.svg"
                alt="icon"
              />
            </div>
            <p>My Documents</p>
          </div>
          <div
            class="individual_tab"
            (click)="toggleProfilePage('transactions')"
          >
            <div class="icon_width">
              <img
                class="label_logo mr_15"
                src="../../../assets/newIcons/transactions_inactive.svg"
                alt="icon"
              />
            </div>
            <p>My Transactions</p>
          </div>
          <div class="individual_tab" (click)="toggleProfilePage('invoice')">
            <div class="icon_width">
              <img
                class="label_logo mr_15"
                src="../../../assets/newIcons/invoice_inactive.svg"
                alt="icon"
              />
            </div>
            <p>My Invoices</p>
          </div>
        </div>
        <div class="individual_tab" *ngIf="isLoggedIn" (click)="handleLogOut()">
          <div class="icon_width">
            <img
              class="label_logo mr_15"
              src="../../../assets/newIcons/logout.svg"
              alt="icon"
            />
          </div>
          <p>Log Out</p>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="right_side"
    *ngIf="!mobileView"
    [style]="
      responsive_side_bar ? 'pointer-events : all' : 'pointer-events : none'
    "
    [ngStyle]="{ opacity: responsive_side_bar ? '0.5' : '0' }"
    (click)="closeSideBar()"
  ></div>

  <div>
    <!-- <app-download-mobile-app *ngIf="mobileView; else routerOutlet"></app-download-mobile-app>
    <ng-template #routerOutlet> -->
    <router-outlet></router-outlet>
    <!-- </ng-template> -->
  </div>
  <app-footer *ngIf="!mobileView"></app-footer>
</div>

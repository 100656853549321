import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";

@Component({
  selector: "app-request-assign",
  templateUrl: "./request-assign.component.html",
  styleUrls: ["./request-assign.component.css"],
})
export class RequestAssignComponent implements OnInit {
  constructor(
    public url: ApicallService,
    public utils: UtilityService,
    public dialog: MatDialogRef<RequestAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  assign_to = "host";
  contractor_list;
  selected_contractor;
  current_step = 1;
  search_value = "";
  current_request_detail;
  ngOnInit(): void {
    this.getAllContractors("");
    if (this.data?.request) {
      this.assign_to = this.data.request.direct_request ? "contractor" : "host";
      this.selected_contractor = this.data.request.contractor;
      this.selected_contractor["contractor_id"] =
        this.data.request.contractor.id;
    }
  }
  radioChange(event) {
    this.assign_to = event.value;
    if (this.assign_to === "contractor") {
    }
  }
  searchCall() {
    this.getAllContractors(this.search_value);
  }
  triggerEnter(e) {
    if (e.key === "Enter") {
      this.searchCall();
    }
  }
  selectedOption(item) {
    this.selected_contractor = item;
  }
  getAllContractors = (contractor) => {
    let query = "";
    contractor ? (query = `?name=${contractor}`) : "";
    this.url.get(
      `user/requests/contractors/loc/${this.data.data.property.id}${query}`,
      {},
      (res) => {
        if (res.success) {
          this.contractor_list = res.data;
        } else {
          this.contractor_list = [];
        }
      }
    );
  };
  back() {
    this.dialog.close();
    this.assign_to = "";
    this.selected_contractor = {};
  }
  onNext() {}
  sumbit() {
    if (this.assign_to === "contractor" && !this.selected_contractor) {
      this.utils.openToast("Please select a contractor to assign", {});
    } else {
      let dataObj: any = {};
      if (this.data.request) {
        dataObj = {
          direct_request: this.assign_to === "host" ? false : true,
        };
        if (this.assign_to === "contractor") {
          dataObj["contractor"] = this.selected_contractor.contractor_id;
        }
      } else {
        if (this.assign_to === "contractor") {
          dataObj["contractor"] = this.selected_contractor.contractor_id;
        }
      }
      this.dialog.close({ data: dataObj });
    }
  }
}

<mat-spinner *ngIf="loading" class="spinner" [diameter]="50"></mat-spinner>
<div *ngIf="!loading">

  <!-- add request Page -->
  <div class="tab_container" *ngIf="add_requst">
    <!-- common_content -->
    <div class="d_flex_space_align mb_40">
      <div class="brudcrumbs">
        <p
          class="ft_family ft_500 ft_16 mr_10 cursor_pointer"
          (click)="reRoute()">
          Requests
        </p>
        <span>/</span>
        <p class="ft_family ft_500 ft_16 ml_10 cursor_pointer">
          {{ request && request.request_id ? "Edit" : "Add Request" }}
        </p>
      </div>
      <div class="buttons">
        <button
          mat-stroked-button
          class="back_button ft_family ft_600 ft_18"
          *ngIf="currentTab > 1"
          (click)="switchTab('back')">
          Back
        </button>
        <!-- <button
          mat-flat-button
          class="next_button ft_family ft_600 ft_18"
          (click)="switchTab('')"
          *ngIf="currentTab === 1 || currentTab === 2">
          Next
        </button> -->
        <button-spinner
          *ngIf="currentTab === 1 || currentTab === 2"
          [showSpinner]="saveSpinner"
          childClass="new_common_button_sm"
          mat-flat-button
          type="submit"
          (click)="switchTab('')"
          >
          Next
        </button-spinner>
        <button
          *ngIf="current_booking_data.allow_request && currentTab === 3"
          mat-flat-button
          class="next_button ft_family ft_600 ft_18"
          (click)="AssignRequest()">
          Assign Request
        </button>
        <button
          *ngIf="!current_booking_data.allow_request && currentTab === 3"
          mat-flat-button
          class="next_button ft_family ft_600 ft_18"
          (click)="createRequest(request && request.request_id, {})">
          Submit Request
        </button>
      </div>
    </div>
    <div class="common_content">
      <div class="mb_60">
        <p class="header ft_family ft_500 ft_20 mb_15" *ngIf="currentTab === 1">
          Hi, {{ current_booking_data.user.name.first }}
          {{ current_booking_data.user.name.last }} !
        </p>
        <p class="header ft_family ft_500 ft_20 mb_15" *ngIf="currentTab === 2">
          Specify the Request Details
        </p>
        <p class="header ft_family ft_500 ft_20 mb_15" *ngIf="currentTab === 3">
          Almost done
        </p>
        <p class="header ft_family ft_400 ft_16 mb_15 sub_content_color">
          {{
          currentTab === 1
          ?
          "Select the problem category to raise an issue. We will help you to solve this."
          : currentTab === 2
          ? "Give us some more details about the date and issues."
          : "Share some photos and problem statements."
          }}
        </p>
      </div>
      <div class="progress_bar_div">
        <div class="progress_bar">
          <div class="line_bar">
            <div
              class="stepper_head d_flex_align_justify"
              [ngClass]="
                currentTab === 1
                  ? 'first'
                  : currentTab === 2
                  ? 'second'
                  : 'third'
              ">
              <img
                alt="stepper"
                src="../../../../../assets/newIcons/stepper.svg" />
            </div>
            <div
              class="small_step step_1"
              [ngClass]="
                currentTab === 2 || currentTab === 3
                  ? 'complete_step_1'
                  : 'in_complete_step_1'
              "></div>
            <div
              class="small_step step_2"
              [ngClass]="
                currentTab === 1
                  ? 'in_complete_step_2'
                  : currentTab === 3
                  ? 'complete_step_2'
                  : 'no_display'
              "></div>
            <div
              class="small_step step_3"
              [ngClass]="
                currentTab === 1 || currentTab === 2
                  ? 'in_complete_step_3'
                  : 'no_display'
              "></div>
            <div
              class="finished_progress_bar"
              [ngClass]="
                currentTab === 1
                  ? 'no_display'
                  : currentTab === 2
                  ? 'half_finished'
                  : 'fully_finished'
              "></div>
          </div>
        </div>
        <p
          class="ft_family ft_500 ft_14 header_1"
          [ngClass]="currentTab === 1 ? 'active_tab' : 'inactive_tab'">
          Category
        </p>
        <p
          class="ft_family ft_500 ft_14 header_2"
          [ngClass]="currentTab === 2 ? 'active_tab' : 'inactive_tab'">
          Issues
        </p>
        <p
          class="ft_family ft_500 ft_14 header_3"
          [ngClass]="currentTab === 3 ? 'active_tab' : 'inactive_tab'">
          Submit
        </p>
        <!-- <p
          class="ft_family ft_500 ft_14 header_3"
          [ngClass]="currentTab === 4 ? 'active_tab' : 'inactive_tab'"
        >
          Assign Work
        </p> -->
      </div>
    </div>
    <!-- end of common content div -->

    <!-- step 1 -->
    <div class="first_container" *ngIf="currentTab === 1">
      <p class="ft_family ft_500 ft_15 problem_header mb_40 mt_20">
        Select the type of issue you are facing
        <span class="manadatory_field">*</span>
      </p>
      <div class="type_of_problem">
        <div class="individual_problem_div">
          <div
            class="individual_problem mb_20 cursor_pointer"
            (click)="typeOfProblem('Supply Request')">
            <img
              [src]="
                type_of_problem === 'Supply Request'
                  ? '../../../../../assets/newIcons/house_active.png'
                  : '../../../../../assets/newIcons/house.png'
              " />
          </div>
          <p
            class="ft_family ft_500 ft_14 problem_header align_center"
            [ngClass]="type_of_problem === 'Supply Request' && 'active_header'">
            Supply Request
          </p>
        </div>
        <div class="individual_problem_div">
          <div
            class="individual_problem mb_20 cursor_pointer"
            (click)="typeOfProblem('Other')">
            <img
              [src]="
                type_of_problem === 'Other'
                  ? '../../../../../assets/newIcons/service_active.svg'
                  : '../../../../../assets/newIcons/service_inactive.svg'
              " />
          </div>
          <p
            class="ft_family ft_500 ft_14 problem_header align_center"
            [ngClass]="type_of_problem === 'Other' && 'active_header'">
            Service and Repairs
          </p>
        </div>
      </div>
    </div>
    <!-- end of step 1 -->

    <!-- step 2 -->
    <div class="second_container" *ngIf="currentTab === 2">
      <div class="sub_container_1">
        <div class="mb_30">
          <p class="ft_family ft_500 ft_15 mb_20 problem_header">
            Date from when you are facing the problem
            <span class="manadatory_field">*</span>
          </p>
          <mat-form-field
            appearance="outline"
            style="width: 50%"
            class="problem_mat_div">
            <input
              (ngModelChange)="changeCheckDate($event, 'problem')"
              matInput
              [matDatepicker]="picker1"
              [(ngModel)]="problem_date"
              [max]="today"
              [min]="problemMin"
              readonly />
            <mat-datepicker #picker1></mat-datepicker>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"></mat-datepicker-toggle>
          </mat-form-field>
        </div>
        <div class="mb_30">
          <p class="ft_family ft_500 ft_15 mb_20 problem_header">
            Priority Level of your Issue?
            <span class="manadatory_field">*</span>
          </p>
          <div class="relative">
            <div
              class="select_div ft_family ft_14 ft_400 sub_content_color cursor_pointer"
              (click)="openBox()">
              {{ selected_problem == "" ? "Select" : selected_problem }}
              <i class="fa fa-angle-down arrow"></i>
            </div>
            <div class="selected_option_div cursor_pointer"
              *ngIf="toggleSelect">
              <div
                class="select_customized_options cursor_pointer"
                (click)="selectedOption('High')">
                High
              </div>
              <div
                class="select_customized_options cursor_pointer"
                (click)="selectedOption('Medium')">
                Medium
              </div>
              <div
                class="select_customized_options cursor_pointer"
                (click)="selectedOption('Low')">
                Low
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d_flex"
        *ngIf="type_of_problem && type_of_problem === 'Other'">
        <div class="mr_20 mb_40 w_25">
          <p class="ft_family ft_500 ft_15 mb_20">
            Select the problem<span class="manadatory_field"> *</span>
          </p>
          <div class="relative">
            <div
              class="select_div w_100 ft_family ft_14 ft_400 sub_content_color cursor_pointer"
              (click)="openProblemSelect()">
              {{
              selected_option_problem?.name
              ? selected_option_problem.name
              : "Select"
              }}
              <i class="fa fa-angle-down arrow"></i>
            </div>
            <div
              class="selected_option_div w_100 cursor_pointer select_div_custom"
              *ngIf="show_problem_select"
              id="problem">
              <div
                class="select_customized_options cursor_pointer"
                *ngFor="let item of problem[selectedProblem].issues"
                (click)="TypeOfProblem(item)">
                <!-- (click)="problemfilter(item)" -->
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="sub_issues_list?.length > 0">
        <p class="ft_family ft_500 ft_15 mb_20">
          Select the {{type_of_problem === 'Other' ? 'sub problem' : 'supply'}}
          <span class="manadatory_field"> *</span>
        </p>
      </div>
      <div class="supplies" *ngIf="sub_issues_list?.length > 0">
        <div (click)="onCardClick(item ,refEl , i)"
          [ngClass]="item.utilized ? 'item_inactive' : 'item_active' "
          class="supplyCards"
          *ngFor="let item of sub_issues_list ; let i = index">
          <ng-container>
            <mat-checkbox
              #refEl
              class="checkbox sub_content_color ft_16 ft_family"
              [checked]="item.is_enabled"
              [disabled]="item.utilized"
              (change)="problemfilter(item)"
              (click)="$event.stopPropagation()">
              <div>
                <div class="item_name">{{item.name | capitalize}}</div>
                <div
                  *ngIf="type_of_problem === 'Supply Request' && item.utilized"
                  class="scheduled_date">Next
                  Available at {{
                  utils.dayMonthUtc(item.next_schedule.start_date)}}</div>
              </div>

            </mat-checkbox>

          </ng-container>
        </div>
      </div>
    </div>
    <!-- end of step 2 -->

    <!-- step 3 -->
    <div class="third_container" *ngIf="currentTab === 3">
      <p class="ft_family ft_500 ft_16 mb_30">
        Please upload the pictures of the issue you are facing
      </p>
      <div class="image_container mb_30">
        <div
          class="uploaded_img"
          *ngFor="let image of imageArray; let i = index">
          <img
            class="uploaded_img"
            [src]="this.utils.imageFormat(120, 130, image, 'c_fill')"
            alt="image" />
          <img
            class="remove_icon cursor_pointer"
            src="../../../../../assets/newIcons/remov.svg"
            (click)="removeImage(image, i)" />
        </div>
        <div class="mat_spinner" *ngIf="imageLoading">
          <mat-spinner class="upload_loader" diameter="20"></mat-spinner>
        </div>
        <div class="upload_button" *ngIf="imageArray.length < 5">
          <img
            src="../../../../../assets/newIcons/addImage.svg"
            class="mb_10" />
          <p style="color: #c4c4c4" class="ft_family ft_500 ft_15">Add</p>
          <input
            class="cursor_pointer upload"
            type="file"
            (change)="upload($event)"
            accept="image/png,image/jpeg" />
        </div>
      </div>
      <div class="description_div">
        <p class="ft_family ft_500 ft_16 mb_30">
          Description
          <span
            *ngIf="this.type_of_problem === 'Other' && this.selected_option_problem.name === 'Others'"
            class="manadatory_field">*</span>
        </p>
        <textarea
          class="desc_area ft_14 ft_300 ft_family"
          [(ngModel)]="desc"
          maxlength="250"></textarea>
      </div>
    </div>
    <!-- step 3 -->
  </div>
  <!-- end of add request Page -->

  <!-- request detail page -->
  <div *ngIf="show_request_details">
    <div class="d_flex_space_align mb_40">
      <div class="brudcrumbs">
        <p
          class="ft_family ft_500 ft_16 mr_10 cursor_pointer"
          (click)="reRoute()">
          Requests
        </p>
        <span>/</span>
        <p class="ft_family ft_500 ft_16 ml_10 cursor_pointer">
          Request Detail
        </p>
      </div>
      <div class="d_flex">
        <button
          mat-stroked-button
          *ngIf="(request_details?.status === 'completed' && !request_details?.review) || (request_details?.status === 'closed'  && !request_details?.review)"
          class="mr_15 secondary_stroked_button password_button"
          (click)="rateTheWork(request_details)">
          Rate This Work
        </button>
        <button
          class="rate d_flex_align_justify"
          *ngIf="request_details?.status === 'completed' || request_details?.status === 'open'"
          (click)="cancelRequest(request_details, 'close')">
          Close Request
        </button>
        <div style="margin-left: 15px;" (click)="comment(request_details)"
          *ngIf="request_details?.status !== 'pending' && request_details?.status !== 'rejected' && request_details?.status !== 'cancelled' && request_details?.status !== 'assigned'"
          class="comment_Bodering">
          <img style="padding: 10px;" src="/assets/images/comment.svg">
        </div>
      </div>
      <!-- <div class="buttons">
        <button
          *ngIf="request_details.status === 'completed' && request_details.assigned_to === 'contractor'"
          class="close_request"
          mat-stroked-button
          (click)="cancelRequest(request_details, 'close')"
        >
          Close Request
        </button>
      </div>
      <div class="buttons">
        <button
          *ngIf="request_details.status === 'completed' && request_details.assigned_to === 'contractor'"
          class="rate"
          mat-stroked-button
          (click)="cancelRequest(request_details, 'close')"
        >
          Rate This Work
        </button>
      </div> -->
    </div>
    <div class="process_container">
      <div class="left_side_detail">
        <div class="single_data mb_30">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Issue From</p>
          <p class="ft_18 ft_400 ft_family w_50">
            {{ this.utils.defaultDate(request_details?.from) }}
          </p>
        </div>
        <div class="single_data mb_30">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Priority</p>
          <p class="ft_18 ft_400 ft_family w_50">
            {{ request_details?.priority_level }}
          </p>
        </div>
        <div class="single_data mb_30">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Issues</p>
          <p class="ft_18 ft_400 ft_family w_50">
            {{
            request_details.issues?.length > 0
            ? request_details?.issues?.toString()
            : "-"
            }}
          </p>
        </div>
        <div class="single_data mb_30">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Assigned To</p>
          <p class="ft_18 ft_400 ft_family w_50 capitalize">
            {{
            request_details?.assigned_to ? request_details.assigned_to : "-"
            }}
          </p>
        </div>
        <div class="single_data mb_30" *ngIf="request_details.schedule_date">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Scheduled On</p>
          <p class="ft_18 ft_400 ft_family w_50">
            {{ this.utils.defaultDate(request_details.schedule_date) }}
          </p>
        </div>
        <div class="single_data mb_30" *ngIf="request_details.type">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Type</p>
          <p class="ft_18 ft_400 ft_family w_50">
            {{ request_details.type }}
          </p>
        </div>

        <div
          class="single_data mb_30"
          *ngIf="
            request_details && request_details.assigned_to === 'contractor'
          ">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Name</p>
          <p class="ft_18 ft_400 ft_family w_50">
            {{
            request_details.contractor ? request_details.contractor.name : "-"
            }}
          </p>
        </div>
        <div
          class="single_data mb_30"
          *ngIf="request_details && request_details.desc">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Description</p>
          <p class="ft_18 ft_400 ft_family w_50 break_overflow">
            {{ request_details.desc ? request_details.desc : "-" }}
          </p>
        </div>
        <div
          class="single_data mb_30"
          *ngIf="
            request_details &&
            request_details.status &&
            request_details.status === 'rejected'
          ">
          <p class="ft_family ft_400 problem_header ft_18 w_50">Rejected</p>
          <p class="ft_18 ft_400 ft_family w_50">
            {{
            request_details.reject_reason
            ? request_details.reject_reason
            : "-"
            }}
          </p>
        </div>
        <div class="image_div mt_20">
          <div class="d_flex_space_align mb_30">
            <p class="ft_family sub_content_color ft_18 ft_400">Photos</p>
            <div
              class="no_image ft_family ft_18 w_50"
              *ngIf="request_details && request_details.images.length === 0">
              No Images Available
            </div>
          </div>
          <div
            class="detail_images"
            *ngIf="request_details && request_details.images.length > 0">
            <div
              class="detail_images"
              *ngFor="let image of request_details.images; let i = index">
              <img
                class="mb_20"
                [src]="this.utils.imageFormat(120, 130, image, 'c_fill')"
                alt="image" />
            </div>
          </div>
        </div>

        <div class="single_data mb_30">
          <p class="ft_family ft_400 problem_header ft_18 w_50">OTP</p>
          <p class="ft_18 ft_400 ft_family w_50">
            {{
            request_details.otp
            ? request_details.otp
            : "-"
            }}
          </p>
        </div>
        <ng-container *ngIf="request_details.review">
          <div class="single_data mb_30">
            <p class="ft_family ft_400 problem_header ft_18 w_50">Rating</p>
            <div style="display: flex;" class="ft_18 ft_400 ft_family w_50">
              <div *ngFor="let count of starsCount">
                <mat-icon style="color:'#077e8b'">star</mat-icon>
              </div>
              <!-- {{
        request_details.review.rating
      }} <mat-icon style="color:'#077e8b'">star</mat-icon> -->
            </div>

          </div>
          <div class="single_data mb_30">
            <p class="ft_family ft_400 problem_header ft_18 w_50">Comments</p>
            <p class="ft_18 ft_400 ft_family w_50">
              {{
              request_details.review.comment ? request_details.review.comment :
              '-'
              }}
            </p>
          </div>
        </ng-container>

      </div>
      <div class="right_side_detail">
        <div class="relative" *ngIf="accepted_view">
          <p class="stroked_mapping"></p>
          <div class="detail_flow_div">
            <div class="individual_request_flow d_flex mb_50">
              <div class="status_container d_flex_align_justify mr_20">
                <img
                  [src]="'../../../../../assets/newIcons/status_finished.svg'" />
              </div>
              <div class="flex_column_justify_center">
                <p class="ft_family ft_500 ft_18 mb_10 sub_content_color">
                  The Request has been generated
                </p>
                <p class="ft_family ft_400 ft_15 sub_content_color">
                  {{ status_1_date }}
                </p>
              </div>
            </div>
            <div class="individual_request_flow d_flex mb_50">
              <div class="status_container d_flex_align_justify mr_20">
                <img
                  [src]="
                    stage_2 === true
                      ? '../../../../../assets/newIcons/status_finished.svg'
                      : '../../../../../assets/newIcons/status_unfinished.svg'
                  " />
              </div>
              <div
                class="flex_column_justify_center"
                [ngClass]="stage_2 === true ? 'active_stage' : 'inactive_stage'">
                <p class="ft_family ft_500 ft_18 mb_10">
                  Request has been accepted
                </p>
                <p class="ft_family ft_400 ft_15">
                  {{ status_2_date && status_2_date }}
                </p>
              </div>
            </div>
            <div class="individual_request_flow d_flex mb_50">
              <div
                class="status_container d_flex_align_justify mr_20"
                [ngClass]="stage_3 && 'p_0'">
                <img
                  *ngIf="!stage_3"
                  [src]="'../../../../../assets/newIcons/work_in_progress.svg'" />
                <lottie-player
                  *ngIf="stage_3"
                  autoplay
                  loop
                  mode="normal"
                  src="../../../../../assets/payment_status/progressAnimate.json"
                  style="width: 300px"
                  class="pending_gif"></lottie-player>
              </div>
              <div
                class="flex_column_justify_center"
                [ngClass]="stage_3 === true ? 'active_stage' : 'inactive_stage'">
                <p class="ft_family ft_500 ft_18 mb_10">
                  Work is under Progress
                </p>
                <p class="ft_family ft_400 ft_15">
                  {{ status_3_date && status_3_date }}
                </p>
              </div>
            </div>
            <div class="individual_request_flow d_flex mb_50">
              <div class="status_container d_flex_align_justify mr_20">
                <img
                  [src]="
                    stage_4 === true
                      ? '../../../../../assets/newIcons/status_finished.svg'
                      : '../../../../../assets/newIcons/status_unfinished.svg'
                  " />
              </div>
              <div
                class="flex_column_justify_center"
                [ngClass]="stage_4 === true ? 'active_stage' : 'inactive_stage'">
                <p class="ft_family ft_500 ft_18 mb_10">Completed</p>
                <p class="ft_family ft_400 ft_15">
                  {{ status_4_date && status_4_date }}
                </p>
              </div>
            </div>

            <div class="individual_request_flow d_flex last_div">
              <div class="status_container d_flex_align_justify mr_20">
                <img
                  [src]="
                    stage_5 === true
                      ? '../../../../../assets/newIcons/status_finished.svg'
                      : '../../../../../assets/newIcons/status_unfinished.svg'
                  " />
              </div>
              <div
                class="flex_column_justify_center"
                [ngClass]="stage_5 === true ? 'active_stage' : 'inactive_stage'">
                <p class="ft_family ft_500 ft_18 mb_10">
                  The Request has been Closed
                </p>
                <p class="ft_family ft_400 ft_15">
                  {{ status_5_date && status_5_date }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="cancelled_view" *ngIf="cancelled_view">
          <div class="detail_flow_div relative">
            <div class="individual_request_flow d_flex mb_50">
              <div class="status_container d_flex_align_justify mr_20">
                <img
                  [src]="'../../../../../assets/newIcons/status_finished.svg'" />
              </div>
              <div class="flex_column_justify_center">
                <p class="ft_family ft_500 ft_18 mb_10 sub_content_color">
                  The Request has been generated
                </p>
                <p class="ft_family ft_400 ft_15 sub_content_color">
                  {{ status_1_date }}
                </p>
              </div>
            </div>
            <div class="individual_request_flow d_flex last_div">
              <div class="status_container d_flex_align_justify mr_20">
                <img
                  [src]="'../../../../../assets/newIcons/stage_rejected.svg'" />
              </div>
              <div class="flex_column_justify_center">
                <p class="ft_family ft_500 ft_18 mb_10 sub_content_color">
                  The Request has been Cancelled
                </p>
                <p class="ft_family ft_400 ft_15 sub_content_color">
                  {{ cancelled_date }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="cancelled_view" *ngIf="rejected_view">
          <div class="detail_flow_div relative">
            <div class="individual_request_flow d_flex mb_50">
              <div class="status_container d_flex_align_justify mr_20">
                <img
                  [src]="'../../../../../assets/newIcons/status_finished.svg'" />
              </div>
              <div class="flex_column_justify_center">
                <p class="ft_family ft_500 ft_18 mb_10 sub_content_color">
                  The Request has been generated
                </p>
                <p class="ft_family ft_400 ft_15 sub_content_color">
                  {{ status_1_date }}
                </p>
              </div>
            </div>
            <div class="individual_request_flow d_flex last_div">
              <div class="status_container d_flex_align_justify mr_20">
                <img
                  [src]="'../../../../../assets/newIcons/stage_rejected.svg'" />
              </div>
              <div class="flex_column_justify_center">
                <p class="ft_family ft_500 ft_18 mb_10 sub_content_color">
                  The Request has been Rejected
                </p>
                <p class="ft_family ft_400 ft_15 sub_content_color">
                  {{ rejected_date }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of request detail page -->
  </div>

  <div class="table-container" *ngIf="!add_requst && !show_request_details">
    <div class="d_flex_space_align mb_30">
      <h2 style="width: 70%" class="ft_family ft_500 ft_18">Requests</h2>
      <div class="d_flex_space_align cursor_pointer" (click)="addRequest()">
        <img
          class="mr_15"
          src="../../../../../assets/newIcons/addDocument.svg" />
        <p class="ft_14 ft_500 ft_family sub_text_green _m">Add Request</p>
      </div>
    </div>

    <div class="d_flex_space_align mb_30 res_filters_div">
      <div class="container_filter">
        <mat-form-field
          appearance="outline"
          class="select_filter arrow_position mr_20 res_filter">
          <mat-label>Date </mat-label>
          <mat-select
            class="arrow_position"
            (selectionChange)="filterValue($event.value)"
            [(value)]="filter_value">
            <mat-option value="this_month">This Month</mat-option>
            <mat-option value="last_month">Last Month</mat-option>
            <mat-option value="this_year">This Year</mat-option>
            <mat-option value="custom_date">Custom Range</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="filters_div" *ngIf="show_filters">
          <mat-form-field appearance="outline" class="select_filter">
            <mat-label>From</mat-label>
            <input
              (ngModelChange)="CheckDate($event, 'from_date')"
              matInput
              [matDatepicker]="picker1"
              [ngModel]="from_date"
              [max]="today"
              readonly />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="filters_div" *ngIf="show_filters">
          <mat-form-field appearance="outline" class="select_filter">
            <mat-label>To</mat-label>
            <input
              (ngModelChange)="CheckDate($event, 'to_date')"
              matInput
              [matDatepicker]="picker2"
              [ngModel]="to_date"
              [min]="minTo"
              [max]="today"
              readonly />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div>
        <div
          style="display: flex; justify-content: flex-end"
          class="paginator"
          *ngIf="!loading">
          <button
            [disabled]="page <= 1"
            (click)="onPageChange('before')"
            [ngClass]="page <= 1 ? 'inactive_button' : 'active_button'">
            <i class="fa fa-angle-left" style="font-size: 24px"></i>
          </button>
          <button
            (click)="onPageChange('after')"
            [disabled]="request_list.length < limit"
            [ngClass]="
              request_list.length < limit ? 'inactive_button' : 'active_button'
            ">
            <i class="fa fa-angle-right" style="font-size: 24px"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="table_div">
      <table class="profile_table">
        <tr class="profile_table_header card-header">
          <th style="padding-left: 32px">Date</th>
          <th>Type</th>
          <th>Issues</th>
          <th *ngIf="request_list.length !== 0">Status</th>
          <th *ngIf="request_list.length !== 0">Priority</th>
          <th *ngIf="request_list.length !== 0"></th>
          <th *ngIf="request_list.length !== 0"></th>
        </tr>
        <tr
          class="profile_table_border cursor_pointer"
          *ngFor="let request of request_list; let i = index">
          <td
            class="cursor_pointer"
            style="padding-left: 32px"
            (click)="getrequestDetail(request.request_id)">
            {{ this.utils.defaultDate(request.createdAt) }}
          </td>
          <td
            class="capitalize cursor_pointer"
            (click)="getrequestDetail(request.request_id)">
            {{ request.type }}
          </td>
          <td
            class="cursor_pointer"
            style="width: 30%"
            (click)="getrequestDetail(request.request_id)">
            <p style="width: 89%; line-height: 30px">
              {{
              request.issues && request.issues.length > 0
              ? request.issues.toString()
              : "-"
              }}
            </p>
          </td>
          <td (click)="getrequestDetail(request.request_id)">
            <span
              class="capitalize cursor_pointer"
              [ngClass]="
                request.status === 'cancelled' || request.status === 'rejected'
                  ? 'closed_color'
                  : request.status === 'accepted' ||
                    request.status === 'in_progress' ||
                    request.status === 'completed' ||
                    request.status === 'closed'
                  ? 'open_color'
                  : 'pending_color'
              ">{{
              request.status === "in_progress"
              ? "In Progress"
              : request.direct_request && request.status === "pending"
              ? "Pending"
              : request.status === "pending"
              ? "Open"
              : request.status
              }}</span>
          </td>
          <td
            class="capitalize cursor_pointer"
            (click)="getrequestDetail(request.request_id)">
            {{ request.priority_level }}
          </td>
          <td style="width: 6%">
            <div
              class="align_center"
              *ngIf="!request.direct_request && request.status === 'pending'">
              <img
                src="../../../../../assets/newIcons/showmore.svg"
                [matMenuTriggerFor]="menu"
                class="cursor_pointer p_l_r_5"
                alt="image" />
              <mat-menu #menu="matMenu" class="menu">
                <p
                  class="update_notice sub_content_color ft_16 cursor_pointer"
                  (click)="editRequest(request.request_id)">
                  Edit
                </p>
                <p
                  class="cancel_notice sub_content_color ft_16 cursor_pointer"
                  (click)="cancelRequest(request, 'cancel')">
                  Cancel
                </p>
              </mat-menu>
            </div>
            <div
              class="align_center"
              *ngIf="
                (request.direct_request && request.status === 'assigned') ||
                (request.direct_request && request.status === 'pending')
              ">
              <img
                src="../../../../../assets/newIcons/showmore.svg"
                [matMenuTriggerFor]="menu"
                class="cursor_pointer p_l_r_5"
                alt="image" />
              <mat-menu #menu="matMenu" class="menu">
                <p
                  class="update_notice sub_content_color ft_16 cursor_pointer"
                  (click)="editRequest(request.request_id)">
                  Edit
                </p>
                <p
                  class="cancel_notice sub_content_color ft_16 cursor_pointer"
                  (click)="cancelRequest(request, 'cancel')">
                  Cancel
                </p>
              </mat-menu>
            </div>
          </td>
        </tr>
        <tr class="profile_table_border" *ngIf="request_list.length === 0">
          <td style="padding-left: 32px">No Request Found</td>
        </tr>
      </table>
    </div>
  </div>

</div>

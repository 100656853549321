import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-filter-popup",
  templateUrl: "./filter-popup.component.html",
  styleUrls: ["./filter-popup.component.css"],
})
export class FilterPopupComponent implements OnInit {
  // ******* HIDE FILTER OPTIONS *******
  collPropType: boolean = false;
  collRoomAndBeds: boolean = false;
  collPriceRange: boolean = false;
  collStarRating: boolean = false;
  collAmen: boolean = false;
  collHouseRule: boolean = false;

  // SHOW ALL FILTER OPTIONS
  featureShowAll: boolean = false;
  propTypeShowAll: boolean = false;
  roomAndBedsShowAll: boolean = false;
  priceRangeShowAll: boolean = false;
  starRatingShowAll: boolean = false;
  amenShowAll: boolean = false;
  houseRuleShowAll: boolean = false;

  // Homes result
  isShowMore: boolean = false;

  // FILTERS

  priceRange = [];
  priceQurey;
  propTypeFilter = [];
  startRateFilter = [];
  amenities = [];
  amenFilter = [];
  // rating

  // Amenities
  amenities_expanded: boolean = false;

  // functions from parent
  clearApi;
  propertyType = [
    {
      name: "Town house",
      is_enabled: false,
    },
    {
      name: "Apartment",
      is_enabled: false,
    },
    {
      name: "Condo",
      is_enabled: false,
    },
    {
      name: "Single Home",
      is_enabled: false,
    },
  ];
  priceArray = [
    {
      min: "0",
      max: "1000",
      is_enabled: false,
    },
    {
      min: "1000",
      max: "2000",
      is_enabled: false,
    },
    {
      min: "2001",
      max: "4000",
      is_enabled: false,
    },
    {
      min: "4001",
      max: "6000",
      is_enabled: false,
    },
    {
      min: "6001",
      max: "8000",
      is_enabled: false,
    },
    {
      min: "8000",
      max: null,
      is_enabled: false,
    },
  ];
  rating: Array<any> = [
    {
      is_enabled: false,
    },
    {
      is_enabled: false,
    },
    {
      is_enabled: false,
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<FilterPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.amenities = this.data.amenities;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  applyFilter() {
    let data = {
      propertyFilter: [],
      amenitiesFilter: [],
      priceQurey: "",
      startRateFilter: [],
      amenFilter: [],
    };
    if (this.propTypeFilter.length > 0) {
      data.propertyFilter = this.propTypeFilter;
    }
    if (this.startRateFilter.length > 0) {
      data.startRateFilter = this.startRateFilter;
    }
    if (this.priceQurey) {
      data.priceQurey = this.priceQurey;
    }
    if (this.amenFilter) {
      data.amenFilter = this.amenFilter;
    }
    this.dialogRef.close(data);
  }
  priceFilter(temp): void {
    this.priceQurey = "";

    for (let i = 0; i < this.priceArray.length; i++) {
      let price = this.priceArray[i];
      if (price.min != temp.min) {
        price.is_enabled = false;
      }
    }
    temp.is_enabled = !temp.is_enabled;

    if (temp.is_enabled) {
      this.priceQurey = `&min_range=${temp.min}`;
      if (temp.max) {
        this.priceQurey += `&max_range=${temp.max}`;
      }
    }
  }

  proTypeFilter(temp): void {
    temp.is_enabled = !temp.is_enabled;
    for (let i = 0; i < this.propTypeFilter.length; i++) {
      var itemval = this.propTypeFilter[i];
      if (itemval == temp.name) {
        this.propTypeFilter.splice(i, 1);
        break;
      }
    }
    if (temp.is_enabled === true) {
      let data = temp.name;
      this.propTypeFilter.push(data);
    }
  }
  starFilter(temp, e): void {
    e.is_enabled = !e.is_enabled;
    let isAdd = true;
    for (let i = 0; i < this.startRateFilter.length; i++) {
      var itemval = this.startRateFilter[i];
      if (itemval == temp) {
        this.startRateFilter.splice(i, 1);
        isAdd = false;
        break;
      }
    }
    if (e.is_enabled) this.startRateFilter.push(temp);
  }
  amenitiesFilter(e, temp): void {

    if (e.checked) {
      this.amenFilter.push(temp.amenity_id);
    } else {
      let i = this.amenFilter.findIndex((x: any) => x === temp.amenity_id);
      this.amenFilter.splice(i, 1);
    }
  }
  returnIsAmenityChecked = (id: any) => {
    return this.amenFilter.findIndex((x: any) => x === id) !== -1;
  };
  showMore(expand) {
    this.amenities_expanded = expand;
  }
  // clearing
  clearAll() {
    this.PropertyClear("clearall");
    this.amenityClear("clearall");
    this.priceClearing("clearall");
    this.clearRating("clearall");
  }
  PropertyClear(e) {
    for (let i = 0; i < this.propertyType.length; i++) {
      let item = this.propertyType[i];
      item.is_enabled = false;
    }
    this.propTypeFilter = [];
  }
  amenityClear(e) {
    for (let i = 0; i < this.amenities.length; i++) {
      let item = this.amenities[i];
      item.is_enabled = false;
    }
    this.amenFilter = [];
  }
  priceClearing(e) {
    this.priceQurey = "";
    this.priceArray.forEach((item) => {
      item.is_enabled = false;
    });
  }
 
  clearRating(e) {
    for (let i = 0; i < this.rating.length; i++) {
      let div = this.rating[i];
      div.is_enabled = false;
    }
    this.startRateFilter = [];
  }
}

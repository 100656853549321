import { Injectable } from "@angular/core";
import { ApicallService } from "./apicall.service";
import { UtilityService } from "./utility.service";
@Injectable({
  providedIn: "root",
})
export class CloudinaryService {
  constructor(
    private apiservice: ApicallService,
    private utils: UtilityService
  ) {}

  public async uploadCloudinary(file: any, path: any, cb: any) {
    this.apiservice.post("user/img_sign", { path }, {}, async (res: any) => {
      if (res && res.success) {
        let data = res.data;
        const url = this.utils.returnCloudinaryUrl(data.cloudname);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("api_key", data.apikey);
        formData.append("timestamp", data.timestamp);
        formData.append("signature", data.signature);
        formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
        formData.append("folder", data.folder);
        let response: any;
        await fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            return response.text();
          })
          .then((data: any) => {
            cb(JSON.parse(data));
          });
      } else cb(null);
    });
  }
}

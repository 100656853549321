<!-- <div class="invite_container" style="padding: 20px">
  <div class="invite_content mb_20">
    <div class="success_div" *ngIf="data === 'success'">
      <div
        class="mb_20"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <img
          style="margin-bottom: 10px; width: 52px"
          src="../../assets/newIcons/success.svg"
          alt="image"
        />
        <h2 class="ft_20 align-center _m" style="color: green">Success</h2>
      </div>
      <p class="mb_20" style="font-size: 14px; color: #757575">
        Your Property has been booked successfully 
      </p>

      <button
        mat-flat-button
        class="primary_common_button"
        (click)="reRoute('success')"
      >
        Ok
      </button>
    </div>
    <div class="success_div" *ngIf="data === 'failure'">
      <div
        class="mb_20"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <img
          style="margin-bottom: 10px; width: 52px"
          src="../../assets/newIcons/failure.svg"
          alt="image"
        />
        <h2 class="ft_20 align-center _m" style="color: #ec5849">Failure</h2>
      </div>
      <p class="mb_20" style="font-size: 14px; color: #757575">
        Your Invite URL has Expired
      </p>
      <div></div>
      <button
        class="primary_common_button"
        mat-flat-button
        autofocus="false"
        (click)="reRoute('failure')"
      >
        Ok
      </button>
    </div>
  </div>
</div> -->

<div class="card_box" style="width: 440px">
  <div
    *ngIf="loader === true"
    class="d_flex_align_justify"
    style="flex-direction: column"
  >
    <mat-spinner diameter="80"></mat-spinner>
    <p class="mt_20 mb_20"></p>
    <p class="ft_family sub_content_color ft_18 align_center">
      Please wait this will take some time.
    </p>
  </div>
  <div
    class="icon icon--order-success svg d_flex_align"
    style="flex-direction: column"
    *ngIf="loader === false && data === 'success'"
  >
    <lottie-player
      autoplay
      loop
      mode="normal"
      src="../../../assets/payment_status/lf20_nus9odlt.json"
      style="width: 150px"
    >
    </lottie-player>
    <p class="mb_20 ft_family ft_500 ft_18 sub_content_color mt_20" style="text-align: center;">
      Your Property has been booked successfully 
    </p>
    <button
    class="primary_common_button"
    mat-flat-button
    autofocus="false"
    (click)="reRoute('success')"
  >
    Ok
  </button>
  </div>
  <div
    class="icon icon--order-success svg d_flex_align"
    style="flex-direction: column"
    *ngIf="!loader && data === 'failure'"
  >
    <lottie-player
      autoplay
      loop
      mode="normal"
      src="../../../assets/payment_status/95614-error-occurred.json"
      style="width: 150px"
    ></lottie-player>
    <p class="mb_20 ft_family ft_500 ft_18 sub_content_color mt_20">
       Can't Book Property 
    </p>
    <p class="mb_30 ft_family ft_16 ft_400 sub_content_color align_center">
      Please try again after sometime.
    </p>
    <button
    class="primary_common_button"
    mat-flat-button
    autofocus="false"
    (click)="reRoute('failure')"
  >
    Ok
  </button>
  </div>
</div>

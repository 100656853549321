import { Component, OnInit, Inject } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import "@lottiefiles/lottie-player";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";
@Component({
  selector: "app-status-check",
  templateUrl: "./status-check.component.html",
  styleUrls: ["./status-check.component.css"],
})
export class StatusCheckComponent implements OnInit {
  loader: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<StatusCheckComponent>,
    public cookie: CookieService,
    public url: ApicallService,
    public utils: UtilityService
  ) {}
  paid_status;
  response;
  params;

  ngOnInit(): void {
    let id = this.cookie.get("transaction_id");
    if (id) {
      setTimeout(() => {
        this.url.get(`user/transactions/${id}`, this.params, (res) => {
          this.loader = false;
          if (res) {
            this.response = res.data.transaction;
            this.paid_status = res.data.transaction.status;
            if (
              this.paid_status === "paid" ||
              this.paid_status === "processing"
            ) {
              setTimeout(() => {
                this.cookie.remove("transaction_id");
                this.cookie.remove("payment_detail");
                this.dialogRef.close(this.data.status);
              }, 5000);
            } else {
              setTimeout(() => {
                this.dialogRef.close(this.data.status);
              }, 5000);
            }
          }
        });
      }, 10000);
    } else {
      this.dialogRef.close("failure");
    }
  }
}

import { Component, OnInit, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { PaymentSuccessComponent } from "./success-popup/success-popup.component";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { from } from "rxjs";
import { pluck } from "rxjs/operators";
import { AuthService } from "src/app/service/auth.service";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";
import { StatusCheckComponent } from "src/app/payment-checkout/status-check/status-check.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CookieServiceService } from "src/app/cookie-service.service";
import { DashboardComponent } from "../dashboard/dashboard.component";
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  hPos: MatSnackBarHorizontalPosition = "left";
  vPos: MatSnackBarVerticalPosition = "bottom";
  restrictedMail: string;
  phone;
  isAllowed: boolean;
  email;
  fullname = "";
  contactNo = "";
  hobbiesInt = "";
  name;
  item: any;
  selectedIndex = 0;
  mobile: any;
  profession: any;
  company: any;
  workingSince: any;
  auth: any;
  due_date: any = 7;
  page: number = 1;
  limit: number = 10;
  UserDashboardData: any;
  profileload = false;
  bookingload = false;
  transactionsload = false;
  documentsload = false;
  requestload = false;
  notificationsload = false;
  transaction: any;
  booking;
  booking_value = false;
  profile: any;
  tabValue = "bookings";
  status;
  public myPropertyData: any = [];
  billing_details: any;
  dashboardTransaction: any = [];
  loading = true;
  params;
  width: number = window.innerWidth;
  constructor(
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    public utils: UtilityService,
    public authService: AuthService,
    private url: ApicallService,
    public spinner: MatProgressSpinnerModule,
    public cokie: CookieServiceService,
    public dashboard: DashboardComponent
  ) {}

  ngOnInit(): void {
    // if (this.cokie.isLoggedIn()) {
    //   this.dashboard.getNotifications();
    // }
    this.route.queryParams.subscribe((params) => {
      if (params.tab) {
        this.tabValue = params.tab;
      }
      // if (params.status) {
      //   // this.tabValue = "bookings";
      // }
      this.utils.email$.subscribe((response) => {
        this.restrictedMail = response;
      });
      // if (this.restrictedMail !== 'smdshafees02062000@gmail.cm' ) {
      //     this.router.navigate(["/rental_application"]);
      // }
    });
  }

  ngOnChanges() {
  }

  // change tab
  changeTab = (tab) => {
    this.selectedIndex = parseInt(tab);
  };
  returnObjExists = (obj) => {
    if (obj && Object.keys(obj).length > 0) return true;
    return false;
  };

  getUserDashboardApi() {
    this.url.get("/user/dashboard", this.params, (res) => {
      if (res.success) {
        this.loading = false;
        this.UserDashboardData = res.data;
        this.dashboardTransaction = res.data.transactions;
        this.due_date = new Date(
          new Date().setDate(
            this.UserDashboardData.property && this.UserDashboardData.property
              ? this.UserDashboardData.property.late_fee.due
              : 7
          )
        );
        this.due_date = moment(this.due_date).format("MMMM Do YYYY");
      } else {
        this.loading = true;
      }
    });
  }

  returnDueDate = (lateFeeObj: any) => {
    if (lateFeeObj && lateFeeObj.due) return lateFeeObj.due;
    return 7;
  };
  handleOnInputChange(e) {
    this.phone = this.utils.getUSFormat(e);
  }
  tabChange(value) {
    this.router.navigate([], {
      queryParams: {
        tab: value,
      },
    });
    this.tabValue = value;
    window.scrollTo(0, 0);
  }
}

<mat-spinner *ngIf="loading" class="spinner" [diameter]="50"></mat-spinner>
<div *ngIf="!loading">
  <div class="d_flex_space_align mb_30">
    <h2 class="ft_family ft_500 ft_18 _m">Documents</h2>
  </div>
  <div class="d_flex_space_align mb_30 res_filters_div">
    <div></div>
  </div>
  <mat-tab-group
    class="documents_tab"
    (activeLink)="(current_tab)"
    (selectedTabChange)="tabClick($event, activeLink)"
  >
    <mat-tab label="My Documents">
      <div class="table_div mt_20" *ngIf="!add_documents">
        <div class="d_flex_space_align mb_20 cursor_pointer">
          <div></div>
          <div class="d_flex_space_align" (click)="addDocument()">
            <img
              class="mr_15"
              src="../../../../../assets/newIcons/addDocument.svg"
            />
            <p class="ft_14 ft_500 ft_family sub_text_green _m">Add Document</p>
          </div>
        </div>
        <table class="profile_table">
          <tr class="profile_table_header card-header">
            <th style="padding-left: 32px">Type</th>
            <th>Created Date</th>
            <th>Expiry Date</th>
            <th></th>
          </tr>
          <tr class="profile_table_border" *ngFor="let item of documents_list">
            <td style="padding-left: 32px; width: 33%">{{ item.type }}</td>
            <td style="width: 28%">{{ utils.defaultDate(item.createdAt) }}</td>
            <td>{{ utils.defaultDate(item.expiry_date) }}</td>
            <td style="width: 4%">
              <div class="">
                <img
                  src="../../../../../assets/newIcons/showmore.svg"
                  [matMenuTriggerFor]="menu"
                  class="cursor_pointer p_l_r_5"
                  alt="image"
                />
                <mat-menu #menu="matMenu" class="menu">
                  <p
                    class="update_notice sub_content_color ft_16 cursor_pointer"
                    (click)="getDocuments(item.document_id)"
                  >
                    Edit
                  </p>
                  <p
                    class="cancel_notice sub_content_color ft_16 cursor_pointer"
                    (click)="deleteDocument(item.document_id)"
                  >
                    Delete
                  </p>
                </mat-menu>
              </div>
            </td>
          </tr>
          <tr class="" *ngIf="documents_list && documents_list.length === 0">
            <td
              style="padding-left: 32px"
              class="ft_family ft_400 mb_15 sub_content_color"
            >
              No Documents available
            </td>
          </tr>
        </table>
      </div>
      <div class="add_edit_div mt_20" *ngIf="add_documents">
        <div class="d_flex_space_align mb_40">
          <div class="brudcrumbs">
            <p
              class="ft_family ft_500 ft_16 mr_10 cursor_pointer"
              (click)="reRoute()"
            >
              Documents
            </p>
            <span>/</span>
            <p class="ft_family ft_500 ft_16 ml_10 cursor_pointer">
              {{
                document_detail && document_detail.document_id
                  ? "Edit"
                  : "Add Document"
              }}
            </p>
          </div>
          <div class="buttons">
            <p
              class="ft_14 ft_500 ft_family sub_text_green _m cursor_pointer"
              [ngClass]="disable_button && 'no_click'"
              (click)="addDocs(document_detail && document_detail.document_id)"
            >
              {{
                document_detail && document_detail.document_id
                  ? "Save Changes"
                  : "Upload Document"
              }}
            </p>
          </div>
        </div>
        <div class="profile_table mb_30">
          <div
            class="profile_table_header card-header ft_family ft_500 ft_16 d_flex_align"
          >
            Document Details
          </div>
          <div class="body_content p_30">
            <div class="d_flex_align mb_40 res_document_div">
              <div class="relative input_holder">
                <p class="ft_family ft_14 ft_500">Document Type*</p>
                <div
                  class="select_div ft_family ft_16 ft_400 sub_content_color"
                  (click)="openBox()"
                  (click)="showPopup(1, $event)"
                >
                  {{ document_type == "" ? "Select" : document_type }}
                  <i class="fa fa-angle-down arrow"></i>
                </div>
                <div
                  class="selected_option_div cursor_pointer w_100"
                  *ngIf="toggleSelect"
                >
                  <div
                    class="select_customized_options cursor_pointer"
                    (click)="selectedOption('Profile Id')"
                  >
                    Profile Id
                  </div>
                  <div
                    class="select_customized_options cursor_pointer"
                    (click)="selectedOption('Employment')"
                  >
                    Employment
                  </div>
                  <div
                    class="select_customized_options cursor_pointer"
                    (click)="selectedOption('Others')"
                  >
                    Others
                  </div>
                </div>
              </div>
              <div class="date_picker_div">
                <p class="ft_family ft_14 ft_500">Expiry Date*</p>
                <mat-form-field appearance="fill" class="expiry_date">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    [(ngModel)]="expiry_date"
                    [min]="today"
                    readonly
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="other_tab mt_20" *ngIf="document_type === 'Others'">
              <p class="ft_family ft_14 ft_500 mb_10">
                Enter the Document Type
              </p>
              <input
                type="text"
                class="input_border profile_input"
                [(ngModel)]="other_type"
              />
            </div>
          </div>
        </div>
        <div class="profile_table">
          <div
            class="profile_table_header d_flex card-header ft_family ft_500 ft_18 d_flex_align"
          >
            <p class="ft_16 mr_10">Files*</p>
            <span class="ft_300 ft_15">
              (Formats : png, jpeg, pdf / Max Size : 5 MB)</span
            >
          </div>
          <div class="image_container mb_30">
            <div
              class="uploaded_img"
              *ngFor="let image of imageArray; let i = index"
              [ngClass]="image.format === 'pdf' && 'd_flex_align_justify'"
            >
              <img
                class="uploaded_img"
                [src]="
                  image.format === 'pdf'
                    ? '../../../../../assets/newIcons/pdf.png'
                    : this.utils.imageFormat(120, 130, image, 'c_fill')
                "
                alt="image"
              />
              <img
                class="remove_icon cursor_pointer"
                src="../../../../../assets/newIcons/remov.svg"
                (click)="removeImage(image, i)"
              />
            </div>
            <div class="mat_spinner" *ngIf="imageLoading">
              <mat-spinner class="upload_loader" diameter="20"></mat-spinner>
            </div>
            <div
              class="upload_button"
              *ngIf="imageArray && imageArray.length < 5"
            >
              <img
                src="../../../../../assets/newIcons/addImage.svg"
                class="mb_10"
              />
              <p style="color: #c4c4c4" class="ft_family ft_500 ft_15">Add</p>
              <input
                class="cursor_pointer upload"
                type="file"
                (change)="upload($event)"
                accept="file/*,jpeg,pdf,png"
              />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Shared with Me">
      <table class="profile_table">
        <tr class="profile_table_header card-header">
          <th style="padding-left: 32px">Name</th>
          <th>Created On</th>
          <th>Expiry On</th>
          <th>Signature Status</th>
          <th>Action</th>
        </tr>
        <tr class="profile_table_border" *ngFor="let item of shared_document">
          <td style="padding-left: 32px; width: 20%">{{ item.name }}</td>
          <td style="width: 20%">{{ utils.defaultDate(item.createdAt) }}</td>
          <td style="width: 20%">-</td>
          <td
            class="capitalize"
            style="width: 20%"
            [ngClass]="
              item.zoho_data.request_status === 'completed'
                ? 'status_created'
                : item.zoho_data.request_status === 'inprogress'
                ? 'status_processing'
                : 'status_cancelled'
            "
          >
            {{ item.zoho_data.request_status }}
          </td>
          <td style="width: 10%; padding-right: 10px">
            <div
              class="cursor_pointer"
              (click)="dowloadApi(item.doc_id, item.name)"
              *ngIf="
                item.zoho_data.request_status === 'completed' && item.is_sign
              "
            >
              <i style="color: #757575" class="fa fa-download"></i>
              <!-- <img
                src="../../../../../assets/newIcons/showmore.svg"
                [matMenuTriggerFor]="menu"
                class="cursor_pointer p_l_r_5"
                alt="image"
              /> -->
            </div>
            <div
              class=""
              *ngIf="
                !item.is_sign && item.zoho_data.request_status === 'completed'
              "
            >
              <a [href]="item.link" target="_blank">View</a>
              <!-- <img
              src="../../../../../assets/newIcons/showmore.svg"
              [matMenuTriggerFor]="menu"
              class="cursor_pointer p_l_r_5"
              alt="image"
            /> -->
            </div>
          </td>
        </tr>
        <tr class="" *ngIf="shared_document && shared_document.length === 0">
          <td
            style="padding-left: 32px"
            class="ft_family ft_400 mb_15 sub_content_color"
          >
            No Documents available
          </td>
        </tr>
      </table>
    </mat-tab></mat-tab-group
  >
</div>

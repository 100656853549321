<div class="profile_content">
  <mat-spinner *ngIf="loading" class="spinner" [diameter]="50"></mat-spinner>
  <div *ngIf="!loading">
    <div class="image_with_button d_flex_space_align">
      <div class="d_flex_space_align">
        <div class="imgae_div">
          <img
            *ngIf="!imageLoading"
            [src]="
              this.profileImageUrl
                ? this.profileImageUrl
                : '../../../../../assets/newIcons/displayUser.png'
            "
            alt="image"
          />
          <mat-spinner
            class="upload_loader"
            *ngIf="imageLoading"
            diameter="20"
          ></mat-spinner>
          <input
            class="cursor_pointer upload"
            type="file"
            (change)="upload($event)"
            accept="image/jpeg,image/jpg,image/png"
          />
          <div
            class="image_edit d_flex_align_justify cursor_pointer"
            *ngIf="!imageLoading"
          >
            <i
              class="material-icons"
              style="color: white; font-size: 1rem !important"
              >edit</i
            >
          </div>
        </div>
        <div>
          <p class="ft_family ft_500 ft_20 mb_10">
            {{ profileData.name.first }} {{ profileData.name.last }}
          </p>
          <div class="d_flex res_check_mail">
            <p class="ft_family ft_14 sub_content_color mr_15">
              {{ profileData.email }}
            </p>
            <div
              class="verify_div cursor_pointer"
              *ngIf="!profileData.is_email_verified"
              (click)="verifyEmail()"
            >
              <p class="sub_text_green ft_14">Click here to verify now</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d_flex">
        <button
          mat-stroked-button
          class="mr_15 secondary_stroked_button password_button"
          (click)="openChangePassword()"
        >
          Change Password
        </button>
        <button-spinner
          [showSpinner]="saveSpinner"
          childClass="profile_save d_flex_align_justify"
          (click)="updateProfile()"
          *ngIf="width > 480"
        >
          Save Changes
        </button-spinner>
      </div>
    </div>
    <div class="basic_details mt_30">
      <div class="card mb_30">
        <div class="card-header d_flex_align">Basic Information</div>
        <div class="card-body" style="padding: 25px 25px 10px 25px !important">
          <div class="d_flex_space_align mb_25 row">
            <div class="col-lg-4 col-sm-12">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                First Name*
              </p>
              <input class="profile_input" [(ngModel)]="fname" />
            </div>
            <div class="col-lg-4 col-sm-12">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Last Name*
              </p>
              <input class="profile_input" [(ngModel)]="lname" />
            </div>
            <div class="col-lg-4 col-sm-12">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Email Id
              </p>
              <input
                class="profile_input"
                [(ngModel)]="email"
                readonly="true"
              />
            </div>
          </div>
          <div class="input_with_select row">
            <div class="col-sm-4">
              <p class="_m ft_family ft_400 ft_14 sub_content_color">Gender*</p>
              <mat-form-field
                appearance="fill"
                class="gender ft_family"
                style="width: 80%"
              >
                <mat-select class="mat_select" [(value)]="gender">
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-4" style="margin-bottom: 15px">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Contact Number*
              </p>
              <input
                class="profile_input"
                [ngModel]="phone"
                maxlength="16"
                (ngModelChange)="handleOnInputChange($event)"
              />
            </div>
            <div class="col-sm-4">
              <p class="_m ft_family ft_400 ft_14 sub_content_color">
                Do you have Pets*
              </p>
              <mat-form-field
                appearance="fill"
                class="gender ft_family"
                style="width: 80%"
              >
                <mat-select
                  class="mat_select"
                  [value]="is_pets_available"
                  (selectionChange)="petsAvailable($event.value)"
                >
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="input_with_select row">
            <div
              class="col-sm-4"
              style="margin-bottom: 15px"
              *ngIf="is_pets_available === 'Yes'"
            >
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Select your Pets
              </p>
              <p class="ft_family ft_400 ft_14 sub_content_color">
                <mat-checkbox
                  *ngFor="let pet of pets; let i = index"
                  class="checkbox sub_content_color mr_15 ft_16 ft_family mb_20"
                  [checked]="pet.is_enabled"
                  (change)="addPets(pet, i)"
                  >{{ pet.name }}</mat-checkbox
                >
              </p>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb_30">
        <div class="card-header d_flex_align">Professional Information</div>
        <div class="card-body">
          <div class="d_flex_space_align mb_25 row">
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Current Employer
              </p>
              <input class="profile_input" [(ngModel)]="currentEmployer" />
            </div>
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Current Client
              </p>
              <input class="profile_input" [(ngModel)]="currentClient" />
            </div>
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Job Title
              </p>
              <input class="profile_input" [(ngModel)]="jobTitle" />
            </div>
          </div>
          <div class="d_flex_space_align row">
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Techology Stack
              </p>
              <input class="profile_input" [(ngModel)]="technologyStack" />
            </div>
          </div>
        </div>
      </div>
      <div class="card mb_30">
        <div class="card-header d_flex_align">Additional Information</div>
        <div class="card-body" style="padding: 25px 25px 10px 25px !important">
          <div class="d_flex_space_align mb_25 row">
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Hobbies and Interest
              </p>
              <input class="profile_input" [(ngModel)]="hobbiesInt" />
            </div>
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Language
              </p>
              <input class="profile_input" [(ngModel)]="language" />
            </div>
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Health Condition to be aware of
              </p>
              <input class="profile_input" [(ngModel)]="healthCondition" />
            </div>
          </div>
          <div class="d_flex_space_align row">
            <div class="col-sm-4">
              <p class="_m ft_family ft_400 ft_14 sub_content_color">
                Food Preferences
              </p>
              <mat-form-field
                appearance="fill"
                class="gender ft_family"
                style="width: 80%"
              >
                <mat-select class="mat_select" [(value)]="food_preference">
                  <mat-option value="Any">Any</mat-option>
                  <mat-option value="Veg">Veg</mat-option>
                  <mat-option value="Non-veg">Non-Veg</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb_30">
        <div class="card-header d_flex_space_align">
          <p>Account Details</p>
          <p
            class="sub_text_green ft_600 cursor_pointer"
            *ngIf="
              !profileData.stripe ||
              (profileData.stripe && !profileData.stripe.is_bank)
            "
            (click)="LinkAccount()"
          >
            Link Account
          </p>
          <p
            *ngIf="profileData.stripe?.is_bank"
            class="sub_text_green ft_600 cursor_pointer"
            (click)="LinkAccount()"
          >
            Update Account
          </p>
        </div>
        <div class="card-body">
          <div class="d_flex_space_align mb_25 row">
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Bank Name
              </p>
              <input
                class="profile_input"
                [(ngModel)]="bank_name"
                disabled="true"
              />
            </div>
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Account No
              </p>
              <input
                class="profile_input"
                name="emergencyContactNumber"
                [ngModel]="acc_no"
                (ngModelChange)="handleOnInputEmergencyChange($event)"
                disabled="true"
              />
            </div>
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Route Number
              </p>
              <input
                class="profile_input"
                [(ngModel)]="route_number"
                disabled="true"
              />
            </div>
            
          </div>
          <div class="d_flex_space_align row">
            <div class="col-sm-4">
              <p class="_m ft_family ft_400 ft_14 mb_14 sub_content_color">
                Payout Status
              </p>
              <input
                class="profile_input"
                [ngModel]="payout_enabled ? 'Enabled' : 'Information Required' "
                [ngStyle]="{'color': payout_enabled ? '#28a745' : '#dc3545' }"
                disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card mb_30">
        <div class="card-header d_flex_align">Emergency Contact Details</div>
        <div class="card-body">
          <div class="d_flex_space_align row">
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Full Name*
              </p>
              <input
                class="profile_input"
                [(ngModel)]="emergencyContactFullName"
              />
            </div>
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Contact Number*
              </p>
              <input
                class="profile_input"
                name="emergencyContactNumber"
                [ngModel]="emergencyContactNumber"
                (ngModelChange)="handleOnInputEmergencyChange($event)"
                maxlength="16"
              />
            </div>
            <div class="col-sm-4">
              <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                Relationship*
              </p>
              <input class="profile_input" [(ngModel)]="relationship" />
            </div>
          </div>
        </div>
      </div>
      <div class="responsive_button" *ngIf="width < 480">
        <button
          class="profile_save d_flex_align_justify"
          (click)="updateProfile()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login100-form">
        <div class="">
          <div class="logo mb_30 align_center">
            <img src="./assets/newLogo/newLogoText.svg" alt="image" />
          </div>
        </div>
        <p class="login100-form-title ft_family ft_20 ft_500 mb_20">
          Reset Password
        </p>
        <div class="row mb_10">
          <div class="input_container mb_20 width_specifier">
            <p class="field_heading ft_family ft_14 ft_400">New Password*</p>
            <div class="relative">
              <input
                name="oldPassword"
                matInput
                [type]="newhide ? 'password' : 'text'"
                [(ngModel)]="newPassword"
              />
              <div class="pass-icon" (click)="newhide = !newhide">
                <img
                  src="../../../../assets/newIcons/show.svg"
                  alt="image"
                  *ngIf="newhide"
                />
                <img
                  src="../../../../assets/newIcons/hide.svg"
                  alt="image"
                  *ngIf="!newhide"
                />
              </div>
            </div>
          </div>
          <div class="input_container mb_20 width_specifier">
            <p class="field_heading ft_family ft_14 ft_400">
              Confirm Password*
            </p>
            <div class="relative">
              <input
                name="password"
                matInput
                [type]="cnfirmhide ? 'password' : 'text'"
                [(ngModel)]="confirmPassword"
                (keyup.enter)="submit()"
              />
              <div class="pass-icon" (click)="cnfirmhide = !cnfirmhide">
                <img
                  src="../../../../assets/newIcons/show.svg"
                  alt="image"
                  *ngIf="cnfirmhide"
                />
                <img
                  src="../../../../assets/newIcons/hide.svg"
                  alt="image"
                  *ngIf="!cnfirmhide"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d_flex">
          <button
            mat-flat-button
            class="btn-div"
            type="submit"
            (click)="submit()"
          >
            Reset Password
          </button>
          <button
            mat-stroked-button
            class="login100-form-btn mrl_15 cancel"
            (click)="cancel()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main_container">
  <div class="dummy_loader" *ngIf="loading">
    <div class="d_flex_space_align">
      <div class="flex_column">
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="348px"
          height="58px"
          borderRadius="5px"
        >
        </ngx-shimmer-loading>
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="591px"
          height="30px"
          borderRadius="5px"
        >
        </ngx-shimmer-loading>
      </div>
      <div class="d_flex_align">
        <ngx-shimmer-loading
          class="host_image"
          width="64px"
          height="64px"
          shape="circle"
        >
        </ngx-shimmer-loading>
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="195px"
          height="58px"
          borderRadius="5px"
        >
        </ngx-shimmer-loading>
      </div>
    </div>
    <div class="dummy_image_container mb_20">
      <div class="left_side_dummy">
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="753px"
          height="407px"
          borderRadius="5px"
        >
        </ngx-shimmer-loading>
      </div>
      <div class="right_side_dummy">
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="245px"
          height="200px"
          borderRadius="5px"
        >
        </ngx-shimmer-loading>
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="245px"
          height="200px"
          borderRadius="5px"
        >
        </ngx-shimmer-loading>
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="245px"
          height="200px"
          borderRadius="5px"
        >
        </ngx-shimmer-loading>
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="245px"
          height="200px"
          borderRadius="5px"
        >
        </ngx-shimmer-loading>
      </div>
    </div>
    <div class="d_flex_align_justify mb_20">
      <div class="tab_div">
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="195px"
          height="58px"
          borderRadius="5px"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="195px"
          height="58px"
          borderRadius="5px"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading
          class="guest_div_shimmer"
          width="195px"
          height="58px"
          borderRadius="5px"
        ></ngx-shimmer-loading>
      </div>
    </div>
    <div class="solo_tab">
      <ngx-shimmer-loading
        class="guest_div_shimmer"
        width="195px"
        height="58px"
        borderRadius="5px"
      ></ngx-shimmer-loading>
    </div>
  </div>
  <div
    class="reviews_pop_up_container"
    *ngIf="!loading"
    [ngClass]="
      show_review_popUp === true ? 'reviews_pop_up_container' : 'no_width'
    "
  >
    <div
      class=""
      (click)="hideReviewPopUp()"
      [ngClass]="show_review_popUp === true ? 'shadow' : 'shadow_display'"
    ></div>
    <div
      class="review_pop_up"
      id="more_review"
      [ngClass]="show_review_popUp === true ? 'review_pop_up' : 'no_width'"
    >
      <div class="d_flex_space_align mb_50">
        <h2 class="details_sub_heading ft_18 _m">Reviews</h2>
        <div>
          <div
            style="display: flex; justify-content: flex-end"
            class="review_pagination_button"
            *ngIf="!loading"
          >
            <button
              [disabled]="page <= 1"
              [ngClass]="page <= 1 ? 'inactive_button' : 'active_button'"
              (click)="onPageChange('before')"
            >
              <i class="fa fa-angle-left" style="font-size: 14px"></i>
            </button>
            <button
              [disabled]="
                comment_section_array && comment_section_array.length < limit
              "
              [ngClass]="
                comment_section_array && comment_section_array.length < limit
                  ? 'inactive_button'
                  : 'active_button'
              "
              (click)="onPageChange('after')"
            >
              <i class="fa fa-angle-right" style="font-size: 14px"></i>
            </button>
          </div>
        </div>
      </div>

      <div
        class="individual_review mb_one_rem"
        *ngFor="let review of comment_section_array"
      >
        <div class="reviewer_image_data mb_one_rem">
          <div class="review_image_div mr_20">
            <img
              class=""
              src="../../assets/newIcons/profileuser.svg"
              alt="image"
            />
          </div>
          <p class="ft_18 ft_500 ft_family mr_20">
            {{ review.user.name.first }}
            {{ review.user.name.last }}
          </p>
          <div class="review_value ft_500 ft_family ft_12 d_flex_align_justify">
            {{ review.overall_rating }}
          </div>
        </div>
        <div class="review_data">
          <p class="ft_family sub_content_color ft_14 ft_400">
            {{ review.comment }}
          </p>
        </div>
      </div>
      <div
        class="no_reviews"
        *ngIf="comment_section_array && comment_section_array.length === 0"
      >
        <p class="ft_500 ft_family ft_16 sub_content_color">
          No Reviews Available
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="!loading && data">
    <div class="d_flex_space_align mb_30">
      <div class="property_name_address">
        <h1 class="ft_family ft_400 ft_25 mb_20 property_header">
          {{ data.name }}
        </h1>
        <div class="d_flex responsive_address">
          <p class="sub_content_color ft_16 ft_family ft_400 mr_35 res_address">
            {{ data.address.line_1 && data.address.line_1 + ", "
            }}{{ data.address.city.name && data.address.city.name + ", "
            }}{{ data.address.state.name && data.address.state.name + ", "
            }}{{ data.address.country }}
          </p>
          <div class="d_flex_align" *ngIf="data.reviews && data.reviews.rating">
            <i class="ft_18 fa fa-star checked sub_text_green mr_15"></i>
            <p class="ft_18 sub_content_color ft_family ft_400">
              {{ data.reviews && data.reviews && data.reviews.rating }}
            </p>
          </div>
        </div>
      </div>

      <div class="proceed_to_pay_div">
        <button
          class="proceed_to_pay"
          (click)="payment(data.check_out, null)"
          *ngIf="data.rental_type === 'entire_home'"
        >
          Pay {{ utils.displayPrice(data.price) }}
        </button>
      </div>
    </div>

    <div class="image_container mb_50">
      <div class="image_holder_div">
        <div class="big_image">
          <img
            [src]="this.utils.imageFormat(765, 400, data.gallery[0], 'c_fit')"
            (click)="openImage(0)"
            alt="image"
          />
        </div>
        <div class="small_images">
          <div class="solo_image" *ngIf="data.gallery.length > 1">
            <img
              [src]="this.utils.imageFormat(350, 200, data.gallery[1], 'c_fit')"
              (click)="openImage(1)"
              alt="image"
            />
          </div>
          <div class="solo_image" *ngIf="data.gallery.length > 2">
            <img
              [src]="this.utils.imageFormat(350, 200, data.gallery[2], 'c_fit')"
              (click)="openImage(2)"
              alt="image"
            />
          </div>
          <div class="solo_image" *ngIf="data.gallery.length > 3">
            <img
              [src]="this.utils.imageFormat(350, 200, data.gallery[3], 'c_fit')"
              (click)="openImage(3)"
              alt="image"
            />
          </div>
          <div class="solo_image" *ngIf="data.gallery.length > 4">
            <img
              [src]="this.utils.imageFormat(350, 200, data.gallery[4], 'c_fit')"
              alt="image"
            />
            <div class="img-dark-gradiant" (click)="openImage(0)">
              <label>More Photos</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hosted_end mb_40">
      <div class="hosted_by_div">
        <div class="host_img" *ngIf="data.host && !data.host.image">
          <img src="../../assets/newIcons/profileuser.svg" />
        </div>
        <div class="host_img" *ngIf="data.host && data.host.image">
          <img [src]="utils.imageFormat(80, 80, data.host.image, 'c_fit')" />
        </div>
        <div class="host">
          <h2 class="ft_family ft_18 ft_500 mb_5">
            Hosted By {{ data.host.name }}
          </h2>
          <p style="color: #22212f" class="ft_15 ft_family ft_300">
            Joined in {{ memberSince }}
          </p>
        </div>
      </div>
    </div>
    <div class="tabs_container">
      <div class="tabs d_flex_align_justify mb_50 sticky" id="tabs">
        <div class="tab_div">
          <h2
            class="ft_25 ft_family"
            (click)="scrollToElement('about')"
            (click)="selectedTab = 'about'"
            [ngClass]="selectedTab === 'about' ? 'selected_tab' : null"
          >
            About
          </h2>
          <h2
            *ngIf="rooms && rooms.length > 0"
            class="ft_25 ft_family"
            (click)="scrollToElement('rooms')"
            (click)="selectedTab = 'rooms'"
            [ngClass]="selectedTab === 'rooms' ? 'selected_tab' : null"
          >
            Rooms
          </h2>
          <h2
            class="ft_25 ft_family"
            (click)="scrollToElement('amenities')"
            (click)="selectedTab = 'amenities'"
            [ngClass]="selectedTab === 'amenities' ? 'selected_tab' : null"
          >
            Amenities
          </h2>
          <h2
            class="ft_25 ft_family"
            (click)="scrollToElement('reviews')"
            (click)="selectedTab = 'reviews'"
            [ngClass]="selectedTab === 'reviews' ? 'selected_tab' : null"
          >
            Reviews
          </h2>
        </div>
      </div>
      <div class="tabs_content">
        <div class="about_content_div mb_60" id="about">
          <h2 class="details_sub_heading mb_50 ft_25">About</h2>
          <p
            class="sub_content_color ft_family ft_17 ft_400"
            style="line-height: 2.188rem"
          >
            {{ data.about }}
          </p>
          <div class="hosted_by_container d_flex_space_align mb_70">
            <div class="rooms_beds_guest d_flex_space_align">
              <div class="individual_detail">
                <img class="mb_20" src="../../assets/newIcons/detail_bed.svg" />
                <p class="ft_family ft_16 sub_content_color ft_400">
                  {{ data.bedrooms }} Bedrooms
                </p>
              </div>
              <div class="individual_detail">
                <img
                  class="mb_20"
                  src="../../assets/newIcons/detail_bathroom.svg"
                  alt="image"
                />
                <p class="ft_family ft_16 sub_content_color ft_400">
                  {{ data.bathrooms }} Bathrooms
                </p>
              </div>
              <div class="individual_detail">
                <img
                  class="mb_20"
                  src="../../assets/newIcons/detail_guests.svg"
                  alt="image"
                />
                <p class="ft_family ft_16 sub_content_color ft_400">
                  {{ data.guests }} Guests
                </p>
              </div>
            </div>
          </div>
          <div
            class="rules_regulations"
            *ngIf="data.house_rules && data.house_rules.length > 0"
          >
            <p class="ft_20 ft_400 ft_family mb_30">Rules and Regulation</p>
            <div class="policy_height">
              <div
                class="rules_div_height"
                *ngFor="let rules of data.house_rules; let i = index"
              >
                <div class="rules d_flex_align" *ngIf="i < initalrulesLength">
                  <img
                    class="mr_20"
                    src="../../assets/newIcons/rules.svg"
                    alt="image"
                  />
                  <p class="ft_family ft_400 sub_content_color ft_17">
                    {{ rules.value }}
                  </p>
                </div>
              </div>
            </div>
            <a
              *ngIf="
                viewRules !== 'rules' &&
                data.house_rules &&
                data.house_rules.length > 2
              "
              class="ft_family ft_400 sub_content_color ft_14 cursor_pointer"
              (click)="showRules('rules')"
              >Show More</a
            >
            <a
              *ngIf="viewRules === 'rules'"
              class="ft_family ft_400 sub_content_color ft_14 cursor_pointer"
              (click)="showRules('')"
              >Show Less</a
            >
          </div>
          <div
            class="rules_regulations"
            *ngIf="data.policy && data.policy.length > 0"
          >
            <p class="ft_20 ft_400 ft_family mb_30 ft_25">Privacy Policy</p>
            <div class="policy_height">
              <div
                class="rules_div_height"
                *ngFor="let policy of data.policy; let i = index"
              >
                <div class="rules d_flex_align" *ngIf="i < initialpolicyLength">
                  <img
                    class="mr_20"
                    src="../../assets/newIcons/rules.svg"
                    alt="image"
                  />
                  <p class="ft_family ft_400 sub_content_color ft_17">
                    {{ policy.value }}
                  </p>
                </div>
              </div>
            </div>
            <a
              *ngIf="
                viewMore !== 'policy' && data.policy && data.policy.length > 2
              "
              class="ft_family ft_400 sub_content_color ft_14 cursor_pointer"
              (click)="showAbout('policy')"
              >Show More</a
            >
            <a
              *ngIf="viewMore === 'policy'"
              class="ft_family ft_400 sub_content_color ft_14 cursor_pointer"
              (click)="showAbout('')"
              >Show Less</a
            >
          </div>
        </div>
        <div
          class="rooms_tab_content mb_60"
          id="rooms"
          *ngIf="rooms && rooms.length > 0"
        >
          <h2 class="details_sub_heading mb_50 ft_25">Rooms</h2>
          <div class="room_content">
            <div
              class="individual_rooms"
              *ngFor="let room of rooms; let i = index"
            >
              <div class="room_image">
                <carousel
                  height="100%"
                  [width]="
                    width < 870
                      ? null
                      : width < 970
                      ? 255
                      : width < 1000
                      ? 266
                      : width < 1200
                      ? 242
                      : 200
                  "
                  [loop]="false"
                  [dots]="true"
                  [arrows]="false"
                  [cellsToShow]="1"
                  class=""
                  style="z-index: 9"
                >
                  <div class="carousel-cell" *ngFor="let image of room.gallery">
                    <img
                      style="object-fit: cover !important"
                      [src]="returnRoomArray(image)"
                      (click)="byRoomimageView(returnRoomArray(image), index)"
                    />
                  </div>
                </carousel>
              </div>
              <div class="room_details">
                <h2 class="ft_20 ft_family bold capitalize">
                  {{ room.room_type }}
                </h2>
                <div class="guest_bed_details">
                  <div class="detail_guest_box" style="margin-left: 0px">
                    <div class="d_flex room_box_detail mb_5">
                      <p class="mr_10">{{ room.beds }}</p>
                      <img
                        src="../../../assets/newIcons/bedroom_home.svg"
                        alt="image"
                      />
                    </div>
                    <p class="ft_14">Bedrooms</p>
                  </div>
                  <div class="detail_guest_box" *ngIf="room.bathrooms">
                    <div
                      class="d_flex room_box_detail mb_5 seperate_bathroom_res"
                    >
                      <p class="mr_10">{{ room.bathrooms }}</p>
                      <img
                        style="width: 1.313rem !important"
                        src="../../../assets/newIcons/bathroom_home.svg"
                        alt="image"
                      />
                    </div>
                    <p class="ft_14">Bathrooms</p>
                  </div>
                  <div class="detail_guest_box guest_box">
                    <div class="d_flex room_box_detail mb_5">
                      <p class="mr_10">{{ room.guests }}</p>
                      <img
                        style="width: 1.063rem !important"
                        src="../../../assets/newIcons/guest_home.svg"
                        alt="image"
                      />
                    </div>
                    <p class="ft_14" style="padding-left: 12px">Guests</p>
                  </div>
                </div>
                <div class="mb_24">
                  <div class="d_flex">
                    <ul class="room_amenities">
                      <ng-container
                        *ngFor="let amen of room.amenities; let i = index"
                      >
                        <li
                          class="amenities_list ft_12 ft_family ft_400 sub_content_color"
                          *ngIf="i <= 3"
                        >
                          {{ amen.name }}
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                  <div
                    class="more_button_div"
                    *ngIf="room.amenities.length > 4"
                  >
                    <buton class="more_button ft_family ft_10"
                      >+{{ room.amenities.length - 4 }} more</buton
                    >
                  </div>
                </div>
                <div class="price_book d_flex_space_align">
                  <h2 class="ft_20 bold ft_400 ft_family _m">
                    $ {{ room.price }}
                  </h2>
                  <div class="button_with_icon" *ngIf="!room.is_occupied">
                    <button
                      class="view_rooms_button"
                      (click)="payment(false, room.room_id)"
                    >
                      Book
                    </button>
                    <img
                      class="save_icon_room"
                      src="../../assets/newIcons/save.svg"
                      alt="image"
                    />
                  </div>
                  <div
                    class="button_with_icon"
                    *ngIf="room.is_occupied && room.pre_booking"
                  >
                    <button mat-stroked-button class="" disabled="true">
                      Book
                    </button>
                  </div>

                  <div
                    class="button_with_icon"
                    *ngIf="
                      room.is_occupied && !room.pre_booking && room.check_out
                    "
                  >
                    <button
                      class="view_rooms_button"
                      (click)="payment(room.check_out, room.room_id)"
                    >
                      Pre Book
                    </button>
                    <img
                      class="save_icon_room"
                      src="../../assets/newIcons/save.svg"
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="amenities_tab_content mb_60" id="amenities">
          <h2 class="details_sub_heading mb_50 ft_25">Amenities</h2>
          <div class="amenities_content">
            <div
              class="amenities_image_content"
              *ngFor="let amen of data.amenities"
            >
              <img
                style="width: 32px; height: 32px"
                [src]="
                  amen.icon
                    ? amen.icon
                    : ' ../../assets/newIcons/noamenitites.svg'
                "
                alt="image"
              />
              <p class="ft_17 ft_family ft_500 sub_content_color">
                {{ amen.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="reviews_tab_content mb_60" id="reviews">
          <h2 class="details_sub_heading mb_50 ft_25" *ngIf="!write_review">
            Reviews
          </h2>
          <div class="fade-in-div" *ngIf="!write_review">
            <div class="reviews_content">
              <div
                class="review_left_div"
                [ngClass]="
                  comment_section_array &&
                  comment_section_array.length === 0 &&
                  !user_review &&
                  'd_flex_align_justify'
                "
              >
                <div>
                  <div class="review_content">
                    <div
                      class="individual_review mb_30"
                      *ngIf="user_review && cokie.isLoggedIn()"
                    >
                      <div class="d_flex_space_align mb_20">
                        <div class="reviewer_image_data">
                          <div class="review_image_div mr_20">
                            <img
                              class=""
                              [src]="
                                utils.imageFormat(
                                  80,
                                  80,
                                  user_review.user.image,
                                  'c_fill'
                                )
                              "
                              alt="image"
                            />
                          </div>
                          <p class="ft_18 ft_500 ft_family mr_20">
                            {{ user_review.user.name.first }}
                            {{ user_review.user.name.last }}
                          </p>
                          <div
                            class="review_value ft_500 ft_family ft_12 d_flex_align_justify"
                          >
                            {{ user_review.overall_rating }}
                          </div>
                        </div>
                        <div
                          class="d_flex cursor_pointer"
                          (click)="editReview()"
                        >
                          <img
                            src="../../assets/newIcons/edit.svg"
                            class="mr_10"
                            style="width: 1rem"
                            alt="image"
                          />
                          <p class="ft_family ft_18 ft_500 sub_content_color">
                            Edit
                          </p>
                        </div>
                      </div>

                      <div class="review_data">
                        <p class="ft_family sub_content_color ft_18 ft_400">
                          {{ user_review.comment }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="individual_review mb_30"
                      *ngFor="
                        let review of comment_section_array;
                        let i = index
                      "
                    >
                      <div *ngIf="i < reviews_to_show">
                        <div class="reviewer_image_data">
                          <div class="review_image_div mr_20">
                            <img
                              class=""
                              src="../../assets/newIcons/profileuser.svg"
                              alt="image"
                            />
                          </div>
                          <p class="ft_18 ft_500 ft_family mr_20">
                            {{ review.user.name.first }}
                            {{ review.user.name.last }}
                          </p>
                          <div
                            class="review_value ft_500 ft_family ft_12 d_flex_align_justify"
                          >
                            {{ review.overall_rating }}
                          </div>
                        </div>
                        <div class="review_data">
                          <p class="ft_family sub_content_color ft_18 ft_400">
                            {{ review.comment }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="ft_family ft_17 c4_code_color ft_500 d_flex_align_justify"
                      *ngIf="
                        comment_section_array &&
                        comment_section_array.length === 0 &&
                        !user_review
                      "
                    >
                      Be the first person to review this Property
                    </div>
                    <p
                      class="ft_family sub_content_color ft_16 ft_400 mb_60 cursor_pointer"
                      *ngIf="
                        comment_section_array &&
                        comment_section_array.length > 2
                      "
                      (click)="reviewSection()"
                    >
                      Show More
                    </p>
                  </div>
                </div>
              </div>
              <div class="review_right_div" *ngIf="!show_my_review">
                <div
                  class="review_box"
                  *ngIf="
                    (comment_section_array &&
                      comment_section_array.length > 0) ||
                    user_review
                  "
                >
                  <div
                    class="rating_image d_flex_align_justify mb_20"
                    style="text-align: center"
                  >
                    <star-rating
                      [value]="show_rating_value"
                      [totalstars]="total_show_review_rating"
                      checkedcolor="#077E8B"
                      uncheckedcolor="#e1e1e1"
                      size="42px"
                      readonly="true"
                    ></star-rating>
                  </div>
                  <div class="total_ratings_count d_flex_align_justify mb_30">
                    <p class="ft_family ft_300 ft_14">
                      (
                      {{
                        data.reviews && data.reviews.count && data.reviews.count
                      }}
                      Reviews )
                    </p>
                  </div>
                  <div class="progress_bar_container">
                    <div class="d_flex_space_align mb_40">
                      <div
                        class="responsive_width_review individual_progress_bar w_45"
                      >
                        <p class="ft_18 ft_family ft_400 progress_bar_header">
                          Clean
                        </p>
                        <p class="ft_18 ft_family ft_400 review_content_div">
                          {{
                            overall_reviews &&
                              overall_reviews.clean &&
                              overall_reviews.clean | number : "1.0-0"
                          }}
                        </p>
                      </div>
                      <div class="individual_progress_bar w_45">
                        <p class="ft_18 ft_family ft_400 progress_bar_header">
                          Location
                        </p>
                        <p class="ft_18 ft_family ft_400 review_content_div">
                          {{
                            overall_reviews &&
                              overall_reviews.location &&
                              overall_reviews.location | number : "1.0-0"
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="d_flex_space_align mb_40">
                      <div
                        class="responsive_width_review individual_progress_bar w_45"
                      >
                        <p class="ft_18 ft_family ft_400 progress_bar_header">
                          Communication
                        </p>
                        <p class="ft_18 ft_family ft_400 review_content_div">
                          {{
                            overall_reviews &&
                              overall_reviews.communication &&
                              overall_reviews.communication | number : "1.0-0"
                          }}
                        </p>
                      </div>

                      <div class="individual_progress_bar w_45">
                        <p class="ft_18 ft_family ft_400 progress_bar_header">
                          Value
                        </p>
                        <p class="ft_18 ft_family ft_400 review_content_div">
                          {{
                            overall_reviews &&
                              overall_reviews.value &&
                              overall_reviews.value | number : "1.0-0"
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="d_flex_space_align mb_40">
                      <div
                        class="responsive_width_review individual_progress_bar w_45"
                      >
                        <p class="ft_18 ft_family ft_400 progress_bar_header">
                          Rooms
                        </p>
                        <p class="ft_18 ft_family ft_400 review_content_div">
                          {{
                            overall_reviews &&
                              overall_reviews.room &&
                              overall_reviews.room | number : "1.0-0"
                          }}
                        </p>
                      </div>
                      <div class="individual_progress_bar w_45">
                        <p class="ft_18 ft_family ft_400 progress_bar_header">
                          Service
                        </p>
                        <p class="ft_18 ft_family ft_400 review_content_div">
                          {{
                            overall_reviews &&
                              overall_reviews.service &&
                              overall_reviews.service | number : "1.0-0"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="write_review d_flex_space_align cursor_pointer"
                  (click)="scrollToElement('reviews')"
                  (click)="checkIsloggedIn()"
                >
                  <div class="reivew_writer_image_div d_flex_align">
                    <div class="image_div_write">
                      <img
                        [src]="
                          this.dashboard.profileImageUrl
                            ? this.dashboard.profileImageUrl
                            : '../../../../../assets/newIcons/profileuser.svg'
                        "
                        alt="image"
                      />
                    </div>
                    <p class="ft_family ft_400 ft_20">Write a Review</p>
                  </div>
                  <img
                    src="../../assets/newIcons/write_review.svg"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="write_review_div fade-in-div"
            *ngIf="write_review"
            id="write_review"
          >
            <div class="d_flex_space_align mb_50 res_review_header">
              <h2 class="details_sub_heading">Write a Review</h2>
              <div class="review_button">
                <button
                  mat-stroked-button
                  class="back ft_500 ft_family ft_18"
                  (click)="goBack()"
                  (click)="scrollToElement('reviews')"
                >
                  Back
                </button>
                <button
                  mat-flat-button
                  class="post ft_500 ft_family ft_18"
                  (click)="submitReview()"
                >
                  Post the Comment
                </button>
              </div>
            </div>
            <div class="sliders_content_div">
              <div class="first_row mb_50">
                <div class="individual_slider">
                  <h2 class="ft_family ft_18 ft_400">Clean</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="clean_rating"
                    [options]="options"
                    (userChange)="getSliderValue()"
                  ></ngx-slider>
                  <p>{{ clean_rating }}</p>
                </div>
                <div class="individual_slider">
                  <h2 class="ft_family ft_18 ft_400">Rooms</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="room_rating"
                    [options]="options"
                    (userChange)="getSliderValue()"
                  ></ngx-slider>
                  <p>{{ room_rating }}</p>
                </div>
                <div class="individual_slider">
                  <h2 class="ft_family ft_18 ft_400">Location</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="location_rating"
                    [options]="options"
                    (userChange)="getSliderValue()"
                  ></ngx-slider>
                  <p>{{ location_rating }}</p>
                </div>
              </div>
              <div class="first_row mb_50">
                <div class="individual_slider">
                  <h2 class="ft_family ft_18 ft_400">Value</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="value_rating"
                    [options]="options"
                    (userChange)="getSliderValue()"
                  ></ngx-slider>
                  <p>{{ value_rating }}</p>
                </div>
                <div class="individual_slider">
                  <h2
                    class="ft_family ft_18 ft_400"
                    style="margin-right: 5.3rem"
                  >
                    Service
                  </h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="service_rating"
                    [options]="options"
                    (userChange)="getSliderValue()"
                  ></ngx-slider>
                  <p>{{ service_rating }}</p>
                </div>
                <div class="individual_slider">
                  <h2
                    class="ft_family ft_18 ft_400 res_communication"
                    style="margin-right: 2.2rem"
                  >
                    Communication
                  </h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="communication_rating"
                    [options]="options"
                    (userChange)="getSliderValue()"
                  ></ngx-slider>
                  <p>{{ communication_rating }}</p>
                </div>
              </div>
            </div>
            <div class="comment_section_container d_flex_align">
              <div class="review_input_container">
                <h2 class="ft_family ft_25 ft_400 mb_30">Write a Comment</h2>
                <textarea
                  class="text_area"
                  placeholder="Type something here..."
                  [(ngModel)]="user_comment"
                  maxlength="300"
                ></textarea>
              </div>
              <div class="star_rating_review">
                <p class="mb_30 ft_family ft_18 ft_500 align_center">
                  Rate your Experience
                </p>
                <star-rating
                  [value]="write_rating_value"
                  [totalstars]="total_write_review_rating"
                  checkedcolor="#077E8B"
                  uncheckedcolor="#e1e1e1"
                  size="42px"
                  readonly="false"
                  (rate)="onRate($event)"
                ></star-rating>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="related_properties_container" *ngIf="related_properties > 0">
      <h2 class="details_sub_heading mb_50 ft_25">Related Properties</h2>
      <div class="property_list_container">
        <carousel
          class="list_carousel"
          [loop]="false"
          [arrowsOutside]="width > 480 ? true : false"
          [cellsToShow]="width > 480 ? 3 : 1"
          [dots]="width > 480 ? false : true"
        >
          <div
            class="individual_property_box carousel-cell"
            *ngFor="let property of related_properties"
          >
            <div
              class="property_div"
              (click)="onNavigateToViewSearch(property.property_id)"
            >
              <div class="image_div">
                <img
                  [src]="
                    this.utils.imageFormat(
                      350,
                      200,
                      property.gallery[0],
                      'c_fit'
                    )
                  "
                  alt="image"
                />
                <p class="image_address ft_14 ft_family">
                  <i class="fa fa-map-marker mr_10" style="color: white"></i>
                  {{ property.address.line_1 }},{{
                    property.address.city.name
                  }},{{ property.address.state.name }},{{
                    property.address.country
                  }}
                </p>
              </div>
              <div class="property_details">
                <div class="guest_div mb_30">
                  <div class="text_with_icon">
                    <div class="guest_room_icon_holder">
                      <img
                        src="../../assets/newIcons/bedroom.svg"
                        alt="image"
                      />
                    </div>
                    <p class="ft_10 sub_content_color ft_family">
                      {{ property.bedrooms }} bedrooms
                    </p>
                  </div>
                  <div class="text_with_icon">
                    <div class="guest_room_icon_holder">
                      <img
                        src="../../assets/newIcons/bathroom.svg"
                        alt="image"
                      />
                    </div>
                    <p class="ft_10 sub_content_color ft_family">
                      {{ property.bathrooms }} bathrooms
                    </p>
                  </div>
                  <div class="text_with_icon">
                    <div class="guest_room_icon_holder">
                      <img src="../../assets/newIcons/guests.svg" alt="image" />
                    </div>
                    <p class="ft_10 sub_content_color ft_family">
                      {{ property.guests }} guests
                    </p>
                  </div>
                </div>
                <div class="proper_name mb_25">
                  <h2 class="ft_family ft_20 ft_600 mb_15">
                    {{ property.name }}
                  </h2>
                  <p
                    class="ft_family sub_content_color ft_14 about_text content_restrict"
                    *ngIf="property.about"
                  >
                    {{ property.about }}
                  </p>
                  <p
                    class="ft_family sub_content_color ft_14 about_text content_restrict"
                    *ngIf="!property.about"
                  ></p>
                </div>
                <div class="price_book">
                  <p class="ft_20 ft_family ft_600">
                    {{ utils.returnRoomPrice(property) }}
                  </p>
                  <div class="button_with_icon">
                    <button
                      class="common_button"
                      (click)="onNavigateToViewSearch(property.property_id)"
                    >
                      Book
                    </button>
                    <img
                      class="save_icon"
                      src="../../assets/newIcons/save.svg"
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</div>

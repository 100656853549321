<div class="email_div">
  <div class="logo mb_30 align_center">
    <img src="./assets/newLogo/newLogoText.svg" alt="icon" />
  </div>
  <p class="header ft_16 ft_family mb_20">Forgot Password ?</p>
  <p class="ft_family ft_14 ft_400 sub_content_color" style="color: grey">
    Enter your email and we'll send you the instructions to reset your password.
  </p>
  <div class="input_container width_specifier">
    <p class="field_heading ft_family ft_14 ft_400">Email*</p>
    <input [(ngModel)]="email" (keyup.enter)="submit()" />
  </div>
  <div class="btn-div mt_20">
    <button mat-raised-button (click)="submit()">Submit</button>
  </div>
  <p class="reroute_tag">
    <strong>Wait</strong>, I remember my password...
    <a (click)="reRoute()">Click here</a>
  </p>
</div>

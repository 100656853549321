<div class="container">
  <div class="d_flex_space_align mb_20">
    <h2 class="ft_family ft_500 ft_35">INVOICE</h2>
    <div class="logo_container">
      <img src="./assets/newLogo/newLogoText.svg" alt="image" />
    </div>
  </div>
  <mat-divider class="divider mb_20"></mat-divider>
  <div class="d_flex mb_20">
    <div class="prop_details">
      <h2 class="ft_family ft_18 ft_400 mb_25">Property Details</h2>
      <p class="ft_500 ft_16 ft_family mb_10">{{ invoice.property.name }}</p>
      <p class="ft_400 ft_16 ft_family" *ngIf="invoice.property.address">
        {{
          invoice.property.rental_type === "entire_home"
            ? "Entire Home"
            : "Room"
        }}
        in {{ data.property && data.property.address.country }}
        {{ data.property && data.property.address.zip }}
      </p>
    </div>
  </div>
  <mat-divider class="divider mb_20"></mat-divider>
  <div>
    <div class="d_flex_space_align mb_20">
      <p class="ft_400 ft_16 ft_family sub_header_color_invoice">Invoice Id</p>
      <p class="ft_16 ft_family ft_400">{{ invoice.invoice_id }}</p>
    </div>
    <div class="d_flex_space_align mb_20">
      <p class="ft_400 ft_16 ft_family sub_header_color_invoice">
        Invoice Date
      </p>
      <p class="ft_16 ft_family ft_400">
        {{ invoice.createdAt | date : "dd/MM/yyyy" }}
      </p>
    </div>
  </div>
  <mat-divider class="divider mb_20"></mat-divider>
  <div class="div mb_20">
    <div class="d_flex_space_align mb_20">
      <h2 class="ft_family sub_header_color_invoice ft_18 _m ft_500">
        Description
      </h2>
      <h2 class="ft_family sub_header_color_invoice ft_18 _m ft_500">Amount</h2>
    </div>
    <div class="d_flex_space_align mb_20">
      <p class="ft_500 ft_16 ft_family" *ngIf="invoice.type === 'rent'">
        Rent for {{ invoice.for | date : "MMMM" }}
      </p>
      <p
        class="ft_500 ft_16 ft_family upperCase"
        *ngIf="invoice.type !== 'rent'"
      >
        {{ invoice.type }}
      </p>
      <p class="ft_family ft_500 ft_16">
        {{
          this.utils.displayPrice(
            data.outstanding_payment
              ? data.outstanding_payment.base_price
              : invoice.base_price
          )
        }}
      </p>
    </div>
    <div class="d_flex_space_align mb_20" *ngIf="late_fee !== 0">
      <p class="ft_500 ft_16 ft_family">Late Fee</p>
      <p class="ft_family ft_500 ft_16">
        {{ this.utils.displayPrice(late_fee) }}
      </p>
    </div>
  </div>
  <mat-divider class="divider mb_20"></mat-divider>
  <div class="div">
    <div class="d_flex_space_align">
      <p class="ft_500 ft_16 ft_family">Total</p>
      <p class="ft_family ft_500 ft_16 sub_text_green">
        {{
          this.utils.displayPrice(
            data.outstanding_payment
              ? data.outstanding_payment.amount
              : invoice.amount
          )
        }}
      </p>
    </div>
  </div>
</div>

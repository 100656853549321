<div *ngIf="loading" class="loader_div">
  <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
</div>
<div class="container">
  <div style="margin-top: 120px" class="container" *ngIf="data">
    <div class="invite_container">
      <div class="logo_holder pull-right">
        <div class></div>
        <img src="./assets/newLogo/newLogoText.svg" />
      </div>
      <div class="invite_content">
        <h2 class="ft_500 ft_20 mb_10">Invite Request</h2>
        <p class="ft_400 sub_content_color ft_14 mb_25">
          {{ data.host.name }} has invited you to join his
          {{ data.property.rental_type === "entire_home" ? "home" : "room" }}
        </p>
        <div class="prperty_details">
          <div class="property_content">
            <div class="property_image" *ngIf="data.property.gallery">
              <div class="property_image_div">
                <img
                  [src]="
                    utils.imageFormat(
                      120,
                      100,
                      data.property.gallery[0],
                      'c_fill'
                    )
                  "
                  alt="image" />
              </div>
            </div>
            <div class="property_data">
              <h2 class="mb_10 ft_16 ft_500">{{ data.property.name }}</h2>
              <p class="sub_content_color ft_15 ft_400 mb_10">
                {{
                data.property.rental_type === "entire_home"
                ? "Entire Home"
                : "Room"
                }}
                in {{ data.property.address.state.name }}
              </p>
              <p class="ft_500 ft_16 sub_text_green">
                {{
                utils.displayPrice(
                data.property.rental_type === "entire_home"
                ? data.property.price
                : data.room.price
                )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="host_details">
          <div class="d_flex_space_align mb_20 check_in">
            <h2 class="_m ft_500 ft_18">Host Details</h2>

            <div>
              <label class="mr_15 ft_500 ft_14" for="checkIn">Check In</label>
              <mat-form-field
                floatLabel="never"
                appearance="outline"
                class="check-in-out-mat-form date-picker">
                <input
                  id="checkIn"
                  class="dp-input"
                  matInput
                  [matDatepicker]="checkin"
                  [(ngModel)]="check_in"
                  placeholder="Select Date"
                  [min]="todayDate"
                  [max]="maxDate"
                  (ngModelChange)="changeCheckDate($event)" />
                <mat-datepicker #checkin></mat-datepicker>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="checkin"></mat-datepicker-toggle>
              </mat-form-field>
            </div>
          </div>
          <div class="d_flex_align mb_20">
            <div class="host_image">
              <img
                [src]="
                  data.host.gallery
                    ? utils.imageFormat(80, 80, data.host.gallery[0])
                    : '../../assets/newIcons/displayUser.png'
                "
                alt="image" />
            </div>
            <div class="ft_16 ft_500">
              <h2 class="_m">{{ data.host.name }}</h2>
              <p
                class="sub_heading ft_12 _m ft_500"
                style="margin-top: 5px !important"></p>
            </div>
          </div>
          <div class="host_desc mb_30 mt_30">
            <h2 class="ft_18 ft_500">Description</h2>
            <p
              class="sub_content_color ft_12 ft_400"
              style="text-transform: capitalize">
              {{ data.invite.description }}
            </p>
          </div>
          <div class="actual_date_div">
            <p class="ft_family ft_16 ft_500 date_header">
              Actual Start Date :
            </p>
            <p class="ft_family ft_15 ft_400">{{ actual_start }}</p>
            <p class="date_para_info">
              <span
                class="info_span"
                title="This is considered to be the inital check in date for this property">
                <i class="fa fa-info"></i>
              </span>
            </p>
          </div>
          <div style="margin:15px 0px;" class="checkBox">
            <mat-checkbox
              class="checkbox  ft_family ft_400"
              (change)="handleCheckBox($event,'min_notice_period')"
              [checked]="this.checkBox.min_notice_period">
              <div style="color: #333333;" class="ft_13">I accept the minimum notice period of {{this.noticeDays}} days</div>
            </mat-checkbox>
          </div>
          <div style="margin:15px 0px;" class="checkBox">
            <mat-checkbox
              class="checkbox  ft_family ft_400"
              (change)="handleCheckBox($event,'accept_kyc')"
              [checked]="this.checkBox.accept_kyc">
              <div style="color: #333333;" class="ft_13">I accept to provide KYC details after successful booking</div>
            </mat-checkbox>
          </div>
          <div class="otp_content mt_10 ">
            <h2 class="_m ft_14 ft_400 mb_20">
              Please enter the code sent to your registered Email Id
            </h2>

            <div class="otp_input mb_30">
              <div>
                <form (ngSubmit)="onSubmit()" [formGroup]="form">
                  <div class="d_flex">
                    <div class="ip" *ngFor="let input of formInput; index as i">
                      <input
                        #formRow
                        type="text"
                        pattern="[0-9]+"
                        maxlength="1"
                        formControlName="{{ input.key }}"
                        class="form-control mr_15 input_otp"
                        (oninput)="restrictEvent(i)"
                        [(ngModel)]="input.value"
                        (keyup)="keyUpEvent($event, i)" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="d_flex_align">
              <button
                mat-stroked-button
                class="secondary_stroked_button mr_15"
                (click)="redirect()"
                style="padding:5px 16px">
                Cancel
              </button>
              <button-spinner
                [disabledClass]="this.disable_btn"
                [showSpinner]="saveSpinner"
                childClass="primary_btn"
                mat-flat-button
                (click)="onSubmit()"
                [childStyle]="{'padding':'12px 16px','border-radius':'3px'}">
                Accept Request
              </button-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

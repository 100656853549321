<div class="container">
        <div class="header" (click)="dialog.close()">
            <p style="font-weight:500;font-size:16px">Rate This Work</p>
            <mat-icon style="cursor:pointer" class="close_icon cancel">close</mat-icon>
        </div>
        <div class="container_body">
            <p class="ft_400 ft_family  mb_20">Rate Your Experience *</p>
            <app-star-rating  [color]="starColor"  [rating]="rating"  [starCount]="starCount"   (ratingUpdated)="onRatingChanged($event)"></app-star-rating>
            <p class="ft_400 ft_family mt_20  mb_20">Comments</p>
            <mat-form-field  style="width:100%" appearance="standard" class="formField commentBox">
                <textarea placeholder="Type your comments here ..." class="comment ft_400 ft_family" [(ngModel)]="comment" matInput rows="2"></textarea>
            </mat-form-field>
        </div>
        <div class="endButton mt_40">
            <button
              mat-stroked-button
              class="mr_15 secondary_stroked_button password_button"
              (click)="dialog.close()"
            >
            Go Back
            </button>
            <button-spinner 
            [showSpinner]="saveSpinner"
              (click)="onSubmit()"
            >
            Submit
            </button-spinner>
          </div>
  </div>

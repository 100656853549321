import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ApicallService } from "../apicall.service";
import { UtilityService } from "../utility.service";
import { Lightbox } from "ngx-lightbox";
import * as moment from "moment";
import { CookieService } from "ngx-cookie";
import { DomSanitizer } from "@angular/platform-browser";
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { CookieServiceService } from "../cookie-service.service";
import { LoginSignupDialogComponent } from "../components/dashboard/login-signup-dialog/login-signup-dialog.component";
import { DashboardComponent } from "../components/dashboard/dashboard.component";
// import {ReviewPopUpComponent } from "../../app/review-pop-up/"
@Component({
  selector: "app-property-detail",
  templateUrl: "./property-detail.component.html",
  styleUrls: ["./property-detail.component.css"],

  // host: {
  //   "(window:resize)": "onWindowResize($event)",
  // },
})
export class PropertyDetailComponent implements OnInit {
  options: Options = {
    floor: 0,
    ceil: 5,
  };
  clean_rating = 1;
  room_rating = 1;
  location_rating = 1;
  value_rating = 1;
  communication_rating = 1;
  service_rating = 1;
  user_comment;
  show_review_popUp = false;
  room_id: any;
  rooms: Array<any> = [];
  show_rating_value: number;
  write_rating_value: number = 1;
  total_show_review_rating: number = 5;
  total_write_review_rating: number = 5;
  breadcrumName: string = "Star Wins 3";
  related_properties;
  loading = true;
  write_review = false;
  selectedTab: string = "about";
  reviews_to_show = 2;
  isShowMore: boolean = false;

  roomDetail = [];

  // about
  public uId: any = [];
  public imageArray: Array<any> = [];
  public roomImage: Array<any> = [];
  public laturl;
  bookamenities: Array<any> = [];

  roomId: any;
  property_id: string;
  data: any;
  memberSince: any;
  status: any;
  page = 1;
  limit = 10;
  viewMore;
  overall_reviews: any;
  property_overall_reviews: 1;
  comment_section_array: Array<any>;
  user_review;
  show_my_review = true;
  viewRules;
  params;
  width: number = window.innerWidth;
  initialpolicyLength = 2;
  initalrulesLength = 2;
  ngOnInit(): void {
    this.addcss();
    let navbar = document.getElementById("navbar");
    this.utils.removeAddTransparent(navbar);
    this.routers.params.forEach((params: any) => {
      if (params["id"] !== undefined) {
        if (params["id"] !== "-1") {
          this.uId = params["id"];
        }
      }
    });
    this.routers.queryParams.subscribe((params: any) => {
      this.status = params.status;
      if (this.status !== undefined) {
        if (params !== "-1") {
          if (this.status === "success") {
            this.utils.openToast("Payment successful", {});
            this.router.navigate([`/detail/${this.uId}`]);
          } else {
            this.utils.openToast("Payment unsuccessful", {});
          }
        }
      }
    });
    this.getProperty();

    this.dashboard.profileImageUrl;
    if (this.cokie.isLoggedIn()) {
      this.loggedInReview("");
    } else {
      this.generalReview("");
    }
  }
  hideReviewPopUp() {
    this.show_review_popUp = false;
    this.cokie.isLoggedIn()
      ? this.loggedInReview("first_page")
      : this.generalReview("first_page");
  }
  loggedInReview(page) {
    if (page) {
      this.page = 1;
    }
    this.url.get(
      `user/review/properties/${this.uId}?page=${this.page}&limit=${this.limit}`,
      this.params,
      (res) => {
        this.loading = false;
        if (res.success) {
          this.overall_reviews = res.data.review;
          this.property_overall_reviews =
            res.data.review &&
            res.data.review.overall_rating &&
            res.data.review.overall_rating;
          this.comment_section_array = res.data.comment;
          this.user_review = res.data.my_review && res.data.my_review;
          this.show_rating_value =
            res.data.review && res.data.review.overall_rating;
          this.show_my_review = false;
        }
      }
    );
  }
  onPageChange(e) {
    this.page = e === "before" && this.page > 1 ? this.page - 1 : this.page + 1;
    this.cokie.isLoggedIn() ? this.loggedInReview("") : this.generalReview("");
  }
  getProperty() {
    this.roomImage = [];
    this.url.get(`common/properties/${this.uId}`, this.params, (res) => {
      this.loading = false;
      if (res.success) {
        this.data = res.data.property;
        this.bookamenities = res.data.property.amenities;
        if (this.data.loc) {
          this.latlng(this.data.loc[1], this.data.loc[0]);
        }
        this.rooms = this.data.rooms && this.data.rooms;
        this.data.gallery.forEach((data) => {
          let imageData = this.utils.returnSourceImg(data);
          let object = {
            src: imageData,
            thumb: imageData,
          };
          this.imageArray.push(object);
        });
        this.data.rooms &&
          this.data.rooms.forEach((data) => {
            data.gallery.forEach((gallery) => {
              let imageData = this.utils.returnSourceImg(gallery);
              // let object = {
              //   src: imageData,
              //   thumb: imageData,
              //   path: imageData,
              //   // type: "image",
              // };
              let object = {};
              this.roomImage.push(object);
            });
          });
        this.memberSince = moment(res.data.property.host.createdAt).format(
          "YYYY"
        );
      }
    });
    this.url.get(
      `common/properties/related/${this.uId}`,
      this.params,
      (res) => {
        if (res.success) {
          this.related_properties = res.data.property;
          this.loading = false;
        } else {
        }
      }
    );
  }
  returnRoomArray(image) {
    let imageData = this.utils.returnSourceImg(image);
    return imageData;
  }
  constructor(
    private router: Router,
    private routers: ActivatedRoute,
    private url: ApicallService,
    private utils: UtilityService,
    private lightbox: Lightbox,
    public cookie: CookieService,
    public cokie: CookieServiceService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public dashboard: DashboardComponent
  ) {}
  getSliderValue() {}
  addcss() {
    window.onscroll = function () {
      var tab = document.getElementById("tabs");
      if (tab) {
        if (window.pageYOffset > 630) {
          tab.classList.add("fixed_tab");
        } else {
          tab.classList.remove("fixed_tab");
        }
      }
    };
  }
  goBack() {
    this.write_review = false;
    this.clean_rating = 1;
    this.room_rating = 1;
    this.location_rating = 1;
    this.value_rating = 1;
    this.communication_rating = 1;
    this.service_rating = 1;
    this.user_comment = "";
  }
  // for scroll
  scrollToElement(element): void {
    var elmnt = document.getElementById(element);
    const offset = -180;
    const y = elmnt.getBoundingClientRect().top + window.pageYOffset + offset;

    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  }
  scrollTowriteReview(e) {
    var elmnt = document.getElementById(e);
    const offset = -20;
    const y = elmnt.getBoundingClientRect().top + window.pageYOffset + offset;

    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  }
  onRate(e) {
    this.write_rating_value = e.newValue;
  }
  latlng(lat, lng) {
    this.laturl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://maps.google.com/maps/embed/v1/place?key=AIzaSyB-s5MrK-QcKMaYhqSb7BxWd3kIx31rFKM&q=${lat},${lng}`
    );
  }
  showAbout(e) {
    this.viewMore = e;
    if (e === "policy") {
      this.initialpolicyLength = this.data.policy.length;
    } else {
      this.initialpolicyLength = 2;
    }
  }
  showRules(e) {
    this.viewRules = e;
    if (e === "rules") {
      this.initalrulesLength = this.data.house_rules.length;
    } else {
      this.initalrulesLength = 2;
    }
  }
  payment(prebook_checkout, room_id) {
    let check_in: any = this.cookie.get("check_in");
    let check_out;

    check_in = check_in
      ? this.utils.returnYYYYMMDDAddformat(check_in, 330, "minutes")
      : this.utils.returnYYYYMMDDAddformat(new Date(), 330, "minutes");

    let data = {
      property_id: this.uId,
      room_id: room_id ? room_id : "",
      check_in: check_in,
      check_out: check_out ? check_out : "",
    };
    if (prebook_checkout) {
      data["pre_booked_checkout"] = this.utils.returnYYYYMMDDAddformat(
        moment(prebook_checkout).add(1, "days"),
        330,
        "minutes"
      );
    }
    let stringifyData = JSON.stringify(data);
    this.cookie.put("payment_detail", stringifyData);
    this.router.navigate(["/checkout"]);
  }
  byRoomimageView(url, index) {
    let array = [
      {
        src: url,
        thumb: url,
      },
    ];
    this.lightbox.open(array, index, {
      fitImageInViewPort: true,
      disableScrolling: true,
      closePosition: top,
    });
  }
  openImage(index) {
    this.lightbox.open(this.imageArray, index, {
      fitImageInViewPort: true,
      disableScrolling: true,
      closePosition: top,
    });
  }
  onNavigateToViewSearch(propertyId: any) {
    this.router.navigate([`detail/${propertyId}`]).then(() => {
      this.getProperty();
    });
  }
  checkIsloggedIn() {
    if (this.cokie.isLoggedIn()) {
      if (!this.user_review) {
        this.write_review = true;
      } else {
        this.utils.openToast("You have already reviewed this property", {});
      }
    } else {
      const dialog = this.dialog.open(LoginSignupDialogComponent);
      dialog.afterClosed().subscribe((res) => {
        if (res) {
          this.scrollToElement("reviews");
        }
      });
    }
  }
  reviewSection() {
    this.show_review_popUp = true;
    let review_div = document.getElementById("more_review");
    review_div.scrollTop = 0;
  }
  generalReview(page) {
    if (page) {
      this.page = 1;
    }
    this.url.get(
      `common/properties/review/${this.uId}?page=${this.page}&limit=${this.limit}`,
      this.params,
      (res) => {
        if (res.success) {
          this.overall_reviews = res.data.review;
          this.property_overall_reviews =
            res.data.review &&
            res.data.review.overall_rating &&
            res.data.review.overall_rating;
          this.comment_section_array = res.data.comment;
          this.user_review = res.data.my_review && res.data.my_review;
          this.show_rating_value =
            res.data.review && res.data.review.overall_rating;
          this.show_my_review = false;
        }
      }
    );
  }
  submitReview() {
    if (this.user_comment === "" || this.user_comment === undefined) {
      this.utils.openToast("Please fill out the comment section", {});
    } else if (this.user_comment.length <= 3) {
      this.utils.openToast("Please fill valid comments", {});
    } else {
      let data = {
        comment: this.user_comment,
        overall_rating: this.write_rating_value,
        ratings: {
          clean: this.clean_rating ? this.clean_rating : 1,
          communication: this.communication_rating
            ? this.communication_rating
            : 1,
          location: this.location_rating ? this.location_rating : 1,
          value: this.value_rating ? this.value_rating : 1,
          room: this.room_rating ? this.room_rating : 1,
          service: this.service_rating ? this.service_rating : 1,
        },
      };
      if (this.user_review) {
        this.url.patch(
          `user/review/${this.user_review.review_id}`,
          data,
          this.params,
          (res) => {
            if (res.success) {
              this.utils.openToast("", res);
              this.write_review = false;
              this.show_my_review = true;
              this.loggedInReview("");
            } else {
              this.utils.openToast("", res);
            }
          }
        );
      } else {
        this.url.post(`user/review/${this.uId}`, data, this.params, (res) => {
          if (res.success) {
            this.utils.openToast("", res);
            this.write_review = false;
            this.show_my_review = true;
            this.loggedInReview("");
          } else {
            this.utils.openToast("", res);
          }
        });
      }
    }
  }
  editReview() {
    this.write_review = true;
    this.user_comment = this.user_review.comment;
    this.write_rating_value = this.user_review.overall_rating;
    this.communication_rating =
      this.user_review.ratings && this.user_review.ratings.communication;
    this.clean_rating =
      this.user_review.ratings && this.user_review.ratings.clean;
    this.location_rating =
      this.user_review.ratings && this.user_review.ratings.location;
    this.value_rating =
      this.user_review.ratings && this.user_review.ratings.value;
    this.service_rating =
      this.user_review.ratings && this.user_review.ratings.service;
    this.room_rating =
      this.user_review.ratings && this.user_review.ratings.room;
  }
}

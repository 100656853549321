import { Component, OnInit } from "@angular/core";
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";

@Component({
  selector: "app-sort-by-sheet",
  templateUrl: "./sort-by-sheet.component.html",
  styleUrls: ["./sort-by-sheet.component.css"],
})
export class SortBySheetComponent implements OnInit {
  sort_by: any;
  constructor(
    private _sortBySheetRef: MatBottomSheetRef<SortBySheetComponent>
  ) {}

  ngOnInit(): void {}

  openLink(event: MouseEvent): void {
    this._sortBySheetRef.dismiss();
    event.preventDefault();
  }
  apply() {
    this._sortBySheetRef.dismiss(this.sort_by);
  }
  handleChange(e) {
    this.sort_by = e.target.value;
  }
}

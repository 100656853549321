import { Component, OnInit } from "@angular/core";
import { ApicallService } from "src/app/apicall.service";
import { MatDialog } from "@angular/material/dialog";
import { BillComponent } from "../components/profile/components/profile-bookings/bill/bill.component";
import { UtilityService } from "../utility.service";
import * as moment from "moment";
import { PayMethodComponent } from "../pay-method/pay-method.component";
import { CookieService } from "ngx-cookie";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-profile-invoice",
  templateUrl: "./profile-invoice.component.html",
  styleUrls: ["./profile-invoice.component.css"],
})
export class ProfileInvoiceComponent implements OnInit {
  page: number = 1;
  limit: number = 10;
  pageLoading: boolean = false;
  length;
  list;
  pendingAmount;
  params = {};
  show_filters = false;
  from_date;
  to_date;
  filter_value = "this_month";
  today = new Date();
  loading = true;
  minTo = new Date();
  constructor(
    public url: ApicallService,
    public dialog: MatDialog,
    public utils: UtilityService,
    public cookie: CookieService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.from_date = new Date();
    this.from_date.setDate(1);
    this.from_date.setHours(0, 0, 0, 0);
    this.to_date = new Date();
    this.getBillingApi();
  }

  filterValue(e) {
    
    if ("custom_date" === e) {
      this.show_filters = true;
      this.filter_value = e;
      this.from_date = "";
      this.to_date = "";
    } else {
      this.pageLoading = true
      this.show_filters = false;
      this.page = 1;
      let filter = this.utils.dateFilter(e, this.from_date, this.to_date);
      this.from_date = filter.start;
      this.to_date = filter.end;
      this.getBillingApi();
    }
  }

  payNow(item?) {
    const dialog = this.dialog.open(PayMethodComponent, {
      width: "652px",
      data: {
        invoiceAmount: item?.amount ?? item,
        is_non_customer: false,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      let url = item?.invoice_id ? item?.invoice_id : "bulk";
      if (res) {
        this.loading = true;
        let data = {
          success_url: `${window.location.href}&status=success`,
          cancel_url: `${window.location.href}&status=failure`,
          type: res.data,
        };
        this.url.post(`user/transactions/${url}`, data, this.params, (res) => {
          this.loading = false;
          if (res.success) {
            window.open(res.data.link, "_self");
            this.cookie.put("transaction_id", res.data.transaction_id);
          } else {
            this.utils.openToast("", res);
          }
        });
      }
    });
  }

  //&from='${start}'&to='${end}
  getBillingApi() {
    const routeCondition = this.route.snapshot.queryParams;
    let start = moment(this.from_date).format("YYYY-MM-DD");
    let end = moment(this.to_date).format("YYYY-MM-DD");
    this.url.get(
      `user/invoice?page=${this.page}&limit=${this.limit}${
        routeCondition.status ? "&status=created" : `&from=${start}&to=${end}`
      }`,
      {},
      (res) => {
        this.pageLoading = false;
        this.loading = false;
        if (res.success) {
          this.list = res.data.invoice;
          this.pendingAmount = res.data.outstanding
        }
      }
    );
  }

  openBill(item) {
    const dialogBox = this.dialog.open(BillComponent, {
      data: item,
    });
  }

  ToggleFilter() {
    this.show_filters = !this.show_filters;
    this.from_date = "";
    this.to_date = "";
  }

  applyFilter() {
    if (this.from_date === "" || this.from_date === undefined) {
      this.utils.openToast("Please select From date", {});
    } else if (this.to_date === "" || this.to_date === undefined) {
      this.utils.openToast("Please select To date", {});
    } else {
      this.page = 1;
      this.from_date = moment(this.from_date).format("YYYY-MM-DD");
      this.from_date = new Date(this.from_date);
      this.to_date = moment(this.to_date).format("YYYY-MM-DD");
      this.to_date = new Date(this.to_date);
      this.params["from"] = this.from_date;
      this.params["to"] = this.to_date;
      this.getBillingApi();
    }
  }

  changeCheckDate(value, input) {
    if (input === "from_date") {
      this.from_date = value;
      this.minTo = value;
    } else {
      this.to_date = value;
    }
    if (this.from_date && this.to_date) {
      this.getBillingApi();
    }
  }

  onPageChange(e) {
    this.pageLoading = true;
    if (e === "before" && this.page > 1) {
      this.page = this.page - 1;
    } else {
      this.page = this.page + 1;
    }
    this.getBillingApi();
  }

  returnObjExists = (obj) => {
    if (obj && Object.keys(obj).length > 0) return true;
    return false;
  };
}

<div class="page_container">
  <div class="image_holder">
    <img src="../../assets/images/404_page.png" />
    <div class="content_box">
      <p class="ft_family ft_700 size mb_10">Oops...</p>
      <p class="ft_family ft_400 size mb_20">Page not found</p>
      <p class="ft_family ft_400 mb_30 ft_18 content_dialog">
        Sorry, the page you’re looking doesn’t exist. Please try after sometime
      </p>
      <button class="go_back_button ft_family ft_18">
        <a [routerLink]="['/']"
          >Go Home <i class="fa fa-long-arrow-right ft_18"></i
        ></a>
      </button>
    </div>
  </div>
</div>

<div class="container">
  <h2 class="sub_text_green ft_500 ft_family ft_20 mb_25">
    Notice Request
  </h2>

  <div class="data_container">
    <div class="notice_r1">
      <div>
        <div class="current_text_design mb_5">Actual Notice Ends</div>
        <mat-form-field appearance="outline" class="mat_form">
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="this.notice_date"
            [disabled]="true"
            readonly />
          <mat-datepicker #picker></mat-datepicker>
          <mat-datepicker-toggle matSuffix
            [for]="picker"></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div>
        <div class="current_text_design mb_5">Check Out Date *</div>
        <mat-form-field appearance="outline" class="mat_form">
          <input
            (ngModelChange)="changeCheckDate($event)"
            matInput
            [matDatepicker]="picker1"
            [(ngModel)]="vaccate"
            [min]="minDate"
            readonly />
          <mat-datepicker #picker1></mat-datepicker>
          <mat-datepicker-toggle matSuffix
            [for]="picker1"></mat-datepicker-toggle>
        </mat-form-field>
      </div>
    </div>
    <div class="note_div" *ngIf="this.vaccateAlarm">
      <div>
        <img src="../assets/newIcons/info_grey.svg">
      </div>
      <div style="line-height: 20px;font-size: 20px;" class="ft_13">
        The selected date is less than the minimum notice period date which is
        {{ this.notice_date | date:'longDate'}}. You can checkout on the
        selected date but you
        have to pay for the full notice period days.
      </div>
    </div>

    <div class="Amount_to_pay"
      style="margin-top: 18px;">
      <div>
        <div class="current_text_design mb_5">Amount to pay</div>
        <mat-form-field appearance="outline" class="mat_form">
          <input [disabled]="true" matInput type="number"
            placeholder="Enter a number"
            [(ngModel)]="this.data.amountToPay">
        </mat-form-field>
      </div>
      <div style="margin-top: 12px;" class="checkBox">
        <mat-checkbox
          class="checkbox  ft_family ft_400"
          (change)="handleCheckBox($event)"
          [checked]="this.isChecked">
          <div style="color: #333333;" class="ft_13">Use my
            security deposit for
            balance invoice</div>
        </mat-checkbox>
      </div>
    </div>
    <div class="text_area_div" style="margin-top: 18px;">
      <h2 class="current_text_design mb_5">Reason for check out*</h2>
      <textarea style="margin-top: 0.25em;" class="text_area"
        [(ngModel)]="reason"></textarea>
    </div>
    <!-- <div class="note_div mb_25" *ngIf="this.vaccateAlarm">
      <div class="ft_500 ft_13 mr_15">Note*</div>
      <p class="ft_13">
        The selected date is less than the minimum notice period date which is
        {{ this.notice_date | date:'longDate'}}. You can checkout on the
        selected date but you
        have to pay for the full notice period days.
      </p>
    </div> -->
    <div class="buttons_div"
      style="padding: 20px 0px 0px 0px;">
      <button
        style="min-width: 150px;"
        mat-stroked-button
        class="cancel_button mr_20"
        (click)="dialog.close()">
        Cancel
      </button>
      <button-spinner
        [showSpinner]="saveSpinner"
        childClass="new_common_button_bg"
        mat-flat-button
        type="submit"
        (click)="onSubmit()">
        Submit
      </button-spinner>
      <!-- <button
        style="min-width: 105px;"
        mat-flat-button
        class="common_button"
        [disabled]="this.set_disabled"
        (click)="onSubmit()">
        Submit
      </button> -->

    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GlobalStoreService {
  floatBar = new BehaviorSubject(true)
  dashboardFloatBarSubscription;
  
  constructor(
    // private animatedDialog: NgDialogAnimationService
  ) { }

  // openAnimatedDialog(componentName, data = null) {
  //   const animatedDialog = this.animatedDialog.open(componentName, {
  //     data: data,
  //     width: "560px",
  //     height: "100%",
  //     position: { rowStart: "0" },
  //     animation: {
  //       to: "left",
  //       incomingOptions: {
  //         keyframeAnimationOptions: { duration: 300, easing: "linear" },
  //       },
  //       outgoingOptions: {
  //         keyframeAnimationOptions: { duration: 300, easing: "linear" },
  //       },
  //     },
  //   });
  //   return animatedDialog;
  // }
}

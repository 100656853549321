import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from '../utility.service';
@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  constructor(
    public utils: UtilityService
  ){}

  transform(value: string): string {
    const data = this.utils.removeUnderscoreAndCapitalize(value)
    return data;
  }

}

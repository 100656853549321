import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GuestInformationPopupComponent } from "./guest-information-popup/guest-information-popup.component";
import { CookieService } from "ngx-cookie";
import { ApicallService } from "../../app/apicall.service";
import { CookieServiceService } from "../cookie-service.service";
import { LoginSignupDialogComponent } from "../../app/components/dashboard/login-signup-dialog/login-signup-dialog.component";
import { Router, ActivatedRoute } from "@angular/router";
import { UtilityService } from "../utility.service";
import { StatusCheckComponent } from "./status-check/status-check.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import * as moment from "moment";
interface guestDetails {
  name: string;
  age: number;
  gender: string;
}

@Component({
  selector: "app-payment-checkout",
  templateUrl: "./payment-checkout.component.html",
  styleUrls: ["./payment-checkout.component.css"],
})
export class PaymentCheckoutComponent implements OnInit {
  readonly tableColumns: string[] = ["name", "age", "gender", "action"];
  checkoutPrice: number = 0;
  roomType: string = "Entire Villa";
  roomSpeciality: string = "Gergeous, Plush, Multi level Villa with views";
  basePrice: number = 25;
  taxPrice: number = 5;
  checkIn: any;
  checkOut: any;
  payment_detail: any;
  room_id: any;
  loading = true;
  response: any;
  data: any;

  guestList: guestDetails[] = [];
  otherConditions = [
    {
      description: "Do you have Pets:",
      value: "",
      options: ["Yes", "No"],
    },
  ];
  minDate;
  checkoutMinDate;
  maxDate;
  params;
  property_url;
  constructor(
    public dialog: MatDialog,
    public cookie: CookieService,
    public url: ApicallService,
    public loggedIn: CookieServiceService,
    public urlparams: ActivatedRoute,
    public utils: UtilityService,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let payment_details = this.cookie.get("payment_detail");
    if (payment_details) {
      if (payment_details) {
        this.payment_detail = JSON.parse(payment_details);
      }
      if (this.payment_detail.pre_booked_checkout) {
        let prev_date = moment(this.payment_detail.pre_booked_checkout).add(
          1,
          "days"
        );
        this.minDate = prev_date.toISOString();
      } else {
        if (this.payment_detail.previous_check_in) {
          let prev_date = moment(this.payment_detail.previous_check_in).add(
            1,
            "days"
          );
          this.minDate = prev_date.toISOString();
          this.payment_detail.check_in = prev_date;
        } else {
          this.minDate = new Date();
          this.checkIn = new Date();
        }
      }

      this.maxDate = moment(new Date()).add(3, "M");
      this.maxDate = new Date(this.maxDate);
      this.checkoutApi("");
    } else {
      this.router.navigate(["/"]);
    }
  }
  checkoutApi(e) {
    if (e === "checkInChanged") {
      this.payment_detail.check_in = this.checkIn;
      this.payment_detail.pre_booked_checkout = this.checkIn;
    } else if (e === "checkOutChanged") {
      this.payment_detail.check_out = this.checkOut;
    }
    this.route.queryParams.subscribe((params) => {
      if (params.status) {
        const dialogbox = this.dialog.open(StatusCheckComponent, {
          disableClose: params.status === "success" ? true : false,
          data: {
            status: params.status,
          },
        });
        dialogbox.afterClosed().subscribe((res) => {
          if (res === "success") {
            this.utils.routeWithQuery("profile", "profile");
          } else {
            this.getData(params);
          }
        });
      } else {
        this.getData(params);
      }
    });
  }
  getData(params) {
    if (this.payment_detail.pre_booked_checkout) {
      this.payment_detail.check_in = this.payment_detail.pre_booked_checkout;
    }
    this.url.post(
      "common/estimate",
      this.payment_detail,
      this.params,
      (res) => {
        this.loading = false;
        if (res.success) {
          this.response = res.data;
          this.data = res.data.property;
          this.checkIn = res.data.check_in;
          this.property_url = this.utils.imageFormat(
            260,
            200,
            res.data.property.gallery[0],
            "c_fill"
          );
          this.checkoutMinDate = new Date(this.checkIn);
          this.checkOut = res.data.check_out && res.data.check_out;
          this.room_id = res.data.room ? res.data.room.room_id : "";
        } else {
          if (!params) this.utils.openToast("", res);
        }
      }
    );
  }
  changeCheckDate(e, name) {
    if (name === "check_in") {
      this.checkoutMinDate = e;
      this.checkIn = moment(e).format("YYYY-MM-DD");
      this.checkoutApi("checkInChanged");
    } else if (name === "check_out") {
      this.maxDate = e;
      this.checkOut = moment(e).format("YYYY-MM-DD");
      this.checkoutApi("checkOutChanged");
    }
  }
  changeCheckOut(e, name) {}
  payment() {
    if (!this.loggedIn.isLoggedIn()) {
      const loginDialog = this.dialog.open(LoginSignupDialogComponent, {
        data: {},
      });

      loginDialog.afterClosed().subscribe((res) => {
        if (res && res.success) {
          window.location.reload();
        }
      });
    } else if (this.checkIn === "") {
      this.utils.openToast("Please enter the check in date", {});
    } else {
      this.checkOut =
        this.checkOut && moment(this.checkOut).format("YYYY-MM-DD");
      let data = {
        property_id: this.data.property_id ? this.data.property_id : "",
        room_id: this.payment_detail.room_id,
        guests: this.guestList,
        check_in: this.response.check_in,
        check_out: this.checkOut,
        success_url: `${window.location.href}?tab=bookings&status=success`,
        cancel_url: `${window.location.href}?tab=bookings&status=failure`,
      };
      if (
        this.response.property.check_out &&
        !this.response?.property?.pre_booking
      )
        data["pre_booking"] = true;
      this.url.post("user/transactions/checkout", data, this.params, (res) => {
        if (res.success) {
          if (this.payment_detail.pre_booked_checkout) {
            window.location.href = "/#/profile?tab=bookings";
            this.utils.openToast("", res);
          }
          window.open(res?.data?.link, "_self");
          this.cookie.put("transaction_id", res?.data?.transaction_id);
        } else {
          this.utils.openToast("", res);
        }
      });
    }
  }
  addGuest(mode, guestDetail?) {
    let index: number;
    if (guestDetail) {
      this.guestList.forEach((guest, i) => {
        if (guest.name === guestDetail.name && guest.age === guestDetail.age)
          index = i;
      });
    }
    const addGuestDialog = this.dialog.open(GuestInformationPopupComponent, {
      data: {
        mode,
        guestDetail,
        index,
      },
    });
    addGuestDialog.afterClosed().subscribe((response) => {
      if (response && response.success) {
        if (index || index === 0)
          this.guestList[response.index] = response.guest;
        else this.guestList.push(response.guest);
      }
    });
  }

  deleteGuest(guestDetail) {
    this.guestList.forEach((guest, i) => {
      if (guest.name === guestDetail.name && guest.age === guestDetail.age) {
        this.guestList.splice(i, 1);
      }
    });
  }
  redirect() {
    this.router.navigate(["/"]);
  }
}

<div style="height:70%" [ngSwitch]="page">
    <div *ngSwitchCase="1"
        class="popup_shadow background_image">
        <div class="center_image">
            <div class="ft_24 ft_600 sub_text_green mb_42">Get Started Now</div>
            <img class="refimage mb_24"
                src="/assets/images/Launching-pana (1) 1.svg">
            <div style="text-align: center;"
                class="ft_400 ft_12 sub_content_color mb_24">
                Submit your rental application in easy steps. This will help
                us to provide better service
            </div>
            <button (click)="startnow()" class='sign_up_button'><span
                    class="ft_600 ft_14">Get Started Now</span></button>
        </div>
    </div>
    <div
        style="display: flex;border: 1px solid #EEEDED;"
        *ngSwitchCase="2">
        <div
            style="width: 35%;background-color: #F9FAFC;padding: 50px 50px;">

            <div class="steps " *ngFor="let step of steps let i = index">
                <div class="seprateStep">
                    <div class="status">
                        <div>
                            <img
                                [src]="step.checked ? '/assets/images/checked.svg' : '/assets/images/unchecked.svg'">
                        </div>
                        <div *ngIf="i !== steps.length - 1">
                            <img
                                [src]="step.checked ?'/assets/images/checkedLine.svg':'/assets/images/uncheckedLine.svg'">
                        </div>
                    </div>
                    <div class="ml_20">
                        <div class="ft_400 ft_14 sub_content_color mb_8">{{step.step
                            | capitalize}}</div>
                        <div
                            [ngStyle]="{'color':step.checked ? '#333333' : '#6D7077'}"
                            class="ft_500 ft_14 ">{{step.detail
                            | capitalize}}</div>
                    </div>
                </div>

            </div>
        </div>

        <div style="position: relative;width: 855px;">
            <ng-container *ngIf="loading else dataTemp">
                <div style="position: absolute;right: 50%;top:45%;">
                    <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
                </div>
            </ng-container>
            <ng-template #dataTemp>
                <div>
                    <div class="Tenant_skip" (click)="toProfile()">Close</div>

                    <div style="min-height: 680px;" [ngSwitch]="fillPage">
                        <div style="padding:50px 50px 0px 50px;"
                            *ngSwitchCase="1">

                            <div>
                                <div style="color: #333333;"
                                    class="ft_600 ft_18 mb_10">About
                                    Yourself</div>
                                <div
                                    class="ft_400 ft_14 sub_content_color mb_24">Share
                                    some basic information about yourself</div>
                            </div>

                            <div>
                                <div class="inputComponent">
                                    <div class="required mr_20">
                                        <label class="labelTag">First Name*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="form_input"
                                                appearance="outline">
                                                <input matInput
                                                    [(ngModel)]="data[0].firstName"
                                                    placeholder="Enter your first Name">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="required">
                                        <label class="labelTag">Last Name</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="form_input"
                                                appearance="outline">
                                                <input matInput
                                                    [(ngModel)]="data[0].lastName"
                                                    placeholder="Enter your last Name">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="inputComponent">
                                    <div class="required mr_20">
                                        <label class="labelTag">Mobile Number*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="form_input"
                                                appearance="outline">
                                                <input matInput
                                                    [disabled]="data[0].mobileNumber"
                                                    [(ngModel)]="data[0].mobileNumber"
                                                    placeholder="Enter your mobile Number">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="required">
                                        <label class="labelTag ">Email Address*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="form_input"
                                                appearance="outline">
                                                <input matInput
                                                    [disabled]="data[0].emailAddress"
                                                    [(ngModel)]="data[0].emailAddress"
                                                    placeholder="Enter your email address">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="inputComponent">
                                    <div class="required mr_20">
                                        <label style="margin-bottom: 5px;"
                                            class="labelTag" (click)="ifNationalityExists()">Date of Birth*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                (click)="picker.open()"
                                                appearance="outline"
                                                class="date">
                                                <input
                                                    placeholder="Select your date of birth"
                                                    matInput
                                                    [matDatepicker]="picker"
                                                    [(ngModel)]="data[0].dateOfBirth"
                                                    [max]="minDate"
                                                    readonly />
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="required">
                                        <label style="margin-bottom: 6px;"
                                            class="labelTag ">Nationality*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="select"
                                                appearance="outline">
                                                <input matInput type="text"
                                                    class="example-right-align"
                                                    placeholder="Search"
                                                    (ngModelChange)="handleOnInputChange($event,'nationality')"
                                                    [(ngModel)]="this.data[0].nationality"
                                                    [matAutocomplete]="auto" />
                                                <mat-autocomplete
                                                    #auto="matAutocomplete">
                                                    <mat-option
                                                        *ngFor="let nation of nations"
                                                        [value]="nation.name">
                                                        {{ nation.name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <span matPrefix>
                                                    <mat-icon
                                                        style="margin-right: 10px; color: #75728f">search</mat-icon>
                                                </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="required">
                                        <label class="labelTag">Current Address*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="form_input"
                                                appearance="outline">
                                                <input matInput
                                                    [(ngModel)]="data[0].currentAddress"
                                                    placeholder="Enter your address">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style="width: 49%;"
                                        class="required mr_20">
                                        <label class="labelTag">Occupation*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="form_input"
                                                appearance="outline">
                                                <input matInput
                                                    [(ngModel)]="data[0].occupation"
                                                    placeholder="Enter your occupation">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="inputComponent">
                                    <div class="required mr_20">
                                        <label class="labelTag ">Do you have
                                            pets*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="select"
                                                appearance="outline">
                                                <mat-select
                                                    placeholder="Select"
                                                    class="mat_placeholder"
                                                    [(ngModel)]="data[0].pets"
                                                    name="food">
                                                    <mat-option value="yes">Yes</mat-option>
                                                    <mat-option value="no">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="required">
                                        <label class="labelTag ">Are you a
                                            smoker*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="select"
                                                appearance="outline">
                                                <mat-select
                                                    placeholder="Select"
                                                    class="mat_placeholder"
                                                    [(ngModel)]="data[0].smoker"
                                                    name="food">
                                                    <mat-option value="yes">Yes</mat-option>
                                                    <mat-option value="no">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngSwitchCase="2">
                            <div class="mb_22"
                                style="padding: 50px 50px 0px 50px;">
                                <div (click)="print()" style="color: #333333;"
                                    class="ft_600 ft_18 mb_10">KYC
                                    Details
                                </div>
                                <div
                                    class="ft_400 ft_14 sub_content_color mb_24">Upload
                                    your identity to verify yourself
                                </div>
                                <div>
                                    <div class="ft_600 fs_14 mb_22"
                                        style="color:#333333">ID
                                        Proof</div>
                                    <div class="inputComponent">
                                        <div class="required mr_20">
                                            <label class="labelTag ">Select
                                                Proof*</label>
                                            <div>
                                                <mat-form-field
                                                    style="width: 100%;"
                                                    class="select"
                                                    appearance="outline">
                                                    <mat-select
                                                        placeholder="Select the proof"
                                                        class="mat_placeholder"
                                                        [(ngModel)]="data[1].kyc.proof_one.p_type">
                                                        <ng-container
                                                            *ngFor="let id_proof of id_proofs">
                                                            <mat-option
                                                                *ngIf="id_proof !== data[1].kyc.proof_two.p_type"
                                                                [value]="id_proof">
                                                                {{id_proof |
                                                                capitalize}}
                                                            </mat-option>
                                                        </ng-container>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="required">
                                            <label class="labelTag ">ID Number*</label>
                                            <div>
                                                <mat-form-field
                                                    style="width: 100%;"
                                                    class="form_input"
                                                    appearance="outline">
                                                    <input matInput
                                                        [ngModel]="data[1].kyc.proof_one.number"
                                                        (ngModelChange)="IdHandler($event,'id_1')"
                                                        placeholder="Enter your ID number">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="gap:22px">
                                        <div
                                            *ngFor="let image of data[1].kyc.proof_one.images let i = index"
                                            style="max-width: 15%;margin-right: 10px;">
                                            <label class="labelTag">{{image.name
                                                |
                                                capitalize}}</label>
                                            <div>
                                                <div class="img_container">
                                                    <ng-template
                                                        #imgTemplate>
                                                        <div
                                                            *ngIf="image.public_id"
                                                            class="uploaded_img">
                                                            <img
                                                                style="object-fit: contain;"
                                                                class="uploaded_img"
                                                                [src]="image.format === 'pdf' ? 'assets/newIcons/pdf.png'
                                                                        : this.utils.imageFormat(120, 130,image, 'c_fill')"
                                                               
                                                                alt="image" />
                                                            <img
                                                                class="remove_icon cursor_pointer"
                                                                src="../../../../../assets/newIcons/remov.svg"
                                                                (click)="removeProofImage(image, i,'proof_one')" />

                                                        </div>
                                                    </ng-template>
                                                    <div
                                                        class="mat_spinner"
                                                        *ngIf="image.imageLoading else imgTemplate">
                                                        <mat-spinner
                                                            class="upload_loader"
                                                            diameter="20"></mat-spinner>
                                                    </div>

                                                    <div
                                                        *ngIf="!image.public_id && !image.imageLoading"
                                                        class="addDocumentDesign">
                                                        <div
                                                            class="upload_button">
                                                            <img
                                                                src="/assets/images/addDocument.svg">
                                                            <div
                                                                class="ft_400 ft_10"
                                                                style="color: #077E8B;">Add</div>
                                                            <input
                                                                class="cursor_pointer upload"
                                                                type="file"
                                                                multiple
                                                                (change)="upload($event,'proof_one',i)"
                                                                accept="file/*,jpeg,pdf,png" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style="width:100%">
                                <div class="horizantal_line"></div>
                            </div>
                            <div class="mt_22"
                                style="padding: 0px 50px 0px 50px;">
                                <div class="ft_600 fs_14 mb_22"
                                    style="color:#333333">Address
                                    Proof</div>
                                <div class="inputComponent">
                                    <div class="required mr_20">
                                        <label class="labelTag ">Select Proof*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="select"
                                                appearance="outline">
                                                <mat-select
                                                    placeholder="Select the proof"
                                                    [(ngModel)]="data[1].kyc.proof_two.p_type"
                                                    class="mat_placeholder">
                                                    <ng-container
                                                        *ngFor="let proof of address_proofs">
                                                        <mat-option
                                                            *ngIf="proof!== data[1].kyc.proof_one.p_type"
                                                            [value]="proof">
                                                            {{proof|capitalize}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="required">
                                        <label class="labelTag ">ID Number*</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="form_input"
                                                appearance="outline">
                                                <input matInput
                                                    [ngModel]="data[1].kyc.proof_two.number"
                                                    (ngModelChange)="IdHandler($event,'id_2')"
                                                    placeholder="Enter your ID number">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex" style="gap:22px">
                                    <div
                                        *ngFor="let image of data[1].kyc.proof_two.images let i = index"
                                        style="max-width: 15%;margin-right: 10px;">
                                        <label class="labelTag">{{image.name
                                            |
                                            capitalize}}</label>
                                        <div>
                                            <div class="img_container">
                                                <ng-template
                                                    #imgTemplate>
                                                    <div
                                                        *ngIf="image.public_id"
                                                        class="uploaded_img">
                                                        <img
                                                        style="object-fit: contain;"
                                                            class="uploaded_img"
                                                            [src]="image?.format === 'pdf' ? 
                                                                    '../../../../../assets/newIcons/pdf.png'
                                                                    :
                                                                    this.utils.imageFormat(120, 130,image, 'c_fill')"
                                                            alt="image" />
                                                        <img
                                                            class="remove_icon cursor_pointer"
                                                            src="../../../../../assets/newIcons/remov.svg"
                                                            (click)="removeProofImage(image, i,'proof_two')" />

                                                    </div>
                                                </ng-template>
                                                <ng-container>
                                                    <div
                                                        class="mat_spinner"
                                                        *ngIf="image.imageLoading else imgTemplate">
                                                        <mat-spinner
                                                            class="upload_loader"
                                                            diameter="20"></mat-spinner>
                                                    </div>
                                                </ng-container>

                                                <div
                                                    *ngIf="!image.public_id && !image.imageLoading"
                                                    class="addDocumentDesign">
                                                    <div
                                                        class="upload_button">
                                                        <img
                                                            src="/assets/images/addDocument.svg">
                                                        <div
                                                            class="ft_400 ft_10"
                                                            style="color: #077E8B;">Add</div>
                                                        <input
                                                            class="cursor_pointer upload"
                                                            type="file"
                                                            multiple
                                                            (change)="upload($event,'proof_two',i)"
                                                            accept="file/*,jpeg,pdf,png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="padding: 50px 50px 0px 50px;"
                            *ngSwitchCase="3">
                            <div class="bankDetails">
                                <div style="color: #333333;"
                                    class="ft_600 ft_18 mb_10">Bank
                                    Details
                                </div>
                                <div
                                    (click)="onBankClick(addAccount ? 'update' : 'skip')"
                                    [ngClass]="addAccount ? 'update' : 'skip'">{{addAccount
                                    ? 'Update' : 'SKIP' }}</div>

                            </div>
                            <div class="ft_400 ft_14 sub_content_color">{{this.addAccount
                                ?
                                'Your linked bank account. Click update to change these details'
                                :
                                'Link your bank accounts to pay & receive invoices and payments'}}</div>
                            <ng-container
                                *ngIf="!addAccount  else editAccountTemplate">
                                <div class="allignItemCenter">
                                    <img
                                        style="margin-bottom: 25px;margin-top: 112px;"
                                        src="/assets/images/linkAccount.svg">
                                    <div style="width: 60%;">
                                        <div class="ft_600 ft_14 mb_12"
                                            style="color: #333333;text-align: center;">
                                            Link Your Account</div>
                                        <div style="text-align: center;"
                                            class="ft_400 ft_12 c_light">Click
                                            the button to link your bank account
                                            in easy steps
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #editAccountTemplate>
                                <div class="mt_22">
                                    <div class="inputComponent">
                                        <div class="required mr_20">
                                            <label class="labelTag">Bank Name*</label>
                                            <div>
                                                <mat-form-field
                                                    style="width: 100%;"
                                                    class="form_input"
                                                    appearance="outline">
                                                    <input matInput
                                                        disabled
                                                        [(ngModel)]="data[2].bankName"
                                                        >
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="required">
                                            <label class="labelTag">Account
                                                Number*</label>
                                            <div>
                                                <mat-form-field
                                                    style="width: 100%;"
                                                    class="form_input"
                                                    appearance="outline">
                                                    <input matInput
                                                        disabled
                                                        [(ngModel)]="data[2].accountNumber"
                                                        >
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inputComponent">
                                        <div class="required mr_20">
                                            <label class="labelTag">Routing
                                                Number</label>
                                            <div>
                                                <mat-form-field
                                                    style="width: 100%;"
                                                    class="form_input"
                                                    appearance="outline">
                                                    <input matInput
                                                        disabled
                                                        [(ngModel)]="data[2].routingNumber"
                                                        >
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="required ">
                                            <label class="labelTag">Payout
                                                Status</label>
                                            <div>
                                                <mat-form-field
                                                    style="width: 100%;"
                                                    class="form_input"
                                                    appearance="outline">
                                                    <input matInput
                                                        disabled
                                                        [(ngModel)]="data[2].payoutStatus"
                                                        >
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                        <!-- <div style="padding:50px 50px 0px 50px"
                            *ngSwitchCase="4">
                            <div style="color: #333333;"
                                class="ft_600 ft_18 mb_10">Property
                                Details</div>
                            <div class="ft_400 ft_14 sub_content_color mb_24">Select
                                your rental property where you are staying now
                            </div>
                            <div>
                                <div class="inputComponent">
                                    <div class="required">
                                        <label class="labelTag ">Select Property</label>
                                        <div>
                                            <mat-form-field style="width: 100%;"
                                                class="select"
                                                appearance="outline">
                                                <input matInput type="text"
                                                    class="example-right-align"
                                                    placeholder="Search"
                                                    (ngModelChange)="propertySearch($event)"
                                                    [(ngModel)]="this.data[3].property"
                                                    [matAutocomplete]="auto" />
                                                <mat-autocomplete
                                                    #auto="matAutocomplete">
                                                    <mat-option
                                                        *ngFor="let property of properties"
                                                        (click)="onPropertSelect(property)"
                                                        [value]="property.name">
                                                        {{ property.name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <span matPrefix>
                                                    <mat-icon
                                                        style="margin-right: 10px; color: #75728f">search</mat-icon>
                                                </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div style="padding:50px 50px 0px 50px"
                            *ngSwitchCase="4">
                            <div style="color: #333333;"
                                class="ft_600 ft_18 mb_10">Emergency
                                Contact</div>
                            <div class="ft_400 ft_14 sub_content_color mb_24">Share
                                the immediate point of contact in case of any
                                emergency
                            </div>

                            <div class="inputComponent">
                                <div class="required mr_20">
                                    <label class="labelTag">Name*</label>
                                    <div>
                                        <mat-form-field style="width: 100%;"
                                            class="form_input"
                                            appearance="outline">
                                            <input matInput
                                                [(ngModel)]="this.data[3].emergencyContactName"
                                                placeholder="Enter a emergency contact Name">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="required">
                                    <label class="labelTag">Relationship*</label>
                                    <div>
                                        <mat-form-field style="width: 100%;"
                                            class="form_input"
                                            appearance="outline">
                                            <input matInput
                                                [(ngModel)]="this.data[3].relation"
                                                placeholder="Enter relation name">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="inputComponent">    
                                <div class="required mr_20">
                                    <label class="labelTag">Mobile Number*</label>
                                    <div>
                                        <mat-form-field style="width: 100%;"
                                            class="form_input"
                                            appearance="outline">
                                            <input matInput
                                                maxlength="16"
                                                [ngModel]="this.data[3].emergencyMobileNumber"
                                                (ngModelChange)="getUsFormat($event,'emergency')"
                                                placeholder="Enter mobile number">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="required ">
                                    <label class="labelTag">Email ID</label>
                                    <div>
                                        <mat-form-field style="width: 100%;"
                                            class="form_input"
                                            appearance="outline">
                                            <input matInput
                                                [(ngModel)]="this.data[3].emailId"
                                                placeholder="Enter email id">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="required">
                                    <label class="labelTag">Address</label>
                                    <div>
                                        <mat-form-field style="width: 100%;"
                                            class="form_input"
                                            appearance="outline">
                                            <input matInput
                                                [(ngModel)]="this.data[3].emergencyAddress"
                                                placeholder="Enter  address">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div
                            *ngSwitchCase="5">
                            <div class="mb_22"
                                style="padding: 50px 50px 0px 50px;">
                                <div style="color: #333333;"
                                    class="ft_600 ft_18 mb_10">Additional
                                    Occupants
                                </div>
                                <div
                                    class="ft_400 ft_14 sub_content_color mb_24">Share
                                    about your family or co-occupant details
                                </div>
                                <div>
                                    <div class="inputComponent">
                                        <div class="required ">
                                            <label (click)="print()" class="labelTag ">Are you
                                                staying
                                                alone or not</label>
                                            <div>
                                                <mat-form-field
                                                    style="width: 100%;"
                                                    [ngStyle]="{'width':this.data[4].stayingAlone  ? '40%' : '100%'}"
                                                    class="select"
                                                    appearance="outline">
                                                    <mat-select
                                                        placeholder="Select"
                                                        class="mat_placeholder"
                                                        [ngModel]="this.data[4].stayingAlone ? 'true' : 'false'"
                                                        (ngModelChange)="isStayingAlone($event)"
                                                        >
                                                        <mat-option value=true>Yes</mat-option>
                                                        <mat-option value=false>No</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="!this.data[4].stayingAlone"
                                            class="required ml_20">
                                            <label class="labelTag ">Number of
                                                Additional occupants</label>
                                            <div>
                                                <mat-form-field
                                                    [disable]="this.data[4].stayingAlone"
                                                    style="width: 100%;"
                                                    class="form_input"
                                                    appearance="outline">
                                                    <mat-select
                                                        [disable]="this.data[4].stayingAlone"
                                                        placeholder="Select"
                                                        class="mat_placeholder"
                                                        [ngModel]="this.data[4].noOfAdditionalOccupants"
                                                        (ngModelChange)="addAdditionalOccupants($event)">

                                                        <mat-option value="1">1</mat-option>
                                                        <mat-option value="2">2</mat-option>
                                                        <mat-option value="3">3</mat-option>
                                                        <mat-option value="4">4</mat-option>
                                                        <mat-option value="5">5</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="additionalContainer" *ngIf="!this.data[4].stayingAlone" >
                                   
                                        <div
                                            *ngFor="let occupant of this.data[4].additionalOccupants;let j = index">
                                            <div class="ft_600 fs_14 mb_22"
                                                style="color:#333333">{{'Person '+(j+1)}}</div>
                                            <div class="inputComponent">
                                                <div class="required mr_20">
                                                    <label class="labelTag ">Name*</label>
                                                    <div>
                                                        <mat-form-field
                                                            style="width: 100%;"
                                                            class="form_input"
                                                            appearance="outline">
                                                            <input matInput
                                                                [(ngModel)]="this.data[4].additionalOccupants[j].name"
                                                                placeholder="Enter a person name">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="required">
                                                    <label class="labelTag ">Contact
                                                        Number*</label>
                                                    <div>
                                                        <mat-form-field
                                                            style="width: 100%;"
                                                            class="form_input"
                                                            appearance="outline">
                                                            <input matInput
                                                                maxlength="16"
                                                                [ngModel]="this.data[4].additionalOccupants[j].phone.national_number"
                                                                (ngModelChange)="getUsFormat($event,'is_extra',j)"
                                                                placeholder="Enter a contact number">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width: 49%;"
                                                class="required mr_20">
                                                <label class="labelTag ">Date of
                                                    Birth</label>
                                                <div>
                                                    <mat-form-field
                                                        style="width: 100%;"
                                                        (click)="picker.open()"
                                                        class="date1"
                                                        appearance="outline">
                                                        <input
                                                            placeholder="Enter a date of birth"
                                                            matInput
                                                            [max]="minDate"
                                                            [(ngModel)]="this.data[4].additionalOccupants[j].dob"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle
                                                            matIconSuffix
                                                            [for]="picker"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div style="padding:50px 50px 0px 50px"
                            *ngSwitchCase="6">
                            <div style="color: #333333;"
                                class="ft_600 ft_18 mb_10">Landlord
                                Reference</div>
                            <div class="ft_400 ft_14 sub_content_color mb_24">Share
                                your previous landlord contact details
                            </div>
                            <div class="inputComponent">
                                <div class="required mr_20">
                                    <label class="labelTag">Name*</label>
                                    <div>
                                        <mat-form-field style="width: 100%;"
                                            class="form_input"
                                            appearance="outline">
                                            <input matInput
                                                [(ngModel)]="this.data[5].landlordName"
                                                placeholder="Enter landlord name">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="required">
                                    <label class="labelTag">Contact Number*</label>
                                    <div>
                                        <mat-form-field style="width: 100%;"
                                            class="form_input"
                                            appearance="outline">
                                            <input matInput
                                                maxlength="16"
                                                [(ngModel)]="this.data[5].phone.national_number"
                                                (ngModelChange)="getUsFormat($event,'landlord')"
                                                placeholder="Enter landlord contact number">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="required">
                                    <label class="labelTag">Address</label>
                                    <div>
                                        <mat-form-field style="width: 100%;"
                                            class="form_input"
                                            appearance="outline">
                                            <input matInput
                                                [(ngModel)]="this.data[5].landlordAddress"
                                                placeholder="Enter your address">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="padding:50px 50px 0px 50px"
                            *ngSwitchCase="7">
                            <div style="color: #333333;"
                                class="ft_600 ft_18 mb_10">Employer Reference
                            </div>
                            <div class="ft_400 ft_14 sub_content_color mb_24">Please
                                confirm your employment status & details
                            </div>
                            <div>

                                <div class="ft_500 ft_18 mb_22"
                                    style="color:#333333">Select Employer Type</div>
                                <div>

                                    <mat-radio-group
                                        class="radio_btn_group mb_22"
                                        aria-label="Select an option">
                                        <div class="radioClass"
                                            *ngFor="let employee_reference of employee_references ; let i = index">
                                            <mat-radio-button
                                                (change)="onCheck($event,i,'reference')"
                                                [checked]="employee_reference.checked"
                                                [value]="employee_reference.value">{{employee_reference.value
                                                | capitalize}}
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>

                                    <div *ngIf="type === 'self_employed'">
                                        <div class="inputComponent">

                                            <div class="required mr_20">
                                                <label class="labelTag">Details
                                                    of
                                                    Employment*</label>
                                                <div>
                                                    <mat-form-field
                                                        style="width: 100%;"
                                                        class="form_input"
                                                        appearance="outline">
                                                        <input matInput
                                                            [(ngModel)]="this.data[6].details"
                                                            placeholder>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <div class="required">
                                                <label class="labelTag">Monthly
                                                    Revenue*</label>
                                                <div>
                                                    <mat-form-field
                                                        style="width: 100%;"
                                                        class="form_input"
                                                        appearance="outline">
                                                        <span>$</span>
                                                        <input matInput
                                                            style="margin-left: 10px;"
                                                            [(ngModel)]="this.data[6].monthlyRevenue"
                                                            (blur)="getUSD($event)"
                                                            type="text"
                                                            placeholder>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                        </div>
                                        <div>
                                            <div class="labelTag" (click)="print()">Tax Slip*</div>
                                            <div>

                                                <div class="img_container">

                                                    <ng-template #imgTemp>
                                                        <div
                                                            *ngIf="data[6].taxSlip.public_id"
                                                            class="uploaded_img">
                                                            <img
                                                                class="uploaded_img"
                                                                [src]="data[6].taxSlip.format === 'pdf' ? 
                                                                                '../../../../../assets/newIcons/pdf.png'
                                                                            :this.utils.imageFormat(120, 130, data[6].taxSlip,'c_fill')"
                                                                style="object-fit: contain;"
                                                                alt="image" />
                                                            <img
                                                                class="remove_icon cursor_pointer"
                                                                src="../../../../../assets/newIcons/remov.svg"
                                                                (click)="removeSelfEmp(data[6].taxSlip)" />
                                                        </div>
                                                    </ng-template>

                                                    <ng-container>
                                                        <div class="mat_spinner"
                                                            *ngIf="data[6].taxSlip.taxslip_uploading else imgTemp">
                                                            <mat-spinner
                                                                class="upload_loader"
                                                                diameter="20"></mat-spinner>
                                                        </div>
                                                    </ng-container>

                                                    <div
                                                        *ngIf="!data[6].taxSlip.public_id && !data[6].taxSlip.taxslip_uploading"
                                                        class="addDocumentDesign">
                                                        <div
                                                            class="upload_button">
                                                            <img
                                                                src="/assets/images/addDocument.svg">
                                                            <div
                                                                class="ft_400 ft_10"
                                                                style="color: #077E8B;">Add</div>
                                                            <input
                                                                class="cursor_pointer upload"
                                                                type="file"
                                                                multiple
                                                                (change)="upload($event,'selEmpTaxSlip')"
                                                                accept="file/*,jpeg,pdf,png" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="type === 'salaried_employee'">
                                        <div class="inputComponent">
                                            <div class="required mr_20">
                                                <label class="labelTag">Company
                                                    Name*</label>
                                                <div>
                                                    <mat-form-field
                                                        style="width: 100%;"
                                                        class="form_input"
                                                        appearance="outline">
                                                        <input matInput
                                                            [(ngModel)]="data[6].salariedEmpCompName"
                                                            placeholder>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="required">
                                                <label class="labelTag">Contact
                                                    Number*</label>
                                                <div>
                                                    <mat-form-field
                                                        style="width: 100%;"
                                                        class="form_input"
                                                        appearance="outline">
                                                        <input matInput
                                                            maxlength="16"
                                                            [ngModel]="data[6].salariedEmpNumber"
                                                            (ngModelChange)="getUsFormat($event,'salaried')"
                                                            placeholder>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="inputComponent">
                                            <div class="required mr_20">
                                                <label class="labelTag">Email
                                                    ID</label>
                                                <div>
                                                    <mat-form-field
                                                        style="width: 100%;"
                                                        class="form_input"
                                                        appearance="outline">
                                                        <input matInput
                                                            [(ngModel)]="data[6].salariedEmpEmail"
                                                            placeholder>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <div class="required">
                                                <label class="labelTag">Address</label>
                                                <div>
                                                    <mat-form-field
                                                        style="width: 100%;"
                                                        class="form_input"
                                                        appearance="outline">
                                                        <input matInput
                                                            [(ngModel)]="data[6].salariedEmpAddress"
                                                            placeholder>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                        </div>

                                        <label class="labelTag" (click)="print()">Have Payslips</label>

                                        <mat-radio-group
                                            class="radio_btn_group mb_22"
                                            aria-label="Select an option">
                                            <div class="radioClass"
                                                *ngFor="let slip of slips ; let i = index">
                                                <mat-radio-button
                                                    (change)="onCheck($event,i,'slips')"
                                                    [checked]="slip.checked"
                                                    [value]="slip.value">{{slip.value | capitalize}}
                                                </mat-radio-button>
                                            </div>
                                        </mat-radio-group>

                                        <div class="d-flex" style="gap:22px"
                                            *ngIf="this.haveSlips else Notemplate">
                                            
                                            <div
                                                *ngFor="let payslip of data[6].paySlips let i = index"
                                                style="max-width: 15%;margin-right: 10px;">
                                                <label class="labelTag">{{payslip.name
                                                    |
                                                    capitalize}}
                                                </label>

                                                <div>
                                                    <div class="img_container">
                                                        <ng-template
                                                            #imgTemplate>
                                                            <div
                                                                *ngIf="payslip.public_id"
                                                                class="uploaded_img">
                                                                <img
                                                                    class="uploaded_img"
                                                                    [src]="payslip?.format === 'pdf' ? 
                                                                                '../../../../../assets/newIcons/pdf.png'
                                                                                :
                                                                                this.utils.imageFormat(120, 130,payslip, 'c_fill')"
                                                                    style="object-fit: contain;"
                                                                    alt="image" />
                                                                <img
                                                                    class="remove_icon cursor_pointer"
                                                                    src="../../../../../assets/newIcons/remov.svg"
                                                                    (click)="removeSalariedEmpImage(payslip, i)" />

                                                            </div>
                                                        </ng-template>

                                                            <div
                                                                class="mat_spinner"
                                                                *ngIf="payslip.payslips_Loading else imgTemplate">
                                                                <mat-spinner
                                                                    class="upload_loader"
                                                                    diameter="20"></mat-spinner>
                                                            </div>

                                                        <div
                                                            *ngIf="!payslip.public_id && !payslip.payslips_Loading"
                                                            class="addDocumentDesign">
                                                            <div
                                                                class="upload_button">
                                                                <img
                                                                    src="/assets/images/addDocument.svg">
                                                                <div
                                                                    class="ft_400 ft_10"
                                                                    style="color: #077E8B;">Add</div>
                                                                <input
                                                                    class="cursor_pointer upload"
                                                                    type="file"
                                                                    multiple
                                                                    (change)="upload($event,'salaried_emp_paySlip',i)"
                                                                    accept="file/*,jpeg,pdf,png" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <ng-template #Notemplate >
                                            <label class="labelTag">Reason for
                                                no
                                                payslips*</label>
                                            <textarea
                                                rows="2"
                                                cols="500"
                                                style="width: 100%;border:1px solid #D9D9D9;padding: 10px;"
                                                matInput
                                                [(ngModel)]="this.data[6].notes"></textarea>
                                        </ng-template>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div *ngSwitchCase="8"
                            style="padding:50px 50px 0px 50px">
                            <div class="allignItemCenter">
                                <img
                                    style="margin-top: 87px;margin-bottom: 25px;"
                                    src="/assets/images/rentalSuccess.svg">
                                <div class="ft_600 ft_18 c_dark">Application
                                    Submitted</div>
                                <div style="width: 60%;margin-top: 30px;">
                                    <div style="text-align: center;"
                                        class="ft_400 ft_12 c_light">
                                        Thank you for submitting your
                                        application. We will update you the
                                        status soon
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="padding:20px 50px 50px 50px" class="d-flex">
                        <button class="backbtn" style="width: 15%;"
                            (click)="onBack()"
                            *ngIf="!(this.fillPage === 1 || this.fillPage === 8)"><span
                                class="backbtntxt">Back</span></button>
                        <ng-container
                            *ngIf="this.fillPage !== 7 else spinrBtnTemplate">
                            <button
                                [ngStyle]="{'width': (this.fillPage === 1 || this.fillPage === 8)? '100%' : '85%'}"
                                class="nextButton"
                                (click)=" this.fillPage === 3 &&
                                            !addAccount ? linkAccount(): this.fillPage === 8 ? toProfile(): onNext()"><span
                                    class="btntxt">{{this.fillPage === 3 &&
                                    !addAccount
                                    ? 'Link Account' : this.fillPage === 8 ?
                                    'Go to profile' : 'Next'}}</span>
                            </button>
                        </ng-container>
                        <ng-template #spinrBtnTemplate>
                            <div style="max-width: 545px;">
                                <button-spinner
                                    [showSpinner]="saveSpinner"
                                    childClass="payOutButton"
                                    mat-flat-button
                                    (click)="onNext()
                                            ">
                                    Submit
                                </button-spinner>
                            </div>
                        </ng-template>

                    </div>
                </div>

            </ng-template>
        </div>

    </div>
</div>
import { Injectable } from "@angular/core";
import axios from "axios";
import { CookieServiceService } from "../app/cookie-service.service";
import { Config } from "./constant";

@Injectable({
  providedIn: "root",
})
export class ApicallService {
  constructor(private cookie: CookieServiceService) {}
  cloudinary_base_url = "https://api.cloudinary.com/v1_1/";

  get(url, params, callback) {
    this.createinstance(url, {}, 0, params)
      .then((result) => {
        callback(result.data);
      })
      .catch((error) => {
      });
  }
  downloadDocument = async (id, doc_name) => {
    axios({
      method: "GET",
      url: `${Config.API_URL}/user/documents/download/${id}`,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.cookie.getCooikie(),
      },
    }).then((response) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(response.data);
      downloadLink.setAttribute("download", doc_name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };
  post(url, data, params, callback) {
    this.createinstance(url, data, 1, params)
      .then((result) => {
        callback(result.data);
      })
      .catch((error) => {});
  }

  patch(url, data, params, callback) {
    this.createinstance(url, data, 2, params)
      .then((result) => {
        callback(result.data);
      })
      .catch((error) => {});
  }

  delete(url, params, callback) {
    this.createinstance(url, null, 3, params)
      .then((result) => {
        callback(result.data);
      })
      .catch((error) => {});
  }

  createinstance(appendURL, data, type, params) {
    let axiosInstance = axios.create();
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
    axiosInstance.defaults.headers.common["Authorization"] =
      "Bearer " + this.cookie.getCooikie();
    // axiosInstance.defaults.headers.common["x-consumer-username"] = this.cookie.getCooikie();
    let url = Config.API_URL + appendURL;

    switch (type) {
      case 0:
        return axiosInstance.get(url, params);
      case 1:
        return axiosInstance.post(url, data, params);
      case 2:
        return axiosInstance.patch(url, data, params);
      default:
        return axiosInstance.delete(url, params);
    }
  }
}

<mat-spinner *ngIf="loading" class="spinner" [diameter]="50"></mat-spinner>
<div class="booking_details" *ngIf="!loading">
  <div class="d_flex_space_align mb_30">
    <h2 class="ft_family ft_18 ft_500 _m">Current Booking</h2>
    <div class="notice_div" *ngIf="item && item?.booking">
      <button
        mat-stroked-button
        class="notice_button ft_family ft_16 ft_500"
        (click)="TenderNotice()"
        *ngIf="showButton">
        Tender Notice
      </button>
    </div>
  </div>

  <div class="card mb_60">
    <div *ngIf="!item?.booking" class="card-body">
      <h2 class="ft_500 ft_family ft_18 _m">No Properties Booked</h2>
    </div>
    <div class="card-body" *ngIf="item.booking">
      <div class="property_detail_div d_flex_space_align mb_25">
        <div class="image_name_div d_flex_align">
          <div class="image_container">
            <img
              [src]="
                this.utils.imageFormat(
                  120,
                  80,
                  item.property.gallery[0],
                  'c_fill'
                )
              "
              alt="image" />
          </div>
          <div>
            <h2 class="ft_family ft_16 ft_500">
              {{ item.property.name }}
            </h2>
            <p
              class="sub_content_color ft_15"
              style="width: 90%; line-height: 26px">
              {{ item.property.address.line_1 }},{{
              item.property.address.city.name
              }},{{ item.property.address.state.name }},{{
              item.property.address.country
              }}
            </p>
          </div>
        </div>
        <div *ngIf="item && item.booking?.status === 'pre_booking'">
          <p
            class="ft_family ft_16 ft_500 cursor_pointer"
            style="color: #077e8b"
            (click)="cancelPreBooked()">
            Cancel Pre-Booking
          </p>
        </div>
        <div *ngIf="item.booking.status !== 'pre_booking'">
          <div class="align_center" *ngIf="item.outstanding_payment.count > 0">
            <button
              class="profile_save d_flex_align_justify"
              (click)="payNow(item?.outstanding_payment.amount)"
              >
              Pay
              {{ this.utils.displayPrice(item?.outstanding_payment.amount) }}*
            </button>
            <p
              class="ft_family ft_14 ft_500 mt_15 cursor_pointer"
              style="color: #077e8b"
              (click)="viewBill()"
              *ngIf="item.outstanding_payment.count === 1">
              View Invoice
            </p>
            <p
              class="ft_family ft_14 ft_500 mt_15 cursor_pointer"
              style="color: #077e8b"
              (click)="payNow()"
              *ngIf="item.outstanding_payment.count > 1">
              View Invoices
            </p>
          </div>
        </div>
      </div>
      
      <div class="details">
        <p class="ft_family ft_500 ft_16 mb_30">Details</p>
        <div class="details_content d_flex_align">
          <div class="by_room_view">
            <p class="ft_family ft_16 ft_400 mb_16">Check In</p>
            <p
              class="ft_family sub_content_color ft_15">
              {{ utils.defaultDate(item.booking.check_in) }}
            </p>
          </div>
          <div class="by_room_view">
            <p class="ft_family ft_16 ft_400 mb_16">Check Out</p>
            <p
              class="ft_family sub_content_color ft_15"
              *ngIf="item?.booking?.check_out">
              {{
              utils.dateUtc(
              item?.booking?.check_out && item.booking.check_out
              )
              }}
            </p>
            <p
              class="ft_family sub_content_color ft_15"
              *ngIf="
                !item?.booking?.check_out
              ">
              -
            </p>
          </div>

          <!-- <div class="by_room_view">
            <p class="ft_family ft_16 ft_400 mb_16">No of Guest</p>
            <p class="ft_family sub_content_color ft_15">
              {{ item.booking.guests && item.booking.guests.length }}
            </p>
          </div> -->
        </div>

        <!-- end of detail -->
        <div
          class="d_flex_align mt_20 mb_20"
          *ngIf="item.property.rental_type === 'by_room'">
          <div class="by_room_view">
            <h2 class="ft_family ft_16 ft_400 mb_16">Room Type</h2>
            <p class="ft_family sub_content_color ft_15 capitalize">
              {{ item?.room?.room_type }}
            </p>
          </div>
          <div class="by_room_view">
            <h2 class="ft_family ft_16 ft_400 mb_16">Room No</h2>
            <p class="ft_family sub_content_color ft_15">
              {{ item.room?.room_number }}
            </p>
          </div>
          <div class="by_room_view"></div>
        </div>

        <div class="notice_div details_content" *ngIf="item.notice">
          <div class="d_flex_space_align mb_30">
            <p class="ft_family ft_500 ft_16 mt_20">Notice Details</p>
            <div *ngIf="item.notice && item.notice?.status === 'accepted'">
              <p
                class="ft_family ft_14 ft_500 cursor_pointer"
                style="color: #077e8b"
                (click)="routeToCheck()"
                *ngIf="!item.booking.check_list">
                Complete CheckList
              </p>
            </div>
            <div
              class
              style="padding-right: 6px"
              *ngIf="
                item.notice &&
                item.notice.status !== 'accepted' &&
                can_cancel_notice
              ">
              <img
                src="../../../../../assets/newIcons/showmore.svg"
                [matMenuTriggerFor]="menu"
                class="cursor_pointer p_l_r_5"
                alt="image" />
              <mat-menu #menu="matMenu" class="menu">
                <p
                  class="update_notice sub_content_color ft_16 cursor_pointer"
                  *ngIf="item.notice.status === 'accepted'"
                  (click)="TenderNotice()">
                  Update Notice
                </p>
                <p
                  class="cancel_notice sub_content_color ft_16 cursor_pointer"
                  *ngIf="item.notice.status === 'pending'"
                  (click)="cancelNotice()">
                  Cancel Notice
                </p>
              </mat-menu>
            </div>
          </div>

          <div class="notification_div">
            <div class="by_room_view">
              <h2 class="ft_family ft_16 ft_400 mb_16">Check Out</h2>
              <p class="ft_family sub_content_color ft_15">
                {{ utils.dateUtc(item.notice.notice_date) }}
              </p>
            </div>
            <div class="by_room_view">
              <h2 class="ft_family ft_16 ft_400 mb_16">Status</h2>
              <p
                class="ft_family ft_15 capitalize ft_500"
                [ngStyle]="{
                  color:
                    item.notice.status === 'pending'
                      ? '#FFC043'
                      : item.notice.status === 'accepted'
                      ? '#009618'
                      : '#F03232'
                }">
                {{
                item.notice.status === "pending"
                ? "Requested"
                : item.notice.status
                }}
              </p>
            </div>
            <div class="by_room_view" *ngIf="item.notice.status === 'accepted'">
              <h2 class="ft_family ft_16 ft_400 mb_16">Checklist</h2>
              <p
                class="ft_family ft_15 capitalize ft_500"
                [ngStyle]="{
                  color: item.booking.check_list ? '#009618' : '#FFC043'
                }">
                {{ item.booking.check_list ? "Completed" : "Pending" }}
              </p>
            </div>
            <div class="by_room_view" *ngIf="item.notice.status === 'rejected'">
              <h2 class="ft_family ft_16 ft_400 mb_16">Reason</h2>
              <p class="ft_family ft_15 capitalize ft_500">
                {{ item.notice.reject_reason }}
              </p>
            </div>
            <div class="info by_room_view">
              <i
                class="material-icons cursor_pointer"
                [matTooltip]="item.notice.reason"
                style="color: rgba(117, 117, 117, 50%); font-size: 22px">info</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="tenantScreening" class="tenant_screening">
    <div class="screening_container">
      <img class="screening_img" src="/assets/images/tenant_screening.svg">
      <div class="screening_hero_cotainer">
        <div class="screening_left_container">
          <div class="screeningHeadTitle">Tenant Screening - <span
              [ngStyle]="{'color':tenantStatus === 'completed' ? '#009618' : tenantStatus === 'pending' ? '#FFC043' : tenantStatus === 'rejected' ? 'red' : tenantStatus === 'approved' ? '#077E8B' : ''}"
              style="color: #FFCF53;">{{tenantStatus | capitalize}}</span>
            </div>
          <div style="margin-top: 10px;" [ngSwitch]="tenantStatus">
            <ng-container  *ngSwitchCase="'pending'" class="screeningText">Your
              property incharge requested some information to do background
              verification. Please complete the steps.
            </ng-container>
            <ng-container  *ngSwitchCase="'completed'" class="screeningText"> Verification is in progress. We will update you once it's done.
            </ng-container>
            <ng-container  *ngSwitchCase="'approved'" class="screeningText">Success. You are a verified tenant of Parknstays.
            </ng-container>
            <ng-container  *ngSwitchCase="'rejected'" class="screeningText">We could not approve your profile due to some issues. Please update the information to get approved
            </ng-container>
          </div>
        </div>

        <div 
        *ngIf="tenantStatus === 'pending' || tenantStatus === 'rejected'"
          class="ft_15 ft_600 sub_text_green cursor_pointer screeningClickHere"
          style="flex:none"
          (click)="onCompleteRentalApplication()" >
          Complete Now <span>
            <img src="/assets/images/tenantScreeningLeft.svg">
          </span>
        </div>
        
      </div>
    </div>
  </div>

  <div class="profile_content" *ngIf="item.security_deposit">
    <div class="profile_table_content">
      <div class="d_flex_space_align mb_40">
        <h2 class="ft_family ft_500 ft_18">Security Deposit</h2>
        <button
          *ngIf="
            item.security_deposit.status === 'created' || item.security_deposit.status === 'pending'"
          mat-stroked-button
          class="notice_button ft_family ft_16 ft_500"
          (click)="PayDeposite()">
          Pay Now
        </button>
      </div>
      <div class="card mb_60">
        <div class="card-body">
          <div style="margin:10px 0px 30px 0px"
            class="d_flex_space_align  deposite_container">
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Overall Amount</p>
              <p class="sub_content_color ft_16 ft_400">
                {{
                this.utils.displayPrice(
                item.security_deposit.amount +
                (item.security_deposit.late_fee_amount
                ? item.security_deposit.late_fee_amount
                : 0)
                )
                }}
              </p>
            </div>
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Amount</p>
              <p class="sub_content_color ft_16 ft_400">
                {{ this.utils.displayPrice(item.security_deposit.amount) }}
              </p>
            </div>
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Late Fee</p>
              <p class="sub_content_color ft_16 ft_400">
                {{
                item.security_deposit.late_fee_amount
                ? this.utils.displayPrice(
                item.security_deposit.late_fee_amount
                )
                : "$" + 0
                }}
                <span *ngIf="lateFeeCount > 0">({{ lateFeeCount }}
                  {{ lateFeeCount === 1 ? "month" : "months" }})</span>
              </p>
            </div>
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Paid</p>
              <p class="sub_content_color ft_16 ft_400">
                {{
                item.security_deposit.paid_amount
                ? this.utils.displayPrice(item.security_deposit.paid_amount)
                : "$" + 0
                }}
              </p>
            </div>
          </div>
          <div style="margin:10px 0px 30px 0px"
            class="d_flex_space_align deposite_container">
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">InProcessing Balance</p>
              <p class="sub_content_color ft_16 ft_400">
                {{
                item.security_deposit.processing_amount
                ? this.utils.displayPrice(item.security_deposit.processing_amount)
                : "$0"
                }}
              </p>
            </div>
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Balance</p>
              <p class="sub_content_color ft_16 ft_400">
                {{
                item.security_deposit.pending_amount
                ? this.utils.displayPrice(item.security_deposit.pending_amount)
                : "$0"
                }}
              </p>
            </div>
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Installments</p>
              <p class="sub_content_color ft_16 ft_400">
                {{ item.security_deposit.installments }}
              </p>
            </div>
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Pending Installments</p>
              <p class="sub_content_color ft_16 ft_400">
                {{ item.security_deposit.pending_installments +
                (item?.security_deposit?.missed_installments ?
                item?.security_deposit?.missed_installments : 0)}}
                <!-- {{ item.security_deposit.pending_installments + (item?.security_deposit?.missed_installments ??  0)}} -->
              </p>
            </div>
          </div>
          <div style="margin:10px 0px 20px 0px"
            class="d_flex_space_align  deposite_container">
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Due on</p>
              <p class="sub_content_color ft_16 ft_400">
                {{
                item.security_deposit.installment_due_on
                ? this.utils.dateUtc(
                item.security_deposit.installment_due_on
                )
                : "-"
                }}
              </p>
            </div>
            <div>
              <p class="ft_family ft_16 ft_400 mb_16">Next Due</p>
              <p class="sub_content_color ft_16 ft_400">
                {{
                item.security_deposit.next_installment
                ? this.utils.defaultDate(
                item.security_deposit.next_installment
                )
                : "-"
                }}
              </p>
            </div>
            <div *ngIf="item?.security_deposit?.history?.length > 0">
              <p class="ft_family ft_16 ft_400 mb_16">History</p>
              <p
                class="sub_content_color ft_16 ft_400 cursor_pointer"
                (click)="viewHistory()">
                View
              </p>
            </div>
            <div *ngIf="item?.security_deposit?.history?.length === 0">
              <div></div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="profile_content" *ngIf="booking && booking.length > 0">
        <div class="profile_table_content">
          <div class="d_flex_space_align mb_40">
            <h2 class="ft_family ft_500 ft_18">My Bookings</h2>
            <div class="paginator">
              <button
                [disabled]="page <= 1"
                (click)="onPageChange('before')"
                [ngClass]="page <= 1 ? 'inactive_button' : 'active_button'">
                <i class="fa fa-angle-left" style="font-size: 24px"></i>
              </button>
              <button
                [disabled]="booking && booking.length < limit"
                [ngClass]="
                  booking && booking.length < limit
                    ? 'inactive_button'
                    : 'active_button'
                "
                (click)="onPageChange('after')">
                <i class="fa fa-angle-right" style="font-size: 24px"></i>
              </button>
            </div>
          </div>
          <div class="table_div">
            <table class="profile_table">
              <tr class="profile_table_header">
                <th class="pl_30">Property Name</th>
                <th>Check In</th>
                <th>Check Out</th>
                <th>Amount</th>
              </tr>
              <tr class="profile_table_border" *ngFor="let item of booking">
                <td class="pl_30">
                  <p class="capitalize ft_16">{{ item?.property.name }}</p>
                </td>
                <td>{{ utils.dateUtc(item.check_in) }}</td>
                <td *ngIf="item?.check_out">
                  {{ utils.dateUtc(item?.check_out) }}
                </td>
                <td style="padding-left: 40px" *ngIf="!item?.check_out">-</td>
                <td>
                  {{ utils.displayPrice(item?.pay_summary?.payable_amount) }}
                </td>
              </tr>
              <tr *ngIf="booking && booking.length === 0">
                <td style="padding-left: 1.875rem">No bookings Found</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

import { Component, OnInit, Input } from "@angular/core";
import { ApicallService } from "src/app/apicall.service";
import { HttpParams } from "@angular/common/http";
import axios from "axios";
import { CookieServiceService } from "src/app/cookie-service.service";
import { UtilityService } from "src/app/utility.service";
import * as moment from "moment";
import { utils } from "protractor";
@Component({
  selector: "app-transactions-table",
  templateUrl: "./transactions-table.component.html",
  styleUrls: ["./transactions-table.component.css"],
})
export class TransactionsTableComponent implements OnInit {
  page: number = 1;
  limit: number = 5;
  length;
  list;
  from_date;
  to_date;
  filter_value = "this_month";
  today = new Date();
  loading = true;
  minTo = new Date();
  params = {};

  show_filters = false;
  constructor(
    public url: ApicallService,
    public cookie: CookieServiceService,
    public utils: UtilityService
  ) {}

  ngOnInit(): void {
    this.from_date = new Date();
    this.from_date.setDate(1);
    this.from_date.setHours(0, 0, 0, 0);
    this.to_date = new Date();
    this.getBillingApi();
  }

  filterValue(e) {
    if ("custom_date" === e) {
      this.show_filters = true;
      this.filter_value = e;
      this.from_date = "";
      this.to_date = "";
    } else {
      this.show_filters = false;
      this.page = 1;
      let filter = this.utils.dateFilter(e, this.from_date, this.to_date);
      this.from_date = filter.start;
      this.to_date = filter.end;
      this.getBillingApi();
    }
  }

  getBillingApi() {
    let start = moment(this.from_date).format("YYYY-MM-DD");
    let end = moment(this.to_date).format("YYYY-MM-DD");
    this.url.get(
      `user/transactions?page=${this.page}&limit=${this.limit}&from=${start}&to=${end}`,
      {},
      (res) => {
        this.loading = false;
        if (res.success) {
          this.list = res.data.transactions;
        }
      }
    );
  }

  onPageChange(e) {
    this.page = e === "before" && this.page > 1 ? this.page - 1 : this.page + 1;
    this.getBillingApi();
  }

  returnObjExists = (obj) => {
    if (obj && Object.keys(obj).length > 0) return true;
    return false;
  };
  changeCheckDate(value, input) {
    if (input === "from_date") {
      this.from_date = value;
      this.minTo = value;
    } else {
      this.to_date = value;
    }
    if (this.from_date && this.to_date) {
      this.getBillingApi();
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApicallService } from "../apicall.service";
import { UtilityService } from "../utility.service";
@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.css"],
})
export class VerifyEmailComponent implements OnInit {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public url: ApicallService,
    public utils: UtilityService
  ) {}
  verify_status;
  loading = true;
  timer = 5;
  ngOnInit(): void {
    let paramsData;
    let data;
    this.route.queryParams.subscribe((params) => {
      paramsData = params;
      data = {
        email: paramsData.email,
        token: paramsData.token,
      };
      this.checkVerified(data);
    });
  }

  checkVerified(data) {
    this.url.post(`auth/user/verify`, data, {}, (res) => {
      this.loading = false;
      let setinteval;
      if (res.success) {
        this.verify_status = true;
        setinteval = setInterval(() => {
          if (this.timer === 0 || this.timer < 0) {
            clearInterval(setinteval);
            this.router.navigate(["/"]);
          } else this.countReduce();
        }, 1000);
      } else {
        this.utils.openToast("", res);
        this.verify_status = false;
        setinteval = setInterval(() => {
          if (this.timer === 0 || this.timer < 0) {
            clearInterval(setinteval);
            this.utils.routeWithQuery("profile", "profile");
            // this.router.navigate(["/profile"]);
          } else this.countReduce();
        }, 1000);
      }
    });
  }
  countReduce() {
    this.timer = this.timer - 1;
  }
}

import { Component, OnInit , Inject } from "@angular/core";
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
@Component({
  selector: "app-pre-book-pop-up",
  templateUrl: "./pre-book-pop-up.component.html",
  styleUrls: ["./pre-book-pop-up.component.css"],
})
export class PreBookPopUpComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PreBookPopUpComponent>
    ) {}

  ngOnInit(): void {}
}

<div class="container_box">
  <div class="d_flex_align_justify mb_30">
    <img
      src="../../../../../../assets/newIcons/request_success.svg"
      alt="success_icon"
    />
  </div>
  <h2 class="ft_family ft_500 ft_16 mb_15 align_center">
    Your Documents has been Successfully
    {{ data === "created" ? "Created" : "Updated" }}
  </h2>
  <img src="" />
</div>

import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";
import { DepositeHistoryComponent } from "../profile-bookings/deposite/deposite-history/deposite-history.component";

@Component({
  selector: "app-profile-deposite",
  templateUrl: "./profile-deposite.component.html",
  styleUrls: ["./profile-deposite.component.css"],
})
export class ProfileDepositeComponent implements OnInit {
  constructor(
    public url: ApicallService,
    public utils: UtilityService,
    public dialog: MatDialog
  ) {}
  page = 1;
  limit = 10;
  params;
  deposite_list;
  loading = true;
  ngOnInit(): void {
    this.depositeListing();
  }
  depositeListing() {
    this.url.get(
      `user/deposits?page=${this.page}&limit=${this.limit}`,
      this.params,
      (res) => {
        this.loading = false;
        if (res.success) {
          this.deposite_list = res.data.deposit;
        }
      }
    );
  }
  viewHistory = (history, title) => {
    const dialog = this.dialog.open(DepositeHistoryComponent, {
      width: "450px",
      position: { right: "0px", top: "0px" },
      height: "100vh",
      data: { data: history, title: title },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
      }
    });
  };
  onPageChange(e) {
    if (e === "before" && this.page > 1) {
      this.page = this.page - 1;
    } else {
      this.page = this.page + 1;
    }
    this.depositeListing();
  }
}

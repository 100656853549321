<div class="fp__main">
  <div class="fp__heading">
    <i class="material-icons" (click)="onNoClick()">arrow_back</i>
    <label>Filter</label>
    <div class="fp-button">
      <button class="invite_btn" mat-button (click)="applyFilter()">
        Apply
      </button>
    </div>
  </div>
  <div style="margin-top: 49px">
    <div class="d_flex_align_justify" style="flex-direction: column">
      <div class="filter-options">
        <div class="filter-heading mb_20 mt_10">
          <h1>FILTERS</h1>
          <label (click)="clearAll()"> Clear All </label>
        </div>

        <!-- Property type -->
        <div class="f-prop_type">
          <div class="f-type_drop">
            <div class="ftp-head">
              <div class="ftp-arrow">
                <i
                  class="material-icons expand-arrow"
                  style="color: #ec5849"
                  (click)="collPropType = !collPropType"
                  >expand_more</i
                >
                <label>Property type</label>
              </div>
              <label (click)="PropertyClear('')">Clear</label>
            </div>

            <div
              class="ftp-content"
              [ngClass]="collPropType ? 'collapse-filter' : ''"
            >
              <div
                *ngFor="let propType of propertyType; let i = index"
                class="ftp-checkbox-div"
              >
                <mat-checkbox
                  class="ftp-checkbox"
                  [checked]="propType.is_enabled"
                  (change)="proTypeFilter(propType)"
                  >{{ propType.name }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <!-- Price Range -->
        <div class="f-prop_type mt_10">
          <div class="f-type_drop">
            <div class="ftp-head">
              <div class="ftp-arrow">
                <i
                  class="material-icons expand-arrow"
                  style="color: #ec5849"
                  (click)="
                    collPriceRange = !collPriceRange;
                    priceFilter(collPriceRange)
                  "
                  >expand_more</i
                >
                <label>Price Range</label>
              </div>
              <label (click)="priceClearing('')">Clear</label>
            </div>

            <div
              class="ftp-content"
              [ngClass]="collPriceRange ? 'collapse-filter' : ''"
            >
              <div *ngFor="let price of priceArray; index as i">
                <div class="ftp-checkbox-div">
                  <mat-checkbox
                    class="ftp-checkbox"
                    [checked]="price.is_enabled"
                    (change)="priceFilter(price)"
                    >${{ price.min }}
                    {{ price.max ? "- $" + price.max : "+" }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Star Rating -->
        <div class="f-prop_type mt_10">
          <div class="f-type_drop">
            <div class="ftp-head">
              <div class="ftp-arrow">
                <i
                  class="material-icons expand-arrow"
                  style="color: #ec5849"
                  (click)="collStarRating = !collStarRating"
                  >expand_more</i
                >
                <label>Star Rating</label>
              </div>
              <label (click)="clearRating('')">Clear</label>
            </div>

            <div
              class="ftp-content"
              [ngClass]="collStarRating ? 'collapse-filter' : ''"
            >
              <div *ngFor="let rate of rating; index as i">
                <div class="ftp-checkbox-div">
                  <mat-checkbox
                    *ngIf="i === 0"
                    class="ftp-checkbox rating"
                    (change)="starFilter('5', rate)"
                    [checked]="rate.is_enabled"
                  >
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                  </mat-checkbox>
                </div>
                <div class="ftp-checkbox-div">
                  <mat-checkbox
                    *ngIf="i === 1"
                    class="ftp-checkbox rating"
                    [checked]="rate.is_enabled"
                    (change)="starFilter('4', rate)"
                  >
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                  </mat-checkbox>
                </div>
                <div class="ftp-checkbox-div">
                  <mat-checkbox
                    *ngIf="i === 2"
                    class="ftp-checkbox rating"
                    [checked]="rate.is_enabled"
                    (change)="starFilter('3', rate)"
                  >
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                    <i class="fa fa-star ftp-star"></i>
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="f-prop_type mt_10">
          <div class="f-type_drop">
            <div class="ftp-head">
              <div class="ftp-arrow">
                <i
                  class="material-icons expand-arrow"
                  style="color: #ec5849"
                  (click)="collAmen = !collAmen"
                  >expand_more</i
                >
                <label>Amenities</label>
              </div>
              <label (click)="amenityClear('')">Clear</label>
            </div>

            <div
              class="ftp-content"
              [ngClass]="collAmen ? 'collapse-filter' : ''"
              id="amenities_show"
              [ngStyle]="{
                height: amenities_expanded === true ? '100%' : '200px'
              }"
              style="height: 200px; text-transform: capitalize"
            >
              <div *ngFor="let amen of amenities" class="ftp-checkbox-div">
                <mat-checkbox
                  class="ftp-checkbox"
                  [checked]="returnIsAmenityChecked(amen.amenity_id)"
                  (change)="amenitiesFilter($event, amen)"
                  >{{ amen.name }}
                </mat-checkbox>
              </div>
            </div>
            <div *ngIf="amenities.length > 5 && amenities_expanded === false">
              <label
                class="ftp-checkbox"
                style="margin-left: 11px; margin-top: 20px"
                (click)="showMore(true)"
              >
                + Show all</label
              >
            </div>
            <div *ngIf="amenities.length > 5 && amenities_expanded === true">
              <label
                class="ftp-checkbox"
                style="margin-left: 11px; margin-top: 10px"
                (click)="showMore(false)"
              >
                - Show less</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

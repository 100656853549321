<div class="main_container_box">
  <div class="content_scroll">
    <div class="flex_container">
      <div class="left_container" *ngIf="width > 480">
        <div class="individual_property_div" *ngFor="let item of property_list">
          <div class="image_holder mr_15">
            <img
              class=""
              [src]="utils.imageFormat(180, 150, item.gallery[0], 'c_fill')"
              alt="image"
            />
          </div>
          <div class="content_holder">
            <p class="ft_family ft_700 ft_18 mb_15">{{ item.name }}</p>
            <p class="ft_family ft_400 sub_content_color ft_16 mb_15">
              {{ item.address.city && item.address.city.name }}
              {{ item.address.state && item.address.state.name }}
              {{ item.address.country }}
            </p>
            <div class="guest_bed_details">
              <div class="detail_guest_box" style="margin-left: 0px">
                <div class="d_flex room_box_detail mb_5">
                  <p class="mr_10">{{ item.bathrooms }}</p>
                  <img
                    src="../../../assets/newIcons/bedroom_home.svg"
                    alt="image"
                  />
                </div>
                <p class="ft_14">Bedrooms</p>
              </div>
              <div class="detail_guest_box">
                <div class="d_flex room_box_detail mb_5">
                  <p class="mr_10">{{ item.bedrooms }}</p>
                  <img
                    style="width: 1.313rem !important"
                    src="../../../assets/newIcons/bathroom_home.svg"
                    alt="image"
                  />
                </div>
                <p class="ft_14">Bathrooms</p>
              </div>
              <div class="detail_guest_box guest_box">
                <div class="d_flex room_box_detail mb_5">
                  <p class="mr_10">{{ item.bathrooms }}</p>
                  <img
                    style="width: 1.063rem !important"
                    src="../../../assets/newIcons/guest_home.svg"
                    alt="image"
                  />
                </div>
                <p class="ft_14" style="padding-left: 12px">Guests</p>
              </div>
            </div>
            <div
              class="rating_amount d_flex_space_align"
              [ngClass]="
                !item.reviews || item.reviews.count === 0 ? 'flex_end' : ''
              "
            >
              <div
                class="rating"
                *ngIf="item.reviews && item.reviews.count !== 0"
              >
                <img
                  class="star"
                  src="../../../assets/newIcons/Star.svg"
                  alt="icon"
                />
                <p class="rating_value ft_family ft_500 ft_15">
                  {{ item.reviews.rating }}
                </p>
                <p class="review sub_content_color ft_family ft_300 ft_15">
                  ( {{ item.reviews.count }} Review )
                </p>
              </div>
              <div class="amount">
                <p class="amount_value ft_family ft_20 align_right">
                  {{ utils.returnRoomPrice(item) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_container">
        <app-map [property_list]="property_list"></app-map>
      </div>
    </div>
  </div>
</div>

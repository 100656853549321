<agm-map
  [latitude]="lat"
  [longitude]="lng"
  style="width: 100%; height: 100%"
  [zoom]="15"
  [fitBounds]="true"
>
  <agm-marker
    *ngFor="let marker of markerArray; let i = index"
    [agmFitBounds]="true"
    [latitude]="marker.lat"
    [zoom]="15"
    [label]="{
      color: 'white',
      fontSize: '14px',
      text: marker.price,
      fontWeight: '500'
    }"
    iconUrl="../../assets/newIcons/map_mark.svg"
    [markerDraggable]="marker.draggable"
    [longitude]="marker.lng"
  >
    <agm-info-window class="info">
      <div class="individual_details">
        <div class="image_container">
          <img
            [src]="utils.imageFormat(60, 60, marker.gallery, 'c_fill')"
            alt="image"
          />
        </div>
        <div class="content" (click)="reRoute(marker.property_id)">
          <p class="ft_16 ft_family ft_400 sub_content_color">
            {{ marker.city && marker.city }} {{ marker.state && marker.state }}
            {{ marker.country && marker.country }}
          </p>
        </div>
      </div>
    </agm-info-window>
  </agm-marker>
</agm-map>

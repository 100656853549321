import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: "app-toast-message",
  templateUrl: "./toast-message.component.html",
  styleUrls: ["./toast-message.component.css"],
})
export class ToastMessageComponent implements OnInit {
  response_message;
  response_status;
  message_to_show;
  constructor(
    public dialog: MatDialogRef<ToastMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public response,
    @Inject(MAT_DIALOG_DATA) public message
  ) {}

  ngOnInit(): void {
    this.response_message = this.response.response;
    this.message_to_show = this.message.message;
    if (this.response_message.success) {
      this.response_status = "success";
    } else {
      if (!this.message_to_show) this.response_status = "failure";
    }
    setTimeout(() => {
      this.closePopUp();
    }, 3000);
  }
  closePopUp() {
    this.dialog.close();
  }
}

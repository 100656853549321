import { Component, OnInit, Input } from "@angular/core";
import { AgmCoreModule } from "@agm/core";
import { UtilityService } from "../utility.service";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { MapViewComponent } from "../property-search/map-view/map-view.component";
export interface marker {
  lat: string;
  lng: string;
  draggable: boolean;
}

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"],
})
export class MapComponent implements OnInit {
  constructor(
    public utils: UtilityService,
    public router: Router,
    public dialog: MatDialogRef<MapViewComponent>
  ) {}
  @Input() property_list: any;
  ngOnInit(): void {
    this.property_list.forEach((property) => {
      if (property.loc && property.loc[0] !== undefined) {
        let data = {
          lat: property.loc[1],
          lng: property.loc[0],
          price: this.utils.mapPrice(property),
          gallery: property.gallery[0],
          city: property.address.city && property.address.city.name,
          state: property.address.state && property.address.state.name,
          country: property.address.country,
          property_id: property.property_id,
        };
        this.markerArray.push(data);
      }
    });
  }
  latitude: number = 40;
  longitude: number = -101;
  latitude1: number;
  longitude1: number;
  zoom: number = 5;
  marker: marker;
  lat = 35.1828135;
  lng = -80.9211079;
  markerArray = [];
  mapClicked($event) {
    this.marker = {
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
    };
  }

  onOkayClick() {
    // if (!this.marker)
    // else this.dialog.close({ success: true, data: this.marker });
  }
  markerDragEnd(m: marker, $event: MouseEvent) {}
  clickedMarker(label: string, index: number) {}
  reRoute(id) {
    this.dialog.close();
    this.router.navigate([`detail/${id}`]);
  }
}

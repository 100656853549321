<div class="footer_container">
  <div class="footer_box">
    <div class="box">
      <div class="individual_content">
        <img
          class="logo mb_40 cursor_pointer"
          src="./assets/newLogo/newLogo.svg"
          style="width: 155px;"
          [routerLink]="['/']"
          alt="image"
        />
        <p class="ft_family ft_15 mb_30" style="width: 84%">
          We support programs that create advancement opportunities for people.
        </p>
        <div class="d_flex_align_start mb_30">
          <p class="follow_us">FOLLOW US</p>
          <div class="d_flex_space_align">
            <a href="https://www.facebook.com/Parknstays/" target="_blank">
              <img
                class="social_media cursor_pointer"
                src="../../assets/newIcons/facebook.svg"
                alt="image"
              />
            </a>
            <a href="https://www.twitter.com/Parknstays/" target="_blank">
              <img
                class="social_media cursor_pointer"
                src="../../assets/newLogo//Twitter.svg"
                alt="image"
              />
            </a>
            <a href="https://www.instagram.com/Parknstays/" target="_blank">
              <img
                class="social_media cursor_pointer"
                src="../../assets/newIcons/instagram.svg"
                alt="image"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="individual_content">
        <p class="mb_40 ft_600 footer_res_heading">ABOUT US</p>
        <div class="about res_div_terms">
          <p
            class="mb_30 cursor_pointer ft_15"
            [routerLink]="['/terms_condtions']"
          >
            Terms & Conditions
          </p>
          <p
            class="mb_30 cursor_pointer ft_15"
            [routerLink]="['/privacy_policy']"
          >
            Privacy Policy
          </p>
        </div>
      </div>
      <div class="individual_content">
        <p class="mb_40 ft_600 footer_res_heading">SERVICES</p>
        <div class="about">
          <p class="mb_30 cursor_pointer ft_15" (click)="rentalType('King')">
            King Rooms
          </p>
          <p class="mb_30 cursor_pointer ft_15" (click)="rentalType('Master')">
            Master Rooms
          </p>
          <p class="mb_30 cursor_pointer ft_15" (click)="rentalType('Private')">
            Private Rooms
          </p>
          <p class="mb_30 cursor_pointer ft_15" (click)="rentalType('')">
            Entire Home
          </p>
        </div>
      </div>
      <div class="individual_content">
        <p class="mb_40 ft_600 footer_res_heading">CONTACT US</p>
        <div class="about">
          <p class="mb_30 cursor_pointer ft_15">
            <a
              style="color: white; text-decoration: none"
              href="tel:+1(704)-804-5996"
              >+1 (704)-804-5996</a
            >
          </p>
          <p class="mb_30 cursor_pointer ft_15">
            <a
              style="color: white; text-decoration: none"
              href="mailto:info.parknstays@gmail.com"
              >info.parknstays@gmail.com</a
            >
          </p>
          <p class="mb_30 ft_15">
            3230 Prosperity Church RD, Suite 202 Charlotte, NC 28269 USA.
          </p>
        </div>
      </div>
    </div>
    <div class="d_flex_space res_footer_align">
      <p class="ft_300 ft_white ft_family ft_18">
        {{ this.utils.returnYear() }} Parknstays.com All Rights Reserved
      </p>
      <div class="d_flex_align res_profile">
        <p class="ft_white ft_family ft_18 mr_15 ft_300">Last Activity:</p>
        <p class="ft_white ft_family ft_18 ft_300">{{ lastActivity }}</p>
      </div>
      <div class="d_flex_align res_profile" *ngIf="profile">
        <p class="ft_white ft_family ft_18 mr_15 ft_300">Profile Id:</p>
        <p class="ft_white ft_family ft_18 ft_300">
          {{ profile.user_id }}
        </p>
      </div>
    </div>
  </div>
</div>

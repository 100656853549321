import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-guest-information-popup",
  templateUrl: "./guest-information-popup.component.html",
  styleUrls: ["./guest-information-popup.component.css"],
})
export class GuestInformationPopupComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = "start";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  name: string = "";
  age: any;
  gender: string = "";

  constructor(
    public dialogRef: MatDialogRef<GuestInformationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.data.guestDetail) {
      let guest = this.data.guestDetail;
      this.name = guest.name;
      this.age = guest.age;
      this.gender = guest.gender;
    }
  }

  addGuest() {
    let onlyNumbers = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
    var onlywords = /^[a-zA-Z\s]*$/;
    if (!this.name) this.showMessage("Please Enter the Name");
    else if (!onlywords.test(this.name))
      this.showMessage("Name field should contain only letters");
    else if (!this.age) this.showMessage("Please Enter  Age");
    else if (!onlyNumbers.test(this.age)) {
      this.showMessage("Age field should contain only numbers");
    } else if (!this.gender) this.showMessage("Please Enter the Gender");
    else {
      let guest = {
        name: this.name,
        age: this.age,
        gender: this.gender,
      };
      this.dialogRef.close({ success: true, guest, index: this.data.index });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  showMessage(message: string) {
    this.snackbar.open(message, "", {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}

<div>
    <div class="chatHeader" (click)="dialog.close()">
        <mat-icon style="cursor:pointer;color: #2F7F8B;"
            class="close_icon cancel">close</mat-icon>
    </div>
</div>
<div class="container" >

    <ng-container *ngIf="!initPageSpinner else spinnerTemPlate">
        <ng-container *ngIf="chats.length else noChatTemplate">
            <div #scrollContainer class="container_body">
                <div *ngFor="let chat of chats index as i ">
                    <div class="chatContainer">
                        <div class="timeContainer"
                            *ngIf="(!i || (moment(chat?.createdAt).startOf('day').format()) !== (moment(chats[i-1]?.createdAt).startOf('day').format()))">
                            <div class="timeContainer_time">
                                {{this.isToday(chat)}}
                            </div>
                        </div>
                        <ng-container
                            *ngIf="this.checkRole(chat) else nonRoleTemplate">
                            <div class="answerContainer">
                                <div class="myMessage">
                                    <div class="wordBreakAll">
                                        {{chat.content.trimStart()}}
                                    </div>
                                    <div
                                        style="color: #ffffff;"
                                        class="time">
                                        {{ returnTimeInSec(chat.createdAt) }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #nonRoleTemplate>
                            <div class="serviceProvider_cotainer chat_container">
                                <div class="serviceMessage">
                                    <div class="role_name"
                                        *ngIf="!(chat | checkRepeatName : [chats,i])"
                                        >{{returnName(chat)}}</div>
                                    <div class="wordBreakAll">
                                        {{chat.content}}
                                    </div>
                                    <div style="color: #0D082C66;" class="time">
                                        {{ returnTimeInSec(chat.createdAt) }}
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div *ngIf="status !== 'completed' && status !== 'closed' && status !== 'cancelled'"
                class="chat_footer">
                <div class="ft_1">
                    <textarea 
                    class="chatTextArea"
                    [rows]="row" #inputTextarea
                        placeholder="Type here ..." autocomplete="off"
                        [(ngModel)]="comment" (keydown)="handleKeyPress($event)"></textarea>
                    <div class="ft_img">
                        <ng-container *ngIf="btnLoader else btnTemplate">
                            <mat-spinner class="spinner" [diameter]="30"></mat-spinner>
                        </ng-container>
                        <ng-template #btnTemplate>
                            <img (click)="onSend()"
                                src="/assets/images/send_chat.svg" />
                        </ng-template>

                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noChatTemplate>
            <div class="emptyContainer">No chats found</div>
        </ng-template>
    </ng-container>
    <ng-template #spinnerTemPlate>
        <div class="spinner_hero" style="height: 100%;">
            <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
        </div>
    </ng-template>

</div>
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
@Component({
  selector: "app-request-status",
  templateUrl: "./request-status.component.html",
  styleUrls: ["./request-status.component.css"],
})
export class RequestInviteStatusComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RequestInviteStatusComponent>,
    public router: Router
  ) {}
  reRoute() {
    this.router.navigate(["/"]);
    this.dialogRef.close();
  }
  ngOnInit(): void {}
}

<div class="request_content">
  <div *ngIf="current_step === 1">
    <p class="ft_16 mb_40 ft_500">
      Whom do you want to take care of this work?
    </p>
    <div class="content_img">
      <img src="../../../../../../assets/newIcons/assign_option_img.png" />
    </div>
    <div class="mb_30">
      <mat-radio-button
        [ngClass]="assign_to === 'host' ? 'active_radio' : 'inactive_radio'"
        class="payment_radio mb_20"
        [checked]="assign_to === 'host'"
        (change)="radioChange($event)"
        value="host"
        >Allow host to take care of this</mat-radio-button
      >
      <mat-radio-button
        [ngClass]="
          assign_to === 'contractor' ? 'active_radio' : 'inactive_radio'
        "
        class="payment_radio"
        [checked]="assign_to === 'contractor'"
        (change)="radioChange($event)"
        value="contractor"
        >I will manually assign to service pro</mat-radio-button
      >
    </div>
    <div class="button_container mt_30">
      <button
        mat-stroked-button
        class="back_button ft_family ft_600 ft_18"
        (click)="back()"
      >
        Back
      </button>
      <div class="mt_40 button_close">
        <button
          class="primary_common_button ft_500 ft_15 rounded_4 d_flex_align_justify p_9"
          [ngClass]="assign_to === 'contractor' && 'next_button'"
          (click)="assign_to === 'contractor' ? (current_step = 2) : sumbit()"
        >
          {{
            assign_to === "contractor"
              ? "Next"
              : assign_to === "host" && data?.request
              ? "Save Request"
              : "Submit Request"
          }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="current_step === 2">
    <p class="ft_16 mb_40 ft_500">
      Please select a contractor to assign this request
    </p>
    <div *ngIf="assign_to === 'contractor'">
      <div class="relative">
        <div class="contractor_search_div">
          <input
            class="contractor_search"
            placeholder="Search contractor"
            [(ngModel)]="search_value"
            (keydown)="triggerEnter($event)"
          />
          <div class="icon_search" (click)="searchCall()">
            <i class="fa fa-search"></i>
          </div>
        </div>
        <div
          *ngIf="contractor_list?.length === 0"
          class="ft_14 ft_500 sub_content_color"
        >
          No Contractor Found
        </div>
        <div class="contractor_listing">
          <div class="" *ngFor="let item of contractor_list">
            <div
              class="select_contractor cursor_pointer"
              (click)="selectedOption(item)"
              [ngClass]="
                item.name === selected_contractor?.name && 'contractor_active'
              "
            >
              <div class="contractor_image">
                <img
                  [src]="
                    item.image
                      ? this.utils.imageFormat(150, 150, item.image)
                      : '../../../../../../assets/newIcons/contractorDummyUser.svg'
                  "
                />
              </div>
              <div class="content_div cursor_pointer">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button_container mt_30">
      <button
        mat-stroked-button
        class="back_button ft_family ft_600 ft_18"
        (click)="current_step = 1"
      >
        Back
      </button>
      <div class="mt_40 button_close">
        <button
          class="primary_common_button ft_500 ft_15 rounded_4 d_flex_align_justify p_9"
          (click)="sumbit()"
        >
          {{ data?.request ? "Save Request" : "Submit Request" }}
        </button>
      </div>
    </div>
  </div>
</div>

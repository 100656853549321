import { Options } from "@angular-slider/ngx-slider";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApicallService } from "../apicall.service";
import { InviteStatusComponent } from "../invite-status/invite-status.component";
import { UtilityService } from "../utility.service";
import { MatDialog } from "@angular/material/dialog";
import { CloudinaryService } from "src/app/cloudinary.service";
import { CookieServiceService } from "../cookie-service.service";
import { LoginSignupDialogComponent } from "../components/dashboard/login-signup-dialog/login-signup-dialog.component";
@Component({
  selector: "app-check-list",
  templateUrl: "./check-list.component.html",
  styleUrls: ["./check-list.component.css"],
})
export class CheckListComponent implements OnInit {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public utils: UtilityService,
    public url: ApicallService,
    public dialog: MatDialog,
    public loggedIn: CookieServiceService,
    public imageUploader: CloudinaryService
  ) {}
  write_rating_value: number = 1;
  imageLoading = false;
  image;
  check_list_array = [];
  user_comment = "";
  invite_id = "";
  params;
  button_spin = false;
  data;
  user_id;
  imageArray = [];
  rating_obj = {
    clean: 1,
    communication: 1,
    location: 1,
    rooms: 1,
    value: 1,
    service: 1,
  };
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.invite_id = params.id;
        this.requestDetail();
      }
    });
  }
  requestDetail = () => {
    if (this.loggedIn.isLoggedIn()) {
      this.url.get(`user/checklist/${this.invite_id}`, {}, (res) => {
        if (res.success) {
          this.data = res.data;
          this.user_id = this.data.booking.user.id;
          let check_list = this.data.property.check_list;
          if (check_list.length > 0) {
            this.data.property.check_list.map((item: any) => {
              let obj = {
                content: item.value,
                is_enabled: false,
              };
              this.check_list_array.push(obj);
            });
          }
        } else {
          const dialog = this.dialog.open(InviteStatusComponent, {
            data: "failure",
            disableClose: true,
            autoFocus: true,
          });
        }
      });
    } else {
      const loginDialog = this.dialog.open(LoginSignupDialogComponent, {
        disableClose: true,
        data: {},
      });
    }
  };

  options: Options = {
    floor: 0,
    ceil: 10,
  };
  onRate(e) {
    this.write_rating_value = e.newValue;
  }

  upload(e: any) {
    let file = e.target.files[0];
    if (file.size > 5 * 1000000) {
      this.utils.openToast("Uploaded image should be less than 5MB", {});
    } else if (file.type !== "image/png" && file.type !== "image/jpeg") {
      this.utils.openToast("Please upload only jpeg or png images", {});
    } else {
      this.imageLoading = true;
      this.image = {
        public_id: "",
        version: "",
        format: "",
      };
      this.imageUploader.uploadCloudinary(
        file,
        `user/${this.user_id}/service_pic`,
        (response: any) => {
          this.imageLoading = false;
          if (response && response.url) {
            this.image = {
              public_id: response.public_id,
              version: "v" + response.version,
              format: response.format,
            };
            this.imageArray.push(this.image);
            // this.profileImageUrl = this.utils.imageFormat(
            //   80,
            //   80,
            //   this.image,
            //   "c_fill"
            // );
            e.target.value = "";
            let saveData = {
              image: this.image,
            };
          } else this.utils.openToast("Could not upload image", {});
        }
      );
    }
  }

  removeImage(e, index) {
    if (this.imageArray[index].public_id === e.public_id) {
      this.imageArray.splice(index, 1);
    }
  }

  redirect() {
    this.router.navigate(["/"]);
  }

  submit() {
    let checkListArray: any = [];
    if (this.loggedIn.isLoggedIn()) {
      if (this.user_comment !== "" && this.user_comment.length < 10) {
        this.utils.openToast(
          "Comments section should contain more than 10 characters",
          {}
        );
      } else {
        if (this.check_list_array.length > 0) {
          checkListArray = this.check_list_array.filter((x) => x.is_enabled);
        }
        this.button_spin = true;
        let data = {
          checklist: checkListArray,
          ratings: this.rating_obj,
          comment: this.user_comment,
          images: this.imageArray,
          overall_rating: this.write_rating_value,
        };
        this.url.post(
          `user/checklist/submit/${this.invite_id}`,
          data,
          {},
          (res) => {
            this.button_spin = false;
            if (res.success) {
              this.utils.openToast("", res);
              window.location.href = "/#/profile?tab=bookings";
            } else {
              this.utils.openToast("", res);
            }
          }
        );
      }
    } else {
      const loginDialog = this.dialog.open(LoginSignupDialogComponent, {
        data: {},
      });
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UtilityService } from "../utility.service";
import { ApicallService } from "../apicall.service";
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  newhide = true;
  cnfirmhide = true;
  newPassword;
  token;
  params;
  confirmPassword;
  constructor(
    public router: Router,
    public utils: UtilityService,
    public url: ApicallService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.token = params.token;
    });
  }
  cancel() {
    this.router.navigate(["/"]);
  }
  submit() {
    if (this.newPassword === "" || this.newPassword === undefined) {
      this.utils.openToast("Please enter New Password", {});
    } else if (
      this.confirmPassword === "" ||
      this.confirmPassword === undefined
    ) {
      this.utils.openToast("Please enter Confirm Password", {});
    } else if (this.newPassword !== this.confirmPassword) {
      this.utils.openToast("Password matching Incorrect", {});
    } else {
      let data = {
        token: this.token,
        new_pass: this.newPassword,
      };
      this.url.post("auth/user/reset_password", data, this.params, (res) => {
        if (res.success) {
          this.utils.openToast("", res);
          this.router.navigate(["/"]);
        } else {
          this.utils.openToast("", res);
        }
      });
    }
  }
}

<div style="padding: 40px">
  <span class="heading">Guest Information</span>
  <div class="formField mt_20 res_guest">
    <mat-form-field appearance="outline">
      <mat-label> Name* </mat-label>
      <input matInput [(ngModel)]="name" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Age*</mat-label>
      <input matInput [(ngModel)]="age" maxlength="2" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Gender*</mat-label>
      <mat-select [(ngModel)]="gender">
        <mat-option value="Male">Male</mat-option>
        <mat-option value="Female">Female</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex-c-c mt_20">
    <button class="square_button filled_red" (click)="addGuest()">Save</button>
    <button class="square_button outline_red ml_20" (click)="cancel()">
      Cancel
    </button>
  </div>
</div>

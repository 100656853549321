<div class="content_holder">
  <div>
    <p style="text-align: center;" class="ft_15 ft_500 mb_30">
     Cancel Pre-Booking
    </p>
    <p style="margin-bottom: 28px;text-align: center;">{{data.message}}</p>
    <div class="button_container">
      <button
        class="closing_button"
        mat-flat-button
        (click)="this.dialogRef.close()"
      >
        Cancel</button
      ><button
        class="submit_button"
        mat-flat-button
        (click)="this.dialogRef.close('close')"
      >
        Yes
      </button>
    </div>
  </div>
</div>

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";

@Component({
  selector: "app-deposite-history",
  templateUrl: "./deposite-history.component.html",
  styleUrls: ["./deposite-history.component.css"],
})
export class DepositeHistoryComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<DepositeHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public url: ApicallService,
    public utils: UtilityService
  ) {}

  ngOnInit(): void {
    this.getHistory();
  }
  close = () => {};
  getHistory = () => {
    if (this.data) {
    }
    // this.url.get()
  };
}

import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";
import * as moment from "moment";
import { PayMethodComponent } from "src/app/pay-method/pay-method.component";
import { CookieService } from "ngx-cookie";
import { ActivatedRoute, Router } from "@angular/router";
import { StatusCheckComponent } from "src/app/payment-checkout/status-check/status-check.component";
@Component({
  selector: "app-deposite-type-payment",
  templateUrl: "./deposite-type-payment.component.html",
  styleUrls: ["./deposite-type-payment.component.css"],
})
export class DepositeTypePaymentComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public paymentDialog: MatDialogRef<PayMethodComponent>,
    public depositeDialog: MatDialogRef<DepositeTypePaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public url: ApicallService,
    public route: ActivatedRoute,
    public utils: UtilityService,
    public cookie: CookieService,
    public router: Router
  ) {}
  payment_method = "full";
  deposite_data;
  loading = true;

  ngOnInit(): void {
    this.getCalculation();
    this.route.queryParams.subscribe((params) => {
      if (params.status) {
        const dialog = this.dialog.open(StatusCheckComponent, {
          data: {
            status: params.status,
          },
        });
        dialog.afterClosed().subscribe((result) => {
          window.location.href = "/#/profile?tab=bookings";
        });
      }
    });
  }
  getCalculation() {
    this.url.get(`user/deposits/summary/${this.data.deposit_id}`, {}, (res) => {
      this.loading = false;
      if (res.success) {
        this.deposite_data = res.data.deposit_summary;
      }
    });
  }
  getValue(value) {
    return moment.localeData().ordinal(value);
  }
  radioChange = (e) => {
    this.payment_method = e.value;
  };
  OpenPaymentType = () => {
    const dialog = this.dialog.open(PayMethodComponent, {
      width: "652px",
      data: {
        invoiceAmount:
          this.payment_method === "full"
            ? this.deposite_data.pending_amount
            : this.deposite_data.installment_amount,
        is_non_customer: false,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        let data = {
          success_url: `${window.location.href}&status=success`,
          cancel_url: `${window.location.href}&status=failure`,
          type: res.data,
        };
        this.url.post(
          `user/deposits/${this.data.deposit_id}${
            this.payment_method === "full" ? "?fixed=1" : ""
          }`,
          data,
          {},
          (res) => {
            this.loading = false;
            if (res.success) {
              window.open(res.data.link, "_self");
              this.cookie.put("transaction_id", res.data.transaction_id);
            } else {
              this.utils.openToast("", res);
            }
          }
        );
      }
    });
  };
}

import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from '../utility.service';
import * as moment from "moment";

@Pipe({
    name: 'dateChecker'
  })
  export class DateChecker implements PipeTransform {
    date;
    constructor(
        public utils: UtilityService
      ){
        this.utils.date$.subscribe((response) => {
          this.date = response;
        });
      }
    
      transform(obj) {
        let retrunValue = false;
        if (this.date !== moment(obj.createdAt).startOf("day").format()) {
          this.utils.date$.next(moment(obj.createdAt).startOf("day").format())
          retrunValue  = true
          
        }
        return retrunValue;
      }
  }  
import { Component, Input, OnInit,OnChanges } from '@angular/core';

@Component({
  selector: 'app-no-data',
  templateUrl: './app-no-data.component.html',
  styleUrls: ['./app-no-data.component.css']
})
export class AppNoDataComponent implements OnInit {

  @Input() spinner:boolean
  @Input() data:any

  constructor() {
   }
   
  ngOnInit() {
  }

  ngOnChanges() {
  }

}

import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StarRatingColor } from './star-rating/star-rating.component';
import { ApicallService } from 'src/app/apicall.service';
import { UtilityService } from 'src/app/utility.service';

@Component({
  selector: 'app-rating-component',
  templateUrl: './rating-component.component.html',
  styleUrls: ['./rating-component.component.css']
})
export class RatingComponentComponent implements OnInit {
  rating:number = 1;
  starCount:number = 5;
  starColor:StarRatingColor = StarRatingColor.red;
  comment:string;
  requestId;
  saveSpinner:boolean = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: UtilityService,
    public dialog: MatDialogRef<RatingComponentComponent>,
    public url: ApicallService,

  ) {}
  ngOnInit(): void {
    this.requestId = this.data.requestId
  }

  onRatingChanged(rating){
    this.rating = rating;
  }

  onSubmit(){
    this.saveSpinner = true
    // this.dialog.close('123')
    const body = {
      "rating": this.rating,
      "comment": this.comment
    }
    this.url.post(`user/requests/review/${this.requestId}`, body, {}, (res) => {
      if (res.success) {
        this.dialog.close(res)
      } else {
        this.saveSpinner = false
        this.utils.openToast("", res);
      }
    });
  }

}

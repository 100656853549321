<div class="container">
  <h2 class="ft_400 ft_family ft_17 mb_40">
    Are you Sure you want to {{this.template}} the Request?
  </h2>
  <div style="gap: 20px;" class="button_div d_flex_align_justify">
    <button-spinner
    [showSpinner]="saveSpinner"
    childClass="new_common_button"
    mat-flat-button
    type="submit"
    (click)="onSubmit()">
    Yes
  </button-spinner>
    <button
      mat-stroked-button
      class="cancel_button"
      (click)="dialog.close()">
      No
    </button>
  </div>
</div>

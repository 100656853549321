<div class="container_div">
  <div
    class="message d_flex_space_align"
    *ngIf="response_message && response_status === 'success'"
  >
    <div class="image_div mr_15">
      <img src="../../assets/newIcons/success.svg" />
    </div>
    <div class="content_box mr_15">
      <p class="ft_16 ft_500 ft_family sub_text_green mb_0">
        {{ response_message.message }}
      </p>
    </div>
    <div class="close_icon cursor_pointer" (click)="closePopUp()">
      <img src="../../assets/newIcons/closeToast.svg" />
    </div>
  </div>
  <div
    class="message d_flex_space_align"
    *ngIf="response_message && response_status === 'failure'"
  >
    <div class="image_div mr_15">
      <img src="../../assets/newIcons/failure.svg" />
    </div>
    <div class="content_box mr_15">
      <p class="ft_16 ft_500 ft_family">{{ response_message.message }}</p>
    </div>
    <div class="close_icon cursor_pointer" (click)="closePopUp()">
      <img src="../../assets/newIcons/closeToast.svg" />
    </div>
  </div>
  <div class="message d_flex_space_align" *ngIf="message_to_show">
    <div class="image_div mr_15">
      <img src="../../assets/newIcons/failure.svg" />
    </div>
    <div class="content_box mr_15">
      <p class="ft_16 ft_500 ft_family">{{ message_to_show }}</p>
    </div>
    <div class="close_icon cursor_pointer" (click)="closePopUp()">
      <img src="../../assets/newIcons/closeToast.svg" />
    </div>
  </div>
</div>

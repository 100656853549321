import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApicallService } from "src/app/apicall.service";
import { UtilityService } from "src/app/utility.service";

@Component({
  selector: "app-cancel-notice",
  templateUrl: "./cancel-notice.component.html",
  styleUrls: ["./cancel-notice.component.css"],
})
export class CancelNoticeComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<CancelNoticeComponent>,
    public url: ApicallService,
    public utils: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(MAT_DIALOG_DATA) public value
  ) {
  }
  params;
  template ;
  saveSpinner = false;

  ngOnInit(): void {
    this.data.value ? this.template = this.data.value : this.template = "cancel"
  }

  onSubmit() {
    this.saveSpinner = true
    let data;
    if (this.data.value === "cancel") {
      this.url.patch(
        `user/requests/cancel/${this.data.data.request_id}`,
        data,
        this.params,
        (res) => {
          this.saveSpinner = false
          if (res.success) {
            this.dialog.close({ event: "success" });
          } else {
            this.utils.openToast(res.message,{});
          }
        }
      );
    } else if (this.data.value === "close") {
      this.url.patch(
        `user/requests/closed/${this.data.data.request_id}`,
        data,
        this.params,
        (res) => {
          this.saveSpinner = false
          if (res.success) {
            this.dialog.close({ event: "success" });
          } else {
            this.utils.openToast(res.message,{});

          }
        }
      );
    } else {
      this.url.patch(
        `user/notice/cancel/${this.data.notice.notice_id}`,
        data,
        this.params,
        (res) => {
          this.saveSpinner = false
          if (res.success) {
            this.dialog.close({ event: "success" });
          } else {
            this.utils.openToast(res.message,{});
          }
        }
      );
    }
  }
}

<div class="min_height">
<mat-spinner *ngIf="loading" class="spinner" [diameter]="50"></mat-spinner>
  <div class="container" *ngIf="!loading">
    <div style="margin-top: 120px" class="container" *ngIf="data">
      <div class="invite_container">
        <div class="logo_holder pull-right">
          <div class=""></div>
          <img src="./assets/newLogo/newLogoText.svg" />
        </div>
        <div class="invite_content">
          <h2 class="ft_500 ft_18 mb_10">Open Request</h2>
          <p
            *ngIf="data.status === 'pending'"
            class="ft_400 sub_content_color ft_14 mb_25"
          >
            Service Pro shared a open request with you. Please check and confirm
            !
          </p>
          <p
            *ngIf="data.status !== 'completed' && data.status !== 'pending'"
            class="ft_400 sub_content_color ft_14 mb_25"
          >
            Request Details
          </p>
          <p
            *ngIf="data.status === 'completed'"
            class="ft_400 sub_content_color ft_14 mb_25"
          >
            Your Service Pro has sent you a payment request.
          </p>
          <div class="prperty_details">
            <div class="property_content">
              <div class="property_data">
                <h2 class="mb_12 ft_16 ft_500">
                  Property
                  <!-- {{ data.property.name }} -->
                </h2>
                <div class="d_flex_align">
                  <p class="sub_content_color ft_13 ft_400">
                    {{ data.property.name }}
                  </p>
                </div>
              </div>
              <div class="property_data" *ngIf="data.status !== 'pending'">
                <h2 class="mb_12 ft_16 ft_500">
                  Customer
                  <!-- {{ data.property.name }} -->
                </h2>
                <div class="d_flex_align">
                  <p class="sub_content_color capitalize ft_13 ft_400">
                    {{ requestData?.user?.name?.first }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="host_details contractor_div">
            <div class="d_flex_space_align mb_20 check_in">
              <h2 class="_m ft_500 ft_16">Service Pro Profile</h2>
            </div>
            <div class="d_flex_align">
              <div class="host_image">
                <img
                  [src]="
                    data?.contractor?.image
                      ? utils.imageFormat(80, 80, data.contractor.image)
                      : '../../assets/newIcons/displayUser.png'
                  "
                  alt="image"
                />
              </div>
              <div class="ft_13 ft_500">
                <h2 class="ft_13">{{ data.contractor.name }}</h2>

                <div class="d_flex_space_align cont_detail">
                  <div class="d_flex_align mr_15">
                    <img class="mr_10" src="../../assets/newIcons/call.svg" />
                    <p class="_m ft_12 sub_content_color">
                      {{ data.contractor.phone.national_number }}
                    </p>
                  </div>
                  <div class="d_flex_align">
                    <img
                      class="mr_10"
                      src="../../assets/newIcons/envelope.svg"
                    />
                    <p class="_m ft_12 sub_content_color">
                      {{ data.contractor.email }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="host_desc mb_30 mt_30">
              <h2 class="ft_16 mb_24 ft_500">Request Details</h2>
              <div class="req_box mb_18">
                <p class="ft_14 ft_500 heading">Issue</p>

                <p class="ft_14 ft_500 req_box_content">
                  {{ data.issues.join(", ") }}
                </p>
              </div>

              <div class="req_box mb_18" *ngIf="data.status !== 'completed'">
                <p class="ft_14 ft_500 heading">Scheduled On</p>

                <p class="ft_14 ft_500 req_box_content">{{ schedule_date }}</p>
              </div>
              <div class="req_box mb_18" *ngIf="data.status === 'completed'">
                <p class="ft_14 ft_500 heading">Completed On</p>

                <p class="ft_14 ft_500 req_box_content">{{ completed_date }}</p>
              </div>
              <div class="req_box mb_18" *ngIf="data.status !== 'completed'">
                <p class="ft_14 ft_500 heading">Approximate Cost</p>

                <p class="ft_14 ft_500 req_box_content">
                  {{ this.utils.displayPrice(data.approximate_amount) }}
                </p>
              </div>
              <div class="req_box mb_18" *ngIf="data.status === 'completed'">
                <p class="ft_14 ft_500 heading">Total Cost</p>

                <p class="ft_14 ft_500 req_box_content">
                  {{ this.utils.displayPrice(data.amount) }}
                </p>
              </div>
              <div class="req_box mb_18" *ngIf="data.invoice">
                <p class="ft_14 ft_500 heading">Invoice Status</p>

                <p
                  class="ft_14 ft_500 capitalize req_box_content"
                  [ngClass]="
                    data.invoice.status === 'paid'
                      ? 'status_paid'
                      : data.invoice.status === 'pending'
                      ? 'status_cancelled'
                      : 'status_processing'
                  "
                >
                  {{ data.invoice.status ? data.invoice.status : "-" }}
                </p>
              </div>
              <div class="req_box mb_18">
                <p class="ft_14 ft_500 heading">Issue Description</p>

                <p class="ft_14 ft_500 req_box_content">
                  {{ data.desc ? data.desc : "-" }}
                </p>
              </div>
            </div>

            <div *ngIf="data.status === 'pending'">
              <h2 class="ft_16 mb_24 ft_500">Enter Your Details</h2>
              <div class="input_container mb_20">
                <div class="col-lg-4 col-sm-12">
                  <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                    Name*
                  </p>
                  <input class="accept_input" [(ngModel)]="name" />
                </div>
                <div class="custom_width">
                  <div class="relative">
                    <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                      Priority*
                    </p>
                    <div
                      class="select_div cursor_pointer ft_family ft_14 ft_400 sub_content_color"
                      (click)="openBox()"
                    >
                      {{ selected_problem == "" ? "Select" : selected_problem }}
                      <i class="fa fa-angle-down arrow"></i>
                    </div>
                    <div
                      class="selected_option_div cursor_pointer"
                      *ngIf="toggleSelect"
                    >
                      <div
                        class="select_customized_options cursor_pointer"
                        (click)="selectedOption('High')"
                      >
                        High
                      </div>
                      <div
                        class="select_customized_options cursor_pointer"
                        (click)="selectedOption('Medium')"
                      >
                        Medium
                      </div>
                      <div
                        class="select_customized_options cursor_pointer"
                        (click)="selectedOption('Low')"
                      >
                        Low
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input_container">
                <div class="col-lg-4 col-sm-12">
                  <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                    Issue From*
                  </p>
                  <mat-form-field
                    floatLabel="never"
                    appearance="outline"
                    class="check-in-out-mat-form date-picker w_100"
                  >
                    <input
                      id="checkIn"
                      class="dp-input"
                      matInput
                      readonly
                      [matDatepicker]="checkin"
                      [(ngModel)]="issue_from"
                      placeholder="Select Date"
                      [min]="minDate"
                      [max]="maxDate"
                      (ngModelChange)="changeCheckDate($event)"
                    />
                    <mat-datepicker #checkin></mat-datepicker>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="checkin"
                    ></mat-datepicker-toggle>
                  </mat-form-field>
                </div>
                <div class="otp_input">
                  <div class="mb_20">
                    <form (ngSubmit)="onSubmit()" [formGroup]="form">
                      <p class="ft_family ft_400 ft_14 mb_14 sub_content_color">
                        Request OTP*
                      </p>
                      <div class="d_flex">
                        <div
                          class=""
                          *ngFor="let input of formInput; index as i"
                        >
                          <input
                            #formRow
                            type="text"
                            pattern="[0-9]+"
                            maxlength="1"
                            formControlName="{{ input.key }}"
                            class="form-control mr_15 input_otp"
                            (oninput)="restrictEvent(i)"
                            [(ngModel)]="input.value"
                            (keyup)="keyUpEvent($event, i)"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="mt_20 d_flex">
                <button
                  mat-stroked-button
                  class="secondary_stroked_button mr_15"
                  (click)="redirect()"
                >
                  Reject
                </button>
                <button
                  class="primary_common_button d_flex"
                  mat-flat-button
                  (click)="onSubmit()"
                >
                  <div class="button_spinner" *ngIf="button_spin">
                    <mat-spinner class="mr_10" [diameter]="20"></mat-spinner>
                  </div>

                  Accept
                </button>
              </div>
            </div>
            <div *ngIf="data.status === 'accepted'">
              <p class="ft_500 ft_16 mb_20">TimeLine</p>
              <div class="d_flex timeline_box">
                <div
                  class="timeline_container"
                  *ngFor="let item of timeLine; let i = index"
                >
                  <div class="relative time_div">
                    <p
                      class="mb_10 ft_12 capitalize timeline_header sub_content_color"
                    >
                      {{
                        item.status === "in_progress"
                          ? "In Progress"
                          : item.status
                      }}
                    </p>
                    <div
                      class="round"
                      [ngClass]="
                        item.is_active ? 'active_round' : 'inactive_round'
                      "
                    ></div>

                    <div
                      [ngClass]="
                        item.is_active && timeLine[i + 1]?.is_active
                          ? 'active_round'
                          : ''
                      "
                      class="line last_child_line"
                    ></div>
                    <p class="ft_12 show_time sub_content_color mt_10">
                      {{ getTimeFormat(item.date) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d_flex_align_justify"
              *ngIf="
                (data.status === 'completed' || data.status === 'closed') &&
                data.invoice.status === 'created'
              "
            >
              <button
                class="primary_common_button pay_button"
                mat-flat-button
                (click)="onPay()"
              >
                Pay Now
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

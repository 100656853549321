import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HomeComponent } from "./components/home/home.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { PropertyDetailComponent } from "./property-detail/property-detail.component";
import { PropertySearchComponent } from "./property-search/property-search.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { PaymentCheckoutComponent } from "./payment-checkout/payment-checkout.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { ParknstaysPolicyComponent } from "./parknstays-policy/parknstays-policy.component";
import { CustomerInviteComponent } from "./customer-invite/customer-invite.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { UnauthGuard } from "./unauth.guard";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { ToastMessageComponent } from "./toast-message/toast-message.component";
import { AcceptRequestComponent } from "./accept-request/accept-request.component";
import { CheckListComponent } from "./check-list/check-list.component";
import { RentalApplicationComponent } from "./components/back-link-pages/rental-application/rental-application.component";
const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
        pathMatch: "full",
      },
      {
        path: "search",
        component: PropertySearchComponent,
      },
      {
        path: "checkout",
        component: PaymentCheckoutComponent,
      },
      {
        path: "terms_condtions",
        component: TermsAndConditionsComponent,
      },
      {
        path: "privacy_policy",
        component: ParknstaysPolicyComponent,
      },
      {
        path: "detail/:id",
        component: PropertyDetailComponent,
      },

      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "check_list/:id",
        component: CheckListComponent,
        // canActivate: [AuthGuard],
      },
      {
        path: "profile/:response",
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "open_request",
        component: AcceptRequestComponent,
      },
      {
        path: "invite/:id",
        component: CustomerInviteComponent,
      },
      // { path: "rental_application", component: RentalApplicationComponent }
    ],
  },
  {
    path: "reset_password/:token",
    component: ForgotPasswordComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: "verify_email",
    component: VerifyEmailComponent,
  },
  { path: "toast", component: ToastMessageComponent },
  { path: "404", component: PageNotFoundComponent },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

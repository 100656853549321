import { Component, OnInit, Input, Output, HostListener } from "@angular/core";
import { ApicallService } from "../apicall.service";
import { Router } from "@angular/router";
import * as moment from "moment";
@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.css"],
})
export class SearchBarComponent implements OnInit {
  public rental_type: any = "by_room";
  public location: string = "";
  public lat: string = "";
  public lng: string = "";
  public check_in: any;
  public checkOut: any;
  @Output() location_name;
  queryPrams;
  width: number = window.innerWidth;
  public locationArray: Array<any> = [];
  @Input() pageName;
  show_options = false;
  @Input() getParams: any;
  @Input() params: any;
  minDate = new Date();
  maxDate;
  constructor(private router: Router, private url: ApicallService) {}
  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    this.showOptions(2, event);
    event.stopPropagation();
  }
  ngOnInit(): void {
    this.maxDate = moment(this.minDate).add(3, "M");
    this.maxDate = new Date(this.maxDate);
    if (this.params) {
      if (this.params.check_in) {
        this.check_in = this.params.check_in;
      }
      if (this.params.check_out) {
        this.checkOut = this.params.check_out;
      }
      if (this.params.rental_type) {
        this.rental_type = this.params.rental_type;
      }
      if (this.params.location_name) {
        this.location_name = this.params.location_name;
      }
    }
  }
  ngAfterViewInit() {
    let location = document.getElementById("location");
    window.addEventListener("click", (event: any) => {
      location.style.display = "none";
    });
  }
  ngOnDestroy() {}
  showOptions(id, event) {
    if (id === 1) {
      this.show_options = true;
      event.stopPropagation();
    } else {
      this.show_options = false;
    }
  }
  rentalType(value) {
    this.rental_type = value;
    this.show_options = false;
  }
  getLocation(item) {
    let placeId = item.place_id;
    let location = document.getElementById("location");
    this.url.get(`common/place/${placeId}`, this.queryPrams, (res) => {
      let data = res.data.place.result.geometry.location;
      this.location_name = res.data.place.result.formatted_address;
      this.lat = data.lat;
      this.lng = data.lng;
      location.style.display = "none";
    });
  }
  locationChange() {
    let location = document.getElementById("location");
    if (this.location_name.length > 0) {
      this.url.get(
        `common/search?input=${this.location_name}`,
        this.queryPrams,
        (res) => {
          this.locationArray = res.data.place.predictions;
        }
      );

      location.style.display = "block";
    } else {
      location.style.display = "none";
    }
  }
  async filterProperty() {
    let checkIn;

    let loc = [];
    let checkOut;

    let queryPrams: any = {};

    if (this.check_in) {
      checkIn = moment(this.check_in).format("YYYY-MM-DD");
      queryPrams["check_in"] = checkIn;
    }

    if (this.checkOut) {
      checkOut = moment(this.checkOut).format("YYYY-MM-DD");
      queryPrams["check_out"] = checkOut;
    }
    if (this.lat) {
      queryPrams["loc"] = `${this.lat},${this.lng}`;
    }

    if (this.rental_type) {
      queryPrams["rental_type"] = this.rental_type;
    }
    if (this.location_name) {
      queryPrams["location_name"] = this.location_name;
    }

    await this.router.navigate([`search`], { queryParams: queryPrams });
    if (this.getParams) this.getParams(queryPrams);
  }
  rentalChange(e) {
    this.rental_type = e;
    if (e === "entire_home") {
      this.checkOut = "";
    }
  }
}

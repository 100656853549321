<div class="home_page_main_container">
  <div class="image_container">
    <div
      id="cookie_container"
      [ngClass]="show_cookie ? ' cookie_div' : 'hide_div'"
    >
      <div class="cookie_container">
        <div class="mb_20">
          <img src="../../../assets/newIcons/cookie_logo.svg" />
        </div>
        <h2 class="ft_family ft_16 ft_500 mb_20">Cookies Disclaimer</h2>
        <p class="ft_family ft_16 sub_content_color mb_20">
          Parknstays uses cookies to ensure you get the best experience on our
          website
        </p>
        <p
          class="sub_text_green align_right cursor_pointer"
          style="padding-right: 8px"
          (click)="allowCookies()"
        >
          Okay
        </p>
      </div>
    </div>
    <div class="image_div">
      <div class="content_div">
        <div class="park mb_30">ALL YOUR PROPERTY UNDER ONE ROOF</div>
        <div class="d_flex_align_justify">
          <p class="discover ft_20 mr_15">Start Booking With Parknstay</p>
          <img
            alt="icon"
            src="../../../assets/newIcons/Arrow.svg"
            class="arrow"
          />
        </div>
      </div>
      <img src="../../../assets/images/homeBanner.png" alt="banner" />
      <div class="search_box_holder">
        <app-search-bar></app-search-bar>
      </div>
    </div>
  </div>
  <div class="home_content_container">
    <div class="rooms_list_container mb_120">
      <div class="third_div_parent">
        <div class="collage_container">
          <img
            class="relative_css collage_image"
            src="../../../assets/images/collage.png"
          />
          <img
            class="collage_dots"
            src="../../../assets/images/collage_dots.svg"
          />
        </div>
        <div class="collage_content">
          <h2 class="common_home_header mb_40 align_left mt_15">
            Why Choose<span> Parknstay?</span>
          </h2>
          <p class="ft_family collage_content_para">
            If you own or manage real estate, chances are you use some type of
            software to help out. For long-term rentals, you probably use one
            site and for short-term rentals you might use another. When you want
            to post a vacancy, you might use another still.  Then, when it comes
            to tracking upkeep and maintenance records, you might scribble down
            random information in a notepad or on your phone. For your own home,
            chances are that you keep very few notes at all. <br />
            <br />That’s why Parknstays is a game-changer. For property owners
            and property management companies alike, it allows you to keep all
            your real estate information on one, secure cloud-based portal. For
            potential renters, it’s a comprehensive resource to find safe,
            stylish long, medium, and short term rentals with all essential
            information such as WiFi, utilities, leases, and even furniture
            types included. Parknstays does all the heavy lifting so you don’t
            have to. 
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="site_details_container" *ngIf="response">
    <div class="home_content_container">
      <div class="site_div">
        <div class="individual_site_detail">
          <img
            class="mb_40"
            src="../../../assets/newIcons/properties.svg"
            alt="icon"
          />
          <p class="ft_family ft_30 ft_600 mb_20">{{ response.property }} +</p>
          <p class="sub_content_color ft_500 ft_20">Properties</p>
        </div>
        <div class="individual_site_detail">
          <img
            class="mb_40"
            src="../../../assets/newIcons/user.svg"
            alt="icon"
          />
          <p class="ft_family ft_30 ft_600 mb_20">{{ response.user }} +</p>
          <p class="sub_content_color ft_500 ft_20">Customers</p>
        </div>
        <div class="individual_site_detail">
          <img
            class="mb_40"
            src="../../../assets/newIcons/rooms.svg"
            alt="icon"
          />
          <p class="ft_family ft_30 ft_600 mb_20">{{ response.room }} +</p>
          <p class="sub_content_color ft_500 ft_20">Rooms</p>
        </div>
        <div class="individual_site_detail">
          <img
            class="mb_40"
            src="../../../assets/newIcons/host.svg"
            alt="icon"
          />
          <p class="ft_family ft_30 ft_600 mb_20">{{ response.host }} +</p>
          <p class="sub_content_color ft_500 ft_20">Hosts</p>
        </div>
      </div>
    </div>
  </div>
  <div class="renters_parent_div">
    <img class="renter_right" src="../../../assets/newIcons/renter_right.svg" />
    <img class="renter_left" src="../../../assets/newIcons/renter_left.svg" />
    <div class="home_content_container">
      <h2 class="common_home_header mb_30 mt_40 letter_spacing_5">
        <span>Rent</span>ers
      </h2>
      <p class="ft_faamily mb_100 align_center ft_18 tenant_desc relative_css">
        ParknStays has a smart cloud-based property management system that is
        fully <br />
        customizable and helps you to maintain your property.
        <img
          class="left_arrow_renter"
          src="../../../assets/newIcons/renter_left_arrow.svg"
        />
        <img
          class="right_arrow_renter"
          src="../../../assets/newIcons/renter_right_arrow.svg"
        />
      </p>
      <div class="tenant_flex_div">
        <div class="common_tenant_div left_tenant">
          <div class="d_flex_align_justify fixed_height_image_div">
            <img src="../../../assets/images/left_tenant.svg" />
          </div>
          <h3 class="ft_family ft_600">
            Impeccable, comfortable <br />
            homes for every budget
          </h3>
          <p class="ft_family ft_400 ft_16">
            Whether you want a vacation home in Montauk or a long-term lease in
            Marin County, we’ve carefully curated a selection of sumptuous,
            fully furnished, and unfurnished contemporary homes that are ready
            for you to call your own.
          </p>
        </div>
        <div class="common_tenant_div relative_css tenant_hide_dots">
          <div class="right_tenant">
            <div class="d_flex_align_justify fixed_height_image_div">
              <img src="../../../assets/images/right_tenant.svg" />
            </div>
            <h3 class="ft_family ft_600">
              True Value, Comfortable<br />
              Homes
            </h3>
            <p class="ft_family ft_400 ft_16">
              We offer tenants a choice that suits them, from stylish co-living
              to spacious single homes. Our co-living spaces come with bi-weekly
              cleanings, spacious kitchens, and convenient workspaces that make
              it affordable to live in style wherever you want.
            </p>
          </div>
          <img
            class="renter_right_dot _m"
            src="../../../assets/newIcons/renter_dots.svg"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="homepage_tab_view relative_css">
    <div class="tabs_container">
      <a
        [ngClass]="tabValue === '1' ? 'active_home_tab' : 'inactive_home_tab'"
        class="cursor_pointer"
        (click)="activeTab('1')"
        >Property Managers</a
      >
      <span>/</span>
      <a
        [ngClass]="tabValue === '2' ? 'active_home_tab' : 'inactive_home_tab'"
        class="cursor_pointer"
        (click)="activeTab('2')"
        >Landlords</a
      ><span>/</span>
      <a
        [ngClass]="tabValue === '3' ? 'active_home_tab' : 'inactive_home_tab'"
        class="cursor_pointer"
        (click)="activeTab('3')"
        >Home Service Pros</a
      >
      <img class="tab_circle" src="../../../assets/newIcons/home_circle.svg" />
      <img
        class="tab_rectangle"
        src="../../../assets/newIcons/tab_rectangle.png"
      />
    </div>
    <div class="tab_content_div">
      <div class="fade-in-div">
        <div class="tab_one">
          <div
            class="individual_tab_data fade_effect"
            *ngFor="let item of roleArray"
          >
            <img class="tab_image_absolute" [src]="item.image" />
            <div class="tab_text mt_30">
              <h2 class="ft_family ft_18 ft_600">{{ item.header }}</h2>
              <p class="ft_family ft_15 ft_400">
                {{ item.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="d_flex_align_justify">
        <button
          mat-raised-button
          class="host-button ft_family ft_500"
          (click)="redirect()"
        >
          JOIN NOW
        </button>
      </div>
    </div>
  </div>
  <div class="home_content_container">
    <div class="become_host_div">
      <div></div>
      <h2 class="ft_500 ft_family ft_30 mb_25">Become a Host</h2>
      <h3 class="ft_15 ft_family ft_400 mb_60">
        Earn extra income and unlock new oppurtunities by sharing your space
      </h3>
      <div class="host_div_icons_div">
        <div class="host_images">
          <img
            class="host_1"
            src="../../../assets/images/host-1.svg"
            alt="host_images"
          />
          <img
            class="host_2"
            src="../../../assets/images/host_2.svg"
            alt="host_images"
          />
          <img
            class="host_3"
            src="../../../assets/images/host_3.svg"
            alt="host_images"
          />
          <img
            class="host_4"
            src="../../../assets/images/host_4.svg"
            alt="host_images"
          />
          <img
            class="host_5"
            src="../../../assets/images/host_5.svg"
            alt="host_images"
          />
          <img
            class="host_6"
            src="../../../assets/images/host_6.svg"
            alt="host_images"
          />
          <img
            class="host_7"
            src="../../../assets/images/host_7.svg"
            alt="host_images"
          />
        </div>

        <div class="first_half_host">
          <div class="d_flex_align mb_30">
            <div class="host_icon_holder">
              <img
                src="../../../assets/newIcons/host_verified.svg"
                alt="host_images"
              />
            </div>
            <p class="ft_family ft_500 ft_15">Verified Rentals</p>
          </div>
          <div class="d_flex_align">
            <div class="host_icon_holder">
              <img src="../../../assets/newIcons/more.svg" alt="host_images" />
            </div>
            <p class="ft_family ft_500 ft_15">More For Less</p>
          </div>
        </div>
        <div class="second_half_host">
          <div class="d_flex_align mb_30">
            <div class="host_icon_holder">
              <img src="../../../assets/newIcons/peace.svg" alt="host_images" />
            </div>
            <p class="ft_family ft_500 ft_15">Peace of mind</p>
          </div>
          <div class="d_flex_align">
            <div class="host_icon_holder">
              <img src="../../../assets/newIcons/brand.svg" alt="host_images" />
            </div>
            <p class="ft_family ft_500 ft_15">A Brand you can Trust</p>
          </div>
        </div>
      </div>
      <div class="host_button">
        <button
          mat-raised-button
          class="host-button ft_family ft_500"
          (click)="host()"
        >
          JOIN NOW
        </button>
      </div>
    </div>
  </div>
  <div class="co_living mb_120">
    <h2 class="common_home_header relative_css">
      <span>Co-Living & Furnished </span> Rentals
      <img
        class="co_living_triangle"
        src="../../../assets/newIcons/tab_triangle.png"
      />
    </h2>
    <div class="co_living_content_container">
      <div class="co_living_individual_container">
        <div class="co_living_image_holder">
          <img src="../../../assets/newIcons/home_comfort.svg" />
        </div>

        <h2 class="ft_family ft_600 ft_24">Classy Comfort</h2>
        <p class="ft_family">
          From the comforter to the couch, the box springs to the bath towels,
          our homes have all the sumptuous items that make relaxation mandatory.
        </p>
      </div>
      <div class="co_living_individual_container">
        <div class="co_living_image_holder">
          <img src="../../../assets/newIcons/home_friendly.svg" />
        </div>

        <h2 class="ft_family ft_600 ft_24">Move-in-Friendly</h2>
        <p class="ft_family">
          Move in, start living! Our rentals come fully stocked with dishes and
          kitchen essentials including coffee makers as well as vacuum cleaners
          and washers and dryers.
        </p>
      </div>
      <div class="co_living_individual_container">
        <div class="co_living_image_holder">
          <img src="../../../assets/newIcons/home_secure.svg" />
        </div>

        <h2 class="ft_family ft_600 ft_24">Supremely Secure</h2>
        <p class="ft_family">
          Our homes meet the CDC standards and are fitted with smoke, fire, and
          Co2 detectors, are meticulously cleaned, and offer secure
          self-check-ins
        </p>
      </div>
    </div>
  </div>
  <div class="feedback_container mb_120">
    <div class="feedback_div">
      <div class="feedback_left_div">
        <img src="../../../assets/images/feedback_image.svg" />
      </div>
      <div class="feedback_right_div">
        <h2 class="common_home_header relative_css align_left mb_0">
          <span>TESTI</span>MONIALS
          <img
            class="feedback_plus"
            src="../../../assets/newIcons/plus_icon.svg"
          />
        </h2>
        <div class="carousel_container">
          <carousel
            class="list_carousel"
            [loop]="true"
            [arrowsOutside]="false"
            [dots]="false"
            [cellsToShow]="1"
            [arrows]="false"
          >
            <!-- [arrowsOutside]="width > 480 ? true : false" -->
            <!-- <div class="carousel-cell carousel_cell">
              <div class="relative_css">
                <img
                  class="quote left_quote"
                  src="../../../assets/newIcons/quotes_feedback.svg"
                />
                <p class="pl_20 relative_css ft_family">
                  Evolve made us feel more secure in booking. They were fabulous
                  in communicating from start to finish and there for us if we
                  them for anything.
                </p>
                <img
                  class="quote right_quote"
                  src="../../../assets/newIcons/quotes_feedback.svg"
                />
              </div>
              <div class="feedback_person_div">
                <div class="feedback_by_div relative_css">
                  <div class="feedback_person_image">
                    <img src="../../../assets/images/host_4.svg" />
                  </div>
                  <div>
                    <h2 class="ft_family">Maria James</h2>
                    <p class="ft_family">Ceo, Founder</p>
                  </div>
                  <img
                    class="feedback_triangle"
                    src="../../../assets/newIcons/tab_triangle.png"
                  />
                </div>
              </div>
            </div> -->
            <div class="carousel-cell carousel_cell">
              <div class="relative_css">
                <img
                  class="quote left_quote"
                  src="../../../assets/newIcons/quotes_feedback.svg"
                />
                <p class="pl_20 relative_css ft_family">
                  Parknstays made us feel more secure in booking. They were fabulous
                  in communicating from start to finish and there for us if we need
                  them for anything.
                </p>
                <img
                  class="quote right_quote"
                  src="../../../assets/newIcons/quotes_feedback.svg"
                />
              </div>
              <div class="feedback_person_div">
                <div class="feedback_by_div relative_css">
                  <div class="feedback_person_image">
                    <img src="../../../assets/images/host_4.svg" />
                  </div>
                  <div>
                    <h2 class="ft_family mb_0">Maria James</h2>
                  </div>
                  <img
                    class="feedback_triangle"
                    src="../../../assets/newIcons/tab_triangle.png"
                  />
                </div>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</div>

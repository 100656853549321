import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: "app-document-status",
  templateUrl: "./document-status.component.html",
  styleUrls: ["./document-status.component.css"],
})
export class DocumentStatusComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<DocumentStatusComponent>
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.dialog.close();
    }, 3000);
  }
}

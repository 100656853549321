import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  SocialLoginModule,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
@NgModule({
  declarations: [],
  imports: [CommonModule, SocialLoginModule],
  providers: [
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "350482334730-k0rnn4tpflr1jaoeo1im1emjkiu1omsv.apps.googleusercontent.com"
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("853738586025001"),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
})
export class LoginSocialModule {}

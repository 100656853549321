<div class="container">
  <h2 class="sub_text_green ft_500 ft_family ft_20 mb_30">Reject Request</h2>
  <div class="data_container">
    <div class="text_area_div mb_30">
      <h2 class="ft_family ft_400 ft_16 mb_20">Mention your Reason*</h2>
      <textarea class="text_area" [(ngModel)]="reason"></textarea>
    </div>
    <div class="otp_input">
      <div class="mb_20">
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
          <p class="ft_family ft_400 ft_14 mb_14">
            Please enter the OTP sent to your mail*
          </p>
          <div class="d_flex">
            <div class="" *ngFor="let input of formInput; index as i">
              <input
                #formRow
                type="text"
                pattern="[0-9]+"
                maxlength="1"
                formControlName="{{ input.key }}"
                class="form-control mr_15 input_otp"
                (oninput)="restrictEvent(i)"
                [(ngModel)]="input.value"
                (keyup)="keyUpEvent($event, i)"
              />
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="buttons_div d_flex_align">
      <button mat-flat-button class="common_button mr_20" (click)="onSubmit()">
        Submit
      </button>
      <button
        mat-stroked-button
        class="cancel_button mr_20"
        (click)="this.dialog.close()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>

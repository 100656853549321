<!-- <div *ngIf="loading" class="loader_div">
  <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
</div>
<div class="container"> -->
<div class="min_height">
  <div style="margin-top: 120px" class="container" *ngIf="data">
    <!-- *ngIf="data" -->
    <div class="invite_container">
      <div class="d_flex_space_align_start">
        <div>
          <h2 class="ft_500 ft_20 mb_20"> list</h2>
        </div>

        <img class="mt-2" src="./assets/newLogo/newLogoText.svg" />
      </div>
      <div class="property_detail_div">
        <div class="property_image" *ngIf="data.property.gallery">
          <div class="property_image_div">
            <img
              [src]="
                utils.imageFormat(120, 100, data.property.gallery[0], 'c_fill')
              "
              alt="image" />
          </div>
        </div>
        <div>
          <p class="ft_13 ft_400 mb_5">
            {{ data.booking.user.name?.first }}
            {{ data.booking.user.name?.last }}
          </p>
          <p class="sub_content_color ft_13 ft_500 mb_5">
            {{ data.booking.property.name }}
          </p>
          <p class="ft_13 ft_400">
            {{ data.booking.property.address.line_1 }}
            {{ data.booking.property.address.city.name }}
            {{ data.booking.property.address.state.name }}
            {{ data.booking.property.address.zip }}
          </p>
        </div>
      </div>
      <div class="invite_content">
        <div class="prperty_details mb_20">
          <div class="property_content">
            <div class="property_data">
              <p class="sub_content_color ft_13 ft_400 mb_10">Booking Date</p>
              <p class="ft_14">
                {{ this.utils.defaultDate(data.booking.createdAt) }}
              </p>
            </div>
            <div class="property_data">
              <p class="sub_content_color ft_13 ft_400 mb_10">Check - In</p>
              <p class="ft_14">
                {{ this.utils.defaultDate(data.booking.check_in) }}
              </p>
            </div>
            <div class="property_data">
              <p class="sub_content_color ft_13 ft_400 mb_10">Check - Out</p>
              <p class="ft_14">
                {{ this.utils.defaultDate(data.booking.check_out) }}
              </p>
            </div>
          </div>
        </div>
        <div class="host_details">
          <div
            class="static_height_container mb_20"
            *ngIf="check_list_array?.length > 0">
            <div class="d_flex_space_align mb_20 check_in">
              <h2 class="_m ft_500 ft_16">Check List</h2>
            </div>
            <div
              class="check_box_container mb_10"
              *ngFor="let item of check_list_array; index as i">
              <mat-checkbox
                class="checkbox mr_15  ft_family ft_400"
                [checked]="item.is_enabled"
                (change)="check_list_array[i].is_enabled = !item.is_enabled"></mat-checkbox>
              <p class="ft_family ft_400">
                {{ item.content }}
              </p>
            </div>
          </div>
          <div>
            <h2 class="_m ft_500 ft_16 mb_20">Images</h2>
            <div class="image_container mb_30">
              <div
                class="uploaded_img"
                *ngFor="let image of imageArray; let i = index"
              >
                <img
                  class="uploaded_img"
                  [src]="this.utils.imageFormat(120, 130, image, 'c_fill')"
                  alt="image"
                />
                <img
                  class="remove_icon cursor_pointer"
                  src="../../../../../assets/newIcons/remov.svg"
                  (click)="removeImage(image, i)"
                />
              </div>
              <div class="mat_spinner" *ngIf="imageLoading">
                <mat-spinner class="upload_loader" diameter="20"></mat-spinner>
              </div>
              <div class="upload_button" *ngIf="imageArray.length < 5">
                <img
                  src="../../../../../assets/newIcons/addImage.svg"
                  class="mb_10"
                />
                <p style="color: #c4c4c4" class="ft_family ft_500 ft_15">Add</p>
                <input
                  class="cursor_pointer upload"
                  type="file"
                  (change)="upload($event)"
                  accept="image/png,image/jpeg"
                />
              </div>
            </div>
          </div>
          <div class="host_desc mb_30 mt_30">
            <h2 class="ft_18 ft_500 mb_30">Review</h2>
            <div class="d_flex_space_align review_container">
              <div>
                <div class="star_rating_review">
                  <p class="mb_30 ft_family ft_18 ft_500 align_center">
                    Rate your Experience
                  </p>
                  <div class="star_rating_review mb_20">
                    <star-rating
                      [value]="write_rating_value"
                      [totalstars]="5"
                      checkedcolor="#077E8B"
                      uncheckedcolor="#e1e1e1"
                      size="42px"
                      space="between"
                      class="star_rating"
                      readonly="false"
                      (rate)="onRate($event)"></star-rating>
                  </div>
                </div>
                <textarea
                  class="text_area ft_13 ft_400"
                  placeholder="Type comments here..."
                  maxlength="300"
                  [(ngModel)]="user_comment"></textarea>
              </div>
              <div class="individual_slider_parent">
                <div class="individual_slider">
                  <h2 class="ft_family ft_14 ft_400">Clean</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="rating_obj.clean"
                    [options]="options"></ngx-slider>
                  <p class="ft_14 ft_400">{{ rating_obj.clean }}</p>
                </div>
                <div class="individual_slider">
                  <h2 class="ft_family ft_14 ft_400">Communication</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="rating_obj.communication"
                    [options]="options"></ngx-slider>
                  <p class="ft_14 ft_400">{{ rating_obj.communication }}</p>
                </div>
                <div class="individual_slider">
                  <h2 class="ft_family ft_14 ft_400">Location</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="rating_obj.location"
                    [options]="options"></ngx-slider>
                  <p class="ft_14 ft_400">{{ rating_obj.location }}</p>
                </div>
                <div class="individual_slider">
                  <h2 class="ft_family ft_14 ft_400">Rooms</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="rating_obj.rooms"
                    [options]="options"></ngx-slider>
                  <p class="ft_14 ft_400">{{ rating_obj.rooms }}</p>
                </div>
                <div class="individual_slider">
                  <h2 class="ft_family ft_14 ft_400">Value</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="rating_obj.value"
                    [options]="options"></ngx-slider>
                  <p class="ft_14 ft_400">{{ rating_obj.value }}</p>
                </div>
                <div class="individual_slider">
                  <h2 class="ft_family ft_14 ft_400">Service</h2>
                  <ngx-slider
                    class="slider"
                    [(value)]="rating_obj.service"
                    [options]="options"></ngx-slider>
                  <p class="ft_14 ft_400">{{ rating_obj.service }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="otp_content mt_10">
            <div class="d_flex">
              <button
                mat-stroked-button
                class="secondary_stroked_button mr_15"
                (click)="redirect()">
                Do it Later
              </button>
              <button
                class="primary_common_button wrapper_button d_flex"
                mat-flat-button
                (click)="submit()">
                <div class="button_spinner" *ngIf="button_spin">
                  <mat-spinner class="mr_10" [diameter]="20"></mat-spinner>
                </div>

                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login100-form">
        <p class="login100-form-title ft_family ft_20 ft_500 mb_20">
          Change Password
        </p>
        <div class="row mb_20">
          <div class="input_container mb_20 width_specifier">
            <p class="field_heading ft_family ft_14 ft_400">
              Current Password*
            </p>
            <div class="relative">
              <input
                name="oldPassword"
                matInput
                [type]="oldHide ? 'password' : 'text'"
                [(ngModel)]="oldPassword"
              />
              <div class="pass-icon" (click)="oldHide = !oldHide">
                <img
                  src="../../../../assets/newIcons/show.svg"
                  alt=""
                  *ngIf="oldHide"
                />
                <img
                  src="../../../../assets/newIcons/hide.svg"
                  alt=""
                  *ngIf="!oldHide"
                />
              </div>
            </div>
          </div>
          <div class="input_container mb_20 width_specifier">
            <p class="field_heading ft_family ft_14 ft_400">New Password*</p>
            <div class="relative">
              <input
                name="oldPassword"
                matInput
                [type]="newhide ? 'password' : 'text'"
                [(ngModel)]="newPassword"
              />
              <div class="pass-icon" (click)="newhide = !newhide">
                <img
                  src="../../../../assets/newIcons/show.svg"
                  alt=""
                  *ngIf="newhide"
                />
                <img
                  src="../../../../assets/newIcons/hide.svg"
                  alt=""
                  *ngIf="!newhide"
                />
              </div>
            </div>
          </div>
          <div class="input_container mb_20 width_specifier">
            <p class="field_heading ft_family ft_14 ft_400">
              Confirm Password*
            </p>
            <div class="relative">
              <input
                name="password"
                matInput
                [type]="cnfirmhide ? 'password' : 'text'"
                [(ngModel)]="confirmPassword"
              />
              <div class="pass-icon" (click)="cnfirmhide = !cnfirmhide">
                <img
                  src="../../../../assets/newIcons/show.svg"
                  alt=""
                  *ngIf="cnfirmhide"
                />
                <img
                  src="../../../../assets/newIcons/hide.svg"
                  alt=""
                  *ngIf="!cnfirmhide"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d_flex_align_justify">
          <button
          mat-stroked-button
          class="login100-form-btn mrl_15 cancel mr_15"
          (click)="cancel()"
        >
          Cancel
        </button>
          <button-spinner
            [showSpinner]="saveSpinner"
            childClass="common_button_bg"
            mat-flat-button
            type="submit"
            (click)="submit()"
          >
            Save Changes
          </button-spinner>
       
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
@Component({
  selector: 'app-alert-overlay',
  templateUrl: './alert-overlay.component.html',
  styleUrls: ['./alert-overlay.component.css']
})
export class AlertOverlayComponent implements OnInit {
  title;
  subTitle;
  constructor(
    public dialogRef: MatDialogRef<AlertOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.subTitle = this.data.subTitle;
  }

  cancel(){
    this.dialogRef.close({res:false})
  }

  proceed(){
    this.dialogRef.close({res:true})
  }

}

<div>
  <div class="d_flex_space_align mb_30 res_filters_div">
    <div></div>
    <div>
      <div
        style="display: flex; justify-content: flex-end"
        class="paginator"
        *ngIf="!loading"
      >
        <button
          [disabled]="page <= 1"
          (click)="onPageChange('before')"
          [ngClass]="page <= 1 ? 'inactive_button' : 'active_button'"
        >
          <i class="fa fa-angle-left" style="font-size: 24px"></i>
        </button>
        <button
          (click)="onPageChange('after')"
          [disabled]="deposite_list?.length < limit"
          [ngClass]="
            deposite_list.length < limit ? 'inactive_button' : 'active_button'
          "
        >
          <i class="fa fa-angle-right" style="font-size: 24px"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="table_div">
    <table class="profile_table">
      <tr class="profile_table_header card-header">
        <th style="padding-left: 32px">Property</th>
        <th>Created At</th>
        <th>Overall</th>
        <th>Paid</th>
        <th>Transferred</th>
        <th>Timeline</th>
        <th>History</th>
      </tr>
      <tr
        class="profile_table_border cursor_pointer"
        *ngFor="let item of deposite_list; let i = index"
      >
        <td class="" style="padding-left: 32px">
          {{ item.property?.name }}
        </td>
        <td class="">
          {{ this.utils.defaultDate(item.createdAt) }}
        </td>
        <td class="">
          {{ this.utils.displayPrice(item.amount) }}
        </td>
        <td class="">
          {{ this.utils.displayPrice(item.paid_amount) }}
        </td>
        <td class="">
          {{ this.utils.displayPrice(item.transferred_amount) }}
        </td>
        <td
          class=""
          *ngIf="item.timeline?.length > 0"
          (click)="viewHistory(item.timeline, 'Timeline')"
        >
          View
        </td>
        <td *ngIf="item.timeline?.length === 0">-</td>

        <td
          class=""
          *ngIf="item.history?.length > 0"
          (click)="viewHistory(item.history, 'History')"
        >
          View
        </td>
        <td *ngIf="item.history?.length === 0">-</td>
      </tr>
      <tr class="profile_table_border" *ngIf="deposite_list?.length === 0">
        <td style="padding-left: 32px">No Deposits Found</td>
      </tr>
    </table>
  </div>
</div>

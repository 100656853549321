import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {
  HashLocationStrategy,
  LocationStrategy,
  CommonModule,
} from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HomeComponent } from "./components/home/home.component";
import { PropertySearchComponent } from "./property-search/property-search.component";
import { CookieModule } from "ngx-cookie";
import { SearchBarComponent } from "../app/search-bar/search-bar.component";
// carousel
import { IvyCarouselModule } from "angular-responsive-carousel";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Pagination
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
// material
import { MaterialModule } from "./material.module";
import { LayoutModule } from "@angular/cdk/layout";
import { MatFormFieldModule } from "@angular/material/form-field";

import { CurrencyPipe } from '@angular/common';

//  Component
import { FilterPopupComponent } from "./property-search/popups/filter-popup/filter-popup.component";
import { SortBySheetComponent } from "./property-search/popups/sort-by-sheet/sort-by-sheet.component";
import { PropertyDetailComponent } from "./property-detail/property-detail.component";
import { LoginSignupDialogComponent } from "./components/dashboard/login-signup-dialog/login-signup-dialog.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { PaymentSuccessComponent } from "./components/profile/success-popup/success-popup.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { TransactionsTableComponent } from "./components/profile/components/transactions-table/transactions-table.component";
import { NotificationsListComponent } from "./components/profile/components/notifications-list/notifications-list.component";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { LightboxModule } from "ngx-lightbox";
import { PaymentCheckoutComponent } from "./payment-checkout/payment-checkout.component";
import { GuestInformationPopupComponent } from "./payment-checkout/guest-information-popup/guest-information-popup.component";
import { StatusCheckComponent } from "./payment-checkout/status-check/status-check.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { ParknstaysPolicyComponent } from "./parknstays-policy/parknstays-policy.component";
import { CustomerInviteComponent } from "./customer-invite/customer-invite.component";
import { NgOtpInputModule } from "ng-otp-input";
import { InviteStatusComponent } from "./invite-status/invite-status.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { EmailInviteComponent } from "./components/dashboard/login-signup-dialog/email-invite/email-invite.component";
import { MatTabsModule } from "@angular/material/tabs";
import { ProfileDetailsComponent } from "./components/profile/components/profile-details/profile-details.component";
import { ProfileBookingsComponent } from "./components/profile/components/profile-bookings/profile-bookings.component";
import { ProfileDocumentsComponent } from "./components/profile/components/profile-documents/profile-documents.component";
import { ProfileRequestComponent } from "./components/profile/components/profile-request/profile-request.component";
import { FooterComponent } from "./footer/footer.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { RatingModule } from "ng-starrating";
import { ProfileInvoiceComponent } from "./profile-invoice/profile-invoice.component";
import { NgxShimmerLoadingModule } from "ngx-shimmer-loading";
import { TenderNoticeComponent } from "./components/profile/components/profile-bookings/tender-notice/tender-notice.component";
import { BillComponent } from "./components/profile/components/profile-bookings/bill/bill.component";
import { CancelNoticeComponent } from "./components/profile/components/profile-bookings/cancel-notice/cancel-notice.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { RequestStatusComponent } from "./components/profile/components/profile-request/request-status/request-status.component";
import { DocumentStatusComponent } from "./document-status/document-status.component";
import "@lottiefiles/lottie-player";
import { CancelDocumentComponent } from "./cancel-document/cancel-document.component";
import { LoginSocialModule } from "./login-social/login-social.module";
import { ReviewPopUpComponent } from "./review-pop-up/review-pop-up.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { MapViewComponent } from "./property-search/map-view/map-view.component";
import { MapComponent } from "./map/map.component";
import { AgmCoreModule } from "@agm/core";
import { ToastMessageComponent } from "./toast-message/toast-message.component";
import { MatRadioModule } from "@angular/material/radio";
import { PayMethodComponent } from "./pay-method/pay-method.component";
import { AcceptRequestComponent } from "./accept-request/accept-request.component";
import { RejectRequestComponent } from "./reject-request/reject-request.component";
import { RequestInviteStatusComponent } from "./request-status/request-status.component";
import { OpenRequestPayStatusComponent } from "./open-request-pay-status/open-request-pay-status.component";
import { CheckListComponent } from "./check-list/check-list.component";
import { DepositeHistoryComponent } from "./components/profile/components/profile-bookings/deposite/deposite-history/deposite-history.component";
import { DepositeTypePaymentComponent } from "./components/profile/components/profile-bookings/deposite/deposite-type-payment/deposite-type-payment.component";
import { AccountLinkStatusComponent } from "./components/profile/components/profile-details/accountStatus/account-link-status/account-link-status.component";
import { ProfileDepositeComponent } from "./components/profile/components/profile-deposite/profile-deposite.component";
import { RequestAssignComponent } from "./components/profile/components/profile-request/request-assign/request-assign.component";
import { RecommendContractorComponent } from "./recommend-contractor/recommend-contractor.component";
import { InvitefriendComponent } from "./invitefriend/invitefriend.component";
import { DownloadMobileAppComponent } from './download-mobile-app/download-mobile-app.component';
import { RatingComponentComponent } from './components/profile/components/profile-request/rating-component/rating-component.component';
import { StarRatingComponent } from './components/profile/components/profile-request/rating-component/star-rating/star-rating.component';
import { ButtonSpinnerComponentComponent } from './components/button-spinner-component/button-spinner-component.component';
import { RentalApplicationComponent } from './components/back-link-pages/rental-application/rental-application.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import {DateChecker} from './pipes/checkDate.pipe'
import {CheckRepeatName} from './pipes/checkRepeatName.pipe'
import { AppNoDataComponent } from './components/no-data/app-no-data/app-no-data.component';
import { AlertOverlayComponent } from './components/back-link-pages/rental-application/alert-overlay/alert-overlay.component';
import { ChatComponent } from './components/profile/components/profile-request/chat/chat.component';
import { TextareaAutoresizeDirective } from './components/profile/components/profile-request/chat/textarea-autoresize.directive';
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    PropertySearchComponent,
    FilterPopupComponent,
    SortBySheetComponent,
    PropertyDetailComponent,
    ForgotPasswordComponent,
    LoginSignupDialogComponent,
    ProfileComponent,
    PaymentSuccessComponent,
    TransactionsTableComponent,
    NotificationsListComponent,
    BreadcrumbsComponent,
    PageNotFoundComponent,
    SearchBarComponent,
    PaymentCheckoutComponent,
    GuestInformationPopupComponent,
    StatusCheckComponent,
    TermsAndConditionsComponent,
    ParknstaysPolicyComponent,
    CustomerInviteComponent,
    InviteStatusComponent,
    EmailInviteComponent,
    ProfileDetailsComponent,
    ProfileBookingsComponent,
    ProfileDocumentsComponent,
    ProfileRequestComponent,
    FooterComponent,
    ProfileInvoiceComponent,
    TenderNoticeComponent,
    BillComponent,
    CancelNoticeComponent,
    ChangePasswordComponent,
    RequestStatusComponent,
    DocumentStatusComponent,
    CancelDocumentComponent,
    ReviewPopUpComponent,
    VerifyEmailComponent,
    MapViewComponent,
    MapComponent,
    ToastMessageComponent,
    PayMethodComponent,
    AcceptRequestComponent,
    RejectRequestComponent,
    RequestStatusComponent,
    RequestInviteStatusComponent,
    OpenRequestPayStatusComponent,
    CheckListComponent,
    DepositeHistoryComponent,
    DepositeTypePaymentComponent,
    AccountLinkStatusComponent,
    ProfileDepositeComponent,
    RequestAssignComponent,
    RecommendContractorComponent,
    InvitefriendComponent,
    DownloadMobileAppComponent,
    RatingComponentComponent,
    StarRatingComponent,
    ButtonSpinnerComponentComponent,
    RentalApplicationComponent,
    CapitalizePipe,
    DateChecker,
    CheckRepeatName,
    AppNoDataComponent,
    AlertOverlayComponent,
    ChatComponent,
    TextareaAutoresizeDirective,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RatingModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    IvyCarouselModule,
    LayoutModule,
    MatFormFieldModule,
    LightboxModule,
    NgOtpInputModule,
    NgxPaginationModule,
    FormsModule,
    LoginSocialModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxShimmerLoadingModule,
    NgxSliderModule,
    MatRadioModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyB-s5MrK-QcKMaYhqSb7BxWd3kIx31rFKM",
    }),
    CookieModule.forRoot(),
  ],
  entryComponents: [PaymentSuccessComponent, GuestInformationPopupComponent],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    PropertySearchComponent,
    DashboardComponent,
    CurrencyPipe
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

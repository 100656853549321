<div
  class="sign_in_up_container"
  [ngClass]="displayPanel !== 'login' && 'sign_up_div_extend'"
>
  <div class="login_container">
    <div
      class="image_conteiner align_center mb_30"
      *ngIf="displayPanel === 'login'"
    >
      <img src="./assets/newLogo/newLogoText.svg" alt="icon" />
    </div>
    <div class="d_flex_space_align mb_40" *ngIf="displayPanel !== 'login'">
      <img src="./assets/newLogo/newLogoText.svg" alt="icon" />
      <p class="login_tag ft_14 ft_family">
        Already have an account ?
        <span (click)="clearData(displayPanel)">Log in</span>
      </p>
    </div>
    <div class="content_container">
      <h2 class="ft_20 ft_500 ft_family mb_30">
        {{ displayPanel === "login" ? "Log in" : "Sign up" }}
      </h2>
    </div>
    <div class="sign_in" *ngIf="displayPanel === 'login'">
      <div class="input_container mb_20">
        <p class="ft_14 ft_400 ft_family">Email*</p>
        <input
          class="ft_14"
          type="email"
          id="email"
          [(ngModel)]="email"
          name="email"
        />
      </div>
      <div class="input_container mb_16">
        <p class="ft_14 ft_400 ft_family">Password*</p>
        <input
          [type]="pHide ? 'password' : 'text'"
          class="ft_14"
          id="password"
          [(ngModel)]="password"
          name="password"
          (keyup.enter)="onLoginClick()"
        />
        <div class="pass-icon" (click)="pHide = !pHide">
          <img
            src="../../../../assets/newIcons/show.svg"
            alt=""
            *ngIf="pHide"
            alt="icon"
          />
          <img
            src="../../../../assets/newIcons/hide.svg"
            alt=""
            *ngIf="!pHide"
            alt="icon"
          />
        </div>
      </div>
      <div class="forget_password_div mb_20">
        <p class="ft_family ft_12 ft_400 cursor_pointer" (click)="openForget()">
          Forgot Password ?
        </p>
      </div>
    </div>
    <div class="sign_up_div" *ngIf="displayPanel !== 'login'">
      <div class="d_flex_space_align">
        <div class="input_container mb_20 width_specifier">
          <p class="ft_14 ft_400 ft_family">First Name*</p>
          <input
            type="text"
            id="firstName"
            [(ngModel)]="firstName"
            name="firstName"
            style="padding-left: 10px"
          />
        </div>
        <div class="input_container mb_20 width_specifier">
          <p class="ft_14 ft_400 ft_family">Last Name*</p>
          <input
            type="text"
            id="lastName"
            [(ngModel)]="lastName"
            name="lastName"
            style="padding-left: 10px"
          />
        </div>
      </div>
      <div class="d_flex_space_align">
        <div class="input_container mb_20 width_specifier">
          <p class="ft_14 ft_400 ft_family">Email*</p>
          <input type="email" id="email" [(ngModel)]="sEmail" name="email" />
        </div>
        <div class="input_container mb_20 width_specifier">
          <p class="ft_14 ft_400 ft_family">Mobile Number*</p>
          <input
            type="text"
            id="mobile"
            [ngModel]="mNum"
            (ngModelChange)="handleOnInputChange($event)"
            maxlength="16"
            name="mobile"
            type="Phone"
            style="padding-left: 10px"
          />
        </div>
      </div>
      <div class="d_flex_space_align">
        <div class="input_container mb_16 width_specifier">
          <p class="ft_14 ft_400 ft_family">Password*</p>
          <input
            [type]="pHide ? 'password' : 'text'"
            class="ft_14"
            id="password"
            [(ngModel)]="pass"
            name="password"
          />
          <div class="pass-icon" (click)="pHide = !pHide">
            <img
              src="../../../../assets/newIcons/show.svg"
              alt="icon"
              *ngIf="pHide"
            />
            <img
              src="../../../../assets/newIcons/hide.svg"
              alt="icon"
              *ngIf="!pHide"
            />
          </div>
        </div>
        <div class="input_container mb_16 width_specifier">
          <p class="ft_14 ft_400 ft_family">Confirm Password*</p>
          <input
            [type]="cnfPHide ? 'password' : 'text'"
            id="cngPassword"
            [(ngModel)]="cnfPass"
            name="cngPassword"
            (keyup.enter)="onSignUpClick()"
            style="padding-left: 10px"
          />
          <div class="pass-icon" (click)="cnfPHide = !cnfPHide">
            <img
              src="../../../../assets/newIcons/show.svg"
              alt="icon"
              *ngIf="cnfPHide"
            />
            <img
              src="../../../../assets/newIcons/hide.svg"
              alt="icon"
              *ngIf="!cnfPHide"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="terms_and_condtions_div mb_30 d_flex">
      <mat-checkbox
        class="checkBox mr_10"
        [(ngModel)]="checked"
        (ngModelChange)="updateCheckBox($event)"
      >
      </mat-checkbox>
      <p class="checkBox">
        <span class="ft_14 ft_family ft_400">
          By clicking Log in, you agree to our
          <a
            class="ft_14 ft_family ft_400"
            style="cursor: pointer"
            (click)="termsCondtions('terms')"
            class="pointer"
            >Terms
          </a>
          ,
          <a style="cursor: pointer" (click)="termsCondtions('privacy')"
            >Policy</a
          >
          and also to receive text messages from Parknstays.
        </span>
      </p>
    </div>
    <div class="btn-div mb_30" *ngIf="displayPanel === 'login'">
      <button-spinner
      [showSpinner]="saveSpinner"
      childClass="login_button"
        mat-flat-button
        (click)="onLoginClick()"
      >
        Log in
      </button-spinner>
    </div>
    <div class="mb_30" *ngIf="displayPanel !== 'login'">
      <!-- <button
        class="ft_family ft_500 ft_18"
        mat-flat-button
        (click)="onSignUpClick()"
      >
        Create Account
      </button> -->
      <button-spinner
      [showSpinner]="saveSpinner"
      childClass="ft_family ft_500 ft_18 sign_up_button"
      mat-flat-button
      (click)="onSignUpClick()"
    >
      Create Account
    </button-spinner>
    </div>
    <div class="mb_30" *ngIf="displayPanel === 'login'">
      <p class="divider">OR</p>
    </div>
    <div class="sign_in_options mb_30" *ngIf="displayPanel === 'login'">
      <button
        mat-stroked-button
        class="individual_sign_in"
        (click)="socialSignIn('google')"
      >
        <img src="../../../../assets/newIcons/google.svg" alt="icon" />
      </button>

      <button
        mat-stroked-button
        class="individual_sign_in"
        (click)="socialSignIn('facebook')"
      >
        <img src="../../../../assets/newIcons/fb.svg" alt="icon" />
      </button>
    </div>
    <p class="align_center" *ngIf="displayPanel === 'login'">
      <span class="ft_14 f_400 ft_family"> Don’t have account ? </span>
      <span
        class="pointer sub_text_green ft_14 ft_500 ft_family cursor_pointer"
        (click)="clearData(displayPanel)"
      >
        Sign Up
      </span>
    </p>
  </div>
</div>

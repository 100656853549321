 <div class="button_header">
     <button class="starButton"  disableRipple mat-icon-button [color]="color"
        *ngFor="let ratingId of ratingArr;let i = index" [id]="'star_'+i"
        (click)="onClick(i+1)">
        <mat-icon>
            {{showIcon(i)}}
        </mat-icon>
    </button>
 </div>


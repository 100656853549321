import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-mobile-app',
  templateUrl: './download-mobile-app.component.html',
  styleUrls: ['./download-mobile-app.component.css']
})
export class DownloadMobileAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onAppStoreClick() {
    window.location.href = "https://apps.apple.com/gb/app/parknstays-tenant/id1664343622"
  }

  onPlayStoreClick() {
    window.location.href = "https://play.google.com/store/apps/details?id=com.parknstays.tenant"
  }

}
